import { getMeasuresPerformance } from '../services/practicePerformanceApi';
import { fnGetPerformanceBarCSSClass } from '@/helper/commonFunction';
import {
    IS_DATA_IN_COMPLETE_REQUIRED,
    PERFORMANCE_SYSTEM_FLAG,
    DISPLAY_PR_BENCHMARK,
    ENTITY_COMPARISON_FLAG,
    FETCH_ED
} from '@/helper/constants';
import i18next from 'i18next';

export const MEASURE_PERFORMANCE_REQUEST = 'MEASURE_PERFORMANCE_REQUEST';
export const MEASURE_PERFORMANCE_RECEIVE = 'MEASURE_PERFORMANCE_RECEIVE';
export const MEASURE_PERFORMANCE_FAILURE = 'MEASURE_PERFORMANCE_FAILURE';

const requestMeasurePerformance = () => ({
    type: MEASURE_PERFORMANCE_REQUEST
});

const receiveMeasurePerformance = payload => ({
    type: MEASURE_PERFORMANCE_RECEIVE,
    payload
});

const failureMeasurePerformance = () => ({
    type: MEASURE_PERFORMANCE_FAILURE
});

export const getPerformanceData = params => {
    return dispatch => {
        let measurePerformance;
        dispatch(requestMeasurePerformance());
        let inputObj = {
            input: {
                EntityName: params.EntityName,
                EntityId: params.EntityId,
                ParentEntityId: params.ParentEntityId,
                ParentEntityName: params.ParentEntityName,
                DurationFrom: params.DurationFrom,
                DurationTo: params.DurationTo,
                Flag: params.Flag,
                MeasureId: params.MeasureId,
                Unit: params.Unit,
                IsPatientSpecific: params.IsPatientSpecific,
                IsDataIncompleteRequired: IS_DATA_IN_COMPLETE_REQUIRED,
                System: PERFORMANCE_SYSTEM_FLAG,
                EntityComparisonFlag: ENTITY_COMPARISON_FLAG
            }
        };
        if (FETCH_ED) {
            inputObj.input.EDMeasureType = params.EDMeasureType ? params.EDMeasureType : 0;
        }
        getMeasuresPerformance(inputObj)
            .then(json => {
                let returnValue = json.data.getMeasurePerformanceAverage;
                measurePerformance = {
                    performanceData: {
                        performanceText: i18next.t('performanceContainer.label.performanceText'),
                        performance: returnValue.EntityAverage,
                        performancePosition: i18next.t(
                            'performanceContainer.performancePosition.left'
                        ),
                        benchMark: [
                            {
                                label: i18next.t('performanceContainer.label.registryAvg'), //'Registry Average',
                                data: returnValue.RegistryAverage,
                                position: i18next.t(
                                    'performanceContainer.performancePosition.above'
                                ),
                                colorcode: ''
                            },
                            {
                                label: i18next.t(
                                    'performanceContainer.label.benchMarkRegistryAverage'
                                ), //'Registry BenchMark',
                                data: returnValue.RegistryBenchmark,
                                position: i18next.t(
                                    'performanceContainer.performancePosition.above'
                                ),
                                colorcode: ''
                            },
                            {
                                label:
                                    DISPLAY_PR_BENCHMARK === 'CMS'
                                        ? i18next.t(
                                              'performanceContainer.label.benchMarkCMSBenchMark'
                                          )
                                        : i18next.t('performanceContainer.label.benchMarkRegistry'),
                                data:
                                    DISPLAY_PR_BENCHMARK === 'CMS'
                                        ? returnValue.CMSBenchmark
                                        : returnValue.RegistryBenchmark,
                                position: i18next.t(
                                    'performanceContainer.performancePosition.below'
                                ),
                                colorcode: ''
                            }
                        ],
                        colorcode: fnGetPerformanceBarCSSClass(returnValue.EntityComparison),
                        actualResponse: returnValue
                    }
                };
                dispatch(receiveMeasurePerformance(measurePerformance));
            })
            .catch(ex => {
                dispatch(failureMeasurePerformance());
            });
    };
};
