import { getActionTypes, getReducer } from '@/utils/redux';

import {
    getEntityStatusApi,
    getModuleStatusApi,
    updateEntityStatusApi
} from '@/redux/services/entityModuleStatusApi';

// Types
export const GetEntityStatusTypes = getActionTypes('GET_ENTITY_STATUS');
export const GetModuleStatusTypes = getActionTypes('GET_MODULE_STATUS');
export const UpdateEntityStatusTypes = getActionTypes('UPDATE_ENTITY_STATUS');

export const getEntityStatus = userID => ({
    types: GetEntityStatusTypes,
    callAPI: () => getEntityStatusApi(userID)
});

export const getModuleStatus = () => ({
    types: GetModuleStatusTypes,
    callAPI: () => getModuleStatusApi()
});

export const updateEntityStatus = requestObj => ({
    types: UpdateEntityStatusTypes,
    callAPI: () => updateEntityStatusApi(requestObj),
    handleAction: async ({ type, payload, store }) => {
        switch (type) {
            case UpdateEntityStatusTypes.SUCCESS: {
                const EntityStatus = store.getState().EntityModuleStatus.EntityStatus;
                EntityStatus.push(requestObj);
                store.dispatch({ type, EntityStatus });
                break;
            }
            default:
                break;
        }
    }
});

const initialState = {
    isLoading: true,
    EntityStatus: [],
    ModuleStatus: []
};

// Reducer
export default getReducer(initialState, {
    [GetEntityStatusTypes.SUCCESS]: (state, { payload }) => ({
        ...state,
        isLoading: false,
        EntityStatus: payload.getEntityAttributess
    }),
    [GetModuleStatusTypes.SUCCESS]: (state, { payload }) => ({
        ...state,
        ModuleStatus: payload.getModuleAttributess
    }),
    [UpdateEntityStatusTypes.SUCCESS]: (state, { payload }) => ({
        ...state
    })
});
