import {
    DELETE_MEASURE_QUESTIONNAIRE,
    SAVE_WEBTOOL_FORM,
    DELETE_ALL_MEASURE_QUESTIONNAIRE
} from '../../graphql/mutation';
import { GET_DISPLAY_MEASURE_NAMES, GET_MEASURES_FOR_WEBTOOL } from '../../graphql/query';

import APIHelper from '@/helper/apihelper';
import { getJwt } from '../../utils/jwtUtils';

export const getMeasuresList = (params,signal) => {
    const api = new APIHelper();
    let requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Action: api.Actions.View,
            Authorization: getJwt()
        }),
        body: JSON.stringify({
            query: GET_MEASURES_FOR_WEBTOOL,
            variables: params
        })
    };
    if(signal){
        requestOptions = {...requestOptions,signal};
    }
    return fetch(api.Resources.Webtool, requestOptions);
};

export const saveWebtoolAnswers = params => {
    const api = new APIHelper();
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Action: api.Actions.Create,
            Authorization: getJwt()
        }),
        body: JSON.stringify({
            query: SAVE_WEBTOOL_FORM,
            variables: params
        })
    };

    return fetch(api.Resources.Webtool, requestOptions);
};

export const getDisplayNamesForMeasures = (params,signal) => {
    const api = new APIHelper();
    let requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Action: api.Actions.View,
            Authorization: getJwt()
        }),
        body: JSON.stringify({
            query: GET_DISPLAY_MEASURE_NAMES,
            variables: params
        })
    };
    if(signal){
        requestOptions = {...requestOptions,signal};
    }
    return fetch(api.Resources.Measure, requestOptions);
};

export const deleteMeasureQuestionnaireApi = params => {
    const api = new APIHelper();
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Action: api.Actions.Delete,
            Authorization: getJwt()
        }),
        body: JSON.stringify({
            query: DELETE_MEASURE_QUESTIONNAIRE,
            variables: params
        })
    };

    return fetch(api.Resources.Webtool, requestOptions);
};

export const deleteAllMeasureQuestionnaireApi = params => {
    const api = new APIHelper();
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Action: api.Actions.Delete,
            Authorization: getJwt()
        }),
        body: JSON.stringify({
            query: DELETE_ALL_MEASURE_QUESTIONNAIRE,
            variables: params
        })
    };

    return fetch(api.Resources.Webtool, requestOptions);
};
