import { getCalendar } from '../services/visitApi';
import { getPractices } from '../services/QualityWebtoolMeasureService';
import { getAllFiles } from '../services/measureBulkUploadApi';
import _ from 'lodash';
import { handleUnauthorizedRequest } from '@/containers/Webtool/webtoolUtils';
// import moment from 'moment';

/**
 * THIS SECTION USED GET YEARS
 */
export const MEASURE_BULK_UPLOAD_YEAR_FAILURE = 'MEASURE_BULK_UPLOAD_YEAR_FAILURE';
export const MEASURE_BULK_UPLOAD_YEAR_REQUEST = 'MEASURE_BULK_UPLOAD_YEAR_REQUEST';
export const MEASURE_BULK_UPLOAD_YEAR_RECEIVE = 'MEASURE_BULK_UPLOAD_YEAR_RECEIVE';

const failureVisitYear = () => ({
    type: MEASURE_BULK_UPLOAD_YEAR_FAILURE
});

const requestVisitYear = () => ({
    type: MEASURE_BULK_UPLOAD_YEAR_REQUEST
});

const receiveVisitYear = payload => ({
    type: MEASURE_BULK_UPLOAD_YEAR_RECEIVE,
    payload
});

export const getYearsData = (signal) => {
    return async dispatch => {
        dispatch(requestVisitYear());
        try {
            let resp = await getCalendar(signal);
            handleUnauthorizedRequest(resp.status);
            if (resp.ok) {
                let parsedResponse = await resp.json();
                const calendarData = parsedResponse.data.getCalendars;
                let year = [];
                let _year = [];
                // eslint-disable-next-line no-unused-vars
                let duration = [];
                // eslint-disable-next-line no-unused-vars
                let _duration = [];

                _.each(calendarData, function(obj) {
                    if (_year.indexOf(obj.year) === -1) {
                        _year.push(obj.year);
                        year.push({
                            id: obj.id,
                            text: obj.year,
                            value: obj.id
                        });
                    }
                });

                dispatch(receiveVisitYear(year));
            } else {
                throw new Error();
            }
        } catch (ex) {
            dispatch(failureVisitYear());
        }
    };
};

/**
 * THIS SECTION USED GET PRACTICE
 */
export const MEASURE_BULK_UPLOAD_PRACTICES_REQUEST = 'MEASURE_BULK_UPLOAD_PRACTICES_REQUEST';
export const MEASURE_BULK_UPLOAD_PRACTICES_RECEIVE = 'MEASURE_BULK_UPLOAD_PRACTICES_RECEIVE';
export const MEASURE_BULK_UPLOAD_PRACTICES_FAILURE = 'MEASURE_BULK_UPLOAD_PRACTICES_FAILURE';

const requestPractices = () => ({
    type: MEASURE_BULK_UPLOAD_PRACTICES_REQUEST
});

const receivePractices = payload => {
    return {
        type: MEASURE_BULK_UPLOAD_PRACTICES_RECEIVE,
        payload
    };
};

const failurePractices = () => ({
    type: MEASURE_BULK_UPLOAD_PRACTICES_FAILURE
});

export const getPracticeData = (param, signal) => {
    return async dispatch => {
        dispatch(requestPractices());
        try {
            let resp = await getPractices(param, signal);
            handleUnauthorizedRequest(resp.status);
            if (resp.ok) {
                let practiceData = await resp.json();

                dispatch(receivePractices(practiceData.data.getPractices));
            } else {
                throw new Error();
            }
        } catch (ex) {
            dispatch(failurePractices());
        }
    };
};

export const FILES_BY_ENTITY_REQUEST = 'FILES_BY_ENTITY_REQUEST';
export const FILES_BY_ENTITY_RECEIVE = 'FILES_BY_ENTITY_RECEIVE';
export const FILES_BY_ENTITY_FAILURE = 'FILES_BY_ENTITY_FAILURE';

const requestFileInfo = () => ({
    type: FILES_BY_ENTITY_REQUEST
});

const receiveFileInfo = payload => ({
    type: FILES_BY_ENTITY_RECEIVE,
    payload
});

const failureFileInfo = () => ({
    type: FILES_BY_ENTITY_FAILURE
});

export const getAllFilesData = (params, signal) => {
    return async dispatch => {
        dispatch(requestFileInfo());
        try {
            let fileList = await getAllFiles(params, signal);
            handleUnauthorizedRequest(fileList.status);
            if (fileList.ok) {
                let parsedfileList = await fileList.json();
                dispatch(receiveFileInfo(parsedfileList.data.getMeasureFileList));
            } else {
                throw new Error();
            }
        } catch (ex) {
            dispatch(failureFileInfo());
        }
    };
};