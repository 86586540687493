import { getActionCreator, getActionType, getActionTypes, getReducer } from '@/utils/redux';

import { userPermissionsApi } from '@/redux/services/userdetailApi';

// Types
export const ResetReduxStateType = getActionType('USER_LOGOUT');
export const UserPermissionsTypes = getActionTypes('USER_PERMISSION_TYPES');
// Actions
export const resetReduxState = getActionCreator(ResetReduxStateType);

export const getUserPermissions = params => ({
    types: UserPermissionsTypes,
    callAPI: () => userPermissionsApi(params)
});

const initialState = {};

export default getReducer(initialState, {
    [UserPermissionsTypes.SUCCESS]: (state, { payload }) => {
        if (payload.resourcedetails.length > 0)
            return {
                ...state,
                [`${payload.resourcedetails[0].resource}`]: payload.resourcedetails[0]
            };
        return state;
    },

    [UserPermissionsTypes.ERROR]: () => initialState
});
