import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { RESEND_OTP_ATTEMPTS } from '@/helper/constants';
import OtpVerificationForm from '@/components/OtpVerificationForm';
import MobileRegistrationForm from '@/components/MobileRegistration';
import OtpverificationModeOptionsForm from '@/components/OtpVerificationModeOptionsForm';
import EmailSent from '@/components/EmailSent';
import { setJwt } from '@/utils/jwtUtils';
import APIHelper from '@/helper/apihelper';
import ReactGA from 'react-ga';
import { googleAnalyticsLoginEvent } from '@/helper/commonFunction';
import queryString from 'query-string';

const apiHelperInstance = new APIHelper();

class OtpVerification extends Component {
    constructor(props) {
        super(props);
        if (props.history.location.state) {
            this.state = props.history.location.state.stateName;
        } else {
            const OTPData = queryString.parse(props.location.search);
            if (Object.keys(OTPData).length) {
                const state = {
                    stateName: { ...OTPData, counter: OTPData.duration, resendOTPCount: 0 }
                };
                this.props.history.replace({
                    ...this.props.history.location,
                    state
                });
                window.location.reload();
            } else window.location.href = '/';
        }
    }

    componentDidMount() {
        let isNoMobile = !this.state.mobile;
        let heading, emaillabel, mobilelabel, codelabel, label, name, placeholder;
        let bothtext = 'mobile number and registered email address';
        let mobilelabeltext = 'mobile number';
        let emaillabeltext = 'registered email address';
        // if (!isNoMobile) {
        heading = '2 Step Verification';
        emaillabel = "This extra step shows it's really you trying to login";
        mobilelabel = `You will receive 6 digit verification code to your given ${
            this.props.history.location.state.stateName.twoFAMode === 'both'
                ? bothtext
                : this.props.history.location.state.stateName.twoFAMode === 'email'
                ? emaillabeltext
                : mobilelabeltext
        }`;
        codelabel = 'Enter code';
        label = 'Enter code';
        placeholder = 'Enter code';

        this.setState(
            {
                ...this.state,
                isNoMobile,
                heading,
                emaillabel,
                mobilelabel,
                codelabel,
                label,
                name,
                placeholder,
                resendOTPCount: 1,
                isAccountBlock: this.props.history.location.state.stateName.isAccountBlock,
                isMobileRegisterShow:
                    (this.props.history.location.state.stateName.twoFAMode === 'mobile' &&
                        isNoMobile) ||
                    this.props.history.location.state.stateName.isMobileRegisterShow,
                isOTPVerificationShow:
                    (this.props.history.location.state.stateName.twoFAMode === 'mobile' &&
                        !isNoMobile) ||
                    this.props.history.location.state.stateName.twoFAMode === 'email' ||
                    this.props.history.location.state.stateName.twoFAMode === 'both' ||
                    this.props.history.location.state.stateName.isOTPVerificationShow,
                selectedPreference:
                    this.props.history.location.state.stateName.twoFAMode === 'mobile'
                        ? 'mobile'
                        : this.props.history.location.state.stateName.twoFAMode === 'email'
                        ? 'email'
                        : this.props.history.location.state.stateName.selectedPreference,
                stopResendOTP:
                    this.props.history.location.state.stateName.resendOTPCount >=
                    RESEND_OTP_ATTEMPTS
            },
            () => {
                if (this.state.counter > 0 && this.state.isOTPVerificationShow) {
                    this.start();
                }
            }
        );
    }

    /* HANDLE WHEN OTP INPUT TEXT VALUE CHANGED :
  ================================================================ */
    otphandleChange = event => {
        const error = '';
        if (event.target.value === '' || event.target.value.length <= 6) {
            const re = /^[0-9]+$/; //^[0-9\b]+$/;
            if (re.test(event.target.value) || event.target.value === '') {
                if (event.target.value.length === 6) {
                    this.setState({ [event.target.name]: event.target.value, error }, () => {
                        this.verifyOTP();
                    });
                } else {
                    this.setState({ [event.target.name]: event.target.value, error });
                }
            }
        }
    };

    /* HANDLE WHEN AT INPUT TEXT ENTER BUTTON HIT IN OTP VERIFICATION
    ================================================================ */
    otphandleKeyUp = event => {
        if (event.keyCode === 13) {
            this.verifyOTP();
        }
    };

    /* VERIFY OTP FROM DB
    ================================================================ */
    verifyOTP = () => {
        if (this.state.counter > 0) {
            if (this.state.otp !== undefined && this.state.otp.length === 6) {
                this.setState({ ...this.state, isProgress: true });
                fetch(apiHelperInstance.Resources.OTPVerification, {
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, cors, *same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json, text/plain, */*'
                    },
                    body: JSON.stringify({
                        token: this.state.token,
                        otp: this.state.otp
                    })
                })
                    .then(res => res.json())
                    .then(res => {
                        if (res.statusCode === 1) {
                            setJwt(res.data.token, res.data.duration);
                            googleAnalyticsLoginEvent(ReactGA, res.data.useranalyticsid);
                            clearInterval(this.timerId);
                            if (res.data.type === 3) this.setState({ type: res.data.type });
                            else this.props.history.push('/layout');
                        } else if (res.statusCode === 0) {
                            const error = res.description;
                            if (res.data.IsAccountBlocked) {
                                this.setState({
                                    error,
                                    isProgress: false,
                                    isAccountBlock: true
                                });
                                const state = { ...this.props.history.location.state };
                                state.stateName.isAccountBlock = true;
                                this.props.history.replace({
                                    ...this.props.history.location,
                                    state
                                });
                                clearInterval(this.timerId);
                            } else this.setState({ error, isProgress: false });
                        }
                    })
                    .catch(() => this.props.history.push('/'));
            } else {
                const error = 'Please Enter 6 digit One Time Password';
                this.setState({ error, isProgress: false });
            }
        } else {
            const error = 'One Time Password validity expired';
            this.setState({ error });
        }
    };

    /* HANDLE WHEN RESEND OTP BUTTON CLICKED :
    ================================================================ */
    handleResendOTP = event => {
        // this.setState({ [event.target.name]: event.target.value });
        this.setState({ ...this.state, isResendOtpProgress: true });

        fetch(apiHelperInstance.Resources.OTPResend, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain, */*'
            },
            body: JSON.stringify({
                token: this.state.token
            })
        })
            .then(res => res.json())
            .then(res => {
                if (res.statusCode === 1) {
                    const error = '';
                    const otp = '';
                    const isResendOtpProgress = false;
                    if (this.state.counter === 0) {
                        this.start();
                    }

                    const state = { ...this.props.history.location.state };
                    state.stateName.resendOTPCount = state.stateName.resendOTPCount + 1;
                    state.stateName.token = res.data.token;
                    this.props.history.replace({ ...this.props.history.location, state });
                    this.setState({
                        ...res.data,
                        error,
                        otp,
                        counter: res.data.duration,
                        isResendOtpProgress,
                        resendOTPCount: this.state.resendOTPCount + 1,
                        stopResendOTP:
                            this.props.history.location.state.stateName.resendOTPCount >=
                            RESEND_OTP_ATTEMPTS
                                ? true
                                : false
                    });
                } else if (res.statusCode === 0 || res.statusCode === 3) {
                    var error = res.description;
                    const isResendOtpProgress = false;
                    this.setState({
                        error,
                        isResendOtpProgress
                    });
                }
            })
            .catch(err => {
                this.props.history.push('/');
            });
    };

    start = () => {
        this.timerId = setInterval(() => this.tick(), 1000);
    };

    tick = () => {
        const OTPData = this.state;
        if (this.state.counter > 0) {
            OTPData.counter = OTPData.counter - 1;
        } else {
            clearInterval(this.timerId);
        }
        this.setState({ OTPData });
        const state = { ...this.props.history.location.state };
        state.stateName.counter = OTPData.counter;
        this.props.history.replace({ ...this.props.history.location, state });
    };

    /* CLEAR INTERVAL WHEN COMPONENT UNMOUNT :
    ================================================================ */
    componentWillUnmount() {
        clearInterval(this.timerId);
    }

    /* HANDLE WHEN RESEND OTP BUTTON CLICKED :
    ================================================================ */
    mobilehandleChange = event => {
        const error = '';
        if (event.target.value === '' || event.target.value.length <= 12) {
            const re = /^[0-9]+$/; //^[0-9\b]+$/;
            let checkValue = event.target.value.replace(/-/g, '');
            if (re.test(checkValue) || event.target.value === '') {
                var string = event.target.value;
                string = string.replace(/(\d{3})\-?(\d{3})\-?(\d{4})/, '$1-$2-$3');

                this.setState({ [event.target.name]: string, error });
            }
        }
    };

    /* HANDLE WHEN AT INPUT TEXT ENTER BUTTON HIT IN OTP VERIFICATION
    ================================================================ */
    handleSubmitMobileKeyUp = event => {
        if (event.keyCode === 13) {
            this.handleSubmitMobile();
        }
    };

    /* HANDLE WHEN RESEND OTP BUTTON CLICKED :
    ================================================================ */
    handleSubmitMobile = event => {
        this.setState({ ...this.state, isRegisterInProgress: true });
        let mobiledigit = '';
        if (this.state.mobile) mobiledigit = this.state.mobile.replace(/-/g, '');
        if (mobiledigit.length === 10) {
            fetch(apiHelperInstance.Resources.MobileRegistration, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, cors, *same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json, text/plain, */*'
                },
                body: JSON.stringify({
                    token: this.state.token,
                    mobilenumber: '+1' + this.state.mobile.replace(/-/g, '')
                })
            })
                .then(res => res.json())
                .then(res => {
                    if (res.statusCode === 1) {
                        this.setState({
                            ...res.data,
                            isNoMobile: false,
                            isRegisterInProgress: false,
                            counter: res.data.duration,
                            resendOTPCount: 0,
                            isMobileRegisterShow: false,
                            isOTPVerificationShow: true,
                            selectedPreference: 'mobile'
                        });

                        const state = { ...this.props.history.location.state };
                        state.stateName.mobile = this.state.mobile.replace(/-/g, '');
                        state.stateName.token = res.data.token;
                        state.stateName.isOTPVerificationShow = true;
                        state.stateName.isMobileRegisterShow = false;
                        state.stateName.selectedPreference = 'mobile';
                        this.props.history.replace({ ...this.props.history.location, state });

                        this.start();
                    } else if (res.statusCode === 0) {
                        const error = res.description;
                        this.setState({ error, isRegisterInProgress: false });
                    }
                })
                .catch(err => {
                    this.props.history.push('/');
                });
        } else {
            const error = 'Please enter a valid Mobile Number';
            this.setState({ error, isRegisterInProgress: false });
        }
    };

    onSelectOption = value => {
        this.setState({ selectedPreference: value, error: '' });
    };

    onSendOTP = () => {
        this.setState({ ...this.state, isSendOTPInProgress: true });
        if (this.state.selectedPreference) {
            fetch(apiHelperInstance.Resources.GenerateOTP, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, cors, *same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json, text/plain, */*'
                },
                body: JSON.stringify({
                    token: this.state.token,
                    twoFAMode: this.state.selectedPreference
                })
            })
                .then(res => res.json())
                .then(res => {
                    if (res.statusCode === 1) {
                        const state = { ...this.props.history.location.state };
                        state.stateName.isOTPVerificationShow = true;
                        state.stateName.selectedPreference = this.state.selectedPreference;
                        state.stateName.token = res.data.token;
                        this.props.history.replace({ ...this.props.history.location, state });

                        this.setState({
                            ...res.data,
                            isNoMobile: false,
                            isSendOTPInProgress: false,
                            counter: res.data.duration,
                            resendOTPCount: 0,
                            isMobileRegisterShow: false,
                            isOTPVerificationShow: true,
                            mobilelabel:
                                this.state.selectedPreference === 'email'
                                    ? 'You will receive 6 digit verification code to your given registered email address'
                                    : 'You will receive 6 digit verification code to your given mobile number on below account'
                        });
                        this.start();
                    } else if (res.statusCode === 0) {
                        const error = res.description;
                        this.setState({ error, isSendOTPInProgress: false });
                    }
                })
                .catch(err => {
                    this.props.history.push('/');
                });
        } else {
            const error = 'Please select the option';
            this.setState({ error, isSendOTPInProgress: false });
        }
    };

    onGenerateRegisterPhoneToken = () => {
        fetch(apiHelperInstance.Resources.GenerateRegisterPhoneToken, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain, */*'
            },
            body: JSON.stringify({
                token: this.state.token
            })
        })
            .then(res => res.json())
            .then(res => {
                if (res.statusCode === 1) {
                    const state = { ...this.props.history.location.state };
                    state.stateName.isMobileRegisterShow = true;
                    state.stateName.token = res.data.token;
                    this.props.history.replace({ ...this.props.history.location, state });

                    let heading, emaillabel, mobilelabel, codelabel, label, placeholder;
                    heading = 'Mobile Number Not Registered';
                    emaillabel =
                        'You will receive 6 digit verification code to your given mobile number on below account';
                    mobilelabel = '';
                    codelabel = 'Enter here received 6 digit verification code.';
                    label = 'Enter here received 6 digit verification code.';
                    placeholder = 'Enter code';
                    this.setState({
                        ...res.data,
                        isNoMobile: false,
                        isMobileRegisterShow: true,
                        heading,
                        emaillabel,
                        mobilelabel,
                        codelabel,
                        label,
                        placeholder,
                        error: ''
                    });
                    this.start();
                } else if (res.statusCode === 0) {
                    const error = res.description;
                    this.setState({ error, generateRegisterPhoneTokenProgress: false });
                }
            })
            .catch(err => {
                this.props.history.push('/');
            });
    };

    render() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {this.state.twoFAMode === 'userpreference' &&
                        !this.state.isMobileRegisterShow &&
                        !this.state.isOTPVerificationShow && (
                            <OtpverificationModeOptionsForm
                                isNoMobile={this.state.isNoMobile}
                                {...this.state}
                                selectedPreference={this.state.selectedPreference}
                                onChange={this.onSelectOption}
                                onSendOTP={this.onSendOTP}
                                error={this.state.error}
                                onGenerateRegisterPhoneToken={this.onGenerateRegisterPhoneToken}
                            />
                        )}

                    {this.state.isMobileRegisterShow && (
                        <Grid item xs={12}>
                            <MobileRegistrationForm
                                onChange={this.mobilehandleChange}
                                onSubmitMobile={this.handleSubmitMobile}
                                onKeyUp={this.handleSubmitMobileKeyUp}
                                error={this.state.error}
                                inputValue={this.state.mobile}
                                email={this.state.email}
                                isRegisterInProgress={this.state.isRegisterInProgress}
                            />
                        </Grid>
                    )}

                    {this.state.isOTPVerificationShow && (
                        <Grid item xs={12}>
                            {this.state.type === 3 ? (
                                <EmailSent {...this.props.registered} />
                            ) : (
                                <OtpVerificationForm
                                    inputValue={this.state.otp}
                                    secleft={this.state.counter}
                                    onChange={this.otphandleChange}
                                    onKeyUp={this.otphandleKeyUp}
                                    onSubmitResendOTP={this.handleResendOTP}
                                    selectedPreference={this.state.selectedPreference}
                                    {...this.state}
                                />
                            )}
                        </Grid>
                    )}
                </Grid>
            </Grid>
        );
    }
}

/* SOME DEFAULT PROPS (REST USED COMPONENT ITSELF):
================================================================ */
OtpVerification.defaultProps = {
    registered: {
        type: {
            heading: '2 step verification is enabled on your profile',
            subheading: '',
            buttonLabel: 'Go to dashboard',
            link: 'layout'
        }
    }
};

export default OtpVerification;
