import './Login.less';
import {
    ACCOUNT_NOT_FOUND_MSG,
    CRYPTR_ASE_KEY,
    EMAIL_ADDRESS_REGEX,
    ENABLE_GOOGLE_ANALYTICS_TRACK_LOGIN_PAGE,
    GOOGLE_ANALYTICS_ACCOUNT_ID,
    IS_REGISTRY_API_AUTH_ENABLED,
    IS_SAML_AUTH_ENABLED,
    REGISTRY_FORGOT_PASSWORD_URL,
    SAML_AUTHENTICATION_ERROR_MSG,
    SAML_REDIRECTION_MSG
} from '@/helper/constants.js';
import React, { Component } from 'react';
import APIHelper from '@/helper/apihelper';
import Cryptr from 'cryptr';
import Grid from '@material-ui/core/Grid';
import Loader from '@/helper/loaders/ComponentLoader';
import LoginForm from '@/components/LoginForm';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { Redirect } from 'react-router';
import { Typography } from '@material-ui/core';
import { setJwt } from '@/utils/jwtUtils';
import { connect } from 'react-redux';
import { resetReduxState } from '@/redux/modules/userModule';
import { ResetMeasurePerformanceListAction } from '@/redux/modules/measurePerformance';
import { googleAnalyticsLoginEvent } from '@/helper/commonFunction';

const apiHelperInstance = new APIHelper();

class Login extends Component {
    constructor(props) {
        super(props);
        /* SET INITIAL STATE:
    ===================================== */
        this.state = {
            errors: {},
            loginbtnClicked: false,
            nextbtnClicked: false,
            isOTPPage: false,
            navigateToPasswordscreen: false,
            OTPData: { email: '', number: '' },
            SAMLErrorMsg: '',
            forgotPasswordbtnClicked: false,
            username: '',
            autoFocusPassword: false
        };
        localStorage.setItem('tabIndex', 0);
    }

    componentDidMount() {
        if (ENABLE_GOOGLE_ANALYTICS_TRACK_LOGIN_PAGE) {
            ReactGA.initialize(GOOGLE_ANALYTICS_ACCOUNT_ID);
            ReactGA.ga('send', 'pageview', '/login');
        }

        if (IS_SAML_AUTH_ENABLED && !IS_REGISTRY_API_AUTH_ENABLED) {
            this.props.ResetMeasurePerformanceListAction();
            this.props.resetReduxState();
            this.getSAMLLoginURL();
        }
        localStorage.removeItem('Unit');
        localStorage.removeItem('API_GATEWAY_URL');
        localStorage.removeItem('activeTabIndex');
        localStorage.removeItem('activeSearchKey');
        localStorage.removeItem('activePageIndex');
    }

    getSAMLLoginURL = async () => {
        try {
            const request = await fetch(apiHelperInstance.Resources.SAMLLogin, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json, text/plain, */*'
                }
            });
            const res = await request.json();
            if (res.statusCode === 1) {
                const { location } = this.props;
                if (location.state && location.state.from)
                    window.sessionStorage.setItem(
                        'requestedpath',
                        JSON.stringify(location.state.from)
                    );
                window.location.assign(res.data.url);
            } else {
                this.setState({
                    SAMLErrorMsg: SAML_AUTHENTICATION_ERROR_MSG
                });
            }
        } catch (error) {
            //console.log(error);
            this.setState({ SAMLErrorMsg: SAML_AUTHENTICATION_ERROR_MSG });
        }
    };

    /* HANDLE WHEN INPUT TEXT VALUE CHANGED (E.G. USERNAME OR PASSWORD):
  ================================================================ */
    handleChange(field, value) {
        if (
            this.state.errors[this.props.inputprops.Userid.name] ||
            this.state.errors[this.props.passwordProps.name] ||
            this.state.errors.callback
        ) {
            const err = this.state.errors;
            err[field] = '';
            err.callback = '';
            this.setState({ errors: err });
        }
        if (this.state.isPasswordExpired) {
            this.setState({ isPasswordExpired: false });
        }
        this.setState({ [field]: value });
    }

    validateFormUserName() {
        const errors = {};
        let formIsValid = true;
        if (!this.state[this.props.inputprops.Userid.name]) {
            formIsValid = false;
            errors[this.props.inputprops.Userid.name] =
                this.props.inputprops.Userid.name + ' is required';
        } else if (typeof this.state[this.props.inputprops.Userid.name] !== 'undefined') {
            let regex = /^.{4,}$/;
            let message = `${this.props.inputprops.Userid.name} is required`;
            if (this.props.inputprops.Userid.validationType === 'EMAIL') {
                regex = EMAIL_ADDRESS_REGEX; ///^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;
                message = 'Enter correct Email';
            } else if (this.props.inputprops.Userid.validationType === 'ALPHANUMERIC') {
                regex = /^[a-zA-Z0-9._@]{4,40}$/;
                message = '0-9,a-z,A-Z,.,_,@ are allowed';
            } else if (this.props.inputprops.Userid.validationType === 'NUMBERIC') {
                regex = /^[0-9]{4,25}$/;
                message = 'Enter only numbers';
            } else if (this.props.inputprops.Userid.validationType === 'CUSTOM') {
                regex = /^.{4,}$/;
                message = 'Enter correct ' + this.props.Userid.name;
            }
            if (!this.state[this.props.inputprops.Userid.name].match(regex)) {
                formIsValid = false;
                errors[this.props.inputprops.Userid.name] = message;
            }
        }

        this.setState({ errors });
        return formIsValid;
    }

    /* HANDLE VALIDATION (USERNAME: REQUIRED, CORRECT FORMAT , PASSWORD:REQUIRED, COMPLEX):
  ================================================================ */
    validateForm() {
        const errors = {};
        let formIsValid = true;
        if (!this.state[this.props.passwordProps.name]) {
            formIsValid = false;
            errors[this.props.passwordProps.name] = this.props.passwordProps.name + ' is required';
        }

        if (!this.state[this.props.inputprops.Userid.name]) {
            formIsValid = false;
            errors[this.props.inputprops.Userid.name] =
                this.props.inputprops.Userid.name + ' is required';
        } else if (typeof this.state[this.props.inputprops.Userid.name] !== 'undefined') {
            let regex = /^.{4,}$/;
            let message = `${this.props.inputprops.Userid.name} is required`;
            if (this.props.inputprops.Userid.validationType === 'EMAIL') {
                regex = EMAIL_ADDRESS_REGEX; // /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;
                message = 'Enter correct Email';
            } else if (this.props.inputprops.Userid.validationType === 'ALPHANUMERIC') {
                regex = /^[a-zA-Z0-9._@]{4,40}$/;
                message = '0-9,a-z,A-Z,.,_,@ are allowed';
            } else if (this.props.inputprops.Userid.validationType === 'NUMBERIC') {
                regex = /^[0-9]{4,25}$/;
                message = 'Enter only numbers';
            } else if (this.props.inputprops.Userid.validationType === 'CUSTOM') {
                regex = /^.{4,}$/;
                message = 'Enter correct ' + this.props.Userid.name;
            }
            if (!this.state[this.props.inputprops.Userid.name].match(regex)) {
                formIsValid = false;
                errors[this.props.inputprops.Userid.name] = message;
            }
        }

        this.setState({ errors });
        return formIsValid;
    }

    /* HANDLE WHEN AT INPUT TEXT ENTER BUTTON HIT
  ================================================================ */
    handleKeyUp(event) {
        if (event.keyCode === 13) this.handleSubmit(event);
    }

    onUsernameKeyUp(event) {
        if (event.keyCode === 13) this.onNextBtnClick(event);
    }

    /* HANDLE SUBMIT (LOGIN) AUTHENTICATE API:
  ================================================================ */
    async handleSubmit(e) {
        e.preventDefault();
        this.setState({ submitted: true });
        const { username, password } = this.state;
        if (this.validateForm()) {
            this.setState({ loginbtnClicked: true });
            try {
                const cryptr = new Cryptr(CRYPTR_ASE_KEY);
                const encryptedPassword = cryptr.encrypt(password);
                const request = await fetch(apiHelperInstance.Resources.Authenticate, {
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, cors, *same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json, text/plain, */*'
                    },
                    body: JSON.stringify({
                        loginname: username,
                        password: encryptedPassword
                    })
                });
                const res = await request.json();
                if (res.statusCode === 1) {
                    localStorage.setItem('ssologin', false);
                    this.props.ResetMeasurePerformanceListAction();
                    this.props.resetReduxState();
                    localStorage.setItem('tabIndex', 0);
                    localStorage.setItem('practiceProfileTabIndex', 0);
                    if (res.data.is2FAEnabled) {
                        const OTPData = {
                            ...res.data,
                            counter: res.data.duration,
                            resendOTPCount: 0
                        };
                        const { location } = this.props;
                        if (location.state && location.state.from)
                            window.sessionStorage.setItem(
                                'requestedpath',
                                JSON.stringify(location.state.from)
                            );
                        this.setState({ isOTPPage: true, OTPData });
                    } else {
                        setJwt(res.data.token, res.data.duration);
                        const { location } = this.props;
                        googleAnalyticsLoginEvent(ReactGA, res.data.useranalyticsid);
                        if (location.state && location.state.from) {
                            window.sessionStorage.setItem(
                                'requestedpath',
                                JSON.stringify(location.state.from)
                            );
                            this.props.history.push(location.state.from);
                        } else {
                            this.props.history.push('/layout');
                        }
                    }
                } else if (res.statusCode === 7) {
                    this.setState({
                        isPasswordExpired: true,
                        passwordExpiredLink: res.data.id,
                        loginbtnClicked: false
                    });
                } else if (res.statusCode === 0) {
                    const errors = {};
                    errors.callback = res.description;
                    this.props.passwordProps.isReset = true;
                    this.setState({ errors, password: '', loginbtnClicked: false });
                    this.props.passwordProps.isReset = false;
                }
            } catch (error) {
                const errors = {};
                errors.callback = error.message;
                this.setState({ errors, loginbtnClicked: false });
            }
        }
    }

    onNextBtnClick = e => {
        e.preventDefault();
        if (this.validateFormUserName()) {
            this.setState({ nextbtnClicked: true });
            this.validateUsername();
        }
    };

    validateUsername = async () => {
        try {
            const { username } = this.state;
            const request = await fetch(apiHelperInstance.Resources.SAMLLogin, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json, text/plain, */*'
                },
                body: JSON.stringify({ loginname: username })
            });
            const res = await request.json();
            if (res.statusCode === 1) {
                if (res.data.url !== '') {
                    const { location } = this.props;
                    if (location.state && location.state.from)
                        window.sessionStorage.setItem(
                            'requestedpath',
                            JSON.stringify(location.state.from)
                        );
                    window.location.assign(res.data.url);
                } else this.setState({ navigateToPasswordscreen: true, autoFocusPassword: true });
            } else {
                this.setState({ navigateToPasswordscreen: true, autoFocusPassword: true });
            }
        } catch (error) {
            const errors = {};
            errors.callback = error.message;
            this.setState({ errors, loginbtnClicked: false });
        }
    };

    forgotPasswordHandler = async e => {
        if (IS_REGISTRY_API_AUTH_ENABLED) {
            if (this.validateFormUserName()) {
                this.setState({ forgotPasswordbtnClicked: true });
                try {
                    const { username } = this.state;
                    const request = await fetch(apiHelperInstance.Resources.GetUserType, {
                        method: 'POST',
                        mode: 'cors',
                        cache: 'no-cache',
                        headers: {
                            'Content-Type': 'application/json',
                            Accept: 'application/json, text/plain, */*'
                        },
                        body: JSON.stringify({ loginname: username })
                    });
                    const res = await request.json();
                    if (res.statusCode === 1) {
                        if (parseInt(res.data.userauthtypeid) === 2) {
                            window.location.assign(REGISTRY_FORGOT_PASSWORD_URL);
                        } else {
                            this.props.history.push('/forgotpassword');
                        }
                    } else if (res.statusCode === 3) {
                        const errors = {};
                        errors.callback = ACCOUNT_NOT_FOUND_MSG;
                        this.setState({
                            errors,
                            forgotPasswordbtnClicked: false
                        });
                    }
                } catch (error) {
                    const errors = {};
                    errors.callback = error.message;
                    this.setState({ errors, forgotPasswordbtnClicked: false });
                }
            }
        } else {
            this.props.history.push('/forgotpassword');
        }
    };

    redirectbackToLogin = e => {
        const errors = {};
        errors.callback = '';
        this.setState({ navigateToPasswordscreen: false, nextbtnClicked: false, errors: errors });
    };

    handleExpiredPassword = e => {
        // window.location.href = this.state.passwordExpiredLink;
        this.props.history.push('/resetpassword?id=' + this.state.passwordExpiredLink);
    };

    /* HANDLE REMEMBER ME CHECKBOX CHANGED:
  ================================================================ */
    // handleRememberMe(field, obj) {}

    render() {
        if (IS_SAML_AUTH_ENABLED && !IS_REGISTRY_API_AUTH_ENABLED) {
            return (
                <React.Fragment>
                    {<Loader />}{' '}
                    <Typography variant='subtitle1' gutterBottom>
                        {SAML_REDIRECTION_MSG}
                    </Typography>
                </React.Fragment>
            );
        }
        const {
            SAMLErrorMsg,
            nextbtnClicked,
            navigateToPasswordscreen,
            forgotPasswordbtnClicked
        } = this.state;
        if (SAMLErrorMsg !== '') {
            return (
                <React.Fragment>
                    {' '}
                    <Typography variant='subtitle1' gutterBottom>
                        {SAMLErrorMsg}
                    </Typography>
                </React.Fragment>
            );
        }
        if (this.state.isOTPPage) {
            return (
                <Redirect
                    to={{
                        pathname: '/otpverification',
                        state: { stateName: this.state.OTPData }
                    }}
                />
            );
        }
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <LoginForm
                        {...this.props}
                        onChange={(field, value) => this.handleChange(field, value)}
                        errors={this.state.errors}
                        onSubmit={event => this.handleSubmit(event)}
                        onKeyUp={event => this.handleKeyUp(event)}
                        onUsernameKeyUp={event => this.onUsernameKeyUp(event)}
                        // onhandleRememberMe={(event, value) =>
                        //   this.handleRememberMe(event, value)
                        // }
                        username={this.state.username}
                        forgotPasswordbtnClicked={forgotPasswordbtnClicked}
                        forgotPasswordHandler={event => this.forgotPasswordHandler(event)}
                        navigateToPasswordscreen={navigateToPasswordscreen}
                        nextbtnClicked={nextbtnClicked}
                        onNextBtnClick={event => this.onNextBtnClick(event)}
                        loginbtnClicked={this.state.loginbtnClicked}
                        redirectbackToLogin={event => this.redirectbackToLogin(event)}
                        autoFocusPassword={this.state.autoFocusPassword}
                        isPasswordExpired={this.state.isPasswordExpired}
                        onExpiredPassword={this.handleExpiredPassword}
                    />
                </Grid>
            </Grid>
        );
    }
}
/* DEFAULT PROPS PASSED TO COMPONENT:
================================================================ */
Login.defaultProps = {
    inputprops: {
        heading: 'Login To Your Account',
        Userid: {
            id: 'username',
            name: 'username',
            type: 'text',
            validationType: 'ALPHANUMERIC',
            LabelName: 'Username',
            placeholder: 'Enter username'
        },
        Password: {
            LabelName: 'Password'
        },
        loginButtonValue: 'Login',
        RegisterLink: {
            label: 'Register',
            link: 'register'
        },
        ForgotPasswordLink: {
            label: 'Forgot Password',
            link: 'forgotpassword'
        },
        Visibility: {
            rememberMe: false,
            forgotPassword: true,
            registerLink: false
        }
    },
    onChange: () => {
        // console.log(('onHandleChnage is executed:' + event + ' : ' + value);
    },
    onSubmit: e => {
        e.stopPropagation();
        // console.log(('onHandleFormSubmit is executed');
    },
    onChangeRememberMe: e => {
        e.stopPropagation();
        // console.log(('RememberMe checkbox checked : ' + v.checked);
    },

    passwordProps: {
        name: 'password',
        placeholder: 'Enter password',
        label: 'Password',
        minLength: 4,
        maxLength: 10,
        readOnly: false,
        disabled: false,
        required: true,
        showToggle: true,
        isReset: false
    },

    errors: {
        id: '',
        password: ''
    }
};

/* PROP TYPES
  ================================================================ */
Login.propTypes = {
    passwordProps: PropTypes.shape({
        name: PropTypes.string,
        placeholder: PropTypes.string,
        label: PropTypes.string,
        minLength: PropTypes.number,
        maxLength: PropTypes.number,
        readOnly: PropTypes.bool,
        disabled: PropTypes.bool,
        required: PropTypes.bool,
        showToggle: PropTypes.bool,
        isReset: PropTypes.bool
    }),
    inputprops: PropTypes.shape({
        heading: PropTypes.string,
        Userid: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            type: PropTypes.string,
            validationType: PropTypes.string,
            labelName: PropTypes.string,
            placeholder: PropTypes.string
        }),
        Password: PropTypes.shape({
            LabelName: PropTypes.string
        }),
        loginButtonValue: PropTypes.string,
        RegisterLink: PropTypes.shape({
            label: PropTypes.string,
            link: PropTypes.string
        }),
        ForgotPasswordLink: PropTypes.shape({
            label: PropTypes.string,
            link: PropTypes.string
        }),
        Visibility: PropTypes.shape({
            rememberMe: PropTypes.bool,
            forgotPassword: PropTypes.bool,
            registerLink: PropTypes.bool
        })
    }),
    errors: PropTypes.shape({
        id: PropTypes.string,
        password: PropTypes.string
    }),
    onSubmit: PropTypes.func,
    onChange: PropTypes.func,
    onChangeRememberMe: PropTypes.func,
    loginbtnClicked: PropTypes.bool
};

const mapDispatchToProps = {
    resetReduxState,
    ResetMeasurePerformanceListAction
};

export default connect(
    null,
    mapDispatchToProps
)(Login);
