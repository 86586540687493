import { DELETE_IA_PI_ELEMENT, SAVE_PI_MEASURE_FORM } from '../../graphql/mutation';
import {
    FETCH_MEASURES_FOR_PI,
    GET_PI_MEASURE_META_DATA,
    GET_TAGS,
    GET_TAG_DETAILS,
    SET_USER_FAVORITE_MEASURE
} from '../../graphql/query';

import APIHelper from '@/helper/apihelper';
import { getJwt } from '../../utils/jwtUtils';

export const fetchMeasureListApi = params => {
    const api = new APIHelper();
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Action: api.Actions.View,
            Authorization: getJwt()
        }),
        body: JSON.stringify({
            query: FETCH_MEASURES_FOR_PI,
            variables: params
        })
    };

    return fetch(api.Resources.Webtool, requestOptions);
};

export const saveMeasureDetailsApi = params => {
    const api = new APIHelper();
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Action: api.Actions.Create,
            Authorization: getJwt()
        }),
        body: JSON.stringify({
            query: SAVE_PI_MEASURE_FORM,
            variables: params
        })
    };

    return fetch(api.Resources.Webtool, requestOptions);
};

export const getMeasureMetaDataApi = params => {
    const api = new APIHelper();
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Action: api.Actions.View,
            Authorization: getJwt()
        }),
        body: JSON.stringify({
            query: GET_PI_MEASURE_META_DATA,
            variables: params
        })
    };

    return fetch(api.Resources.Measure, requestOptions);
};

export const deleteIA_PI_ElementApi = params => {
    const api = new APIHelper();
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Action: api.Actions.Delete,
            Authorization: getJwt()
        }),
        body: JSON.stringify({
            query: DELETE_IA_PI_ELEMENT,
            variables: params
        })
    };

    return fetch(api.Resources.Webtool, requestOptions);
};

export const setFavoriteActivityApi = params => {
    const api = new APIHelper();
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Action: api.Actions.Update,
            Authorization: getJwt()
        }),
        body: JSON.stringify({
            query: SET_USER_FAVORITE_MEASURE,
            variables: params
        })
    };

    return fetch(api.Resources.Measure, requestOptions);
};

export const getFilterTagsApi = params => {
    const api = new APIHelper();
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Action: api.Actions.View,
            Authorization: getJwt()
        }),
        body: JSON.stringify({
            query: GET_TAGS,
            variables: params
        })
    };

    return fetch(api.Resources.Common, requestOptions);
};

export const getFilterTagDetailsApi = params => {
    const api = new APIHelper();
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Action: api.Actions.View,
            Authorization: getJwt()
        }),
        body: JSON.stringify({
            query: GET_TAG_DETAILS,
            variables: params
        })
    };

    return fetch(api.Resources.Common, requestOptions);
};
