import {    
    MEASURE_BULK_UPLOAD_YEAR_REQUEST,
    MEASURE_BULK_UPLOAD_YEAR_RECEIVE,
    MEASURE_BULK_UPLOAD_YEAR_FAILURE,
    MEASURE_BULK_UPLOAD_PRACTICES_REQUEST,
    MEASURE_BULK_UPLOAD_PRACTICES_RECEIVE,
    MEASURE_BULK_UPLOAD_PRACTICES_FAILURE,
    FILES_BY_ENTITY_REQUEST,
    FILES_BY_ENTITY_RECEIVE,
    FILES_BY_ENTITY_FAILURE
} from '../actions/measureBulkUploadAction';

const initialState = {
    isYearFailure: false,
    isPracticeFailure: false,
    yearList: null,
    practices: null,
    isFileListLoading: false,
    fileList: []
}

const filterReducer = (state = initialState, action) => {
    switch (action.type) {
        case MEASURE_BULK_UPLOAD_YEAR_REQUEST:
            return {
                ...state,
                isYearFailure: false
            }

        case MEASURE_BULK_UPLOAD_YEAR_RECEIVE:
            return {
                ...state,
                isYearFailure: false,
                yearList: action.payload
            }

        case MEASURE_BULK_UPLOAD_YEAR_FAILURE:
            return {
                ...state,
                isYearFailure: true
            }

        case MEASURE_BULK_UPLOAD_PRACTICES_REQUEST:
            return {
                ...state,
                isPracticeFailure: false
            }

        case MEASURE_BULK_UPLOAD_PRACTICES_RECEIVE:
            return {
                ...state,
                isPracticeFailure: false,
                practices: action.payload
            }

        case MEASURE_BULK_UPLOAD_PRACTICES_FAILURE:
            return {
                ...state,
                isPracticeFailure: true
            }
        case FILES_BY_ENTITY_REQUEST:
            return {
                ...state,
                isLoading: true,
                isFailure: false,
                isFileListLoading: true
            };
        case FILES_BY_ENTITY_RECEIVE:
            return Object.assign({}, state, {
                isLoading: false,
                isFailure: false,
                fileList: action.payload,
                isFileListLoading: false
            });
        case FILES_BY_ENTITY_FAILURE:
            return {
                ...state,
                isLoading: false,
                isFailure: true,
                isFileListLoading: false
            };
        default:
            return state;
    }
}

export default filterReducer;