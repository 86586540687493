import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
    Grid,
    Typography,
    FormHelperText,
    Fab,
    TextField,
    FormControl,
    CircularProgress,
    InputAdornment
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MobileRegistration = props => {
    const {
        heading,
        emaillabel,
        email,
        mobilelabel,
        buttonlabel,
        countrycode,
        placeholder,
        name,
        error,
        inputValue,
        isRegisterInProgress
    } = props;
    return (
        <Grid className='login__contanier  mobile-form__wrapper '>
            <Grid item className='login__signin'>
                <Grid item className='login__head'>
                    <Link to='/'>
                        <Typography variant='h4' component='h3' className='login__title'>
                            <ArrowBack name='arrow left' size='large' />
                            {heading}
                        </Typography>
                    </Link>
                </Grid>
                <Grid item className='mobile-form__mail-label'>
                    <FormHelperText className='mobile-form__email__h6'>{emaillabel}</FormHelperText>
                </Grid>
                <Grid item>
                    <Typography variant='h6'>{email}</Typography>
                </Grid>
                <Grid item className='mobile-form__phone-lable'>
                    <FormHelperText>{mobilelabel}</FormHelperText>
                </Grid>
                <Grid item xs={12} className='mobile-form__phone'>
                    <FormControl className='mobile-form__phone-control'>
                        <FontAwesomeIcon icon={['fal', 'mobile-alt']} className='phone__icon' />
                        <TextField
                            error={error ? true : false}
                            type='text'
                            className='phone__icon--textfield'
                            placeholder={placeholder}
                            name={name}
                            onChange={props.onChange}
                            value={inputValue}
                            onKeyUp={props.onKeyUp}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment
                                        position='start'
                                        className='phone__icon--textfield-country-code'
                                    >
                                        {countrycode}
                                    </InputAdornment>
                                )
                            }}
                        />
                        {isRegisterInProgress ? <CircularProgress size={20} /> : null}
                    </FormControl>
                </Grid>
                {error ? (
                    <Grid item xs={12} className='error__wrapper'>
                        <FormHelperText className='error__text'> {error} </FormHelperText>
                    </Grid>
                ) : null}

                <Grid item xs={12} className='mobile-form__phone'>
                    <Fragment>
                        <Fab
                            disabled={isRegisterInProgress}
                            variant='extended'
                            onClick={props.onSubmitMobile}
                            color='primary'
                            className='mobile-form--resend__otp'
                        >
                            {buttonlabel}
                        </Fab>
                    </Fragment>
                </Grid>
            </Grid>
        </Grid>
    );
};

MobileRegistration.defaultProps = {
    heading: 'Mobile Number Not Registered',
    emaillabel:
        "We didn't find your mobile number registered with us on following account to enable 2 Step Verification.",
    email: 'abc@xyz.com',
    mobilelabel: 'Please enter your mobile number to activate 2 Step Verification',
    buttonlabel: 'Verify',
    name: 'mobile',
    countrycode: '+1',
    placeholder: '541-754-3010',
    onChange: () => {
        // console.log(("onHandleChnage is executed:" + event + " : " + value);
    },
    onSubmitMobile: () => {
        // console.log(("onHandleChnage is executed:" + event + " : " + value);
    },
    error: '',
    inputValue: ''
};

/* PROP TYPES
================================================================ */
MobileRegistration.propTypes = {
    heading: PropTypes.string,
    emaillabel: PropTypes.string,
    email: PropTypes.string,
    mobilelabel: PropTypes.string,
    buttonlabel: PropTypes.string,
    name: PropTypes.string,
    countrycode: PropTypes.string,
    placeholder: PropTypes.string,
    error: PropTypes.string,
    inputValue: PropTypes.string,
    isRegisterInProgress: PropTypes.bool,
    onSubmit: PropTypes.func,
    onKeyUp: PropTypes.func,
    onChange: PropTypes.func,
    onSubmitMobile: PropTypes.func
};

export default MobileRegistration;
