import { getActionCreator, getActionType, getReducer } from '@/utils/redux';

// Types
export const ResetMeasurePerformanceListType = getActionType('RESETMEASUREPERFORMANCELIST');
export const UpdateMeasurePerformanceListType = getActionType('UPDATEMEASUREPERFORMANCELIST');

// Action
export const ResetMeasurePerformanceListAction = getActionCreator(ResetMeasurePerformanceListType);
export const UpdateMeasureperformanceListAction = getActionCreator(
    UpdateMeasurePerformanceListType
);

// Initial state
const initialState = {
    measurePerformanceList: []
};

// Reducer
export default getReducer(initialState, {
    [UpdateMeasurePerformanceListType]: (state, { payload }) => {
        const list = [...state.measurePerformanceList, payload];
        return {
            ...state,
            measurePerformanceList: list
        };
    },
    [ResetMeasurePerformanceListType]: (state, { payload }) => {
        return {
            ...state,
            measurePerformanceList: []
        };
    }
});
