import ForgotPasswordPage from '@/containers/ForgotPassword';
import LoginPage from '@/containers/Login';
import OtpVerificationPage from '@/containers/OtpVerification';
import ProAppComponent from '@/containers/Pro';
import ResetpasswordPage from '@/containers/Resetpassword';
import UnsubscribePRO from '@/containers/Unsubscribe';
import {
    ENABLE_AUTHENTICATION_LINKS,
    ENABLE_PRO_MODULE,
    IMPORT_CAREGAP_SUBAPP,
    MEASURE_BULK_UPLOAD_PATH,
    PIA_PATHS,
    WEBTOOL_PATH
} from '@/helper/constants';
import Loader from '@/helper/loaders';
import { dashboardLayout } from '@/layouts/DashboardLayout';
import { errorLayout } from '@/layouts/ErrorLayout';
import { publicLayout } from '@/layouts/PublicLayout';
import React from 'react';
import { Switch } from 'react-router-dom';
import ConsentFormModal from '../containers/ConsentForm/ConsentFormModal';
import { auth } from '../utils/auth';
import AppRoute from './AppRoute';

const VisitsBulkUpload = Loader(() =>
    import(/* webpackChunkName: "FileUpload" */ '@/containers/Webtool/QualityWebtoolMeasure/VisitsBulkUpload/VisitsBulkUpload')
);
const QualityDashboardPage = Loader(() =>
    import(/* webpackChunkName: "QualityDashboardPage" */ '@/containers/QualityDashboard/QualityDashboard')
);

const DashboardNotAvailable = Loader(() =>
    import(/* webpackChunkName: "DashboardNotAvailable" */ '@/containers/DashboardOOS/DashboardOOS')
);

const MeasureDetails = Loader(() =>
    import(/* webpackChunkName: "MeasureDetails" */ '@/containers/MeasureDetails/MeasureDetails')
);

const Help = Loader(() => import(/* webpackChunkName: "HelpPage" */ '@/containers/Welcome'));

const IA = Loader(() =>
    import(/* webpackChunkName: "IAWebtool" */ '@/containers/Webtool/IA/IAWebtool')
);

const PI = Loader(() =>
    import(/* webpackChunkName: "PIWebtool" */ '@/containers/Webtool/PI/PIWebtool')
);

const MeasureBulkUpload = Loader(() =>
    import(/* webpackChunkName: "MeasureBulkUpload" */ '@/containers/Webtool/MeasureBulkUpload/MeasureBulkUpload')
);

const NotFoundPage = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '@/containers/not-found-page.component')
);

// const QualityManualEntryPage = Loader(() =>
//     import(/* webpackChunkName: "QualityManualEntry" */ '@/containers/Webtool/QualityManualEntry')
// );

const WebtoolPage = Loader(() =>
    import(/* webpackChunkName: "QualityManualEntry" */ '@/containers/Webtool/Webtool')
);

const EditMeasuresPage = Loader(() =>
    import(/* webpackChunkName: "DynamicFormRunner" */ '@/containers/Webtool/MeasureQuestionnaire/MeasureQuestionnaire')
);

const PatientVisits = Loader(() =>
    import(/* webpackChunkName: "VisitList" */ '@/containers/Webtool/Visit/VisitList')
);

// const EditVisit = Loader(() => {
//     switch (REGISTRY_UNIT_NAME) {
//         case 'CAP':
//             return import(/* webpackChunkName: "EditVisitCAP" */ '@/containers/Webtool/EditVisit/RegistryCap/EditVisit');
//         case 'AAO':
//             return import(/* webpackChunkName: "EditVisitAAO" */ '@/containers/Webtool/EditVisit/RegistryAAO/EditVisit');
//         case 'APTA':
//             return import(/* webpackChunkName: "EditVisitAPTA" */ '@/containers/Webtool/EditVisit/RegistryAPTA/EditVisit');
//         case 'AAD':
//             return import(/* webpackChunkName: "EditVisitAAD" */ '@/containers/Webtool/EditVisit/RegistryAAD/EditVisit');
//         default:
//             return import(/* webpackChunkName: "EditVisit" */ '@/containers/Webtool/EditVisit/Baseline/EditVisit');
//     }
// });

const fileUploadPage = Loader(() =>
    import(/* webpackChunkName: "DynamicFormRunner" */ '@/containers/Webtool/fileupload/BulkFileUpload')
);

const SSORouter = Loader(() =>
    import(/* webpackChunkName: "SSORouter" */ '@/containers/SSORouter/SSORouter')
);

const NoAccess = Loader(() =>
    import(/* webpackChunkName: "NoAccess" */ '@/containers/NoAccess/NoAccess')
);

const ServiceDeskPage = Loader(() =>
    import(/* webpackChunkName: "ServiceDeskPage" */ '@/containers/ServiceDesk/ServiceDesk')
);

const PracticeProfilePage = Loader(() =>
    import(/* webpackChunkName: "PracticeDetailEntry" */ '@/containers/PracticeProfile/PracticeDetail')
);

const TicketDetailPage = Loader(() =>
    import(/* webpackChunkName: "TicketDetailPage" */ '@/containers/ServiceDesk/TicketDetails')
);

const GroupPerformance = Loader(() =>
    import(/* webpackChunkName: "GroupPerformanceEntry" */ '@/containers/GroupPerformance/GroupPerformance')
);

const CareGap = Loader(() =>
    IMPORT_CAREGAP_SUBAPP
        ? import(/* webpackChunkName: "CareGap" */ '@/containers/CareGap-Extended')
        : import(/* webpackChunkName: "CareGap" */ '@/containers/CareGap/CareGap')
);

const CaregapPatientDetails = Loader(() =>
    import(/* webpackChunkName: "CaregapPatientDetails" */ '@/containers/CareGap/CaregapPatientDetails')
);

const AuthError = Loader(() =>
    import(/* webpackChunkName: "AuthError" */ '@/containers/AuthError/AuthError')
);

const MipsLanding = Loader(() => import(/* webpackChunkName: "MipsLanding" */ '@/containers/MIPS'));

const CreatePlan = Loader(() =>
    import(/* webpackChunkName: "CreatePlan" */ '@/containers/CreatePlan/CreatePlan')
);

const DisplayPlans = Loader(() =>
    import(/* webpackChunkName: "DisplayPlans" */ '@/containers/DisplayPlans/DisplayPlans')
);

const QualityDashboardApp = Loader(() =>
    import(/* webpackChunkName: "QualityDashboardApp" */ '@/containers/QD-Extended')
);

const Routers = store => {
    return (
        <div className='fi-grid fi-grid--hor fi-grid--root fi-grid--root-2'>
            <div className='fi-grid fi-grid--hor fi-grid--root fi-grid--root-3'>
                <Switch>
                    <AppRoute
                        exact={true}
                        path='/'
                        component={ENABLE_AUTHENTICATION_LINKS ? LoginPage : SSORouter}
                        requireAuth={auth}
                        layout={publicLayout}
                        type='public'
                    />
                    {ENABLE_AUTHENTICATION_LINKS && (
                        <AppRoute
                            exact={true}
                            path='/login'
                            component={LoginPage}
                            requireAuth={auth}
                            layout={publicLayout}
                            store={store}
                            type='public'
                        />
                    )}

                    <AppRoute
                        exact={true}
                        path='/AuthError'
                        component={AuthError}
                        requireAuth={auth}
                        layout={publicLayout}
                        type='public'
                    />
                    <AppRoute
                        path='/consent'
                        component={ConsentFormModal}
                        requireAuth={auth}
                        layout={dashboardLayout}
                        store={store}
                    />
                    <AppRoute
                        exact
                        path={PIA_PATHS.PLAN_DASHBOARD}
                        component={DisplayPlans}
                        requireAuth={auth}
                        layout={dashboardLayout}
                        store={store}
                    />
                    <AppRoute
                        exact
                        path={PIA_PATHS.CREATE_PLAN}
                        component={CreatePlan}
                        requireAuth={auth}
                        layout={dashboardLayout}
                        store={store}
                    />
                    <AppRoute
                        exact
                        path='/dashboard'
                        component={QualityDashboardPage}
                        requireAuth={auth}
                        layout={dashboardLayout}
                        store={store}
                    />
                    <AppRoute
                        exact
                        path='/abfmreport'
                        component={QualityDashboardApp}
                        requireAuth={auth}
                        layout={dashboardLayout}
                        store={store}
                    />
                    <AppRoute
                        exact={true}
                        path='/dashboardoos'
                        component={DashboardNotAvailable}
                        requireAuth={auth}
                        layout={dashboardLayout}
                        store={store}
                    />
                    <AppRoute
                        exact
                        path='/visits-bulk-upload'
                        component={VisitsBulkUpload}
                        requireAuth={auth}
                        layout={dashboardLayout}
                        store={store}
                    />
                    <AppRoute
                        exact={true}
                        path='/NoAccess'
                        component={NoAccess}
                        requireAuth={auth}
                        layout={dashboardLayout}
                        store={store}
                    />
                    <AppRoute
                        exact
                        path='/layout'
                        component={dashboardLayout}
                        requireAuth={auth}
                        layout={dashboardLayout}
                        store={store}
                    />
                    <AppRoute
                        exact
                        path='/help'
                        component={Help}
                        requireAuth={auth}
                        layout={dashboardLayout}
                        store={store}
                    />
                    <AppRoute
                        exact
                        path='/IA'
                        component={IA}
                        requireAuth={auth}
                        layout={dashboardLayout}
                        store={store}
                    />
                    <AppRoute
                        exact
                        path='/PI'
                        component={PI}
                        requireAuth={auth}
                        layout={dashboardLayout}
                        store={store}
                    />
                    <AppRoute
                        exact
                        path={MEASURE_BULK_UPLOAD_PATH}
                        component={MeasureBulkUpload}
                        requireAuth={auth}
                        layout={dashboardLayout}
                        store={store}
                    />
                    {/* <AppRoute
                        exact
                        path={WEBTOOL_PATH.QPM}
                        component={QualityManualEntryPage}
                        requireAuth={auth}
                        layout={dashboardLayout}
                        store={store}
                    /> */}
                    <AppRoute
                        exact
                        path={WEBTOOL_PATH.QPM}
                        component={WebtoolPage}
                        requireAuth={auth}
                        layout={dashboardLayout}
                        store={store}
                    />
                    <AppRoute
                        exact
                        path={WEBTOOL_PATH.EDIT_MEASURES}
                        component={() => <EditMeasuresPage requestFromLegacy={true} />}
                        requireAuth={auth}
                        layout={dashboardLayout}
                        store={store}
                    />
                    <AppRoute
                        exact
                        path={WEBTOOL_PATH.FILE_UPLOAD}
                        component={fileUploadPage}
                        requireAuth={auth}
                        layout={dashboardLayout}
                        store={store}
                    />
                    <AppRoute
                        exact
                        path={WEBTOOL_PATH.VISIT_LIST}
                        component={PatientVisits}
                        requireAuth={auth}
                        layout={dashboardLayout}
                        store={store}
                    />
                    {/* <AppRoute
                        exact
                        path={WEBTOOL_PATH.EDIT_VISIT}
                        component={EditVisit}
                        requireAuth={auth}
                        layout={dashboardLayout}
                        store={store}
                    /> */}
                    <AppRoute
                        exact
                        path='/measuredetails'
                        component={MeasureDetails}
                        requireAuth={auth}
                        layout={dashboardLayout}
                        store={store}
                    />
                    <AppRoute
                        exact
                        path='/GroupPerformance'
                        component={GroupPerformance}
                        requireAuth={auth}
                        layout={dashboardLayout}
                        store={store}
                    />
                    <AppRoute
                        exact
                        path='/caregap'
                        component={CareGap}
                        requireAuth={auth}
                        layout={dashboardLayout}
                        store={store}
                    />
                    <AppRoute
                        exact
                        path='/CaregapPatientDetails'
                        component={CaregapPatientDetails}
                        requireAuth={auth}
                        layout={dashboardLayout}
                        store={store}
                    />
                    <AppRoute
                        exact
                        path='/SSORouter'
                        component={SSORouter}
                        requireAuth={() => false}
                        layout={publicLayout}
                        type='public'
                    />
                    {ENABLE_AUTHENTICATION_LINKS && (
                        <AppRoute
                            exact
                            path='/forgotpassword'
                            component={ForgotPasswordPage}
                            requireAuth={() => false}
                            layout={publicLayout}
                            store={store}
                            type='public'
                        />
                    )}
                    {ENABLE_AUTHENTICATION_LINKS && (
                        <AppRoute
                            exact
                            path='/resetpassword'
                            component={ResetpasswordPage}
                            requireAuth={() => false}
                            layout={publicLayout}
                            store={store}
                            type='public'
                        />
                    )}
                    <AppRoute
                        exact
                        path='/servicedesk'
                        component={ServiceDeskPage}
                        requireAuth={auth}
                        layout={dashboardLayout}
                        store={store}
                    />
                    <AppRoute
                        exact
                        path='/practiceprofile'
                        component={PracticeProfilePage}
                        requireAuth={auth}
                        layout={dashboardLayout}
                        store={store}
                    />
                    <AppRoute
                        exact
                        path='/servicedesk/ticketdetails'
                        component={TicketDetailPage}
                        requireAuth={auth}
                        layout={dashboardLayout}
                        store={store}
                    />
                    <AppRoute
                        exact
                        path='/mips'
                        component={MipsLanding}
                        requireAuth={auth}
                        layout={dashboardLayout}
                        store={store}
                    />
                    <AppRoute
                        exact
                        path='/OtpVerification'
                        component={OtpVerificationPage}
                        requireAuth={() => false}
                        layout={publicLayout}
                        store={store}
                        type='public'
                    />
                    {ENABLE_PRO_MODULE && (
                        <AppRoute
                            path='/pro'
                            component={ProAppComponent}
                            requireAuth={auth}
                            layout={dashboardLayout}
                            store={store}
                        />
                    )}
                    <AppRoute
                        path='/manageConsent'
                        component={UnsubscribePRO}
                        layout={errorLayout}
                        requireAuth={() => false}
                        type='public'
                    />
                    <AppRoute
                        exact
                        path='*'
                        component={NotFoundPage}
                        requireAuth={() => false}
                        layout={errorLayout}
                        store={store}
                        type='public'
                    />
                </Switch>
            </div>
        </div>
    );
};

export default Routers;
