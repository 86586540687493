import { getActionTypes, getReducer } from '@/utils/redux';
import {
    getAllProgramDetails,
    getPracticeProgramDetails
} from '@/redux/services/programDetailsApi';
export const AllProgramTypes = getActionTypes('GETALLPROGRAMS');
export const PracticeProgramTypes = getActionTypes('GETPRACTICEPROGRAM');

export const getAllProgramDetailsList = param => ({
    types: AllProgramTypes,
    callAPI: () => getAllProgramDetails(param)
});

export const getPracticeProgramDetailsList = param => ({
    types: PracticeProgramTypes,
    callAPI: () => getPracticeProgramDetails(param)
});

const initialState = {
    allProgramIsLoading: true,
    isFailure: false,
    allProgramList: [],
    practiceProgramList: []
};

export default getReducer(initialState, {
    [AllProgramTypes.READY]: state => ({
        ...state,
        allProgramIsLoading: true
    }),

    [PracticeProgramTypes.READY]: state => ({
        ...state,
        isLoading: true
    }),

    [AllProgramTypes.SUCCESS]: (state, { payload }) => ({
        ...state,
        allProgramIsLoading: false,
        isFailure: false,
        allProgramList: payload.getModuleAttributess ? payload.getModuleAttributess : []
    }),

    [PracticeProgramTypes.SUCCESS]: (state, { payload }) => ({
        ...state,
        isLoading: false,
        isFailure: false,
        practiceProgramList: payload.getEntityAttributess ? payload.getEntityAttributess : []
    }),

    [AllProgramTypes.ERROR]: () => initialState,
    [PracticeProgramTypes.ERROR]: () => initialState
});
