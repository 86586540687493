import { GET_PROVIDERS_FOR_PMS } from '@/graphql/query';
import { getJwt } from '@/utils/jwtUtils';
import APIHelper from '@/helper/apihelper';

const api = new APIHelper();

export const getClinicians = params => {
    const requestOption = api.getRequestOption(
        GET_PROVIDERS_FOR_PMS,
        params,
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.Provider, requestOption, response => response);
};
