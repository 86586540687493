import { getJwt } from '../../utils/jwtUtils';
import APIHelper from '@/helper/apihelper';

const api = new APIHelper();

export const searchticketbycustomerAPI = (
    filterInput,
    issuetype,
    summary,
    description,
    startAt = 0,
    maxResults = 10
) => {
    const inputObject = {
        project: `${process.env.JIRA_PROJECT_KEY}`,
        type: ['Service Request', 'Incident'],
        externalid: `cf[11209]~${filterInput.practiceID}`,
        startAt: startAt,
        maxResults,
        fields: [
            'summary',
            'status',
            'assignee',
            'description',
            'issuetype',
            'customfield_11105',
            'customfield_11480'
        ],
        fieldsByKeys: false
    };
    if (filterInput.ticketID !== '') {
        inputObject.ticketno = `${filterInput.ticketID}`;
    }
    if (filterInput.reporter !== '') {
        inputObject.reporter = `cf[11480] ~'${filterInput.reporter}'`;
    }
    if (filterInput.status.length !== 0) {
        inputObject.status = filterInput.status.map(a => `${a.value}`);
    }
    const requestOption = api.JiraGetRequestOption(inputObject, api.Actions.View, getJwt());
    return api.PegasusAPI(api.Resources.JiraAPI.searchTicketByCustomer, requestOption, response => {
        return response;
    });
};

export const createIssueAPI = (issuetype, summary, description) => {
    const requestOption = api.JiraGetRequestOption(
        {
            fields: {
                project: {
                    key: process.env.JIRA_PROJECT_KEY
                },
                summary: summary,
                description: description,
                issuetype: {
                    name: issuetype
                }
            }
        },
        api.Actions.View,
        getJwt()
    );

    return api.PegasusAPI(api.Resources.JiraAPI.createIssue, requestOption, response => {
        return response;
    });
};

export const getIssueCommentAPI = ticketId => {
    const requestOption = api.JiraGetRequestOption(
        {
            ticketid: ticketId
        },
        api.Actions.View,
        getJwt()
    );

    return api.PegasusAPI(api.Resources.JiraAPI.getIssueComment, requestOption, response => {
        return response;
    });
};

export const addIssueCommentsAPI = (ticketId, comment) => {
    const requestOption = api.JiraGetRequestOption(
        {
            body: comment,
            ticketid: ticketId
        },
        api.Actions.View,
        getJwt()
    );

    return api.PegasusAPI(api.Resources.JiraAPI.addIssueComments, requestOption, response => {
        return response;
    });
};

export const getMeasureStatusAPI = (
    practiceId,
    measureName,
    startDate = '2019-01-01',
    endDate = '2019-12-31'
) => {
    const requestOption = api.JiraGetRequestOption(
        {
            project: process.env.JIRA_PROJECT_KEY,
            issueType: 'Mapping',
            startDate,
            endDate,
            practiceId,
            measureName
        },
        api.Actions.View,
        getJwt()
    );

    return api.PegasusAPI(api.Resources.JiraAPI.getMeasureStatus, requestOption, response => {
        return response;
    });
};

// FEB SPRINT

export const requestTypesAssociatedWithSpecificServiceDeskAPI = groupname => {
    const requestOption = api.JiraGetRequestOption(
        {
            serviceDeskId: process.env.JIRA_PROJECT_SERVICEDESKID,
            start: 0,
            limit: 100,
            groupname: groupname
        },
        api.Actions.View,
        getJwt()
    );

    return api.PegasusAPI(
        api.Resources.JiraAPI.RequestTypesAssociatedWithSpecificServiceDesk,
        requestOption,
        response => {
            return response;
        }
    );
};

export const createTicketAPI = (
    createdBy,
    fileUrl,
    practiceId,
    requestTypeId,
    requestSubTypeId,
    summary,
    description
) => {
    // requestTypeId = requestTypeId == '10700' ? 'Service Request' : 'Incident';
    // return createIssueAPI(requestTypeId, summary, description);
    const requestOption = api.JiraGetRequestOption(
        {
            serviceDeskId: process.env.JIRA_PROJECT_SERVICEDESKID,
            requestTypeId: requestSubTypeId,
            practiceId,
            requestFieldValues: {
                summary,
                description
            },
            fileUrl,
            createdBy
        },
        api.Actions.View,
        getJwt()
    );

    return api.PegasusAPI(api.Resources.JiraAPI.createTicket, requestOption, response => {
        return response;
    });
};

export const getMeasureStatusByMeasureSetID = (
    practiceId,
    measureSetId,
    startDate = '2019-01-01',
    endDate = '2019-12-31'
) => {
    const requestOption = api.JiraGetRequestOption(
        {
            project: process.env.JIRA_PROJECT_KEY,
            issueType: 'Mapping',
            startDate,
            endDate,
            practiceId,
            measureSetId,
            measureNo: []
            //['QPP128']
        },
        api.Actions.View,
        getJwt()
    );

    return api.PegasusAPI(
        api.Resources.JiraAPI.getMeasureStatusByMeasureset,
        requestOption,
        response => {
            return response;
        }
    );
};
