import { getActionCreator, getActionType, getActionTypes, getReducer } from '@/utils/redux';
import { FETCH_ED } from '@/helper/constants';
import { getLocations, getPractices } from '@/redux/services/locationsApi';

// Types
export const LocationListTypes = getActionTypes('LOCATIONLISTPMS');
// export const UpdateSelectedProviderIdType = getActionType('UPDATESELECTEDPROVIDERID');
export const UpdateEDListDataAndDetailsPMSType = getActionType('UPDATEEDLISTDATAANDDETAILSPMS');
export const EDListTypes = getActionTypes('ED');
// export const UpdateSelectedProviderIdAction = getActionCreator(UpdateSelectedProviderIdType);
export const UpdateEDListDataAndDetailsPMSAction = getActionCreator(
    UpdateEDListDataAndDetailsPMSType
);

export const getLocationPMSAction = queryParam => ({
    types: FETCH_ED ? EDListTypes : LocationListTypes,
    callAPI: () => (FETCH_ED ? getEDData(queryParam) : getLocations(queryParam)),
    handleAction: async ({ type, payload, store }) => {
        if (FETCH_ED) {
            switch (type) {
                case EDListTypes.SUCCESS: {
                    if (payload.location.length > 0) {
                        const resultObj = {
                            ...payload
                        };
                        store.dispatch(UpdateEDListDataAndDetailsPMSAction(resultObj));
                    } else {
                        const paloadObj = {
                            ...payload
                        };
                        store.dispatch(UpdateEDListDataAndDetailsPMSAction(paloadObj));
                    }
                    break;
                }
                default:
                    break;
            }
        }
    }
});

const initialState = {
    isLocationLoading: true,
    location: [],
    error: {
        code: '',
        Message: ''
    }
};

export const getEDData = async queryParam => {
    const fetchEDQqueryParam = {
        input: {
            orderBy: 'name',
            practicetype: 0,
            parentid: queryParam.input.practiceid
        }
    };
    const response = await getPractices(fetchEDQqueryParam);
    const edList = response.data.getPractices != null ? response.data.getPractices : [];
    return {
        data: {
            location: edList,
            isLocationLoading: false
        }
    };
};

// Reducer
export default getReducer(initialState, {
    [LocationListTypes.READY]: state => ({
        ...state,
        isLocationLoading: true
    }),
    [LocationListTypes.SUCCESS]: (state, { payload }) => ({
        ...state,
        location: payload.getLocations,
        isLocationLoading: false
    }),
    [LocationListTypes.ERROR]: (state, { payload }) => {
        return {
            ...state,
            isLocationLoading: false,
            error: {
                code: '',
                Message: 'Error occured while fetching error.'
            }
        };
    },
    [UpdateEDListDataAndDetailsPMSType]: (state, { payload }) => ({
        ...state,
        location: payload.location,
        isLocationLoading: false
    })
});
