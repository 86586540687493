import {
    EDMeasureType,
    PRACTICE_REFRESH_END_DATE_LABEL,
    DEFAULT_FILTER_BY_REFRESH_DATE,
    ENABLE_GOOGLE_ANALYTICS,
    GOOGLE_ANALYTICS_ACCOUNT_ID
} from '@/helper/constants';
import {
    UpdateMeasureFilterDefaultSelectionAction,
    SetMeasureSetFilterLoadingFlagAction
} from '@/redux/modules/measureFilter';
import i18next from 'i18next';
/* eslint-disable no-array-constructor */
export const generateDropDownObject = (data, label, selectedLabel, Handler, defaultValue) => ({
    data,
    label,
    selectedLabel,
    onchangeHandler: Handler,
    selectedId: defaultValue
});

export const generateMeasurePerformanceParamObj = (
    filter,
    EntityName,
    props,
    EntityId,
    ParentEntityId,
    ParentEntityName
) => {
    return {
        MeasuresetId: props.measureSet,
        EntityId,
        DurationFrom: props.customrange ? props.fromdate.replace(/-/g, '/') : props.durationFrom,
        DurationTo: props.customrange ? props.todate.replace(/-/g, '/') : props.durationTo,
        Flag: props.durationFlag,
        EntityName,
        ParentEntityId,
        ParentEntityName
    };
};

export function fnGetPerformanceBarCSSClass(EntityComparison) {
    return {
        '1': 'performance-green',
        '2': 'performance-amber',
        '3': 'performance-red',
        '4': 'performance-gray'
    }[EntityComparison];
}

export const fnSetUserFavoriteMeasure = async (_this, e, props) => {
    // let classes = e.target.classList;

    if (props.favourite.icon.includes('heart')) {
        if (props.favourite.icon.includes('fal')) {
            e.target.setAttribute('icon', ['fas', 'heart']);
        } else {
            e.target.setAttribute('icon', ['fal', 'heart']);
        }

        await _this.props.SetUserFavoriteMeasureAction({
            measureId: props.measureId,
            isFavorite: props.isFavourite
        });

        _this.setState({
            messageInfo: {
                open: true,
                variant: 'success',
                message: props.isFavourite ? 'Removed From Favorite List' : 'Added To Favorite List'
            }
        });
    }
};

export function fnUpdateFilterInStore(_this, year) {
    _this.props.measureFilterUpdateAction(
        Object.assign({}, _this.props.MeasureFilterData.selectedValues, {
            year: year,
            duration: _this.props.MeasureFilterData.duration.filter((duration, index) => {
                return duration.year === year && /^(\d{4}Q[1-4])$/.test(duration.id);
            })[0].id
        })
    );
}

// Global Function
export const formateDate = (date, dateFormat, customRange = false) => {
    const _date = new Date(date);
    if (customRange) {
        return fnDateFormat(
            fnFormatNumber(_date.getMonth() + 1),
            fnFormatNumber(_date.getDate()),
            fnFormatNumber(_date.getFullYear()),
            dateFormat
        ).join(dateFormat.replace(/[^-/:]/g, '').replace(/([-\/:])+/g, '$1'));
    } else {
        return fnDateFormat(
            fnFormatNumber(_date.getUTCMonth() + 1),
            fnFormatNumber(_date.getUTCDate()),
            fnFormatNumber(_date.getUTCFullYear()),
            dateFormat
        ).join(dateFormat.replace(/[^-/:]/g, '').replace(/([-\/:])+/g, '$1'));
    }

    function fnFormatNumber(number) {
        if (/[0-9]+/.test(number)) {
            number = Number(number);
            return number < 10 ? ('0' + number).toString() : number.toString();
        }
        return number;
    }

    function fnDateFormat(mm, dd, yy, dateFormat) {
        switch (dateFormat.toUpperCase().replace(/[^A-Z]/gi, '')) {
            case 'MMDDYYYY':
                return [mm, dd, yy];
            case 'DDMMYYYY':
                return [dd, mm, yy];
            case 'YYYYMMDD':
                return [yy, mm, dd];
            default:
                return [mm, dd, yy];
        }
    }
};

export const fnRemoveHtmlCharacterFromString = (value = '') => {
    return (value || '').replace(/(\<|\>|\&|\'|\")/g, $1 => {
        return {
            '<': '&lt;',
            '>': '&gt;',
            '&': '&amp;',
            "'": '&apos;',
            '"': '&quot;'
        }[$1];
    });
};

export const fnGenericAPICall = async (fn, params = []) => {
    let returnValue;
    if (typeof fn === 'function') {
        await fn(...params)
            .then(res => {
                returnValue = {
                    APIStatus: 'success',
                    ...res
                };
            })
            .catch(ex => {
                returnValue = {
                    APIStatus: 'fail',
                    ...ex
                };
            });

        return returnValue;
    }
};

export const ServiceDeskJSONSchema = function(_this) {
    //let PracticeID;
    // if (PracticeList) {
    //     PracticeID = {
    //         type: 'string',
    //         title: 'Practice',
    //         enum: PracticeList.map(function(obj) {
    //             return obj.externalid;
    //         }),
    //         enumNames: PracticeList.map(function(obj) {
    //             return obj.label;
    //         }),
    //         validationMessage: 'Please select valid Practice Id'
    //     };
    // } else {
    //     PracticeID = {
    //         type: 'string',
    //         title: 'Practice Id',
    //         validationFormula: '^([0-9]{1,10})$',
    //         validationMessage: 'Please enter valid Practice Id'
    //     };
    // }

    const PracticeID = {
        type: 'string',
        title: i18next.t('ServiceDeskSchema.PracticeIDFieldTitle'),
        validationFormula: '^([0-9]{1,10})$',
        validationMessage: i18next.t('ServiceDeskSchema.PracticeFieldValidationMsg')
    };

    const subtypeenum = _this.state.dialog.ticketsubtypes.map(p => p.id);
    const subtypeenumnames = _this.state.dialog.ticketsubtypes.map(p => p.name);

    return Object({
        schema: {
            title: '',
            // description: `DO NOT ENTER ANY PHI UNLESS YOU FIRST SELECT THE "SELECT TO ADD PHI" BUTTON BELOW AND ENTER IT INTO THE "PHI DESCRIPTION FIELD" AND/OR UPLOAD SUPPORTING DOCUMENTS; SUCH DOCUMENTS MAY CONTAIN PHI ONLY IF THE "SELECT TO ADD PHI" BUTTON IS PRESSED.`,
            description: '',
            type: 'object',
            required: ['SubType', 'PracticeID', 'Summary', 'Description'],
            properties: {
                // TicketType: {
                //     type: 'string',
                //     enum: ['10700', '10807'],
                //     enumNames: ['Service request', 'Incident'],
                //     title: 'Ticket Type'
                // },
                SubType: {
                    type: 'string',
                    enum: subtypeenum,
                    enumNames: subtypeenumnames,
                    title: i18next.t('ServiceDeskSchema.SubTypeFieldTitle')
                },
                PracticeID,
                Summary: {
                    type: 'string',
                    validationFormula: '^(.{0,255})?$',
                    validationMessage: i18next.t('ServiceDeskSchema.SummaryFieldValidationMsg')
                },
                Description: {
                    type: 'string'
                }
            }
        },
        uiSchema: {
            // TicketType: { 'ui:widget': 'select' },
            SubType: { 'ui:widget': 'select' },
            Description: { 'ui:widget': 'textarea' },
            PracticeID: { 'ui:disabled': true }
        }
    });
};

export const AddDynamicFieldsInServiceDeskJSONSchema = (serviceDeskSchemaObj, fieldsName = []) => {
    fieldsName.map(field => {
        if (field.required) {
            serviceDeskSchemaObj.schema.required.push(field.name);
        }
        serviceDeskSchemaObj.schema.properties[field.name] = {
            type: 'string',
            title: field.title || field.name,
            validationFormula: field.validationFormula || undefined,
            validationMessage: field.validationMessage || undefined
        };
        serviceDeskSchemaObj.uiSchema[field.name] = {
            'ui:disabled': field.disabled || false
        };
    });
};

export const fnCreateTicket = async (
    _this,
    serviceEndPointAPI,
    Data,
    fnSearchTicketByCustomer,
    practiceData
) => {
    let formData = Object.assign({}, Data);
    formData.TicketType = _this.state.dialog.ticketsubtypes.filter(
        p => p.id === formData.SubType
    )[0].issueTypeId;
    let ServiceDeskSchema = ServiceDeskJSONSchema(_this);

    Object.keys(formData).forEach((key, index) => {
        if (key !== 'fileUploadResponse' && key !== 'guuid' && key !== 'TicketType') {
            if (!(key in ServiceDeskSchema.schema.properties)) {
                formData['Description'] = `${key}: ${formData[key]}\n${formData['Description']}`;
            }
        }
    });

    // formData['Description'] = `${ServiceDeskSchema.schema.properties.PracticeID.title}: ${
    //     formData['PracticeID']
    // }\n${formData['Description']}`;

    let fileUrl = '';
    if (formData['fileUploadResponse']) {
        // const str = `\nUSED FOR INTERNAL PURPOSE ONLY`;
        // const folderName = ''; //`Directory: ${formData['guuid']}\n`;
        const fileUploadPaths = formData['fileUploadResponse'].map(p => p.path);
        const links = fileUploadPaths.join(`\n`);
        fileUrl = `${links}`;
        // formData['Description'] = `${formData['Description']}\n\n${str}\n${folderName}\n\n${links}`;
    }
    formData.createdBy = `${_this.props.UserProfileDetails.firstname} ${_this.props.UserProfileDetails.lastname}`;
    formData.fileUrl = fileUrl;
    let returnValue = await fnGenericAPICall(serviceEndPointAPI, [
        formData.createdBy,
        formData.fileUrl,
        formData.PracticeID,
        formData.TicketType,
        formData.SubType,
        formData.Summary,
        formData.Description
    ]);

    if (returnValue.issueKey || returnValue.key) {
        if (typeof fnSearchTicketByCustomer === 'function') {
            _this.handleDialogClose();
            _this.setState({
                isLoading: true
            });
            fnSearchTicketByCustomer(_this, 1, _this.props.SelectedPractice.externalID);
        } else {
            _this.handleDialogClose();
        }
    } else {
        _this.setState({
            messageInfo: {
                open: true,
                message: `Error occured please try after sometime.`,
                variant: 'error'
            },
            isLoading: false
        });
        _this.handleDialogClose();
    }
    return returnValue;
};

export const fnRequestTypesAssociatedWithSpecificServiceDesk = async (
    _this,
    serviceEndPointAPI,
    filterItem = []
) => {
    let res = await fnGenericAPICall(serviceEndPointAPI, filterItem);

    if (res.APIStatus === 'fail') {
        return [];
    }

    /**
     * FILTER IN TEXT BY USING REGULAR EXPRESSION
     */
    // let regexp = new RegExp(
    //     filterItem
    //         .map(item => {
    //             return '(' + item + ')';
    //         })
    //         .join('|'),
    //     'img'
    // );

    const returnValue =
        res.values !== null && res.values !== undefined
            ? res.values.filter(
                  ({ description, helpText, id, issueTypeId, name, serviceDeskId }) => {
                      //if (regexp.test(description)) {
                      return fnCreateDataWithUniqueKey({
                          description,
                          helpText,
                          id,
                          issueTypeId,
                          name,
                          serviceDeskId
                      });
                      //}
                  }
              )
            : [];

    let newState = _this.state;
    newState.dialog.ticketsubtypes = returnValue;

    _this.setState({
        ...newState
    });
};

let rowid = 0;
export function fnCreateDataWithUniqueKey(...params) {
    rowid += 1;
    return { rowid, ...params[0] };
}
// Removes spaces after newlines
// replaces multiple spaces with one space
// removes leading and trailing spaces

export const removeSpaces = el => el.replace(/  +/g, ' ');

// .replace(/(^\s*)|(\s*$)/gi, '')
// .replace(/[ ]{2,}/gi, ' ')
// .replace(/\n +/, '\n');

// store the keys of the unique objects
// eliminate the dead keys & store unique objects
export const getUnique = (arr, comp) => {
    const unique = arr
        .map(e => e[comp])
        .map((e, i, final) => final.indexOf(e) === i && i)
        .filter(e => arr[e])
        .map(e => arr[e]);
    return unique;
};

export const customFilterOption = (option, rawInput) => {
    const words = rawInput.split(' ');
    return words.reduce(
        (acc, cur) => acc && option.label.toLowerCase().includes(cur.toLowerCase()),
        true
    );
};

export const fnHandleServiceDeskDialogClose = (_this, props, ServiceDeskSchema, formData) => {
    var newDialogState = Object.assign({}, _this.state.dialog, {
        open: !_this.state.dialog.open,
        schema: ServiceDeskSchema.schema,
        uiSchema: ServiceDeskSchema.uiSchema,
        formData: formData,
        measureData: props
    });

    _this.setState({
        dialog: newDialogState
    });
};

export const formateDOBString = dob => {
    const [datepart] = dob.split(' ');
    const [year, month, day] = datepart.split('-');
    return `${month}/${day}/${year}`;
};

export const formateVisitDate = visitDate => {
    const [visitdatepart] = visitDate.split(' ');
    const [year, month, day] = visitdatepart.split('-');
    return `${month}/${day}/${year}`;
};

//, 'MM/DD/YYYY'
export const convertDateToUTC = now => new Date(now.getTime() + now.getTimezoneOffset() * 60000);

export const providerDisplayFormat = option => {
    const label = option.npi
        ? `${option.npi}` +
          (option.firstname
              ? ` - ${option.firstname}` + (option.lastname ? ' ' + option.lastname : '')
              : option.lastname
              ? ' - ' + option.lastname
              : '')
        : option.firstname
        ? option.firstname + (option.lastname ? ' ' + option.lastname : '')
        : option.lastname
        ? option.lastname
        : '';
    return label;
};

export const clinicianDisplayNameFormat = option => {
    const label = option.npi
        ? `${option.npi}` +
          (option.physicianfirstname
              ? ` - ${option.physicianfirstname}` +
                (option.physicianlastname ? ' ' + option.physicianlastname : '')
              : option.physicianlastname
              ? ' - ' + option.physicianlastname
              : '')
        : option.physicianfirstname
        ? option.physicianfirstname +
          (option.physicianlastname ? ' ' + option.physicianlastname : '')
        : option.physicianlastname
        ? option.physicianlastname
        : '';
    return label;
};

export const practiceDisplayFormat = option => {
    const label = option.externalid
        ? `${option.externalid}` + (option.name ? ` - ${option.name}` : '')
        : option.name
        ? `${option.name}`
        : '';
    return label;
};

export const filterMeasureListByMeasureStatus = (measureList, measureStatusList) => {
    if (measureStatusList.length > 0 && measureList.length > 0) {
        let returnList = measureList.map(obj => {
            let measureIds = measureStatusList.filter(item => item.measure_id === obj.measureSDID);
            if (measureIds.length > 0) {
                obj.measureStatus = measureIds.map(m => m.status);
            }
            return obj;
        });
        return returnList;
    }
    return measureList;
};

export const filterEDMeasureByStatusAndEHR = (measureList, edMeasureStatusList) => {
    if (edMeasureStatusList.length > 0 && measureList.length > 0) {
        let returnList = measureList.map(obj => {
            var measureIds = edMeasureStatusList.filter(item => item.measure_id === obj.id);
            if (measureIds.length > 0) {
                obj.measureStatus = measureIds.map(m => m.status);
                obj.performance.performanceData.EDMeasureType = measureIds[0].ed_profile
                    ? EDMeasureType[measureIds[0].ed_profile.toUpperCase()]
                    : 0;
                obj.measureEHRFlag = measureIds[0].ed_profile ? measureIds[0].ed_profile : '';
            }
            return obj;
        });
        // const hiddenMeasureStatusList = HIDDEN_MEASURE_STATUS.join(',')
        //     .toLowerCase()
        //     .split(',');
        // for (var value of hiddenMeasureStatusList) {
        //     returnList = returnList.filter(
        //         p =>
        //             p.measureStatus
        //                 .join(',')
        //                 .toLowerCase()
        //                 .split(',')
        //                 .indexOf(value) < 0
        //     );
        // }
        return returnList;
    }
    return measureList;
};

export const filterByColumn = (columns, item) => {
    if (columns.length === 0) {
        return item;
    }
    let filterItem = item.filter(obj => {
        for (let key in obj) {
            if (
                columns
                    .join('|')
                    .toLowerCase()
                    .split('|')
                    .indexOf(key.toLowerCase()) !== -1
            ) {
                return true;
            }
        }
        return false;
    });
    return filterItem;
};

export const getRefreshDataObject = edrefreshDateData => {
    return [
        {
            label: i18next.t('qualityDashboard.refreshDateData.label.updatedOn'),
            format: 'MMM Do YYYY HH:mm',
            date:
                edrefreshDateData &&
                edrefreshDateData.data.getPracticeRefreshStatus &&
                edrefreshDateData.data.getPracticeRefreshStatus.length > 0 &&
                edrefreshDateData.data.getPracticeRefreshStatus[0].lastrefreshtime &&
                convertDateToUTC(
                    new Date(
                        Number(edrefreshDateData.data.getPracticeRefreshStatus[0].lastrefreshtime)
                    )
                ),
            title: i18next.t('qualityDashboard.refreshDateData.title.updatedOn')
        },
        {
            label: PRACTICE_REFRESH_END_DATE_LABEL,
            format: 'MMM Do YYYY',
            date:
                edrefreshDateData &&
                edrefreshDateData.data.getPracticeRefreshStatus &&
                edrefreshDateData.data.getPracticeRefreshStatus.length > 0 &&
                edrefreshDateData.data.getPracticeRefreshStatus[0].enddate &&
                convertDateToUTC(
                    new Date(Number(edrefreshDateData.data.getPracticeRefreshStatus[0].enddate))
                ),
            title: i18next.t('qualityDashboard.refreshDateData.title.endDate')
        }
    ];
};

export const setDefaultDurationFilter = (storeObj, practicepayload, store) => {
    if (storeObj !== null && practicepayload !== null) {
        var dataAvailableTillDate =
            practicepayload.refreshDateData.length > 0
                ? practicepayload.refreshDateData[1].date
                : null;
        if (DEFAULT_FILTER_BY_REFRESH_DATE && dataAvailableTillDate instanceof Date) {
            const month = dataAvailableTillDate.getMonth() + 1;
            const quater = Math.ceil(month / 3);
            const defaultSelectedValue = store
                .getState()
                .MeasureFilter.measureFilterData.duration.filter(item => {
                    return item.value === `${dataAvailableTillDate.getFullYear()}Q${quater}`;
                });
            const currentMeasureSetId = storeObj.selectedValues.measureSet;
            const currentMeasureSetData = storeObj.measureSet.filter(
                p => p.id === currentMeasureSetId
            );
            const currMeasureSetYear =
                currentMeasureSetData.length > 0 ? currentMeasureSetData[0].year : null;
            if (
                defaultSelectedValue.length > 0 &&
                currMeasureSetYear === dataAvailableTillDate.getFullYear()
            ) {
                storeObj.selectedValues.year = dataAvailableTillDate.getFullYear();
                storeObj.selectedValues.duration = defaultSelectedValue[0].value;
                storeObj.selectedValues.durationFrom = defaultSelectedValue[0].startdate;
                storeObj.selectedValues.durationTo = defaultSelectedValue[0].enddate;
                const customDateDuration = getFromToDateByMeasureSetYear(
                    storeObj.selectedValues.year
                );
                storeObj.selectedValues.fromdate = customDateDuration.fromdate;
                storeObj.selectedValues.todate = customDateDuration.todate;
                store.dispatch(UpdateMeasureFilterDefaultSelectionAction(storeObj));
            } else
                store.dispatch(
                    UpdateMeasureFilterDefaultSelectionAction(
                        getLatestYearQuaterFilter(store, storeObj)
                    )
                );
        } else
            store.dispatch(
                UpdateMeasureFilterDefaultSelectionAction(
                    getLatestYearQuaterFilter(store, storeObj)
                )
            );
    } else store.dispatch(SetMeasureSetFilterLoadingFlagAction());
};

export const getLatestYearQuaterFilter = (store, storeObj) => {
    const currentMeasureSetId = storeObj.selectedValues.measureSet;
    const currentMeasureSetData = storeObj.measureSet.filter(p => p.id === currentMeasureSetId);
    const currMeasureSetYear =
        currentMeasureSetData.length > 0 ? currentMeasureSetData[0].year : null;
    let latestYear = DEFAULT_FILTER_BY_REFRESH_DATE
        ? store.getState().MeasureFilter.measureFilterData.year[0].id
        : currMeasureSetYear;
    if (DEFAULT_FILTER_BY_REFRESH_DATE && currMeasureSetYear !== latestYear) {
        latestYear = currMeasureSetYear;
    }
    let latestQuater = store
        .getState()
        .MeasureFilter.measureFilterData.duration.filter(
            item => item.year === latestYear && item.flag === 'QNR'
        );
    if (DEFAULT_FILTER_BY_REFRESH_DATE) {
        const currentDateQuater = store
            .getState()
            .MeasureFilter.measureFilterData.duration.filter(
                item =>
                    item.flag === 'QNR' &&
                    item.value ===
                        `${new Date().getFullYear()}Q${Math.ceil((new Date().getMonth() + 1) / 3)}`
            );
        if (currentDateQuater.length > 0 && latestYear === new Date().getFullYear()) {
            latestQuater = currentDateQuater;
        }
    }
    const customDateDuration = getFromToDateByMeasureSetYear(latestYear);
    storeObj.selectedValues.year = latestYear;
    storeObj.selectedValues.duration = latestQuater[0].value;
    storeObj.selectedValues.durationFrom = latestQuater[0].startdate;
    storeObj.selectedValues.durationTo = latestQuater[0].enddate;
    storeObj.selectedValues.fromdate = customDateDuration.fromdate;
    storeObj.selectedValues.todate = customDateDuration.todate;
    return storeObj;
};

export const getFromToDateByMeasureSetYear = measureSetYear => {
    if (measureSetYear !== new Date().getFullYear())
        return { fromdate: `01-01-${measureSetYear}`, todate: `12-31-${measureSetYear}` };
    else
        return {
            fromdate: `01-01-${new Date().getFullYear()}`,
            todate: `${new Date().getMonth() +
                1}-${new Date().getDate()}-${new Date().getFullYear()}`
        };
};

export const googleAnalyticsLoginEvent = (ReactGA, userId) => {
    if (ENABLE_GOOGLE_ANALYTICS) {
        ReactGA.initialize(GOOGLE_ANALYTICS_ACCOUNT_ID);
        ReactGA.set({
            userId: userId
            //res.data.useranalyticsid
        });
        ReactGA.event({
            category: 'User',
            action: 'Logged In',
            label: userId
        });
    }
};

export const googleAnalyticsTrackPageView = (ReactGA, page) => {
    if (ENABLE_GOOGLE_ANALYTICS) {
        ReactGA.set({
            page
        });
        ReactGA.pageview(page);
    }
};
