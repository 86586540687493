import { getActionCreator, getActionType, getActionTypes, getReducer } from '@/utils/redux';

// import { push } from 'react-router-redux';
import { getClinicianData } from '../services/clinicianPerformanceApi';
// import { getMeasureAllOutputPerformance } from '../services/PerformanceTrendInfoApi';
import { providerDisplayFormat } from '@/helper/commonFunction';
// Types
export const ProviderTypes = getActionTypes('PROVIDER');
export const ProviderPerformanceTypes = getActionTypes('PROVIDERPERFORMANCE');
export const LoadClinicianTrendType = getActionType('CLINICIANTREND');
export const SetClinicianTrendLoadingType = getActionType('SETCLINICIANTRENDLOADING');
// Actions
export const LoadClinicianTrend = getActionCreator(LoadClinicianTrendType);
export const SetClinicianTrendLoadingAction = getActionCreator(SetClinicianTrendLoadingType);

export const getproviders = params => {
    return {
        types: ProviderTypes,
        callAPI: () => getClinicianData(params.clinician),
        handleAction: ({ type, payload, store }) => {
            switch (type) {
                case ProviderTypes.SUCCESS:
                    var data =
                        payload.getProvidersByPracticeId &&
                        payload.getProvidersByPracticeId.map(m => {
                            return {
                                performance: {
                                    performanceData: {
                                        ...params.measureComputations.input,
                                        EntityId: `${m.id}`
                                    }
                                }
                            };
                        });
                    if (data === null) {
                        store.dispatch({ type: LoadClinicianTrendType, payload: [] });
                    } else {
                        var resolveddata = Promise.all(data);
                        resolveddata.then(d => {
                            var mergeArray = d.map((m, i) => {
                                return {
                                    ...m,
                                    firstname: providerDisplayFormat(
                                        payload.getProvidersByPracticeId[i]
                                    ),
                                    id: payload.getProvidersByPracticeId[i].id
                                };
                            });
                            store.dispatch(LoadClinicianTrend(mergeArray));
                        });
                    }
                    break;
                default:
                    break;
            }
        }
    };
};

const initialState = {
    isLoading: true,
    Providers: []
};

// Reducer
export default getReducer(initialState, {
    [SetClinicianTrendLoadingType]: (state, { payload }) => {
        return {
            ...state,
            isLoading: payload
        };
    },
    [ProviderTypes.LOADING]: state => {
        return {
            ...state,
            isLoading: true
        };
    },
    [LoadClinicianTrendType]: (state, { payload }) => {
        return {
            ...state,
            Providers: payload,
            isLoading: false
        };
    },
    [ProviderTypes.ERROR]: (state, { payload }) => {
        return {
            isLoading: false,
            Providers: []
        };
    }
});
