export const API_GATEWAY_URL = process.env.API_GATEWAY_URL;
export const DOMAIN_NAME = process.env.DOMAIN_NAME === '0' ? undefined : process.env.DOMAIN_NAME;
export const SECURE_COOKIES = process.env.SECURE_COOKIES;
export const SAME_SITE = process.env.SAME_SITE === '0' ? undefined : 'Strict';
export const HTTP_ONLY = process.env.HTTP_ONLY;
export const RESET_PASSWORD_URL = process.env.RESET_PASSWORD_URL;
export const CRYPTR_ASE_KEY = process.env.CRYPTR_ASE_KEY;

// export const API_GATEWAY_URL = 'https://pegasus-demo.figmd.com:3434/';
// export const DOMAIN_NAME = 'pegasus-demo.figmd.com';
// export const RESET_PASSWORD_URL = 'https://pegasus-demo.figmd.com/resetpassword';

export const ENTITY_COMPARISON_FLAG = process.env.ENTITY_COMPARISON_FLAG
    ? process.env.ENTITY_COMPARISON_FLAG
    : 'cms';

export const MINUS_TOKEN_TIME = process.env.MINUS_TOKEN_TIME;
export const PRACTICE = process.env.PRACTICE;
export const PROVIDER = process.env.PROVIDER;
export const LOCATION = process.env.LOCATION;
export const ED = 'ED';
export const UNDEFINED = undefined;

// CommonRouter
// export const PRACTICE_TAB_CONTAINER = 'PracticePerformance';
// export const PROVIDER_TAB_CONTAINER = 'CliniciansPerformance';
// export const LOCATION_TAB_CONTAINER = 'LocationPerformance';

export const VISIT = process.env.VISIT;
export const SEARCHVISIT = process.env.SEARCHVISIT;
export const API_GET_WEBDATAENTRY = process.env.API_GET_WEBDATAENTRY;
export const API_PATIENTVISIT_URL = process.env.API_PATIENTVISIT_URL;
export const ENTITYCOMPARISONFLAG = process.env.ENTITYCOMPARISONFLAG;
export const MINUSTOKENTIME = process.env.MINUSTOKENTIME;
export const REGISTRY_UNIT_NAME = process.env.REGISTRY_UNIT;
export const REGISTRY_DISPLAY_NAME = process.env.REGISTRY_DISPLAY_NAME;
export const IA_MEASURE_FILTER_ENABLED = process.env.IA_MEASURE_FILTER_ENABLED;
export const PI_MEASURE_FILTER_ENABLED = process.env.PI_MEASURE_FILTER_ENABLED;
export const ENABLE_QUALITY_PERFORMANCE_WIDGET_IN_WEBTOOL =
    process.env.ENABLE_QUALITY_PERFORMANCE_WIDGET_IN_WEBTOOL;
export const BULK_UPLOAD_ENABLED = process.env.BULK_UPLOAD_ENABLED;
export const ENABLE_ADD_VISIT = process.env.ENABLE_ADD_VISIT;
export const ENABLE_EDIT_VISIT = process.env.ENABLE_EDIT_VISIT;
export const ENABLE_DELETE_VISIT = process.env.ENABLE_DELETE_VISIT;
export const ENABLE_VISIT_DATE_ON_EDIT_VISIT_SCREEN =
    process.env.ENABLE_VISIT_DATE_ON_EDIT_VISIT_SCREEN === 'true';
export const VISIT_LIST_COMMUNICATION_BANNER_MESSAGE =
    process.env.VISIT_LIST_COMMUNICATION_BANNER_MESSAGE;

export const SSO_ROUTER_STATUS_N_5 = process.env.SSO_ROUTER_STATUS_N_5;
export const SSO_ROUTER_STATUS_N_4 = process.env.SSO_ROUTER_STATUS_N_4;
export const SSO_ROUTER_STATUS_N_3 = process.env.SSO_ROUTER_STATUS_N_3;
export const SSO_ROUTER_STATUS_N_2 = process.env.SSO_ROUTER_STATUS_N_2;
export const SSO_ROUTER_STATUS_N_1 = process.env.SSO_ROUTER_STATUS_N_1;
export const SSO_ROUTER_STATUS_P_0 = process.env.SSO_ROUTER_STATUS_P_0;
export const SSO_ROUTER_STATUS_P_1 = process.env.SSO_ROUTER_STATUS_P_1;
export const SSO_ROUTER_STATUS_P_2 = process.env.SSO_ROUTER_STATUS_P_2;
export const SSO_ROUTER_STATUS_P_3 = process.env.SSO_ROUTER_STATUS_P_3;

export const DASHBOARD_NOT_AVAILABLE_MESSAGE = process.env.DASHBOARD_NOT_AVAILABLE_MESSAGE;

export const MENU_ACCESS_NOT_AVAILABLE = process.env.MENU_ACCESS_NOT_AVAILABLE;

export const ENABLE_AUTHENTICATION_LINKS = process.env.ENABLE_AUTHENTICATION_LINKS === 'true';

export const WEBTOOL_PATH = {
    QPM: '/qualitypreferencemanagement',
    EDIT_MEASURES: '/editmeasures',
    VISIT_LIST: '/visits',
    EDIT_VISIT: '/editvisit',
    FILE_UPLOAD: '/fileupload',
    WEBTOOL: '/webtool'
};

export const AUTO_HIDE_DURATION_FOR_SNACK_BAR = process.env.AUTO_HIDE_DURATION_FOR_SNACK_BAR
    ? Number(process.env.AUTO_HIDE_DURATION_FOR_SNACK_BAR)
    : 3000;
export const MEASURE_BULK_UPLOAD_PATH = '/measureBulkUpload';

export const WEBTOOL_ADD_VISIT_DEFAULT_TIME_IN24HRFORMAT =
    process.env.WEBTOOL_ADD_VISIT_DEFAULT_TIME_IN24HRFORMAT;

export const PEGASUS_ROUTES = {
    LAYOUT: '/layout'
};

export const PRO_MENU_PATH = {
    PATIENT_BROWSER: '/pro/patientbrowse',
    PATIENT_VIEW: '/pro/patientview',
    QUESTIONNAIRE_DETAILS: '/pro/questionnariedetails',
    SCORE_TRENDS: '/pro/scoretrends'
};

export const SERVICE_DESK_PATH = {
    SERVICE_DESK: '/servicedesk',
    TICKET_DETAILS: '/ticketdetails',
    SERVICE_DESK_TICKET_DETAILS: '/servicedesk/ticketdetails'
};

export const PAYMENTFOR_WEBTOOL_PROVIDER = process.env.PAYMENTFOR_WEBTOOL_PROVIDER;

export const MEASURE_DEF_FILE_NAME_FORMAT = process.env.MEASURE_DEF_FILE_NAME_FORMAT;

export const GROUP_DASHBOARD_COUNT_ZERO_MSG =
    'You have not created any groups yet, Please Create Group';

export const GROUP_BY_ENTITY = {
    PRACTICE: 'Practice',
    PROVIDER: 'Provider',
    LOCATION: 'Location',
    ED: 'ED'
};

export const CLINICIAN_GROUP_MESSAGE = process.env.CLINICIAN_GROUP_MESSAGE;
export const INACTIVE_ENTITY_GROUP_COUNT_ZERO = process.env.INACTIVE_ENTITY_GROUP_COUNT_ZERO;

export const PRACTICE_RESPONSE_RETURN_NULL = process.env.PRACTICE_RESPONSE_RETURN_NULL;
export const PRACTICE_ERROR_MESSAGE_403 = process.env.PRACTICE_ERROR_MESSAGE_403;

export const CLINICIAN_ERROR_MESSAGE_403 = process.env.CLINICIAN_ERROR_MESSAGE_403;
export const CLINICIAN_RESPONSE_RETURN_NULL = process.env.CLINICIAN_RESPONSE_RETURN_NULL;

export const LOCATION_ERROR_MESSAGE_403 = process.env.LOCATION_ERROR_MESSAGE_403;
export const LOCATION_RESPONSE_RETURN_NULL = process.env.LOCATION_RESPONSE_RETURN_NULL;

// For 2FA
export const RESEND_OTP_ATTEMPTS = process.env.RESEND_OTP_ATTEMPTS;

//For QPM
export const SYSTEM_IN = [2, 3];

export const QualityDashboardTab = {
    PRACTICETAB: 'DashboardPracticePerformance',
    CLINICIANTAB: 'DashboardClinicianPerformance',
    LOCATIONTAB: 'DashboardLocationPerformance'
};

export const GroupDashboardTab = {
    PRACTICETAB: 'GroupDashboardPracticePerformance',
    CLINICIANTAB: 'GroupDashboardClinicianPerformance',
    LOCATIONTAB: 'GroupDashboardLocationPerformance'
};

export const visitBulkUploadTemplatePath = process.env.visitBulkUploadTemplatePath;
export const visitBulkUploadTemplateSampleFilePath =
    process.env.visitBulkUploadTemplateSampleFilePath;
export const measureBulkUploadTemplatePath = process.env.measureBulkUploadTemplatePath;

export const IS_SAML_AUTH_ENABLED = process.env.ISSAMLENABLED === 'true';
export const IS_REGISTRY_API_AUTH_ENABLED = process.env.ISREGISTRYAPIENABLED === 'true';

export const REGISTRY_FORGOT_PASSWORD_URL = process.env.REGISTRY_FORGOT_PASSWORD_URL;

export const SAML_REDIRECTION_MSG = process.env.SAML_REDIRECTION_MSG;
export const SAML_AUTHENTICATION_ERROR_MSG = process.env.SAML_AUTHENTICATION_ERROR_MSG;

export const ENABLE_GOOGLE_ANALYTICS_TRACK_LOGIN_PAGE =
    process.env.ENABLE_GOOGLE_ANALYTICS_TRACK_LOGIN_PAGE === 'true';

export const MEASURE_SET_LISTORDER_COLUMN = process.env.MEASURE_SET_LISTORDER_COLUMN;

export const GOOGLE_ANALYTICS_ACCOUNT_ID = process.env.GOOGLE_ANALYTICS_ACCOUNT_ID;

export const UNDIFFERENTIATED = 'UNDIFFERENTIATED';
export const UN = 'UN';
export const MTF = 'MTF';
export const FTM = 'FTM';

export const SAML_LOGIN_ERROR_MSG = process.env.SAML_LOGIN_ERROR_MSG;

export const ACCOUNT_NOT_FOUND_MSG = 'No account found for the provided username.';

export const CREATE_ASSERTION_URL = process.env.CREATE_ASSERTION_URL;

export const MIPS_URL = process.env.MIPS_URL;

export const CAREGAP_GCP_BUCKET_PATH = process.env.CAREGAP_GCP_BUCKET_PATH;

// PRO Module consts
export const ENABLE_PRO_MODULE = process.env.ENABLE_PRO_MODULE === 'true';
export const EMIT_DECLINED_STATUS_ASSIGN_PRO_LIST =
    process.env.EMIT_DECLINED_STATUS_ASSIGN_PRO_LIST === 'true' ? true : false;
export const PERMIT_ASSIGN_TO_PENDING_CONSENT =
    process.env.PERMIT_ASSIGN_TO_PENDING_CONSENT === 'true' ? true : false;
export const HIDE_PATIENT_BROWSER_EMAIL_FILTER =
    process.env.HIDE_PATIENT_BROWSER_EMAIL_FILTER === 'true' ? true : false;
export const DISPLAY_PRO_EXPIRY_IN_DAYS =
    process.env.DISPLAY_PRO_EXPIRY_IN_DAYS === 'true' ? true : false;
export const DISPLAY_PRO_SCORE_MISSING =
    process.env.DISPLAY_PRO_SCORE_MISSING === 'true' ? true : false;

// Uncomment value to fix: PEG-4557: Difference of 5.30 hrs between added date on UI and date captured in response
export const WEBTOOL_DATE_TIME_PICKER_AJV_VALIDATION =
    process.env.IS_WEBTOOL_DATE_TIME_FORMAT_CONVERT_TO_UTC === 'true'
        ? {}
        : {
              'date-time': /^\d\d\d\d-(0?[1-9]|1[0-2])-(0?[1-9]|[12][0-9]|3[01]) ((2[0-3]|[01][0-9])):([0-9]|[0-5][0-9]):([0-9]|[0-5][0-9])$/
          };
export const IS_WEBTOOL_DATE_TIME_FORMAT_CONVERT_TO_UTC =
    process.env.IS_WEBTOOL_DATE_TIME_FORMAT_CONVERT_TO_UTC;

export const PRACTICE_REFRESH_END_DATE_LABEL = process.env.PRACTICE_REFRESH_END_DATE_LABEL;

///Group of the registries
export const GROUP_LIST = process.env.GROUP_LIST;
export const LOGINNAME_VALIDATION_TYPE = process.env.LOGINNAME_VALIDATION_TYPE
    ? process.env.LOGINNAME_VALIDATION_TYPE
    : 'ALPHANUMERIC';
export const CONTACT_USER_TYPE_LIST = process.env.CONTACT_USER_TYPE_LIST;

// Default is false to disable Pagination feature on Dashboard and Group Dashboard
//export const PAGINATION_ENABLED = 'LTP | CTP | QPDP | QPDC | QPDL | GPD';
export const PAGINATION_ENABLED = process.env.PAGINATION_ENABLED
    ? process.env.PAGINATION_ENABLED
    : '';
export const PAGESIZE = process.env.PAGESIZE ? Number(process.env.PAGESIZE) : 10;
//export const PAGESIZE_ARRAY = process.env.PAGESIZE_ARRAY ?  process.env.PAGESIZE_ARRAY : '10 | 20 | 30';
export const PAGESIZE_ARRAY = process.env.PAGESIZE_ARRAY
    ? process.env.PAGESIZE_ARRAY
    : '10 | 20 | 30';
export const QUALITY_DASHBOARD_HEADING_LABEL = process.env.QUALITY_DASHBOARD_HEADING_LABEL;

export const CUSTOM_DURATION_PERFORMANCE_TREND_LABEL =
    process.env.CUSTOM_DURATION_PERFORMANCE_TREND_LABEL;
// Default is [] to shows all the columns on Clinicians page.
export const PRACTICE_PROFILE_HEADER = process.env.PRACTICE_PROFILE_HEADER
    ? process.env.PRACTICE_PROFILE_HEADER.split(',')
    : ['CliniciansNameNpi', 'designationname', 'providerSpeciality'];

export const LOCATION_ADD_EDIT_MANDATORY_FIELDS = process.env.LOCATION_ADD_EDIT_MANDATORY_FIELDS
    ? process.env.LOCATION_ADD_EDIT_MANDATORY_FIELDS
    : 'locationId,locationName';
export const EMAIL_ADDRESS_REGEX = /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9]+)*(\.[a-zA-Z]{2,4})$/;

export const HIDDEN_MEASURE_STATUS = process.env.HIDDEN_MEASURE_STATUS.split(',');

export const IS_DATA_IN_COMPLETE_REQUIRED =
    process.env.IS_DATA_IN_COMPLETE_REQUIRED.toLowerCase() === 'true';

export const PHI_IDENTIFIER_URL = process.env.PHI_IDENTIFIER_URL;

export const SD_PHI_WARNING_MSG = `DO NOT ENTER ANY PHI UNLESS YOU FIRST SELECT THE "SELECT TO ADD PHI" BUTTON BELOW AND ENTER IT INTO THE "PHI DESCRIPTION FIELD". ANY SUPPORTING DOCUMENTS OR OTHER INFORMATION THAT MAY CONTAIN PHI SHOULD BE ENTERED <strong>ONLY IF THE "SELECT TO ADD PHI" BUTTON IS PRESSED.</strong> PLEASE CLICK <a href='${PHI_IDENTIFIER_URL}' target='blank'>HERE</a> TO VIEW PHI IDENTIFIERS. FIGMD IS NOT LIABLE FOR ANY PHI SENT TO US IN ERROR.`;

export const MEASURE_COMPUTATION_TYPE = ['CUSTOMPRIME100', 'CUSTOMPRIMEPRO', 'MEDIANZSCORE'];
export const CUSTOMPRIME100 = 'CUSTOMPRIME100';
export const CUSTOMPRIMEPRO = 'CUSTOMPRIMEPRO';
export const MEDIANZSCORE = 'MEDIANZSCORE';
export const CUSTOMIROMS = 'CUSTOMIROMS';
export const NORMAL = 'NORMAL';

export const FETCH_PRACTICE_REFRESH_DATE =
    process.env.FETCH_PRACTICE_REFRESH_DATE.toLowerCase() === 'true';
export const FILTER_PRACTICE_BY_PARENTID =
    process.env.FILTER_PRACTICE_BY_PARENTID.toLowerCase() === 'true';
export const FETCH_ED = process.env.FETCH_ED.toLowerCase() === 'true';
export const PERFORMANCE_SYSTEM_FLAG = process.env.PERFORMANCE_SYSTEM_FLAG
    ? parseInt(process.env.PERFORMANCE_SYSTEM_FLAG)
    : 0;

export const HELP_PAGE_URL = process.env.HELP_PAGE_URL;
export const DISPLAY_HELP_ICON = process.env.DISPLAY_HELP_ICON === 'true';
export const DEFAULT_FILTER_BY_REFRESH_DATE = process.env.DEFAULT_FILTER_BY_REFRESH_DATE === 'true';
export const SD_MAX_FILE_SIZE = parseInt(process.env.SD_MAX_FILE_SIZE);
export const DISPLAY_PR_BENCHMARK = process.env.DISPLAY_PR_BENCHMARK;

// PIA Module consts
export const ENABLE_CREATE_PLAN = process.env.ENABLE_CREATE_PLAN === 'true' ? true : false;

export const PIA_PATHS = {
    DASHBOARD: '/dashboard',
    PLAN_DASHBOARD: '/pia',
    CREATE_PLAN: '/dashboard/createplan'
};

export const SD_FILE_EXT_ACCEPTED = process.env.SD_FILE_EXT_ACCEPTED;

export const ALLOW_WEBTOOL_DATA_ENTRY_PREVIOUS_YEAR_DATE_TIME =
    process.env.ALLOW_WEBTOOL_DATA_ENTRY_PREVIOUS_YEAR_DATE_TIME;
export const ALLOW_WEBTOOL_DATA_ENTRY_CURRENT_YEAR_DATE_TIME =
    process.env.ALLOW_WEBTOOL_DATA_ENTRY_CURRENT_YEAR_DATE_TIME;
export const SHOW_CAREGAP_ON_DASHBOARD =
    process.env.SHOW_CAREGAP_ON_DASHBOARD === 'true' ? true : false;

export const GET_CURRENT_DATE_FROM_SERVER = process.env.GET_CURRENT_DATE_FROM_SERVER;
export const CAREGAP_DEVELOPED_MEASURENO = process.env.CAREGAP_DEVELOPED_MEASURENO;
export const FETCH_PRACTICE_MEASURE_STATUS =
    process.env.FETCH_PRACTICE_MEASURE_STATUS === 'true' ? true : false;

export const EDMeasureType = {
    DEFAULT: 0,
    'RCM ONLY': 1,
    'RCM+EHR': 2,
    'EHR ONLY': 3,
    'IA ONLY': 4
};

export const MSG_DISPLAY_CONTAINER = {
    QUALITY_DASHBOARD: 'QUALITY_DASHBOARD',
    PATIENT_DRILL_DOWN: 'PATIENT_DRILL_DOWN',
    CLINICIAN_TREND: 'CLINICIAN_TREND',
    MEASURE_DETAILS: 'MEASURE_DETAILS'
};

export const JIRA_STATUS_IN_DB = process.env.JIRA_STATUS_IN_DB === 'true' ? 'true' : 'false';

export const DISPLAY_MEASURE_LEVEL_REFRESH_DATE =
    process.env.DISPLAY_MEASURE_LEVEL_REFRESH_DATE === 'true';

export const MEASURE_BULK_UPLOAD_FILE_SIZE = parseInt(process.env.MEASURE_BULK_UPLOAD_FILE_SIZE);

export const ENABLE_GOOGLE_ANALYTICS =
    process.env.ENABLE_GOOGLE_ANALYTICS === 'true' ? true : false;

export const IMPORT_CAREGAP_SUBAPP =
    process.env.IMPORT_CAREGAP_SUBAPP === 'true' || process.env.IMPORT_CAREGAP_SUBAPP === true;
