import { GET_ALL_PROGRAM_DETAILS, GET_PRACTICE_PROGRAM_DETAILS } from '@/graphql/query';

import APIHelper from '@/helper/apihelper';
import { getJwt } from '../../utils/jwtUtils';

const api = new APIHelper();

export const getAllProgramDetails = params => {
    const requestOption = api.getRequestOption(
        GET_ALL_PROGRAM_DETAILS,
        params,
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.Common, requestOption, response => response);
};

export const getPracticeProgramDetails = params => {
    const requestOption = api.getRequestOption(
        GET_PRACTICE_PROGRAM_DETAILS,
        params,
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.Common, requestOption, response => response);
};

export const updatePracticeProgramDetails = (params, paramQuery) => {
    const requestOption = api.getRequestOption(paramQuery, params, api.Actions.Create, getJwt());
    return api.PegasusAPI(api.Resources.Common, requestOption, response => response);
};

//Sample reference  Query for update
export const UPDATE_PROGRAM_BY_PRACTICE = `mutation(
  $setEntityAttributesInput1: SetEntityAttributesInput!
  $setEntityAttributesInput2: SetEntityAttributesInput!
  $setEntityAttributesInput3: SetEntityAttributesInput!
  $setEntityAttributesInput4: SetEntityAttributesInput!
  $setEntityAttributesInput5: SetEntityAttributesInput!
) {
  setEntityAttributes1: setEntityAttributes(input: $setEntityAttributesInput1) {
    id
  }
  setEntityAttributes2: setEntityAttributes(input: $setEntityAttributesInput2) {
    id
  }
  setEntityAttributes3: setEntityAttributes(input: $setEntityAttributesInput3) {
    id
  }
  setEntityAttributes4: setEntityAttributes(input: $setEntityAttributesInput4) {
    id
  }
  setEntityAttributes5: setEntityAttributes(input: $setEntityAttributesInput5) {
    id
  }
}`;
