import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import Routers from '@/routes/app.routes';

const theme = createMuiTheme({
    palette: {
        primary: { main: '#4194F2' }, // Purple and green play nicely together.
        secondary: { main: '#6ABC6A' } // This is just green.A700 as hex.
    }
});

export default class Root extends Component {
    render() {
        const { store, history } = this.props;
        return (
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <MuiThemeProvider theme={theme}>
                        <Routers {...store} />
                    </MuiThemeProvider>
                </ConnectedRouter>
            </Provider>
        );
    }
}
