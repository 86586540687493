import { getActionCreator, getActionType, getActionTypes, getReducer } from '@/utils/redux';
import {
    getPerformanceGroupList,
    getPerformanceGroupEntityData,
    getClinicianListEntityGroup
} from '@/redux/services/groupDashboardApi';
import { getLocationCount, getPractices, getProviderCount } from '@/redux/services/practiceApi';
import { getLocationData, getEdsList } from '@/redux/services/locationPerformanceApi';
import { GROUP_BY_ENTITY, FETCH_ED } from '@/helper/constants';
import {
    setDefaultGroupSelected,
    getClinicanList,
    getLocationList
} from '@/containers/GroupPerformance/groupCommonMethods';
import { getUnique } from '@/helper/commonFunction';
import { providerDisplayFormat, practiceDisplayFormat } from '@/helper/commonFunction';
// Types
export const PerformanceGroupTypes = getActionTypes('PERFORMANCEGROUPLIST');
export const GetSelectedGroupInfoTypes = getActionTypes('GROUPINFO');
export const GetPracticeGroupEntityListTypes = getActionTypes('GETPRACTICEGROUPENTITYLIST');
export const GetLocationGroupEntityListTypes = getActionTypes('GETLOCATIONGROUPENTITYLIST');
export const GetClinicanGroupEntityListTypes = getActionTypes('GETCLINICIANGROUPENTITYLIST');
export const CreatePerformanceGroupByEntityTypes = getActionTypes('CREATEPERFORMANCEGROUPBYENTITY');
export const FilterClinicianTypes = getActionTypes('FILTERCLINICIAN');
export const FilterLocationTypes = getActionTypes('FILTERLOCATION');
export const PracticeClinicianCountTypes = getActionTypes('PRACTICECLINICIANCOUNT');
export const PracticeLocationCountTypes = getActionTypes('PRACTICELOCATIONCOUNT');

export const SetSelectedGroupInfoType = getActionType('SETSELECTEDGROUPINFO');
export const SetSelectedGroupIDType = getActionType('SETSELECTEDGROUPID');
export const AddNewGroupToGroupListType = getActionType('ADDNEWGROUPTOGROUPLIST');
export const ResetClinicianLocationEntityListType = getActionType('RESETCLINICIANLOCATIONLIST');
export const SetEditGroupType = getActionType('SetEditGroup');
export const SetDefaultPracticeOptionType = getActionType('SetDefaultPracticeOption');
export const SetDefaultProviderOptionType = getActionType('SetDefaultProviderOption');
export const SetDefaultLocationOptionType = getActionType('SetDefaultLocationOption');
export const ResetSetPracticeLocationCountType = getActionType('ResetSetPracticeLocationCount');

// Actions
export const SetDefaultLocationOptionAction = getActionCreator(SetDefaultLocationOptionType);
export const SetDefaultPracticeOptionAction = getActionCreator(SetDefaultPracticeOptionType);
export const SetDefaultProviderOptionAction = getActionCreator(SetDefaultProviderOptionType);

export const SetEditGroupAction = getActionCreator(SetEditGroupType);
export const SetSelectedGroupInfoAction = getActionCreator(SetSelectedGroupInfoType);
export const SetSelectedGroupIDAction = getActionCreator(SetSelectedGroupIDType);
export const AddNewGroupToGroupListAction = getActionCreator(AddNewGroupToGroupListType);
export const ResetClinicianLocationEntityListAction = getActionCreator(
    ResetClinicianLocationEntityListType
);
export const ResetSetPracticeLocationCountAction = getActionCreator(
    ResetSetPracticeLocationCountType
);

export const getPracticeCinicianCount = practiceId => ({
    types: PracticeClinicianCountTypes,
    callAPI: () => getProviderCount(practiceId)
});

export const getPracticeLocationCount = practiceId => ({
    types: PracticeLocationCountTypes,
    callAPI: () => getLocationCount(practiceId)
});

export const getSelectedGroupData = async (selectedGroupId, practiceList) => {
    const response = await getPerformanceGroupEntityData(selectedGroupId);
    if (response.data.getGroupEntitys) {
        const entityName =
            response.data.getGroupEntitys.length > 0
                ? response.data.getGroupEntitys[0].entityname
                : '';

        let groupInfo = response.data.getGroupEntitys;
        if (entityName === GROUP_BY_ENTITY.PRACTICE) {
            groupInfo = filterGroupEntityInfo(groupInfo, practiceList, 'entityid');
        } else if (
            entityName === GROUP_BY_ENTITY.PROVIDER ||
            entityName === GROUP_BY_ENTITY.LOCATION
        ) {
            groupInfo = filterGroupEntityInfo(groupInfo, practiceList, 'parententityid');
        }
        const groupEntityIds = [...new Set(groupInfo.map(item => parseInt(item.entityid)))];
        let groupEntityOptionList = [];
        if (entityName === GROUP_BY_ENTITY.PROVIDER) {
            groupEntityOptionList = await getClinicanList(groupEntityIds);
        } else if (entityName === GROUP_BY_ENTITY.LOCATION || entityName === GROUP_BY_ENTITY.ED) {
            groupEntityOptionList = await getLocationList(groupEntityIds);
        }
        return {
            data: {
                groupInfo: groupInfo,
                groupEntityOptionList: groupEntityOptionList,
                isGroupDataLoading: false
            }
        };
    } else
        return {
            data: {
                groupInfo: [],
                groupEntityOptionList: [],
                isGroupDataLoading: false
            }
        };
};

export const filterGroupEntityInfo = (groupInfo, practiceList, objprop) => {
    // console.log(
    //     'TCL: filterGroupEntityInfo -> groupInfo, practiceList, objprop',
    //     groupInfo,
    //     practiceList,
    //     objprop
    // );
    const pracIdArr = practiceList.map(i => i.id);
    // console.log(
    //     "TCL: filterGroupEntityInfo -> groupInfo.filter(p => pracIdArr.indexOf(parseInt(p[objprop])) >= 0 || p[objprop] === '')",
    //     groupInfo.filter(p => pracIdArr.indexOf(parseInt(p[objprop])) >= 0 || p[objprop] === '')
    // );
    return groupInfo.filter(p => pracIdArr.indexOf(parseInt(p[objprop])) >= 0 || p[objprop] === '');
};

export const addGroupToList = async (store, param, grpId) => {
    const updatedGroupList = store.getState().GroupDashboard.performanceGroupList;
    updatedGroupList.push({
        id: grpId,
        name: param.input.name,
        entity: param.input.entity,
        description: null,
        isactive: true
    });
    return {
        data: {
            performanceGroupList: updatedGroupList,
            selectedGroup: {
                id: grpId,
                name: param.input.name,
                groupEntity: param.input.entity
            }
        }
    };
};

export const getSelectedGroupInfoAction = (param, practiceList) => ({
    types: GetSelectedGroupInfoTypes,
    callAPI: () => getSelectedGroupData(param, practiceList)
});

export const getPracticeGroupEntityListAction = () => ({
    types: GetPracticeGroupEntityListTypes,
    callAPI: () => getPractices(),
    handleAction: async ({ type, payload, store }) => {
        switch (type) {
            case GetPracticeGroupEntityListTypes.SUCCESS: {
                if (store.getState().GroupDashboard.editGroup) {
                    let defaultValue = [],
                        entityList = [];
                    payload.getPractices.map(item => {
                        entityList.push({
                            value: item.id,
                            label: practiceDisplayFormat(item), //`${item.externalid} - ${item.name}`,
                            entityid: item.id,
                            parententityid: '',
                            parententityname: ''
                        });
                    });
                    const groupInfo = store.getState().GroupDashboard.selectedGroup.groupInfo;
                    groupInfo.map(item => {
                        entityList.map(opt => {
                            if (
                                item.entityname.toString() === GROUP_BY_ENTITY.PROVIDER ||
                                item.entityname.toString() === GROUP_BY_ENTITY.LOCATION
                            ) {
                                if (
                                    opt.value.toString() === item.parententityid.toString() &&
                                    !defaultValue.includes(opt)
                                ) {
                                    defaultValue.push(opt);
                                }
                            } else {
                                if (
                                    opt.value.toString() === item.entityid.toString() &&
                                    !defaultValue.includes(opt)
                                ) {
                                    defaultValue.push(opt);
                                }
                            }
                        });
                    });
                    store.dispatch(SetDefaultPracticeOptionAction(defaultValue));
                }
                break;
            }
            default:
                break;
        }
    }
});

export const getLocationGroupEntityListAction = (queryParams, prevSelectedPracticeOpt) => ({
    types: GetLocationGroupEntityListTypes,
    callAPI: () => (FETCH_ED ? getEdsList(queryParams) : getLocationData(queryParams)),
    handleAction: async ({ type, payload, store }) => {
        switch (type) {
            case GetLocationGroupEntityListTypes.SUCCESS: {
                const responseList = FETCH_ED ? payload.getPractices : payload.getLocations;
                if (store.getState().GroupDashboard.editGroup) {
                    let defaultValue = [],
                        entityList = [];
                    let physicianGrpSelected = false;
                    if (FETCH_ED) {
                        const entityList = store.getState().GroupDashboard.selectedGroup
                            .groupEntityOptionList;
                        physicianGrpSelected =
                            entityList.length > 0
                                ? entityList[0].parententityid !== ''
                                    ? true
                                    : false
                                : false;
                    }
                    responseList.map(item => {
                        if (item.practicename) {
                            entityList.push({
                                value: item.id,
                                label:
                                    item.externalid !== null
                                        ? `${item.externalid}${
                                              item.name !== null ? item.name && '-' + item.name : ''
                                          }(${item.practiceexternalid} - ${item.practicename})`
                                        : `${item.name}(${item.practiceexternalid} - ${item.practicename})`,
                                entityid: item.id,
                                parententityid: item.practiceid,
                                parententityname: GROUP_BY_ENTITY.PRACTICE
                            });
                        } else {
                            entityList.push({
                                value: item.id,
                                label:
                                    item.externalid !== null
                                        ? `${item.externalid}${
                                              item.name !== null ? item.name && '-' + item.name : ''
                                          }`
                                        : `${item.name}`,
                                entityid: item.id,
                                parententityid: physicianGrpSelected ? item.parentid : '',
                                parententityname: GROUP_BY_ENTITY.PRACTICE,
                                parentId: FETCH_ED ? item.parentid : ''
                            });
                        }
                    });
                    const selectedGroup = store.getState().GroupDashboard.selectedGroup;
                    selectedGroup.groupInfo.map(item => {
                        entityList.map(opt => {
                            if (
                                opt.value.toString() === item.entityid.toString() &&
                                !defaultValue.includes(opt)
                            ) {
                                defaultValue.push(opt);
                            }
                        });
                    });
                    const currentSelectedLocationOption = store.getState().GroupDashboard
                        .selectedLocationOption;
                    if (currentSelectedLocationOption.length > 0) {
                        const currentPracSelectedOpt = store.getState().GroupDashboard
                            .selectedPracticeOption;
                        if (prevSelectedPracticeOpt.length !== currentPracSelectedOpt.length) {
                            let practiceFilterOpt = [];
                            currentSelectedLocationOption.forEach(function(val, index) {
                                if (
                                    currentPracSelectedOpt.filter(
                                        obj => obj.value === val.parententityid
                                    ).length > 0
                                ) {
                                    practiceFilterOpt.push(val);
                                }
                            });
                            defaultValue = practiceFilterOpt;
                        }
                        store.dispatch(SetDefaultLocationOptionAction(defaultValue));
                    } else {
                        if (prevSelectedPracticeOpt.length === 0) {
                            store.dispatch(SetDefaultLocationOptionAction(defaultValue));
                        }
                    }
                } else {
                    const selectedLocation = store.getState().GroupDashboard.selectedLocationOption;
                    if (selectedLocation.length > 0) {
                        let updatedOption = [];
                        selectedLocation.map(item => {
                            responseList.map(opt => {
                                if (opt.id === item.entityid) {
                                    updatedOption.push(item);
                                }
                            });
                        });
                        store.dispatch(SetDefaultLocationOptionAction(updatedOption));
                    }
                }
                break;
            }
            default:
                break;
        }
    }
});

export const getClinicianGroupEntityListAction = (queryParams, prevSelectedPracticeOpt) => ({
    types: GetClinicanGroupEntityListTypes,
    callAPI: () => getClinicianListEntityGroup(queryParams),
    handleAction: async ({ type, payload, store }) => {
        switch (type) {
            case GetClinicanGroupEntityListTypes.SUCCESS: {
                if (store.getState().GroupDashboard.editGroup) {
                    let defaultValue = [],
                        entityList = [];
                    payload.getProviderPracticeDetail.map(item => {
                        if (item.practice) {
                            item.practice.map(pracObj => {
                                entityList.push({
                                    value: `${item.id}- ${pracObj.id}`,
                                    providerId: item.id,
                                    label:
                                        providerDisplayFormat(item) +
                                        ` (${pracObj.externalid} - ${pracObj.name})`,
                                    entityid: item.id,
                                    parententityid: pracObj.id,
                                    parententityname: GROUP_BY_ENTITY.PRACTICE
                                });
                            });
                        } else {
                            entityList.push({
                                value: item.id,
                                providerId: item.id,
                                label: providerDisplayFormat(item), //`${item.firstname} ${item.lastname} - ${item.npi}`,
                                entityid: item.id,
                                parententityid: '',
                                parententityname: GROUP_BY_ENTITY.PRACTICE
                            });
                        }
                    });
                    const selectedGroup = store.getState().GroupDashboard.selectedGroup;
                    selectedGroup.groupInfo.map(item => {
                        entityList.map(opt => {
                            if (GROUP_BY_ENTITY.PROVIDER === selectedGroup.groupEntity) {
                                if (
                                    opt.providerId.toString() === item.entityid.toString() &&
                                    opt.parententityid.toString() ===
                                        item.parententityid.toString() &&
                                    !defaultValue.includes(opt)
                                ) {
                                    defaultValue.push(opt);
                                }
                            } else {
                                if (
                                    opt.value.toString() === item.entityid.toString() &&
                                    !defaultValue.includes(opt)
                                ) {
                                    defaultValue.push(opt);
                                }
                            }
                        });
                    });
                    const currentSelectedProviderOption = store.getState().GroupDashboard
                        .selectedClinicianOption;
                    if (currentSelectedProviderOption.length > 0) {
                        const currentPracSelectedOpt = store.getState().GroupDashboard
                            .selectedPracticeOption;
                        if (prevSelectedPracticeOpt.length !== currentPracSelectedOpt.length) {
                            let practiceFilterOpt = [];
                            currentSelectedProviderOption.forEach(function(val, index) {
                                if (
                                    currentPracSelectedOpt.filter(
                                        obj => obj.value === val.parententityid
                                    ).length > 0
                                ) {
                                    practiceFilterOpt.push(val);
                                }
                            });
                            defaultValue = practiceFilterOpt;
                        }
                        store.dispatch(SetDefaultProviderOptionAction(defaultValue));
                    } else {
                        if (prevSelectedPracticeOpt.length === 0) {
                            store.dispatch(SetDefaultProviderOptionAction(defaultValue));
                        }
                    }
                } else {
                    const selectedClinician = store.getState().GroupDashboard
                        .selectedClinicianOption;
                    if (selectedClinician.length > 0) {
                        let updatedOption = [];
                        selectedClinician.map(item => {
                            payload.getProviderPracticeDetail.map(opt => {
                                if (item.providerId === opt.id) {
                                    updatedOption.push(item);
                                }
                            });
                        });
                        store.dispatch(SetDefaultProviderOptionAction(updatedOption));
                    }
                }
                break;
            }
            default:
                break;
        }
    }
});

export const filterClinicianAction = queryParams => ({
    types: FilterClinicianTypes,
    callAPI: () => getClinicianListEntityGroup(queryParams)
});

export const filterLocationAction = queryParams => ({
    types: FilterLocationTypes,
    callAPI: () => (FETCH_ED ? getEdsList(queryParams) : getLocationData(queryParams))
});

export const getGroupPerformanceListAction = permissionObj => ({
    types: PerformanceGroupTypes,
    callAPI: () => getPerformanceGroupList(permissionObj),
    handleAction: async ({ type, payload, store }) => {
        switch (type) {
            case PerformanceGroupTypes.SUCCESS: {
                if (payload.getGroups !== null) {
                    const selectedGroupId = setDefaultGroupSelected(payload).id;
                    const practiceList = store.getState().GroupDashboard.groupPracticeDDLList;
                    if (payload.getGroups.length > 0) {
                        store.dispatch(
                            SetSelectedGroupInfoAction(
                                (await getSelectedGroupData(selectedGroupId, practiceList)).data
                            )
                        );
                    }
                }
                break;
            }
            default:
                break;
        }
    }
});

const initialState = {
    editGroup: false,
    isLoading: true,
    performanceGroupList: [],
    selectedGroup: {
        id: null,
        name: null,
        groupEntity: null,
        groupInfo: [],
        groupEntityOptionList: [],
        isGroupDataLoading: false
    },
    groupPracticeDDLList: [],
    groupProviderDDLList: [],
    groupLocationDDLList: [],
    entityListLoading: false,
    isLocationListLoading: false,
    isClinicianListLoading: false,
    isFilter: false,
    selectedPracticeOption: [],
    selectedLocationOption: [],
    selectedClinicianOption: [],
    providerCnt: null,
    locationCnt: null,
    error: {
        code: '',
        message: ''
    }
};

// Reducer
export default getReducer(initialState, {
    [SetDefaultLocationOptionType]: (state, { payload }) => ({
        ...state,
        selectedLocationOption: payload
    }),
    [SetDefaultProviderOptionType]: (state, { payload }) => ({
        ...state,
        selectedClinicianOption: payload
    }),
    [SetDefaultPracticeOptionType]: (state, { payload }) => ({
        ...state,
        selectedPracticeOption: payload
    }),
    [SetEditGroupType]: (state, { payload }) => ({
        ...state,
        editGroup: payload
    }),
    [FilterLocationTypes.READY]: (state, { payload }) => ({
        ...state,
        isFilter: true
    }),
    [FilterLocationTypes.SUCCESS]: (state, { payload }) => ({
        ...state,
        groupLocationDDLList: FETCH_ED
            ? getUnique([...state.groupLocationDDLList, ...payload.getPractices], 'id')
            : getUnique([...state.groupLocationDDLList, ...payload.getLocations], 'id'),
        isFilter: false
    }),
    [FilterClinicianTypes.READY]: (state, { payload }) => ({
        ...state,
        isFilter: true
    }),
    [FilterClinicianTypes.SUCCESS]: (state, { payload }) => ({
        ...state,
        groupProviderDDLList: getUnique(
            [...state.groupProviderDDLList, ...payload.getProviderPracticeDetail],
            'id'
        ),
        isFilter: false
    }),
    [ResetClinicianLocationEntityListType]: (state, { payload }) => ({
        ...state,
        groupProviderDDLList:
            state.groupPracticeDDLList.length > 1 ? [] : state.groupProviderDDLList,
        groupLocationDDLList:
            state.groupPracticeDDLList.length > 1 ? [] : state.groupLocationDDLList,
        selectedPracticeOption: [],
        selectedLocationOption: [],
        selectedClinicianOption: []
    }),
    [GetLocationGroupEntityListTypes.SUCCESS]: (state, { payload }) => ({
        ...state,
        groupLocationDDLList: FETCH_ED ? payload.getPractices || [] : payload.getLocations || [],
        isLocationListLoading: false
    }),
    [GetLocationGroupEntityListTypes.READY]: (state, { payload }) => ({
        ...state,
        isLocationListLoading: true
    }),
    [GetClinicanGroupEntityListTypes.SUCCESS]: (state, { payload }) => ({
        ...state,
        groupProviderDDLList: payload.getProviderPracticeDetail || [],
        isClinicianListLoading: false
    }),
    [GetClinicanGroupEntityListTypes.READY]: (state, { payload }) => ({
        ...state,
        isClinicianListLoading: true
    }),
    [PerformanceGroupTypes.SUCCESS]: (state, { payload }) => {
        return {
            ...state,
            isLoading: false,
            performanceGroupList: payload.getGroups || [],
            selectedGroup: {
                id: payload.getGroups !== null ? setDefaultGroupSelected(payload).id : '',
                name: payload.getGroups !== null ? setDefaultGroupSelected(payload).name : '',
                groupEntity:
                    payload.getGroups !== null ? setDefaultGroupSelected(payload).entity : '',
                groupInfo: [],
                groupEntityOptionList: [],
                isGroupDataLoading:
                    payload.getGroups !== null ? payload.getGroups.length !== 0 && true : false
            },
            error: {
                code: '',
                message: ''
            }
        };
    },
    [AddNewGroupToGroupListType]: (state, { payload }) => ({
        ...state,
        isLoading: false,
        performanceGroupList: payload.performanceGroupList || [],
        selectedGroup: {
            id: payload.selectedGroup.id,
            name: payload.selectedGroup.name,
            groupEntity: payload.selectedGroup.groupEntity,
            groupInfo: [],
            groupEntityOptionList: [],
            isGroupDataLoading: true
        }
    }),
    [SetSelectedGroupInfoType]: (state, { payload }) => ({
        ...state,
        selectedGroup: {
            ...state.selectedGroup,
            groupInfo: payload.groupInfo,
            groupEntityOptionList: payload.groupEntityOptionList,
            isGroupDataLoading: payload.isGroupDataLoading
        }
    }),
    [SetSelectedGroupIDType]: (state, { payload }) => ({
        ...state,
        selectedGroup: {
            id: payload.value,
            name: payload.label,
            groupEntity: payload.entity,
            groupInfo: [],
            groupEntityOptionList: [],
            isGroupDataLoading: true
        }
    }),
    [GetSelectedGroupInfoTypes.SUCCESS]: (state, { payload }) => ({
        ...state,
        selectedGroup: {
            ...state.selectedGroup,
            groupInfo: payload.groupInfo,
            groupEntityOptionList: payload.groupEntityOptionList,
            isGroupDataLoading: payload.isGroupDataLoading
        }
    }),
    [GetPracticeGroupEntityListTypes.READY]: (state, { payload }) => ({
        ...state,
        entityListLoading: true
    }),
    [GetPracticeGroupEntityListTypes.SUCCESS]: (state, { payload }) => ({
        ...state,
        entityListLoading: false,
        groupPracticeDDLList: payload.getPractices ? payload.getPractices : []
    }),
    [GetPracticeGroupEntityListTypes.ERROR]: (state, { payload }) => ({
        ...state,
        entityListLoading: false,
        groupPracticeDDLList: []
        // error: {
        //     code: '',
        //     message: 'Error occured while fetching error.'
        // }
    }),
    [PerformanceGroupTypes.ERROR]: (state, { payload }) => {
        return {
            ...state,
            isLoading: false,
            performanceGroupList: [],
            error: {
                code: '',
                message: 'Error occured.'
            }
        };
    },
    [PracticeClinicianCountTypes.SUCCESS]: (state, { payload }) => ({
        ...state,
        providerCnt: payload.getProviderCount.count
    }),
    [PracticeLocationCountTypes.SUCCESS]: (state, { payload }) => ({
        ...state,
        locationCnt: FETCH_ED
            ? payload.getPracticeChildCount
                ? payload.getPracticeChildCount.count
                : ''
            : payload.getLocationCount
            ? payload.getLocationCount.count
            : ''
    }),
    [ResetSetPracticeLocationCountType]: (state, { payload }) => ({
        ...state,
        providerCnt: null,
        locationCnt: null
    })
});
