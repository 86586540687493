import {
    faUserChart,    
    faAngleDown,
    faAngleLeft,
    faAngleUp,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faBallotCheck,
    faBan,
    faBirthdayCake,
    faBullseyePointer,
    faCalendarAlt,
    faCalendarStar,
    faCaretDown,
    faCaretRight,
    faChartLine,
    faClipboardListCheck,
    faCloudUpload,
    faCog,
    faDiagnoses,
    faEdit,
    faEnvelope,
    faExclamationCircle,
    faEye,
    faFileChartLine,
    faFileExport,
    faFileImport,
    faFilePdf,
    faHandHoldingHeart,
    faHeadset,
    faHeart,
    faHospital,
    faHospitals,
    faInfoCircle,
    faLayerPlus,
    faLocationArrow,
    faLongArrowLeft,
    faLongArrowRight,
    faMapMarkedAlt,
    faMapMarkerAlt,
    faMars,
    faMobileAlt,
    faPen,
    faPlus,
    faPlusCircle,
    faQuestionCircle,
    faSearch,
    faStar,
    faStethoscope,
    faThumbsUp,
    faTicketAlt,
    faTimes,
    faTrashAlt,
    faUserCog,
    faUserEdit,
    faUserPlus,
    faUserShield,
    faUsersCog,
    faVenus,
    faVenusMars,
    faFilter,
    faBookmark,
    faCreditCard,
    faRibbon,
    faChartScatter,
    faNotesMedical,
    faPhoneAlt,
    faUser,
    faFileUpload,
    faFileDownload,
    faFile,
    faFileCheck,
    faUserCircle
} from '@fortawesome/pro-light-svg-icons';

export default [
    faUserChart,
    faFileDownload,    
    faAngleUp,
    faCaretRight,
    faCaretDown,
    faUsersCog,
    faUserCog,
    faMapMarkedAlt,
    faLongArrowLeft,
    faUserShield,
    faTicketAlt,
    faQuestionCircle,
    faCog,
    faChartLine,
    faLayerPlus,
    faStar,
    faPen,
    faHeart,
    faTimes,
    faAngleDown,
    faExclamationCircle,
    faFilePdf,
    faThumbsUp,
    faArrowUp,
    faArrowDown,
    faAngleLeft,
    faUserEdit,
    faClipboardListCheck,
    faTrashAlt,
    faUserPlus,
    faHospital,
    faStethoscope,
    faMapMarkerAlt,
    faBullseyePointer,
    faCloudUpload,
    faHeadset,
    faLocationArrow,
    faFileExport,
    faEye,
    faHospitals,
    faBan,
    faHandHoldingHeart,
    faMobileAlt,
    faEnvelope,
    faSearch,
    faCalendarAlt,
    faBirthdayCake,
    faDiagnoses,
    faLongArrowRight,
    faArrowRight,
    faArrowLeft,
    faEdit,
    faPlusCircle,
    faPlus,
    faFileImport,
    faFileChartLine,
    faBallotCheck,
    faInfoCircle,
    faVenusMars,
    faVenus,
    faMars,
    faCalendarStar,
    faFilter,
    faBookmark,
    faCreditCard,
    faRibbon,
    faChartScatter,
    faNotesMedical,
    faPhoneAlt,
    faUser,
    faFileUpload,
    faFile,
    faFileCheck,
    faUserCircle
];
