import { getActionCreator, getActionType, getActionTypes, getReducer } from '@/utils/redux';
import { getClinicianData } from '@/redux/services/clinicianPerformanceApi';

// Types
export const ProviderListTypes = getActionTypes('PROVIDERLIST');
export const UpdateSelectedProviderIdType = getActionType('UPDATESELECTEDPROVIDERID');
export const UpdateProviderPracticeIdType = getActionType('UPDATEPROVIDERPRACTICEID');

export const UpdateSelectedProviderIdAction = getActionCreator(UpdateSelectedProviderIdType);
export const UpdateProviderPracticeIdAction = getActionCreator(UpdateProviderPracticeIdType);

export const getProviderAction = queryParam => ({
    types: ProviderListTypes,
    callAPI: () => getClinicianData(queryParam),
    handleAction: async ({ type, payload, store }) => {
        switch (type) {
            case ProviderListTypes.SUCCESS: {
                store.dispatch(UpdateProviderPracticeIdAction(queryParam.input.practiceid));
                break;
            }
            default:
                break;
        }
    }
});

const initialState = {
    isProviderLoading: true,
    provider: [],
    providerPracticeId: '',
    selectedProviderId: null,
    error: {
        code: '',
        Message: ''
    }
};

// Reducer
export default getReducer(initialState, {
    [UpdateProviderPracticeIdType]: (state, { payload }) => {
        return {
            ...state,
            providerPracticeId: payload
        };
    },
    [ProviderListTypes.READY]: state => ({
        ...state,
        isProviderLoading: true,
        provider: [],
        providerPracticeId: '',
        selectedProviderId: null,
        error: {
            code: '',
            Message: ''
        }
    }),
    [ProviderListTypes.SUCCESS]: (state, { payload }) => ({
        ...state,
        provider: payload.getProvidersByPracticeId || [],
        selectedProviderId:
            payload.getProvidersByPracticeId !== null
                ? payload.getProvidersByPracticeId.length !== 0 &&
                  payload.getProvidersByPracticeId[0].id
                : null,
        isProviderLoading: false,
        error: {
            code: '',
            Message: ''
        }
    }),
    [UpdateSelectedProviderIdType]: (state, { payload }) => ({
        ...state,
        selectedProviderId: payload,
        error: {
            code: '',
            Message: ''
        }
    }),
    [ProviderListTypes.ERROR]: (state, { payload }) => {
        return {
            ...state,
            isProviderLoading: false,
            error: {
                code: '',
                Message: 'Error occured while fetching error.'
            }
        };
    }
});
