import React, { Component } from 'react';
import { ConsentForm } from 'consent-form-app';
import { connect } from 'react-redux';
import * as constants from '../../helper/constants';
import { getJwt } from '../../utils/jwtUtils';
class ConsentFormModal extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.saveLocalStorage();
    }
    componentWillReceiveProps() {
        if (!getJwt()) {
            this.clearLocalStorage();
        }
    }

    saveLocalStorage() {
        localStorage.setItem('Unit', constants.REGISTRY_UNIT_NAME);
        localStorage.setItem('API_GATEWAY_URL', constants.API_GATEWAY_URL);
    }
    clearLocalStorage() {
        localStorage.removeItem('Unit');
        localStorage.removeItem('API_GATEWAY_URL');
        
    }
  
    render() {
        return (
            <div>
                <ConsentForm 
                Unit={process.env.REGISTRY_UNIT}
                constants={constants}
                />
            </div>
        );
    }
}

export default ConsentFormModal;
