import i18next from 'i18next';

import {
    getMeasuresList,
    saveWebtoolAnswers,
    getDisplayNamesForMeasures,
    deleteMeasureQuestionnaireApi,
    deleteAllMeasureQuestionnaireApi
} from '@/redux/services/getMeasuresApi';
import { handleUnauthorizedRequest } from '@/containers/Webtool/webtoolUtils';

export const MEASURES_REQUEST = 'MEASURES_REQUEST';
export const MEASURES_RECEIVE = 'MEASURES_RECEIVE';
export const MEASURES_FAILURE = 'MEASURES_FAILURE';
export const MEASURE_RECEIVE_BY_ID = 'MEASURE_RECEIVE_BY_ID';
export const SAVING_ANSWER = 'SAVING_ANSWER';
export const SAVED_ANSWER = 'SAVED_ANSWER';
export const WEBTOOL_MEASURES_FAILURE = 'WEBTOOL_MEASURES_FAILURE';
export const WEBTOOL_MEASURES_RECEIVE = 'WEBTOOL_MEASURES_RECEIVE';
export const WEBTOOL_MEASURES_REQUEST = 'WEBTOOL_MEASURES_REQUEST';
export const RECV_DISPLAY_NAMES = 'RECV_DISPLAY_NAMES';
export const DELETING_MEASURE_QUESTIONNAIRE = 'DELETING_MEASURE_QUESTIONNAIRE';
export const DELETED_MEASURE_QUESTIONNAIRE = 'DELETED_MEASURE_QUESTIONNAIRE';
export const DELETING_ALL_MEASURE_QUESTIONNAIRE = 'DELETING_ALL_MEASURE_QUESTIONNAIRE';
export const DELETED_ALL_MEASURE_QUESTIONNAIRE = 'DELETED_ALL_MEASURE_QUESTIONNAIRE';
export const CLEAR_STORE = 'CLEAR_STORE';

const requestMeasures = () => ({
    type: MEASURES_REQUEST
});

const receiveMeasures = payload => ({
    type: MEASURES_RECEIVE,
    payload
});

export const failureMeasures = errMsg => ({
    type: MEASURES_FAILURE,
    errorMessage: errMsg
});

const savingAnswer = () => ({
    type: SAVING_ANSWER
});

const savedAnswer = () => ({
    type: SAVED_ANSWER
});

const receivedDisplayNamesForMeasureNo = payload => ({
    type: RECV_DISPLAY_NAMES,
    payload
});

const deletingMeasureQuestionnaire = () => ({
    type: DELETING_MEASURE_QUESTIONNAIRE
});

const deletedMeasureQuestionnaire = payload => ({
    type: DELETED_MEASURE_QUESTIONNAIRE,
    payload: payload
});

const deletingAllMeasureQuestionnaire = () => ({
    type: DELETING_ALL_MEASURE_QUESTIONNAIRE
});

const deletedAllMeasureQuestionnaire = payload => ({
    type: DELETED_ALL_MEASURE_QUESTIONNAIRE,
    payload: payload
});

export const getMeasures = (params,signal) => {
    return async dispatch => {
        dispatch(requestMeasures());
        try {
            let measureInfo = await getMeasuresList(params,signal);
            handleUnauthorizedRequest(measureInfo.status);
            if (measureInfo.ok) {
                let parsedMeasureInfo = await measureInfo.json();
                dispatch(receiveMeasures(parsedMeasureInfo));
            } else {
                throw new Error();
            }
        } catch (error) {
            dispatch(failureMeasures());
        }
    };
};

export const saveAnswers = params => {
    return async dispatch => {
        dispatch(savingAnswer());
        try {
            let resp = await saveWebtoolAnswers(params);
            handleUnauthorizedRequest(resp.status);
            if (resp.ok) {
                let parsedResponse = await resp.json();
                if (parsedResponse.errors) {
                    let errorMsg = '';
                    errorMsg += parsedResponse.errors.map(error => {
                        return error.message + ' ';
                    });
                    console.log('errorMsg ===>', errorMsg);
                    dispatch(failureMeasures(errorMsg));
                } else {
                    dispatch(savedAnswer());
                }
            } else {
                throw new Error();
            }
        } catch (error) {
            dispatch(failureMeasures());
        }
    };
};

export const getDisplayNamesForMeasureNo = (params,signal) => {
    return async dispatch => {
        try {
            let resp = await getDisplayNamesForMeasures(params,signal);
            handleUnauthorizedRequest(resp.status);
            if (resp.ok) {
                let parsedResponse = await resp.json();
                if (parsedResponse.errors) {
                    let errorMsg = '';
                    errorMsg += parsedResponse.errors.map(error => {
                        return error.message + ' ';
                    });
                    console.log('errorMsg ===>', errorMsg);
                } else {
                    dispatch(receivedDisplayNamesForMeasureNo(parsedResponse));
                }
            } else {
                throw new Error();
            }
        } catch (error) {
            console.log('error ===>', error);
        }
    };
};

export const deleteMeasureQuestionnaire = params => {
    return async dispatch => {
        dispatch(deletingMeasureQuestionnaire());
        try {
            let resp = await deleteMeasureQuestionnaireApi(params);
            handleUnauthorizedRequest(resp.status);
            if (resp.ok) {
                let parsedResponse = await resp.json();
                if (parsedResponse.errors) {
                    dispatch(
                        failureMeasures(
                            i18next.t('measureQuestionnaire.errorMessages.somethingWentWrong')
                        )
                    );
                } else {
                    dispatch(deletedMeasureQuestionnaire(parsedResponse));
                }
            } else {
                throw new Error();
            }
        } catch (error) {
            dispatch(
                failureMeasures(i18next.t('measureQuestionnaire.errorMessages.somethingWentWrong'))
            );
        }
    };
};

export const deleteAllMeasureQuestionnaire = params => {
    return async dispatch => {
        dispatch(deletingAllMeasureQuestionnaire());
        try {
            let resp = await deleteAllMeasureQuestionnaireApi(params);
            handleUnauthorizedRequest(resp.status);
            if (resp.ok) {
                let parsedResponse = await resp.json();
                if (parsedResponse.errors) {
                    dispatch(
                        failureMeasures(
                            i18next.t('measureQuestionnaire.errorMessages.somethingWentWrong')
                        )
                    );
                } else {
                    dispatch(deletedAllMeasureQuestionnaire(parsedResponse));
                }
            } else {
                throw new Error();
            }
        } catch (error) {
            dispatch(
                failureMeasures(i18next.t('measureQuestionnaire.errorMessages.somethingWentWrong'))
            );
        }
    };
};

const clearStoreForMeasureQuestionnaire = () => {
    return {
        type: CLEAR_STORE
    }
}

export const clearStore = () => {
    return async dispatch => {
        dispatch(clearStoreForMeasureQuestionnaire());
    }
}