import {
    CONTACT_USER_TYPE_LIST,
    PRACTICE_REFRESH_END_DATE_LABEL,
    FETCH_PRACTICE_REFRESH_DATE,
    FETCH_ED
} from '@/helper/constants';
import { getActionCreator, getActionType, getActionTypes, getReducer } from '@/utils/redux';
import {
    getDemographicDetailsByPracticeId,
    getEntityContactDetails,
    getLocationCountPMS,
    getPracticeContactDetailsData,
    getPracticesPMS,
    getProviderCountPMS
} from '@/redux/services/practiceApi';

import { convertDateToUTC } from '@/helper/commonFunction';
import { getPracticesRefreshDate } from '@/redux/services/practiceRefreshDateApi';

// Types
export const PracticeListTypes = getActionTypes('PRACTICELISTPMS');
export const PracticeContactDetailsTypes = getActionTypes('PRACTICECONTACTDETAILS');
export const PracticeChangeTypes = getActionTypes('PRACTICECHANGEPMS');
export const SetSelectedPracticeType = getActionType('SETSELECTEDPRACTICEPMS');
export const PracticeDemographicDetailsTypes = getActionTypes('GETDEMOGRAPHICSDETAILS');
export const PracticeGetEntityContactDetailsTypes = getActionTypes('GETENTITYCONTACTDETAILS');
export const PracticeCountChangeTypes = getActionType('PRACTICECOUNTCHANGE')
export const ContactLoadingTypes= getActionType('CONTACTLOADING')
// Actions

export const SetSelectedPracticeAction = getActionCreator(SetSelectedPracticeType);
export const SetLocationCountAction = getActionCreator(PracticeCountChangeTypes);
export const SetContactLoadingAction = getActionCreator(ContactLoadingTypes);

export const getSelectedPracticeData = async (
    selectedPracticeId,
    selectedPracticeName,
    selectedPracticeExternalID,
    selectedPracticeInactive,
    selectedPracticeAddress,
    selectedPracticeCity,
    selectedPracticeState,
    selectedPracticePostalCode,
    selectedPracticeCountry,
    selectedPracticeEmail,
    selectedPracticeContactNumber,
    selectedPracticeTin,
    selectedPracticePracticeType,
    selectedPracticeLogo,
    selectedPracticeTimeZone,
    selectedPracticeCreatedat,
    selectedPracticeEHR
) => {
    const response = FETCH_PRACTICE_REFRESH_DATE
        ? await Promise.all([
              getProviderCountPMS(selectedPracticeId),
              getLocationCountPMS(selectedPracticeId),
              getPracticesRefreshDate(selectedPracticeId)
          ])
        : await Promise.all([
              getProviderCountPMS(selectedPracticeId),
              getLocationCountPMS(selectedPracticeId)
          ]);
    return {
        data: {
            id: selectedPracticeId,
            name: selectedPracticeName,
            externalid: selectedPracticeExternalID,
            inactive: selectedPracticeInactive,
            address: selectedPracticeAddress,
            city: selectedPracticeCity,
            state: selectedPracticeState,
            postalcode: selectedPracticePostalCode,
            country: selectedPracticeCountry,
            email: selectedPracticeEmail,
            contactnumber: selectedPracticeContactNumber,
            tin: selectedPracticeTin,
            practicetype: selectedPracticePracticeType,
            logo: selectedPracticeLogo,
            timezone: selectedPracticeTimeZone,
            createdat: selectedPracticeCreatedat,
            ehr: selectedPracticeEHR,
            providerCnt:
                response[0].status === 403
                    ? null
                    : response[0].data.getProviderCount === null ||
                      response[0].data.getProviderCount === undefined
                    ? null
                    : response[0].data.getProviderCount.count,
            locationCnt:
                    response[1].status === 403
                        ? null
                        : FETCH_ED
                            ? response[1].data.getPracticeChildCount && response[1].data.getPracticeChildCount.count
                            : response[1].data.getLocationCount === null ||
                                      response[1].data.getLocationCount === undefined
                                    ? null
                                    : response[1].data.getLocationCount.count,                            
            refreshDateData: FETCH_PRACTICE_REFRESH_DATE
                ? [
                      {
                          label: 'Updated on',
                          format: 'MMM Do YYYY HH:mm',
                          date:
                              response[2].data.getPracticeRefreshStatus &&
                              response[2].data.getPracticeRefreshStatus.length > 0 &&
                              response[2].data.getPracticeRefreshStatus[0].lastrefreshtime &&
                              convertDateToUTC(
                                  new Date(
                                      Number(
                                          response[2].data.getPracticeRefreshStatus[0]
                                              .lastrefreshtime
                                      )
                                  )
                              ),
                          title:
                              'Last when the practice date was refreshed & measure re-calculated.'
                      },
                      // {
                      //     label: 'Start Date',
                      //     format: 'MMM Do YYYY',
                      //     date:
                      //         response[2].data.getPracticeRefreshStatus &&
                      //         response[2].data.getPracticeRefreshStatus.length > 0 &&
                      //         response[2].data.getPracticeRefreshStatus[0].startdate &&
                      //         new Date(Number(response[2].data.getPracticeRefreshStatus[0].startdate)),
                      //     title: 'Start date of the duration against which the date was last refreshed'
                      // },
                      {
                          label: PRACTICE_REFRESH_END_DATE_LABEL,
                          format: 'MMM Do YYYY',
                          date:
                              response[2].data.getPracticeRefreshStatus &&
                              response[2].data.getPracticeRefreshStatus.length > 0 &&
                              response[2].data.getPracticeRefreshStatus[0].enddate &&
                              convertDateToUTC(
                                  new Date(
                                      Number(response[2].data.getPracticeRefreshStatus[0].enddate)
                                  )
                              ),
                          title: 'Last encounter date in the system.'
                      }
                  ]
                : []
        }
    };
};

export const getPracticeListAction = () => ({
    types: PracticeListTypes,
    callAPI: () => getPracticesPMS(),
    handleAction: async ({ type, payload, store }) => {
        switch (type) {
            case PracticeListTypes.SUCCESS: {
                const qualityDashboardSelectedPractice = store.getState().QualityDashboard
                    .selectedPractice.id;
                const {
                    selectedPracticeId,
                    selectedPracticeName,
                    selectedPracticeExternalID,
                    selectedPracticeInactive,
                    selectedPracticeAddress,
                    selectedPracticeCity,
                    selectedPracticeState,
                    selectedPracticePostalCode,
                    selectedPracticeCountry,
                    selectedPracticeEmail,
                    selectedPracticeContactNumber,
                    selectedPracticeTin,
                    selectedPracticePracticeType,
                    selectedPracticeLogo,
                    selectedPracticeTimeZone,
                    selectedPracticeCreatedat,
                    selectedPracticeEHR
                } = getDashboardPractice(payload, qualityDashboardSelectedPractice);

                store.dispatch(
                    SetSelectedPracticeAction(
                        (await getSelectedPracticeData(
                            selectedPracticeId,
                            selectedPracticeName,
                            selectedPracticeExternalID,
                            selectedPracticeInactive,
                            selectedPracticeAddress,
                            selectedPracticeCity,
                            selectedPracticeState,
                            selectedPracticePostalCode,
                            selectedPracticeCountry,
                            selectedPracticeEmail,
                            selectedPracticeContactNumber,
                            selectedPracticeTin,
                            selectedPracticePracticeType,
                            selectedPracticeLogo,
                            selectedPracticeTimeZone,
                            selectedPracticeCreatedat,
                            selectedPracticeEHR
                        )).data
                    )
                );
                break;
            }
            default:
                break;
        }
    }
});

export const onPracticeChangeAction = param => ({
    types: PracticeChangeTypes,
    callAPI: () => getSelectedPracticeData(param),
    handleAction: ({ type, payload, store }) => {
        switch (type) {
            case PracticeChangeTypes.SUCCESS: {
                payload.name = store
                    .getState()
                    .PracticeDetail.practiceList.filter(obj => obj.id === payload.id)[0].name;
                payload.externalid = store
                    .getState()
                    .PracticeDetail.practiceList.filter(obj => obj.id === payload.id)[0].externalid;
                payload.address = store
                    .getState()
                    .PracticeDetail.practiceList.filter(obj => obj.id === payload.id)[0].address;
                payload.inactive = store
                    .getState()
                    .PracticeDetail.practiceList.filter(obj => obj.id === payload.id)[0].inactive;
                payload.city = store
                    .getState()
                    .PracticeDetail.practiceList.filter(obj => obj.id === payload.id)[0].city;
                payload.state = store
                    .getState()
                    .PracticeDetail.practiceList.filter(obj => obj.id === payload.id)[0].state;
                payload.postalcode = store
                    .getState()
                    .PracticeDetail.practiceList.filter(obj => obj.id === payload.id)[0].postalcode;
                payload.country = store
                    .getState()
                    .PracticeDetail.practiceList.filter(obj => obj.id === payload.id)[0].country;
                payload.email = store
                    .getState()
                    .PracticeDetail.practiceList.filter(obj => obj.id === payload.id)[0].email;
                payload.timezone = store
                    .getState()
                    .PracticeDetail.practiceList.filter(obj => obj.id === payload.id)[0].timezone;
                payload.contactnumber = store
                    .getState()
                    .PracticeDetail.practiceList.filter(
                        obj => obj.id === payload.id
                    )[0].contactnumber;
                payload.ehr =
                    store
                        .getState()
                        .PracticeDetail.practiceList.filter(obj => obj.id === payload.id)[0]
                        .ehrdetails[0] &&
                    store
                        .getState()
                        .PracticeDetail.practiceList.filter(obj => obj.id === payload.id)[0]
                        .ehrdetails[0].ehrname;

                store.dispatch(SetSelectedPracticeAction(payload));
                break;
            }
            default:
                break;
        }
    }
});

export const getPracticeContactDetails = param => ({
    types: PracticeContactDetailsTypes,
    callAPI: () => getPracticeContactDetailsData(param)
});

export const getDemographicsDetails = param => ({
    types: PracticeDemographicDetailsTypes,
    callAPI: () => getDemographicDetailsByPracticeId(param)
});

export const getEntityContactDetailsPMS = param => ({
    types: PracticeGetEntityContactDetailsTypes,
    callAPI: () => getEntityContactDetails(param)
});

const initialState = {
    isLoading: true,
    practiceChangeLoading: false,
    practiceList: [],
    demographicDetails: {},
    demographicLoading: true,
    selectedPractice: {
        id: null,
        name: null,
        externalid: null,
        inactive: null,
        address: null,
        city: null,
        state: null,
        postalcode: null,
        country: null,
        email: null,
        contactnumber: null,
        tin: null,
        practicetype: null,
        logo: null,
        timezone: null,
        createdat: null,
        providerCnt: null,
        locationCnt: null,
        refreshDateData: []
    },
    practiceContactDetails: [],
    contactsLoading: true,
    contactinformation: [],
    contactinformationMain: [],
    contactinformationLoading: true,
    isContactExist: false,
    isFirstContactExist: false,
    isSecondContactExist: false,
    error: {
        code: '',
        Message: ''
    }
};

// Reducer
export default getReducer(initialState, {
    [PracticeChangeTypes.LOADING]: state => ({
        ...state,
        practiceChangeLoading: true,
        error: {
            code: '',
            Message: ''
        }
    }),
    [PracticeDemographicDetailsTypes.LOADING]: state => ({
        ...state,
        demographicLoading: true,
        error: {
            code: '',
            Message: ''
        }
    }),
    [PracticeGetEntityContactDetailsTypes.LOADING]: state => ({
        ...state,
        contactinformationLoading: true,
        error: {
            code: '',
            Message: ''
        }
    }),
    [PracticeCountChangeTypes]:state =>({
      ...state,
      selectedPractice:{
          ...state.selectedPractice,
           locationCnt : state.selectedPractice.locationCnt+1
        }
    }),
    [PracticeChangeTypes.SUCCESS]: state => ({
        ...state,
        practiceChangeLoading: false,
        error: {
            code: '',
            Message: ''
        }
    }),
    [PracticeListTypes.SUCCESS]: (state, { payload }) => ({
        ...state,
        practiceList: payload.getPractices || [],
        isLoading: payload.getPractices === null ? false : state.isLoading,
        error: {
            code: '',
            Message: ''
        }
    }),
    [PracticeContactDetailsTypes.SUCCESS]: (state, { payload }) => ({
        ...state,
        practiceContactDetails: payload.userdetails || [],
        //practiceContactDetails: payload.getPracticeContactDetails || [],
        contactsLoading: false
    }),
    [PracticeDemographicDetailsTypes.SUCCESS]: (state, { payload }) => ({
        ...state,
        demographicDetails: payload.getPractices[0] || {},
        demographicLoading: false
    }),
    [PracticeGetEntityContactDetailsTypes.SUCCESS]: (state, { payload }) => {
        if (payload.getEntityContacts.length === 2) {
            if (
                payload.getEntityContacts[0].usertype === payload.getEntityContacts[1].usertype &&
                payload.getEntityContacts[0].firstname.toLowerCase() >
                    payload.getEntityContacts[1].firstname.toLowerCase()
            ) {
                let temp = [];
                temp = { ...payload.getEntityContacts };
                payload.getEntityContacts[0] = payload.getEntityContacts[1];
                payload.getEntityContacts[1] = temp[0];
            }
        }
        return {
            ...state,
            contactinformationLoading: false,
            contactinformation:
                (payload.getEntityContacts &&
                    payload.getEntityContacts.map(contact => {
                        let arrUserType = CONTACT_USER_TYPE_LIST.split('$');
                        let contactUserTypeList = CONTACT_USER_TYPE_LIST
                            ? arrUserType.map(item => JSON.parse(item))
                            : [];
                        const selectedUserType =
                            contactUserTypeList &&
                            Array.isArray(contactUserTypeList) &&
                            contactUserTypeList.filter(
                                item => item.value.toString() === contact.usertype
                            );
                        if (!(selectedUserType && selectedUserType.length > 0))
                            contact.usertype = '';
                        return { ...contact };
                    })) ||
                [],
            contactinformationMain:
                (payload.getEntityContacts &&
                    JSON.stringify(
                        payload.getEntityContacts.map(contact => {
                            let arrUserType = CONTACT_USER_TYPE_LIST.split('$');
                            let contactUserTypeList = CONTACT_USER_TYPE_LIST
                                ? arrUserType.map(item => JSON.parse(item))
                                : [];
                            const selectedUserType =
                                contactUserTypeList &&
                                Array.isArray(contactUserTypeList) &&
                                contactUserTypeList.filter(
                                    item => item.value.toString() === contact.usertype
                                );
                            if (!(selectedUserType && selectedUserType.length > 0))
                                contact.usertype = '';
                            return { ...contact };
                        })
                    )) ||
                [],
            isContactExist: payload.getEntityContacts && payload.getEntityContacts.length > 0,
            isFirstContactExist: payload.getEntityContacts && payload.getEntityContacts.length > 0,
            isSecondContactExist: payload.getEntityContacts && payload.getEntityContacts.length > 1
        };
    },

    [SetSelectedPracticeType]: (state, { payload }) => ({
        ...state,
        selectedPractice: payload,
        isLoading: false,
        contactsLoading: true,
        error: {
            code: '',
            Message: ''
        }
    }),
    [ContactLoadingTypes]: (state, { payload }) => ({
        ...state,
        contactsLoading: payload,
    }),
    [PracticeDemographicDetailsTypes.ERROR]: (state, { payload }) => {
        return {
            ...state,
            demographicDetails: {},
            isLoading: false,
            error: {
                code: '',
                Message: 'Error occurred while fetching data.'
            }
        };
    },
    [PracticeGetEntityContactDetailsTypes.ERROR]: (state, { payload }) => {
        return {
            ...state,
            contactinformation: [
                {
                    firstname: '',
                    middlename: '',
                    lastname: '',
                    email: '',
                    contact1: '',
                    usertype: ''
                },
                {
                    firstname: '',
                    middlename: '',
                    lastname: '',
                    email: '',
                    contact1: '',
                    usertype: ''
                }
            ],
            contactinformationLoading: false,
            error: {
                code: '',
                Message: 'Error occurred while fetching data.'
            }
        };
    },
    [PracticeListTypes.ERROR]: (state, { payload }) => {
        return {
            ...state,
            practiceList: [],
            isLoading: false,
            error: {
                code: '',
                Message: 'Error occured while fetching error.'
            }
        };
    }
});

function getDashboardPractice(payload, qualityDashboardSelectedPractice) {
    let selectedPracticeId = null;
    let selectedPracticeName = null;
    let selectedPracticeExternalID = null;
    let selectedPracticeInactive = null;
    let selectedPracticeAddress = null;
    let selectedPracticeCity = null;
    let selectedPracticeState = null;
    let selectedPracticePostalCode = null;
    let selectedPracticeCountry = null;
    let selectedPracticeEmail = null;
    let selectedPracticeContactNumber = null;
    let selectedPracticeTin = null;
    let selectedPracticePracticeType = null;
    let selectedPracticeLogo = null;
    let selectedPracticeTimeZone = null;
    let selectedPracticeCreatedat = null;
    let selectedPracticeEHR = null;
    if (
        qualityDashboardSelectedPractice === null ||
        qualityDashboardSelectedPractice === undefined
    ) {
        selectedPracticeId = payload.getPractices && payload.getPractices[0].id;
        selectedPracticeName = payload.getPractices && payload.getPractices[0].name;
        selectedPracticeExternalID = payload.getPractices && payload.getPractices[0].externalid;
        selectedPracticeInactive = payload.getPractices && payload.getPractices[0].inactive;
        selectedPracticeAddress = payload.getPractices && payload.getPractices[0].address;
        selectedPracticeCity = payload.getPractices && payload.getPractices[0].city;
        selectedPracticeState = payload.getPractices && payload.getPractices[0].state;
        selectedPracticePostalCode = payload.getPractices && payload.getPractices[0].postalcode;
        selectedPracticeCountry = payload.getPractices && payload.getPractices[0].country;
        selectedPracticeEmail = payload.getPractices && payload.getPractices[0].email;
        selectedPracticeContactNumber =
            payload.getPractices && payload.getPractices[0].contactnumber;
        selectedPracticeTin = payload.getPractices && payload.getPractices[0].tin;
        selectedPracticePracticeType = payload.getPractices && payload.getPractices[0].practicetype;
        selectedPracticeLogo = payload.getPractices && payload.getPractices[0].logo;
        selectedPracticeTimeZone = payload.getPractices && payload.getPractices[0].timezone;
        selectedPracticeCreatedat = payload.getPractices && payload.getPractices[0].createdat;
        selectedPracticeEHR =
            payload.getPractices &&
            payload.getPractices[0].ehrdetails[0] &&
            payload.getPractices[0].ehrdetails[0].ehrname;
    } else {
        selectedPracticeId =
            payload.getPractices &&
            payload.getPractices.filter(obj => obj.id === qualityDashboardSelectedPractice)[0].id;
        selectedPracticeName =
            payload.getPractices &&
            payload.getPractices.filter(obj => obj.id === qualityDashboardSelectedPractice)[0].name;
        selectedPracticeExternalID =
            payload.getPractices &&
            payload.getPractices.filter(obj => obj.id === qualityDashboardSelectedPractice)[0]
                .externalid;
        selectedPracticeInactive =
            payload.getPractices &&
            payload.getPractices.filter(obj => obj.id === qualityDashboardSelectedPractice)[0]
                .inactive;
        selectedPracticeAddress =
            payload.getPractices &&
            payload.getPractices.filter(obj => obj.id === qualityDashboardSelectedPractice)[0]
                .address;
        selectedPracticeCity =
            payload.getPractices &&
            payload.getPractices.filter(obj => obj.id === qualityDashboardSelectedPractice)[0].city;
        selectedPracticeState =
            payload.getPractices &&
            payload.getPractices.filter(obj => obj.id === qualityDashboardSelectedPractice)[0]
                .state;
        selectedPracticePostalCode =
            payload.getPractices &&
            payload.getPractices.filter(obj => obj.id === qualityDashboardSelectedPractice)[0]
                .postalcode;
        selectedPracticeCountry =
            payload.getPractices &&
            payload.getPractices.filter(obj => obj.id === qualityDashboardSelectedPractice)[0]
                .country;
        selectedPracticeEmail =
            payload.getPractices &&
            payload.getPractices.filter(obj => obj.id === qualityDashboardSelectedPractice)[0]
                .email;
        selectedPracticeContactNumber =
            payload.getPractices &&
            payload.getPractices.filter(obj => obj.id === qualityDashboardSelectedPractice)[0]
                .contactnumber;
        selectedPracticeTin =
            payload.getPractices &&
            payload.getPractices.filter(obj => obj.id === qualityDashboardSelectedPractice)[0].tin;
        selectedPracticePracticeType =
            payload.getPractices &&
            payload.getPractices.filter(obj => obj.id === qualityDashboardSelectedPractice)[0]
                .practicetype;
        selectedPracticeLogo =
            payload.getPractices &&
            payload.getPractices.filter(obj => obj.id === qualityDashboardSelectedPractice)[0].logo;
        selectedPracticeTimeZone =
            payload.getPractices &&
            payload.getPractices.filter(obj => obj.id === qualityDashboardSelectedPractice)[0]
                .timezone;
        selectedPracticeCreatedat =
            payload.getPractices &&
            payload.getPractices.filter(obj => obj.id === qualityDashboardSelectedPractice)[0]
                .createdat;
        selectedPracticeEHR =
            payload.getPractices &&
            payload.getPractices.ehrdetails !== null &&
            payload.getPractices.ehrdetails &&
            payload.getPractices.ehrdetails[0] &&
            payload.getPractices.ehrdetails[0].ehrname &&
            payload.getPractices.filter(obj => obj.id === qualityDashboardSelectedPractice)[0]
                .ehrdetails[0].ehrname;
    }

    return {
        selectedPracticeId,
        selectedPracticeName,
        selectedPracticeExternalID,
        selectedPracticeInactive,
        selectedPracticeAddress,
        selectedPracticeCity,
        selectedPracticeState,
        selectedPracticePostalCode,
        selectedPracticeCountry,
        selectedPracticeEmail,
        selectedPracticeContactNumber,
        selectedPracticeTin,
        selectedPracticePracticeType,
        selectedPracticeLogo,
        selectedPracticeTimeZone,
        selectedPracticeCreatedat,
        selectedPracticeEHR
    };
}
