import { Divider, Grid, List, ListItem } from '@material-ui/core';

import Collapse from '@material-ui/core/Collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Loader from '@/helper/loaders';

const MIPSLogo = Loader(() =>
    import(/* webpackChunkName: "MIPSLogo" */ '@/components/MIPSLogo/MIPSLogo')
);

const ConsentLogo = Loader(() =>
    import(/* webpackChunkName: "MIPSLogo" */ '@/components/ConsentLogo/ConsentLogo')
);
const styles = () => ({
    selected1: {
        backgroundColor: 'rgba(204, 224, 248, 1)',
        color: 'rgba(65, 148, 242, 1)'
    }
});
/* THIS IS SIDE MENU COMPONENT:
  ================================================================ */
const MasterNavigationMenu = props => {
    const { classes, menu } = props;
    return (
        <div className='main-navigation__container'>
            <List className='main-navigation__ul'>
                {menu.length
                    ? menu.map((menuObj, index) => {
                          if (menuObj.submenus) {
                              return (
                                  <ListItem
                                      className={classNames(
                                          'main-navigation-with-submenu__li',
                                          props.selectedMenuIndex === menuObj.id
                                              ? props.activeIcon
                                              : ' '
                                      )}
                                      selected={props.selectedMenuIndex === menuObj.id}
                                      classes={{ selected: classes.selected }}
                                      key={menuObj.resource}
                                      onClick={e => {
                                        // props.handleOpen();
                                        // props.handleDrawerClose();
                                          props.handleSubMenuNavigation(
                                              e,
                                              menuObj.id,
                                              menuObj.resource
                                          );
                                      }}
                                  >
                                      <Grid container className='main-nav-with-submenu_content'>
                                          <ListItemIcon className='main-nav--icon__wrapper'>
                                              <Tooltip
                                                  title={menuObj.tooltip || menuObj.resource}
                                                  placement='right'
                                              >
                                                  <span>
                                                      <FontAwesomeIcon
                                                          icon={['fal', menuObj.class]}
                                                      />
                                                  </span>
                                              </Tooltip>
                                          </ListItemIcon>
                                          <ListItemText
                                              primary={menuObj.label || menuObj.resource}
                                          />
                                          <Grid item className='collapse-icon__container'>
                                              {props.currentIndex === menuObj.id &&
                                              props.CollapseIndex ? (
                                                  <FontAwesomeIcon icon={['fal', 'angle-up']} />
                                              ) : (
                                                  <FontAwesomeIcon icon={['fal', 'angle-down']} />
                                              )}
                                          </Grid>
                                      </Grid>
                                      <Collapse
                                          className='submenu-content_wrapper'
                                          in={props.CollapseIndex}
                                          timeout='auto'
                                          unmountOnExit
                                      >
                                          <List className='sub-navigation__ul'>
                                              {menuObj.submenus.map((submenu, i) => {
                                                  return (
                                                      <ListItem
                                                          button
                                                          className={
                                                              props.selectedMenuIndex === submenu.id
                                                                  ? `${props.activeIcon} sub-navigation__li`
                                                                  : 'sub-navigation__li'
                                                          }
                                                          selected={
                                                              props.selectedMenuIndex === submenu.id
                                                          }
                                                          classes={{ selected: classes.selected1 }}
                                                          key={submenu.resource}
                                                          onClick={e => {
                                                              props.handleOpen();
                                                              props.handleDrawerClose();
                                                              props.onListItemClick(
                                                                  '',
                                                                  submenu.id,
                                                                  submenu,
                                                                  submenu.router,
                                                                  true
                                                              );
                                                              e.stopPropagation();
                                                          }}
                                                          data-routeinfo={encodeURI(
                                                              JSON.stringify(submenu)
                                                          )}
                                                          data-path={submenu.router}
                                                      >
                                                          {submenu.class && (
                                                              <ListItemIcon className='main-nav--icon__wrapper'>
                                                                  <Tooltip
                                                                      title={
                                                                          submenu.tooltip ||
                                                                          submenu.resource
                                                                      }
                                                                      placement='right'
                                                                  >
                                                                      <span>
                                                                          <FontAwesomeIcon
                                                                              icon={[
                                                                                  'fal',
                                                                                  submenu.class
                                                                              ]}
                                                                          />
                                                                      </span>
                                                                  </Tooltip>
                                                              </ListItemIcon>
                                                          )}
                                                          <ListItemText
                                                              primary={
                                                                  submenu.label || submenu.resource
                                                              }
                                                          />
                                                      </ListItem>
                                                  );
                                              })}
                                              <Divider />
                                          </List>
                                      </Collapse>
                                  </ListItem>
                              );
                          } else {
                              return (
                                  <ListItem
                                      className={
                                          props.selectedMenuIndex === menuObj.id
                                              ? props.activeIcon
                                              : ' '
                                      }
                                      button
                                      selected={props.selectedMenuIndex === menuObj.id}
                                      classes={{ selected: classes.selected }}
                                      key={menuObj.resource}
                                      onClick={() => {
                                          props.handleOpen();
                                          props.handleDrawerClose();
                                          props.onListItemClick(
                                              '',
                                              menuObj.id,
                                              menuObj,
                                              menuObj.router
                                          );
                                      }}
                                      data-routeinfo={encodeURI(JSON.stringify(menuObj))}
                                      data-path={menuObj.router}
                                  >
                                      <ListItemIcon className='main-nav--icon__wrapper'>
                                          <Tooltip
                                              title={menuObj.tooltip || menuObj.resource}
                                              placement='right'
                                          >
                                              <span>
                                                  {menuObj.resource === 'Mips' && <MIPSLogo />}
                                                  {menuObj.resource === 'consent' && <ConsentLogo />}
                                                  {menuObj.resource !== 'Mips' && menuObj.resource !== 'consent' && (
                                                      <FontAwesomeIcon
                                                          icon={['fal', menuObj.class]}
                                                      />
                                                  )}
                                              </span>
                                          </Tooltip>
                                      </ListItemIcon>
                                      <ListItemText primary={menuObj.label || menuObj.resource} />
                                  </ListItem>
                              );
                          }
                      })
                    : null}
            </List>
        </div>
    );
};

/* PROP TYPES OF SUBMENUS:
================================================================ */
const Submenus = {
    label: PropTypes.string,
    resource: PropTypes.string,
    router: PropTypes.string,
    class: PropTypes.string,
    id: PropTypes.number,
    listorder: PropTypes.number
};

/* PROP TYPES OF MAIN MENUES:
================================================================ */
const MasterNavigationMenuPropTypes = {
    label: PropTypes.string,
    resource: PropTypes.string,
    router: PropTypes.string,
    class: PropTypes.string,
    id: PropTypes.number,
    listorder: PropTypes.number,
    submenus: PropTypes.arrayOf(PropTypes.shape(Submenus))
};

/* PROP TYPES:
================================================================ */
MasterNavigationMenu.propTypes = {
    menu: PropTypes.oneOfType([
        PropTypes.objectOf(PropTypes.object),
        PropTypes.arrayOf(PropTypes.shape(MasterNavigationMenuPropTypes))
    ]),
    selectedMenuIndex: PropTypes.number,
    onListItemClick: PropTypes.func,
    handleOpen: PropTypes.func,
    classes: PropTypes.shape({
        selected: PropTypes.string
    }),
    activeIcon: PropTypes.string
};

export default withStyles(styles)(MasterNavigationMenu);
