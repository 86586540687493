import { DELETE_MEASURE_DATA, DELETE_SOFT_VISIT } from '../../graphql/mutation';
import {
    GET_CALENDAR,
    GET_INSURANCE,
    GET_LOCATIONS,
    GET_PATIENT_VISIT,
    GET_PRACTICE,
    GET_PREFERENCE_MEASURE,
    GET_PROVIDER_PROFILE,
    GET_VISIT,
    GET_PATIENTS_DEMOGRAPHICS,
    GET_PATIENTS_VISITS,
    GET_MEASURE_SET
} from '../../graphql/query';

import APIHelper from '@/helper/apihelper';
import APIHelperService from '@/helper/MockableIOAPIHelper';
import { REGISTRY_UNIT_NAME, MEASURE_SET_LISTORDER_COLUMN } from '@/helper/constants';
import { getJwt } from '../../utils/jwtUtils';

const api = new APIHelper();

// export const getVisits = (params, signal) => {
//     const requestOption = api.getRequestOption(
//         GET_VISIT,
//         params,
//         api.Actions.View,
//         getJwt(),
//         signal
//     );
//     return api.PegasusAPI(api.Resources.patientVisit, requestOption, response => {
//         return response;
//     });
// };

export const getVisits = (params, signal) => {
    let requestOption = api.getRequestOption(
        GET_PATIENTS_VISITS,
        params,
        api.Actions.View,
        getJwt()
    );
    if(signal){
        requestOption = {...requestOption,signal};
    }
    return api.PegasusAPI(api.Resources.patientVisit, requestOption, response => {
        return response;
    });
};

export const getPractices = () => {
    const requestOption = api.getRequestOption(
        GET_PRACTICE,
        {
            input: {
                inactive: false,
                orderBy: 'name',
                practicetype: 0
            }
        },
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.Practice, requestOption, response => response);
};

export const searchVisits = () => {
    const api = new APIHelperService();
    const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Action: api.Actions.View,
            Authorization: getJwt()
        })
        // body: JSON.stringify({ query: GET_VISIT, variables: {} }),
    };

    return fetch(api.Resources.SearchVisit, requestOptions);
};

export const deleteSoftVisit = params => {
    const requestOption = api.getRequestOption(
        DELETE_SOFT_VISIT,
        params,
        api.Actions.Delete,
        getJwt()
    );

    return api.PegasusAPI(api.Resources.patientVisit, requestOption, response => {
        return response;
    });
};

export const deleteMeasures = params => {
    const requestOption = api.getRequestOption(
        DELETE_MEASURE_DATA,
        params,
        api.Actions.Delete,
        getJwt()
    );

    return api.PegasusAPI(api.Resources.Webtool, requestOption, response => {
        return response;
    });
};

export const getCalendar = (signal) => {
    const api = new APIHelper();
    let requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Action: api.Actions.View,
            Authorization: getJwt()
        }),
        body: JSON.stringify({ query: GET_CALENDAR, variables: { input: { orderBy: 'id' } } })
    };
    if (signal) {
        requestOptions = {...requestOptions, signal}
    }
    return fetch(api.Resources.Measure, requestOptions);
};

export const getVisitProviderProfile = (params,signal) => {
    const api = new APIHelper();
    let requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Action: api.Actions.View,
            Authorization: getJwt()
        }),
        body: JSON.stringify({ query: GET_PROVIDER_PROFILE, variables: params })
    };
    if(signal){
        requestOptions={...requestOptions,signal}
    } 
    return fetch(api.Resources.qualityPreference, requestOptions);
};

export const getVisitProfileMeasurePreference = (params,signal) => {
    const api = new APIHelper();
    let requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Action: api.Actions.View,
            Authorization: getJwt()
        }),
        body: JSON.stringify({ query: GET_PREFERENCE_MEASURE, variables: params })
    };
    if(signal){
        requestOptions={...requestOptions,signal}
    }
    return fetch(api.Resources.qualityPreference, requestOptions);
};

export const getPatientVisitList = params => {
    /**
     * TODO - remove hardcoded values once search API is in place
     */
    params = {
        input: {
            PracticeId: 23,
            ProviderId: 1652,
            Unit: REGISTRY_UNIT_NAME,
            Year: 2018,
            PageSize: 50,
            PageNumber: 1
        }
    };

    const requestOption = api.getRequestOption(
        GET_PATIENT_VISIT,
        params,
        api.Actions.View,
        getJwt()
    );

    return api.PegasusAPI(api.Resources.patientVisit, requestOption, response => {
        return response;
    });
};

export const getInsuranceType = (params,signal) => {
    const api = new APIHelper();
    let requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Action: api.Actions.View,
            Authorization: getJwt()
        }),
        body: JSON.stringify({ query: GET_INSURANCE, variables: params })
    };
    if(signal){
        requestOptions={...requestOptions,signal}
    }
    return fetch(api.Resources.Practice, requestOptions);
};

export const getLocations = (params,signal) => {
    const api = new APIHelper();
    let requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Action: api.Actions.View,
            Authorization: getJwt()
        }),
        body: JSON.stringify({ query: GET_LOCATIONS, variables: params })
    };
    if(signal){
        requestOptions={...requestOptions,signal}
    }
    return fetch(api.Resources.Location, requestOptions);
};

export const getPatientsDemographics = params => {
    const api = new APIHelper();
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Action: api.Actions.View,
            Authorization: getJwt()
        }),
        body: JSON.stringify({ query: GET_PATIENTS_DEMOGRAPHICS, variables: params })
    };
    return fetch(api.Resources.patientVisit, requestOptions);
};

export const getMeasureSet = () => {
    let requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Action: api.Actions.View,
            Resource: 'measureset',
            Authorization: getJwt()
        }),
        body: JSON.stringify({
            query: GET_MEASURE_SET,
            variables: { input: { inactive: false, orderBy: MEASURE_SET_LISTORDER_COLUMN } }
        })
    };
    return api.PegasusAPI(api.Resources.Measure, requestOptions);
};