import {
    CREATE_ENTITY_CONTACT_DETAILS,
    GET_DEMOGRAPHIC_DETAILS_PMS,
    GET_ENTITY_CONTACT_DETAILS,
    GET_MEASURE_STATUS_BY_PRACTICE,
    GET_PRACTICE,
    GET_PRACTICE_CONTACT_DETAILS,
    GET_PRACTICE_COUNT,
    GET_PRACTICE_LOCATION_COUNT,
    GET_PRACTICE_PMS,
    GET_PRACTICE_PROVIDER_COUNT,
    UPDATE_ENTITY_CONTACT_DETAILS,
    UPDATE_PRACTICE_DETAILS,
    GET_PHYSICIAN_GROUP_ED_COUNT
} from '@/graphql/query';
import { FETCH_ED } from '@/helper/constants';
import APIHelper from '@/helper/apihelper';
import { getJwt } from '../../utils/jwtUtils';
import { FILTER_PRACTICE_BY_PARENTID, JIRA_STATUS_IN_DB } from '@/helper/constants';

const api = new APIHelper();

export const getPractices = () => {
    const param = FILTER_PRACTICE_BY_PARENTID
        ? {
              input: {
                  inactive: false,
                  orderBy: 'name',
                  practicetype: 0,
                  parentidIsNull: true
              }
          }
        : {
              input: {
                  practicetype: 0,
                  inactive: false,
                  orderBy: 'name'
              }
          };
    const requestOption = api.getRequestOption(GET_PRACTICE, param, api.Actions.View, getJwt());
    return api.PegasusAPI(api.Resources.Practice, requestOption, response => response);
};

export const getPracticesPMS = (param) => {
    if(FETCH_ED){
        param = {
            input: {
                practicetype: 0,
                orderBy: 'name',
                parentidIsNull : true
            }
        }
    }else{
        param = {
            input: {
                practicetype: 0,
                orderBy: 'name'
            }
        }
    }
    const requestOption = api.getRequestOption(
        GET_PRACTICE_PMS,
        param,
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.Practice, requestOption, response => response);
};

export const getDemographicDetailsByPracticeId = param => {
    const requestOption = api.getRequestOption(
        GET_DEMOGRAPHIC_DETAILS_PMS,
        {
            input: {
                id: param
            }
        },
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.Practice, requestOption, response => response);
};

export const getProviderCount = param => {
    const queryInput = { input: { practiceid: param, inactive: false } };
    const requestOption = api.getRequestOption(
        GET_PRACTICE_PROVIDER_COUNT,
        queryInput,
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.Provider, requestOption, response => response);
};

export const getLocationCount = param => {
    const queryInput = { input: { practiceid: param, inactive: false } };
    if (FETCH_ED) {
        const requestOption = api.getRequestOption(
            GET_PHYSICIAN_GROUP_ED_COUNT,
            queryInput,
            api.Actions.View,
            getJwt()
        );
        return api.PegasusAPI(api.Resources.Practice, requestOption, response => response);
    } else {
        const requestOption = api.getRequestOption(
            GET_PRACTICE_LOCATION_COUNT,
            queryInput,
            api.Actions.View,
            getJwt()
        );
        return api.PegasusAPI(api.Resources.Location, requestOption, response => response);
    }
};

export const getPracticeContactDetailsDataBack = param => {
    const queryInput = { input: { practiceid: param } };
    const requestOption = api.getRequestOption(
        GET_PRACTICE_CONTACT_DETAILS,
        queryInput,
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.Practice, requestOption, response => response);
};

export const getPracticeContactDetailsData = param => {
    const queryInput = { practiceid: param };
    const requestOption = api.IAMGetRequestOption(queryInput, api.Actions.View, getJwt());
    return api.PegasusAPI(
        api.Resources.IAMApi.getAllPracticeUserByPractice,
        requestOption,
        response => response
    );
};

export const getPracticeCount = () => {
    const queryInput = {};
    const requestOption = api.getRequestOption(
        GET_PRACTICE_COUNT,
        queryInput,
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.Practice, requestOption, response => response);
};

export const getProviderCountPMS = param => {
    const queryInput = { input: { practiceid: param } };
    const requestOption = api.getRequestOption(
        GET_PRACTICE_PROVIDER_COUNT,
        queryInput,
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.Provider, requestOption, response => response);
};

export const getLocationCountPMS = param => {
    const queryInput = { input: { practiceid: param } };
    const requestOption = api.getRequestOption(
        FETCH_ED ? GET_PHYSICIAN_GROUP_ED_COUNT : GET_PRACTICE_LOCATION_COUNT,
        queryInput,
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(
        FETCH_ED ? api.Resources.Practice : api.Resources.Location,
        requestOption,
        response => response
    );
};

export const getPracticeStatus = (project, practiceId) => {
    const requestOption = api.JiraGetRequestOption(
        {
            project: project,
            practiceId: practiceId,
            JiraStatusInDB: JIRA_STATUS_IN_DB
        },
        api.Actions.View,
        getJwt()
    );

    return api.PegasusAPI(
        api.Resources.JiraAPI.getPracticeStatus,
        requestOption,
        response => response
    );
};

export const getPracticeSDMeasureStatus = (param, measureNoArr, year) => {
    const queryInput = {
        input: {
            practice_id: param.toString(),
            measure_idIn: measureNoArr,
            year: year && year.toString()
        }
    };
    const requestOption = api.getRequestOption(
        GET_MEASURE_STATUS_BY_PRACTICE,
        queryInput,
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.SD, requestOption, response => response);
};

export const getUpdatePracticeDetails = param => {
    const requestOption = api.getRequestOption(
        UPDATE_PRACTICE_DETAILS,
        param,
        api.Actions.Update,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.Practice, requestOption, response => response);
};

export const getEntityContactDetails = param => {
    const queryInput = {
        input: { entityid: param, entityname: 'practice', isactive: true, orderByDesc: 'usertype' }
    };
    const requestOption = api.getRequestOption(
        GET_ENTITY_CONTACT_DETAILS,
        queryInput,
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.Common, requestOption, response => response);
};

export const updateEntityContactDetails = param => {
    // const queryInput = { input: { param }};
    const requestOption = api.getRequestOption(
        UPDATE_ENTITY_CONTACT_DETAILS,
        param,
        api.Actions.Update,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.Common, requestOption, response => response);
};

export const addEntityContactDetails = param => {
    // const queryInput = { input: { param }};
    const requestOption = api.getRequestOption(
        CREATE_ENTITY_CONTACT_DETAILS,
        param,
        api.Actions.Create,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.Common, requestOption, response => response);
};
