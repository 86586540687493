import { getActionCreator, getActionType, getActionTypes, getReducer } from '@/utils/redux';
import {
    getClinicianListEntityGroup,
    getPerformanceGroupEntityData,
    getEDsListEntityGroup
} from '@/redux/services/practiceUserApi';
import { getUnique, practiceDisplayFormat, providerDisplayFormat } from '@/helper/commonFunction';

import { GROUP_BY_ENTITY } from '@/helper/constants';
import { getClinicanList } from '@/containers/PracticeProfile/PracticeAdminContactDetails/practiceUserCommonMethods';
import { getPractices } from '@/redux/services/practiceApi';

// Types
export const GetPracticeGroupEntityListTypes = getActionTypes('GETPRACTICEGROUPENTITYLIST');
export const GetClinicanGroupEntityListTypes = getActionTypes('GETCLINICIANGROUPENTITYLIST');
export const GetEDGroupEntityListTypes = getActionTypes('GETEDGROUPENTITYLIST');
export const FilterClinicianTypes = getActionTypes('FILTERCLINICIAN');

export const GetSelectedUserInfoTypes = getActionTypes('USERINFO');
export const SetEditUserType = getActionType('SetEditUser');
export const SetIsTouchedType = getActionType('SetIsTouched');
export const SetSelectedGroupInfoType = getActionType('SETSELECTEDGROUPINFO');
export const SetSelectedGroupIDType = getActionType('SETSELECTEDGROUPID');
export const ResetClinicianLocationEntityListType = getActionType('RESETCLINICIANLOCATIONLIST');

export const SetDefaultPracticeOptionType = getActionType('SetDefaultPracticeOption');
export const SetDefaultProviderOptionType = getActionType('SetDefaultProviderOption');
export const SetDefaultEDOptionType = getActionType('SetDefaultEDOption');

// Actions
export const SetEditUserAction = getActionCreator(SetEditUserType);
export const SetIsTouchedAction = getActionCreator(SetIsTouchedType);
export const SetDefaultPracticeOptionAction = getActionCreator(SetDefaultPracticeOptionType);
export const SetDefaultProviderOptionAction = getActionCreator(SetDefaultProviderOptionType);
export const SetDefaultEDOptionAction = getActionCreator(SetDefaultEDOptionType);

export const SetSelectedGroupInfoAction = getActionCreator(SetSelectedGroupInfoType);
export const SetSelectedGroupIDAction = getActionCreator(SetSelectedGroupIDType);

export const ResetClinicianLocationEntityListAction = getActionCreator(
    ResetClinicianLocationEntityListType
);
export const getSelectedUserInfoAction = getActionCreator(GetSelectedUserInfoTypes);

// export const getSelectedUserInfoAction = param => ({
//     types: GetSelectedUserInfoTypes,
//     callAPI: ()=>{},
//     handleAction:  async ({ type, payload, store }) => {
//     debugger;
//         if (store.getState().practiceUser.editUser) {
//             let defaultValue = [],
//                 entityList = [];
//                 store.getState().getProviderPracticeDetail.map(item => {
//                 if (item.practice !== null) {
//                     item.practice.map(pracObj => {
//                         entityList.push({
//                             value: `${item.id}- ${pracObj.id}`,
//                             providerId: item.id,
//                             label:
//                                 providerDisplayFormat(item) +
//                                 ` (${pracObj.externalid} - ${pracObj.name})`,
//                             entityid: item.id,
//                             parententityid: pracObj.id,
//                             parententityname: GROUP_BY_ENTITY.PRACTICE
//                         });
//                     });
//                 } else {
//                     entityList.push({
//                         value: item.id,
//                         providerId: item.id,
//                         label: providerDisplayFormat(item), //`${item.firstname} ${item.lastname} - ${item.npi}`,
//                         entityid: item.id,
//                         parententityid: '',
//                         parententityname: GROUP_BY_ENTITY.PRACTICE
//                     });
//                 }
//             });
// debugger;
// let paramVal=param;
//             const selectedUser = store.getState().practiceUser.selectedUser.userInfo.clinicianlist;
//             selectedUser && selectedUser.map(item => {
//                 entityList.map(opt => {

//                         if (
//                             opt.providerId.toString() === item.toString() &&
//                             //opt.parententityid.toString() ===
//                                 // item.parententityid.toString() &&
//                             !defaultValue.includes(opt)
//                         ) {
//                             defaultValue.push(opt);
//                         }
//                     // } else {
//                     //     if (
//                     //         opt.value.toString() === item.entityid.toString() &&
//                     //         !defaultValue.includes(opt)
//                     //     ) {
//                     //         defaultValue.push(opt);
//                     //     }
//                     // }
//                 });
//             });
//             store.dispatch(SetDefaultProviderOptionAction(defaultValue));
//         }
//     }

// });

export const getSelectedGroupData = async selectedGroupId => {
    const response = await getPerformanceGroupEntityData(selectedGroupId);
    const entityName =
        response.data.getGroupEntitys.length > 0 ? response.data.getGroupEntitys[0].entityname : '';
    const groupEntityIds = [
        ...new Set(response.data.getGroupEntitys.map(item => parseInt(item.entityid)))
    ];
    let groupEntityOptionList = [];
    if (entityName === GROUP_BY_ENTITY.PROVIDER) {
        groupEntityOptionList = await getClinicanList(groupEntityIds);
    }

    return {
        data: {
            groupInfo: response.data.getGroupEntitys,
            groupEntityOptionList: groupEntityOptionList,
            isGroupDataLoading: false
        }
    };
};

export const addGroupToList = async (store, param, grpId) => {
    const updatedGroupList = store.getState().practiceUser.performanceGroupList;
    updatedGroupList.push({
        id: grpId,
        name: param.input.name,
        entity: param.input.entity,
        description: null,
        isactive: true
    });
    return {
        data: {
            performanceGroupList: updatedGroupList,
            selectedGroup: {
                id: grpId,
                name: param.input.name,
                groupEntity: param.input.entity
            }
        }
    };
};

export const getPracticeGroupEntityListAction = () => ({
    types: GetPracticeGroupEntityListTypes,
    callAPI: () => getPractices(),
    handleAction: async ({ type, payload, store }) => {
        switch (type) {
            case GetPracticeGroupEntityListTypes.SUCCESS: {
                if (store.getState().practiceUser.editUser) {
                    let defaultValue = [],
                        entityList = [];
                    payload.getPractices.map(item => {
                        entityList.push({
                            value: item.id,
                            label: practiceDisplayFormat(item), //`${item.externalid} - ${item.name}`,
                            entityid: item.id,
                            parententityid: '',
                            parententityname: ''
                        });
                    });
                    console.log(store.getState().practiceUser.selectedUser);
                    const userInfo =
                        store.getState().practiceUser.selectedUser &&
                        store.getState().practiceUser.selectedUser.userInfo &&
                        store.getState().practiceUser.selectedUser.userInfo.practicelist;
                    userInfo &&
                        userInfo.map(item => {
                            entityList.map(opt => {
                                if (
                                    opt.value.toString() === item.toString() &&
                                    !defaultValue.includes(opt)
                                ) {
                                    defaultValue.push(opt);
                                }
                            });
                        });
                    store.dispatch(SetDefaultPracticeOptionAction(defaultValue));
                }
                break;
            }
            default:
                break;
        }
    }
});

export const getEDOnPhycianAction = (queryParams, prevSelectedPracticeOpt) => ({
    types: GetEDGroupEntityListTypes,
    callAPI: () => getEDsListEntityGroup(queryParams),
    handleAction: async ({ type, payload, store }) => {
        switch (type) {
            case GetEDGroupEntityListTypes.SUCCESS: {
                if (store.getState().practiceUser.editUser) {
                    let defaultValue = [],
                        entityList = [];
                    payload.getPractices.map(item => {
                        entityList.push({
                            value: item.id,
                            //providerId: item.id,
                            label: item.name, //`${item.firstname} ${item.lastname} - ${item.npi}`,
                            externalid: item.externalid,
                            parentid: item.parentid,
                            parententityname: GROUP_BY_ENTITY.ED
                        });
                    });

                    const userInfo =
                        store.getState().practiceUser.selectedUser &&
                        store.getState().practiceUser.selectedUser.userInfo &&
                        store.getState().practiceUser.selectedUser.userInfo.practicelist;

                    userInfo &&
                        userInfo.map(item => {
                            entityList.map(opt => {
                                if (
                                    opt.value.toString() === item.toString() &&
                                    !defaultValue.includes(opt)
                                ) {
                                    defaultValue.push(opt);
                                }
                            });
                        });
                    const currentSelectedEDOption = store.getState().practiceUser.selectedEDOption;
                    if (currentSelectedEDOption.length > 0) {
                        const currentPracSelectedOpt = store.getState().practiceUser
                            .selectedPracticeOption;
                        //if (prevSelectedPracticeOpt.length !== currentPracSelectedOpt.length) {
                        let practiceFilterOpt = [];
                        currentSelectedEDOption.forEach(function(val, index) {
                            if (
                                currentPracSelectedOpt.filter(obj => obj.value === val.parentid)
                                    .length > 0
                            ) {
                                practiceFilterOpt.push(val);
                            }
                        });
                        defaultValue = practiceFilterOpt;
                        store.dispatch(SetDefaultEDOptionAction(defaultValue));
                    } else {
                        if (prevSelectedPracticeOpt.length === 0) {
                            store.dispatch(SetDefaultEDOptionAction(defaultValue));
                        }
                    }
                    //store.dispatch(SetDefaultEDOptionAction(defaultValue));
                } else {
                    const selectedEd = store.getState().practiceUser.selectedEDOption;
                    if (selectedEd.length > 0) {
                        let updatedOption = [];
                        selectedEd.map(item => {
                            payload.getPractices.map(opt => {
                                if (item.value === opt.id) {
                                    updatedOption.push(item);
                                }
                            });
                        });
                        store.dispatch(SetDefaultEDOptionAction(updatedOption));
                    }
                }
                break;
            }
            default:
                break;
        }
    }
});

export const getClinicianGroupEntityListAction = (queryParams, prevSelectedPracticeOpt) => ({
    types: GetClinicanGroupEntityListTypes,
    callAPI: () => getClinicianListEntityGroup(queryParams),
    handleAction: async ({ type, payload, store }) => {
        switch (type) {            
            case GetClinicanGroupEntityListTypes.SUCCESS: {
                if (store.getState().practiceUser.editUser) {
                    let defaultValue = [],
                        entityList = [];
                    payload.getProviderPracticeDetail.map(item => {
                        if (item.practice !== null) {
                            item.practice.map(pracObj => {
                                entityList.push({
                                    value: `${item.id}- ${pracObj.id}`,
                                    providerId: item.id,
                                    label:
                                        providerDisplayFormat(item) +
                                        ` (${pracObj.externalid} - ${pracObj.name})`,
                                    entityid: item.id,
                                    parententityid: pracObj.id,
                                    parententityname: GROUP_BY_ENTITY.PRACTICE
                                });
                            });
                        } else {
                            entityList.push({
                                value: item.id,
                                providerId: item.id,
                                label: providerDisplayFormat(item), //`${item.firstname} ${item.lastname} - ${item.npi}`,
                                entityid: item.id,
                                parententityid: '',
                                parententityname: GROUP_BY_ENTITY.PRACTICE
                            });
                        }
                    });

                    const selectedUser = store.getState().practiceUser.selectedUser.userInfo
                        .clinicianlist;
                    selectedUser &&
                        selectedUser.map(item => {
                            entityList.map(opt => {
                                if (
                                    opt.providerId.toString() === item.toString() &&
                                    //opt.parententityid.toString() ===
                                    // item.parententityid.toString() &&
                                    !defaultValue.includes(opt)
                                ) {
                                    defaultValue.push(opt);
                                }
                                // } else {
                                //     if (
                                //         opt.value.toString() === item.entityid.toString() &&
                                //         !defaultValue.includes(opt)
                                //     ) {
                                //         defaultValue.push(opt);
                                //     }
                                // }
                            });
                        });
                    const currentSelectedProviderOption = store.getState().practiceUser
                        .selectedClinicianOption;
                    if (currentSelectedProviderOption.length > 0) {
                        const currentPracSelectedOpt = store.getState().practiceUser
                            .selectedPracticeOption;
                        //if (prevSelectedPracticeOpt.length !== currentPracSelectedOpt.length) {
                        let practiceFilterOpt = [];
                        currentSelectedProviderOption.forEach(function(val, index) {
                            if (
                                currentPracSelectedOpt.filter(
                                    obj => obj.value === val.parententityid
                                ).length > 0
                            ) {
                                practiceFilterOpt.push(val);
                            }
                        });
                        defaultValue = practiceFilterOpt;
                        //}
                        store.dispatch(SetDefaultProviderOptionAction(defaultValue));
                    } else {
                        if (prevSelectedPracticeOpt.length === 0) {
                            store.dispatch(SetDefaultProviderOptionAction(defaultValue));
                        }
                    }
                } else {
                    const selectedClinician = store.getState().practiceUser.selectedClinicianOption;
                    if (selectedClinician.length > 0) {
                        let updatedOption = [];
                        selectedClinician.map(item => {
                            payload.getProviderPracticeDetail.map(opt => {
                                if (item.providerId === opt.id) {
                                    updatedOption.push(item);
                                }
                            });
                        });
                        store.dispatch(SetDefaultProviderOptionAction(updatedOption));
                    }
                }
                break;
            }
            default:
                break;
        }
    }
});

export const filterClinicianAction = queryParams => ({
    types: FilterClinicianTypes,
    callAPI: () => getClinicianListEntityGroup(queryParams)
});

const initialState = {
    editGroup: false,
    editUser: false,
    isTouched: false,
    isLoading: true,
    groupPracticeDDLList: [],
    groupProviderDDLList: [],
    groupEDList: [],
    entityListLoading: false,
    isClinicianListLoading: false,
    isFilter: false,
    selectedPracticeOption: [],
    selectedClinicianOption: [],
    selectedEDOption: [],
    providerCnt: null,
    locationCnt: null,
    error: {
        code: '',
        message: ''
    }
};

// Reducer
export default getReducer(initialState, {
    [SetEditUserType]: (state, { payload }) => {
        return{
            ...state,
            editUser: payload
        }
    },
    [SetIsTouchedType]: (state, { payload }) => ({
        ...state,
        isTouched: payload
    }),
    [GetSelectedUserInfoTypes]: (state, { payload }) => ({
        ...state,
        selectedUser: {
            ...state.selectedUser,
            userInfo: payload
        }
    }),
    [SetDefaultProviderOptionType]: (state, { payload }) => {
        return {
            ...state,
            selectedClinicianOption: payload
        };
    },
    [SetDefaultEDOptionType]: (state, { payload }) => {
        return {
            ...state,
            selectedEDOption: payload
        };
    },
    [SetDefaultPracticeOptionType]: (state, { payload }) => ({
        ...state,
        selectedPracticeOption: payload
    }),
    [FilterClinicianTypes.READY]: (state, { payload }) => ({
        ...state,
        isFilter: true
    }),
    [FilterClinicianTypes.SUCCESS]: (state, { payload }) => ({
        ...state,
        groupProviderDDLList: getUnique(
            [...state.groupProviderDDLList, ...payload.getProviderPracticeDetail],
            'id'
        ),
        isFilter: false
    }),
    [ResetClinicianLocationEntityListType]: (state, { payload }) => {
        return {
            ...state,
            groupProviderDDLList:
                state.groupPracticeDDLList.length > 1 ? [] : state.groupProviderDDLList,
            groupLocationDDLList:
                state.groupPracticeDDLList.length > 1 ? [] : state.groupLocationDDLList,
            groupEDList: state.groupPracticeDDLList.length > 1 ? [] : state.groupEDList,
            selectedPracticeOption: [],
            selectedClinicianOption: [],
            selectedEDOption: []
        };
    },
    [GetClinicanGroupEntityListTypes.SUCCESS]: (state, { payload }) => {
        return {
            ...state,
            groupProviderDDLList: payload.getProviderPracticeDetail || [],
            isClinicianListLoading: false
        };
    },
    [GetClinicanGroupEntityListTypes.READY]: (state, { payload }) => ({
        ...state,
        isClinicianListLoading: true
    }),
    [GetEDGroupEntityListTypes.SUCCESS]: (state, { payload }) => {
        return {
            ...state,
            groupEDList: payload.getPractices || []
            //isClinicianListLoading: false
        };
    },
    [GetEDGroupEntityListTypes.READY]: (state, { payload }) => ({
        ...state
        // isClinicianListLoading: true
    }),
    [GetPracticeGroupEntityListTypes.READY]: (state, { payload }) => ({
        ...state,
        entityListLoading: true
    }),
    [GetPracticeGroupEntityListTypes.SUCCESS]: (state, { payload }) => ({
        ...state,
        entityListLoading: false,
        groupPracticeDDLList: payload.getPractices
    }),
    [GetPracticeGroupEntityListTypes.ERROR]: (state, { payload }) => ({
        ...state,
        entityListLoading: false,
        groupPracticeDDLList: []
    })
});
