import {
    CAREGAP_MEASURES,
    CAREGAP_MEASURE_DESCRIPTION,
    CAREGAP_PATIENT_VISIT_CARE_GAP_DETAILS,
    CAREGAP_VISIT_SCORE_CARD,
    GET_CAREGAP_PATIENTS,
    GET_CAREGAP_PATIENT_VISITS
} from '@/graphql/query';

import APIHelper from '@/helper/apihelper';
import { getJwt } from '@/utils/jwtUtils';

const api = new APIHelper();

export const getPatients = (params, signal) => {
    const requestOption = api.getRequestOption(
        GET_CAREGAP_PATIENTS,
        params,
        api.Actions.View,
        getJwt(),
        signal
    );
    return api.PegasusAPI(api.Resources.Patient, requestOption, response => response);
};

export const getPatientsVisits = params => {
    const requestOption = api.getRequestOption(
        GET_CAREGAP_PATIENT_VISITS,
        params,
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.Patient, requestOption, response => response);
};

export const getCareGapMeasures = params => {
    const requestOption = api.getRequestOption(
        CAREGAP_MEASURES,
        params,
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.Common, requestOption, response => response);
};

export const getCareGapMeasuresDescription = params => {
    const requestOption = api.getRequestOption(
        CAREGAP_MEASURE_DESCRIPTION,
        params,
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.Measure, requestOption, response => response);
};

export const getVisitScoreCard = (params, signal) => {
    const requestOption = api.getRequestOption(
        CAREGAP_VISIT_SCORE_CARD,
        params,
        api.Actions.View,
        getJwt(),
        signal
    );
    return api.PegasusAPI(api.Resources.Patient, requestOption, response => response);
};

export const getPatientVisitCareGapDetails = (params, signal) => {
    const requestOption = api.getRequestOption(
        CAREGAP_PATIENT_VISIT_CARE_GAP_DETAILS,
        params,
        api.Actions.View,
        getJwt(),
        signal
    );
    return api.PegasusAPI(api.Resources.Patient, requestOption, response => response);
};
