import {
    ED,
    FETCH_ED,
    GROUP_BY_ENTITY,
    REGISTRY_UNIT_NAME,
    PRACTICE,
    PROVIDER,
    LOCATION,
    IS_DATA_IN_COMPLETE_REQUIRED
} from '@/helper/constants';
import { getLocationData, getEdsList } from '@/redux/services/locationPerformanceApi';
import {
    getGroupMeasurePerformance,
    getClinicianListEntityGroup
} from '@/redux/services/groupDashboardApi';
import { formateDate } from '@/helper/commonFunction';
import { providerDisplayFormat, practiceDisplayFormat } from '@/helper/commonFunction';
import i18next from 'i18next';

export const getEntityGroupDropDownOptionList = optionList => {
    const practiceListOption = [];
    const providerListOption = [];
    const locationListOption = [];
    optionList.map(obj => {
        switch (obj.entity) {
            case GROUP_BY_ENTITY.PRACTICE:
                practiceListOption.push({ value: obj.id, label: obj.name, entity: obj.entity });
                break;
            case GROUP_BY_ENTITY.PROVIDER:
                providerListOption.push({ value: obj.id, label: obj.name, entity: obj.entity });
                break;
            case GROUP_BY_ENTITY.LOCATION:
            case GROUP_BY_ENTITY.ED:
                locationListOption.push({ value: obj.id, label: obj.name, entity: obj.entity });
                break;
            default:
                break;
        }
    });
    return [
        {
            label: i18next.t('groupDashboard.label.practiceGroupDropDown'),
            options: practiceListOption
        },
        {
            label: i18next.t('groupDashboard.label.clinician'),
            options: providerListOption
        },
        {
            label: i18next.t('groupDashboard.label.locationGroupDropDown'),
            options: locationListOption
        }
    ];
};

export const groupDashboardTabProp = (selectedGroupInfo, GroupPerformanceGrid) => {
    // const tabName =
    //     selectedGroupInfo.groupEntity === GROUP_BY_ENTITY.PROVIDER
    //         ? i18next.t('groupDashboard.label.clinician')
    //         : selectedGroupInfo.groupEntity;
    let fontIcon = '',
        iconUrl = '',
        tabName = '';
    switch (selectedGroupInfo.groupEntity) {
        case GROUP_BY_ENTITY.PRACTICE:
            tabName = i18next.t('groupDashboard.label.practiceTab');
            fontIcon = i18next.t('qualityDashboard.fontIcon.hospital');
            iconUrl = '../../assets/svg/practice.svg';
            break;
        case GROUP_BY_ENTITY.PROVIDER:
            tabName = i18next.t('groupDashboard.label.clinicianTab');
            fontIcon = i18next.t('qualityDashboard.fontIcon.stethoscope');
            iconUrl = '../../assets/svg/clinician.svg';
            break;
        case GROUP_BY_ENTITY.LOCATION:
        case GROUP_BY_ENTITY.ED:
            tabName = i18next.t('groupDashboard.label.locationTab');
            fontIcon = i18next.t('qualityDashboard.fontIcon.map-marker-alt');
            iconUrl = '../../assets/svg/ehr.svg';
            break;
    }
    return [
        {
            menuItem:
                selectedGroupInfo.id && `${tabName} ` + `(${selectedGroupInfo.groupInfo.length})`,
            defaultActiveIndex: true,
            iconUrl: iconUrl,
            fontIcon: fontIcon,
            router: GroupPerformanceGrid,
            action: () => {}
        }
    ];
};

export const getGroupEntityDropDownList = (entity, props) => {
    let entityList = [];
    switch (entity) {
        case GROUP_BY_ENTITY.PRACTICE:
            props.GroupPracticeList.map(item => {
                entityList.push({
                    value: item.id,
                    label: practiceDisplayFormat(item),
                    entityid: item.id,
                    parententityid: '',
                    parententityname: ''
                });
            });
            break;
        case GROUP_BY_ENTITY.PROVIDER:
            props.ProviderList.map(item => {
                if (item.practice !== null) {
                    item.practice.map(pracObj => {
                        entityList.push({
                            value: `${item.id}- ${pracObj.id}`,
                            providerId: item.id,
                            label:
                                providerDisplayFormat(item) +
                                ` (${practiceDisplayFormat(pracObj)})`,
                            entityid: item.id,
                            parententityid: pracObj.id,
                            parententityname: GROUP_BY_ENTITY.PRACTICE
                        });
                    });
                } else {
                    entityList.push({
                        value: item.id,
                        providerId: item.id,
                        label: providerDisplayFormat(item),
                        entityid: item.id,
                        parententityid: '',
                        parententityname: GROUP_BY_ENTITY.PRACTICE
                    });
                }
            });
            break;
        case GROUP_BY_ENTITY.ED:
            props.LocationList.map(item => {
                entityList.push({
                    value: item.id,
                    label:
                        item.externalid !== null
                            ? `${item.externalid}${
                                  item.name !== null ? item.name && '-' + item.name : ''
                              }`
                            : `${item.name}`,
                    entityid: item.id,
                    parententityid: item.parentid,
                    parententityname: GROUP_BY_ENTITY.PRACTICE
                });
            });
            break;
        case GROUP_BY_ENTITY.LOCATION:
            props.LocationList.map(item => {
                if (item.practicename !== null) {
                    entityList.push({
                        value: item.id,
                        label:
                            item.externalid !== null
                                ? `${item.externalid}${
                                      item.name !== null ? item.name && '-' + item.name : ''
                                  } (${item.practiceexternalid} - ${item.practicename})`
                                : `${item.name} (${item.practiceexternalid} - ${item.practicename})`,
                        entityid: item.id,
                        parententityid: item.practiceid,
                        parententityname: GROUP_BY_ENTITY.PRACTICE
                    });
                } else {
                    entityList.push({
                        value: item.id,
                        label:
                            item.externalid !== null
                                ? `${item.externalid}${
                                      item.name !== null ? item.name && '-' + item.name : ''
                                  }`
                                : `${item.name}`,
                        entityid: item.id,
                        parententityid: '',
                        parententityname: GROUP_BY_ENTITY.PRACTICE
                    });
                }
            });
            break;
        default:
            break;
    }
    return entityList;
};

export const getCurrentGroupDetails = props => {
    if (props.selectedEntity === GROUP_BY_ENTITY.PROVIDER) {
        const providerID = [];
        const practiceID = [];
        let qparams = '';
        if (props.GroupPracticeList.length === 1) {
            practiceID.push(props.GroupPracticeList[0].id);
            qparams = {
                input: {
                    practiceidIn: practiceID,
                    orderBy: 'firstname',
                    inactive: false
                }
            };
        } else {
            props.selectedGroupInfo.groupInfo.map(obj => {
                if (!providerID.includes(parseInt(obj.entityid))) {
                    providerID.push(parseInt(obj.entityid));
                }
                if (
                    !practiceID.includes(parseInt(obj.parententityid)) &&
                    obj.parententityid !== '' &&
                    obj.parententityid !== null
                ) {
                    practiceID.push(parseInt(obj.parententityid));
                }
            });
            qparams = {
                input: {
                    orderBy: 'firstname',
                    inactive: false
                }
            };
            if (practiceID.length > 0) {
                qparams.input.practiceidIn = practiceID;
            } else {
                qparams.input.idIn = providerID;
            }
        }
        props.getClinicianGroupEntityListAction(qparams, props.selectedPracticeOption);
    } else if (
        props.selectedEntity === GROUP_BY_ENTITY.LOCATION ||
        props.selectedEntity === GROUP_BY_ENTITY.ED
    ) {
        const practiceID = [];
        const locationID = [];
        let qparams = '';
        if (props.GroupPracticeList.length === 1) {
            practiceID.push(props.GroupPracticeList[0].id);
            qparams = {
                input: FETCH_ED
                    ? {
                          parentidIn: practiceID,
                          orderBy: 'firstname',
                          inactive: false,
                          practicetype: 0
                      }
                    : {
                          practiceidIn: practiceID,
                          orderBy: 'firstname',
                          inactive: false
                      }
            };
        } else {
            props.selectedGroupInfo.groupInfo.map(obj => {
                if (!locationID.includes(parseInt(obj.entityid))) {
                    locationID.push(parseInt(obj.entityid));
                }
                if (
                    !practiceID.includes(parseInt(obj.parententityid)) &&
                    obj.parententityid !== '' &&
                    obj.parententityid !== null
                ) {
                    practiceID.push(parseInt(obj.parententityid));
                }
            });
            qparams = {
                input: {
                    orderBy: 'name',
                    inactive: false
                }
            };
            if (practiceID.length > 0) {
                if (FETCH_ED) {
                    qparams.input.parentidIn = practiceID;
                    qparams.input.practicetype = 0;
                } else qparams.input.practiceidIn = practiceID;
            } else {
                qparams.input.idIn = locationID;
            }
        }
        props.getLocationGroupEntityListAction(qparams, props.selectedPracticeOption);
    }
};

export const getDefaultPracticeSelectedOption = props => {
    let defaultValue = [],
        entityList = [];
    props.GroupPracticeList.map(item => {
        entityList.push({
            value: item.id,
            label: practiceDisplayFormat(item),
            entityid: item.id,
            parententityid: '',
            parententityname: ''
        });
    });
    props.selectedGroupInfo.groupInfo.map(item => {
        if (item.parententityid != null) {
            entityList.map(opt => {
                if (
                    item.entityname.toString() === GROUP_BY_ENTITY.PROVIDER ||
                    item.entityname.toString() === GROUP_BY_ENTITY.LOCATION ||
                    item.entityname.toString() === GROUP_BY_ENTITY.ED
                ) {
                    if (
                        opt.value.toString() === item.parententityid.toString() &&
                        !defaultValue.includes(opt)
                    ) {
                        defaultValue.push(opt);
                    }
                } else {
                    if (
                        opt.value.toString() === item.entityid.toString() &&
                        !defaultValue.includes(opt)
                    ) {
                        defaultValue.push(opt);
                    }
                }
            });
        }
    });
    props.SetDefaultPracticeOptionAction(defaultValue);
};

export const selectfilterbyEntity = (inputValue, props) => {
    if (
        props.selectedPracticeOption.length === 0 &&
        inputValue !== '' &&
        props.GroupPracticeList.length > 1
    ) {
        const entity = props.selectedEntity;
        if (entity === GROUP_BY_ENTITY.PROVIDER) {
            let queryParams = '';
            if (isNaN(inputValue)) {
                queryParams = {
                    input: {
                        firstnameLikeNoCase: `%${inputValue}%`,
                        inactive: false,
                        orderBy: 'firstname'
                    }
                };
            } else {
                queryParams = {
                    input: {
                        npi: parseInt(inputValue),
                        inactive: false,
                        orderBy: 'firstname'
                    }
                };
            }
            props.filterClinicianAction(queryParams);
        } else {
            let queryParams = '';
            if (isNaN(inputValue)) {
                queryParams = {
                    input: {
                        nameLikeNoCase: `%${inputValue}%`,
                        inactive: false,
                        orderBy: 'name'
                    }
                };
            } else {
                queryParams = {
                    input: {
                        externalid: parseInt(inputValue),
                        inactive: false,
                        orderBy: 'name'
                    }
                };
            }
            if (FETCH_ED) {
                queryParams.input.parentidIsNull = false;
            }
            props.filterLocationAction(queryParams);
        }
    }
};

export const createEditGroupHelper = (props, groupName) => {
    const entity = props.selectedEntity;
    let groupentitylist = [];
    switch (entity) {
        case GROUP_BY_ENTITY.PRACTICE:
            groupentitylist = parseSelectedOptionBysEntity(
                props.selectedPracticeOption,
                entity,
                props
            );
            break;
        case GROUP_BY_ENTITY.PROVIDER:
            groupentitylist = parseSelectedOptionBysEntity(
                props.selectedClinicianOption,
                entity,
                props
            );
            break;
        case GROUP_BY_ENTITY.LOCATION:
        case GROUP_BY_ENTITY.ED:
            groupentitylist = parseSelectedOptionBysEntity(
                props.selectedLocationOption,
                entity,
                props
            );
            break;
        default:
            break;
    }
    const createGroupInput = {
        input: {
            name: groupName.trim(),
            entity: entity,
            groupentitylist: groupentitylist
        }
    };
    if (props.editGroup) {
        createGroupInput.input.id = props.selectedGroupInfo.id;
    }
    props.createEditGroupHandle(createGroupInput);
};

export const parseSelectedOptionBysEntity = (selectedOptionList, entity, props) => {
    const returnValue = [];
    if (FETCH_ED && GROUP_BY_ENTITY.ED === entity) {
        selectedOptionList.map(obj => {
            returnValue.push({
                entityid: obj.entityid.toString(),
                parententityid:
                    props.selectedPracticeOption.length !== 0 ? obj.parententityid.toString() : '',
                parententityname: obj.parententityname
            });
        });
    } else {
        selectedOptionList.map(obj => {
            returnValue.push({
                entityid:
                    GROUP_BY_ENTITY.PROVIDER === entity
                        ? obj.providerId.toString()
                        : obj.entityid.toString(),
                parententityid:
                    props.selectedPracticeOption.length === 0 && GROUP_BY_ENTITY.LOCATION === entity
                        ? ''
                        : obj.parententityid.toString(),
                parententityname: obj.parententityname
            });
        });
    }
    return returnValue;
};

export const onPracticeselectionHelper = (props, selectedOptions) => {
    if (props.selectedPracticeOption.length > 0 && selectedOptions.length === 0) {
        props.ResetClinicianLocationEntityListAction();
    }
    props.SetDefaultPracticeOptionAction(selectedOptions);
    switch (props.selectedEntity) {
        case GROUP_BY_ENTITY.PROVIDER:
            const qparams = {
                input: {
                    practiceidIn: selectedOptions.map(obj => obj.value),
                    orderBy: 'firstname',
                    inactive: false
                }
            };
            if (selectedOptions.length > 0) {
                props.getClinicianGroupEntityListAction(qparams, selectedOptions);
            } else {
                if (props.selectedClinicianOption.length > 0)
                    props.SetDefaultProviderOptionAction([]);
            }
            break;
        case GROUP_BY_ENTITY.LOCATION:
        case GROUP_BY_ENTITY.ED:
            const queryParams = {
                input: FETCH_ED
                    ? {
                          parentidIn: selectedOptions.map(obj => obj.value),
                          orderBy: 'name',
                          inactive: false,
                          practicetype: 0
                      }
                    : {
                          practiceidIn: selectedOptions.map(obj => obj.value),
                          orderBy: 'name',
                          inactive: false
                      }
            };
            if (selectedOptions.length > 0) {
                props.getLocationGroupEntityListAction(queryParams, selectedOptions);
            } else {
                if (props.selectedLocationOption.length > 0)
                    props.SetDefaultLocationOptionAction([]);
            }
            break;
        default:
            break;
    }
};

export const getEntityListByPracticeID = props => {
    if (props.GroupPracticeList.length === 1) {
        let qparams = '';
        const practiceID = [];
        practiceID.push(props.GroupPracticeList[0].id);
        if (props.selectedEntity === GROUP_BY_ENTITY.PROVIDER) {
            qparams = {
                input: {
                    practiceidIn: practiceID,
                    orderBy: 'firstname',
                    inactive: false
                }
            };
            props.getClinicianGroupEntityListAction(qparams, props.selectedPracticeOption);
        } else {
            qparams = {
                input: FETCH_ED
                    ? { parentidIn: practiceID, orderBy: 'name', inactive: false, practicetype: 0 }
                    : {
                          practiceidIn: practiceID,
                          orderBy: 'name',
                          inactive: false
                      }
            };
            props.getLocationGroupEntityListAction(qparams, props.selectedPracticeOption);
        }
    }
};

export const getSelectedGroupPerformance = (filterValue, props) => {
    const params = getInputParamForGroupPerformance(props.MeasureFilterData.selectedValues);
    const customRange = props.MeasureFilterData.selectedValues.customrange;
    let groupMeasureList = props.MeasureList;
    let returnList = [];
    groupMeasureList.forEach(obj => {
        if (obj.isStrata && obj.parentmeasureid !== null) return;
        returnList.push({
            ...obj,
            isDisabled: false,
            practiceid: params.EntityId,
            performance: {
                performanceData: {
                    GroupId: parseInt(props.selectedGroupInfo.id),
                    DurationFrom: formateDate(
                        new Date(params.DurationFrom),
                        'YYYY-MM-DD',
                        customRange
                    ),
                    DurationTo: formateDate(new Date(params.DurationTo), 'YYYY-MM-DD', customRange),
                    Flag: params.Flag,
                    MeasureId: obj.id,
                    Unit: REGISTRY_UNIT_NAME,
                    IsPatientSpecific: obj.performance.performanceData.IsPatientSpecific,
                    IsDataIncompleteRequired: IS_DATA_IN_COMPLETE_REQUIRED,
                    childmeasureno: obj.childmeasureno
                },
                isStopPropagation: false
            },
            getGroupMeasurePerformance: getGroupMeasurePerformance
        });
    });
    // groupMeasureList = groupMeasureList.map(obj => ({
    //     ...obj,
    //     isDisabled: false,
    //     practiceid: params.EntityId,
    //     performance: {
    //         performanceData: {
    //             GroupId: parseInt(props.selectedGroupInfo.id),
    //             DurationFrom: formateDate(new Date(params.DurationFrom), 'YYYY-MM-DD', customRange),
    //             DurationTo: formateDate(new Date(params.DurationTo), 'YYYY-MM-DD', customRange),
    //             Flag: params.Flag,
    //             MeasureId: obj.id,
    //             Unit: REGISTRY_UNIT_NAME,
    //             IsPatientSpecific: obj.performance.performanceData.IsPatientSpecific,
    //             IsDataIncompleteRequired: IS_DATA_IN_COMPLETE_REQUIRED
    //             // childmeasureno: obj.childmeasureno
    //         },
    //         isStopPropagation: false
    //     },
    //     getGroupMeasurePerformance: getGroupMeasurePerformance
    // }));
    return returnList;
};

export const getInputParamForGroupPerformance = props => ({
    MeasuresetId: props.measureSet,
    DurationFrom: props.customrange ? props.fromdate.replace(/-/g, '/') : props.durationFrom,
    DurationTo: props.customrange ? props.todate.replace(/-/g, '/') : props.durationTo,
    Flag: props.durationFlag
});

export const setDefaultGroupSelected = payload => {
    if (payload.getGroups.length > 1) {
        let uniqueEntity = [...new Set(payload.getGroups.map(item => item.entity))];
        let practiceGroups;
        if (uniqueEntity.includes(GROUP_BY_ENTITY.PRACTICE)) {
            practiceGroups = payload.getGroups.filter(option => {
                if (option.entity === GROUP_BY_ENTITY.PRACTICE) {
                    return option;
                }
            });
            return setDefaultGroupObj(practiceGroups);
        } else if (uniqueEntity.includes(GROUP_BY_ENTITY.PROVIDER)) {
            practiceGroups = payload.getGroups.filter(option => {
                if (option.entity === GROUP_BY_ENTITY.PROVIDER) {
                    return option;
                }
            });
            return setDefaultGroupObj(practiceGroups);
        } else {
            return setDefaultGroupObj(payload.getGroups);
        }
    } else {
        return setDefaultGroupObj(payload.getGroups);
    }
};

export const setDefaultGroupObj = practiceGroups => ({
    id: practiceGroups.length !== 0 && practiceGroups[0].id,
    name: practiceGroups.length !== 0 && practiceGroups[0].name,
    entity: practiceGroups.length !== 0 && practiceGroups[0].entity
});

export const getMeasurePerformanceByGroupEntity = props => {
    switch (props.selectedGroupInfo.groupEntity) {
        case GROUP_BY_ENTITY.PRACTICE:
            return getMeasurePerformanceForGroupPracticesInput(props);
        case GROUP_BY_ENTITY.PROVIDER:
            return getMeasurePerformanceForGroupProviderInput(props);
        case GROUP_BY_ENTITY.LOCATION:
        case GROUP_BY_ENTITY.ED:
            return getMeasurePerformanceForGroupLocationInput(props);
        default:
            break;
    }
};

export const generatePerformanceObject = (
    entityName,
    entityId,
    parentEntityId,
    parentEntityName,
    formatOptionLabel,
    props
) => ({
    id: props.id,
    displayname: props.displayname,
    measureId: props.id,
    measure: props.measure,
    groupEntityheader: formatOptionLabel,
    performance: {
        performanceData: {
            EntityName: entityName,
            EntityId: `${entityId}`,
            ParentEntityId: parentEntityId,
            ParentEntityName: parentEntityName,
            DurationFrom: props.performance.performanceData.DurationFrom,
            DurationTo: props.performance.performanceData.DurationTo,
            Flag: props.performance.performanceData.Flag,
            MeasureId: props.performance.performanceData.MeasureId,
            Unit: REGISTRY_UNIT_NAME,
            IsPatientSpecific: props.performance.performanceData.IsPatientSpecific,
            IsDataIncompleteRequired: IS_DATA_IN_COMPLETE_REQUIRED
        },
        isStopPropagation: false
    },
    basePageLink: '/GroupPerformance'
});

export const getMeasurePerformanceForGroupProviderInput = props => {
    const inputEntityList = [];
    const uniqueProviderList = [
        ...new Set(props.selectedGroupInfo.groupInfo.map(item => item.entityid))
    ];
    uniqueProviderList.map(provider => {
        var parentityEntityIds = [];
        props.selectedGroupInfo.groupInfo.filter(item => {
            if (item.entityid.toString() === provider.toString()) {
                if (
                    item.parententityid !== '' &&
                    !parentityEntityIds.includes(parseInt(item.parententityid))
                ) {
                    parentityEntityIds.push(parseInt(item.parententityid));
                } else if (item.PracticeIdList.length === 1 && item.parententityid === '') {
                    parentityEntityIds.push(parseInt(item.PracticeIdList[0]));
                }
            }
        });

        const clincianData = props.selectedGroupInfo.groupEntityOptionList.filter(
            option => option.id.toString() === provider.toString()
        );
        const optionLabel = clincianData.length > 0 && providerDisplayFormat(clincianData[0]);
        inputEntityList.push(
            generatePerformanceObject(
                PROVIDER,
                provider,
                parentityEntityIds,
                PRACTICE,
                optionLabel,
                props
            )
        );
    });
    return inputEntityList;
};

export const getMeasurePerformanceForGroupLocationInput = props => {
    const inputEntityList = [];
    const uniqueLocationList = [
        ...new Set(props.selectedGroupInfo.groupInfo.map(item => item.entityid))
    ];
    uniqueLocationList.map(location => {
        var parentityEntityIds = [];
        props.selectedGroupInfo.groupInfo.filter(item => {
            if (item.entityid.toString() === location.toString()) {
                if (
                    item.parententityid !== '' &&
                    !parentityEntityIds.includes(parseInt(item.parententityid))
                ) {
                    parentityEntityIds.push(parseInt(item.parententityid));
                }
            }
        });
        const locationData = props.selectedGroupInfo.groupEntityOptionList.filter(
            option => option.id.toString() === location.toString()
        );
        let optionLabel = '';
        if (FETCH_ED) {
            if (parentityEntityIds.length === 0) {
                if (locationData.length > 0) {
                    parentityEntityIds.push(parseInt(locationData[0].parentid));
                }
            }
            optionLabel = locationData.length > 0 && practiceDisplayFormat(locationData[0]);
            inputEntityList.push(
                generatePerformanceObject(
                    PRACTICE,
                    location.toString(),
                    parentityEntityIds,
                    ED,
                    optionLabel,
                    props
                )
            );
        } else {
            if (parentityEntityIds.length === 0) {
                if (locationData.length > 0) {
                    parentityEntityIds.push(parseInt(locationData[0].practiceid));
                }
            }
            optionLabel =
                locationData.length > 0 && locationData[0].externalid !== null
                    ? `${locationData[0].externalid}${
                          locationData[0].name !== null
                              ? locationData[0].name && '-' + locationData[0].name
                              : ''
                      } (${locationData[0].practiceexternalid} - ${locationData[0].practicename})`
                    : `${locationData[0].name} (${locationData[0].practiceexternalid} - ${locationData[0].practicename})`;
            inputEntityList.push(
                generatePerformanceObject(
                    LOCATION,
                    location.toString(),
                    parentityEntityIds,
                    PRACTICE,
                    optionLabel,
                    props
                )
            );
        }
    });
    return inputEntityList;
};

export const getMeasurePerformanceForGroupPracticesInput = props => {
    const inputEntityList = [];
    props.selectedGroupInfo.groupInfo.map(obj => {
        const returnValue = props.GroupPracticeList.filter(
            option => option.id.toString() === obj.entityid.toString()
        );
        const formatOptionLabel = returnValue.length > 0 && practiceDisplayFormat(returnValue[0]);
        inputEntityList.push(
            generatePerformanceObject(PRACTICE, obj.entityid, [], '', formatOptionLabel, props)
        );
    });
    return inputEntityList;
};

export const getGroupEntityByHeader = entity => {
    let header = '';
    switch (entity) {
        case GROUP_BY_ENTITY.PRACTICE:
            header = i18next.t('groupDashboard.getGroupEntityByHeader.practice');
            break;
        case GROUP_BY_ENTITY.PROVIDER:
            header = i18next.t('groupDashboard.getGroupEntityByHeader.provider');
            break;
        case GROUP_BY_ENTITY.LOCATION:
        case GROUP_BY_ENTITY.ED:
            header = i18next.t('groupDashboard.getGroupEntityByHeader.location');
            break;
        default:
            break;
    }
    return header;
};

export const getClinicanList = async providerId => {
    const param = {
        input: {
            idIn: providerId,
            orderBy: 'firstname',
            inactive: false
        }
    };
    let data = await getClinicianListEntityGroup(param)
        .then(res => {
            if (res.data.getProviderPracticeDetail != null) {
                return res.data.getProviderPracticeDetail;
            }
        })
        .catch(error => {});
    return data;
};

export const getLocationList = async locationId => {
    const param = {
        input: {
            idIn: locationId,
            inactive: false
        }
    };
    let data = null;
    if (FETCH_ED) {
        data = await getEdsList(param)
            .then(res => {
                if (res.data.getPractices != null) {
                    return res.data.getPractices;
                }
            })
            .catch(error => {});
    } else {
        data = await getLocationData(param)
            .then(res => {
                if (res.data.getLocations != null) {
                    return res.data.getLocations;
                }
            })
            .catch(error => {});
    }
    return data;
};
