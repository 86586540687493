import {
    FILES_BY_ENTITY_REQUEST,
    FILES_BY_ENTITY_RECEIVE,
    FILES_BY_ENTITY_FAILURE,
    DELETE_FILE_MESSAGE,
    RECEIVE_DOWNLOAD_SUCCESS,
    VISIT_BULK_UPLOAD_PRACTICES_REQUEST,
    VISIT_BULK_UPLOAD_PRACTICES_RECEIVE,
    VISIT_BULK_UPLOAD_PRACTICES_FAILURE,
    VISIT_BULK_UPLOAD_YEAR_FAILURE,
    VISIT_BULK_UPLOAD_YEAR_REQUEST,
    VISIT_BULK_UPLOAD_YEAR_RECEIVE
} from '../actions/fileUploadAction';

const initialState = {
    isLoading: false,
    isFailure: false,
    fileList: [],
    isPracticeFailure: false,
    practices: null,
    isYearFailure: false,
    yearList: null
};

export const fileUploadReducer = (state = initialState, action) => {
    switch (action.type) {
        case VISIT_BULK_UPLOAD_YEAR_REQUEST:
            return {
                ...state,
                isYearFailure: false
            }

        case VISIT_BULK_UPLOAD_YEAR_RECEIVE:
            return {
                ...state,
                isYearFailure: false,
                yearList: action.payload
            }

        case VISIT_BULK_UPLOAD_YEAR_FAILURE:
            return {
                ...state,
                isYearFailure: true
            }

        case FILES_BY_ENTITY_REQUEST:
            return {
                ...state,
                isLoading: true,
                isFailure: false,
                isFileListLoading: true
            };
        case FILES_BY_ENTITY_RECEIVE:
            return Object.assign({}, state, {
                isLoading: false,
                isFailure: false,
                fileList: action.payload,
                isFileListLoading: false
            });
        case FILES_BY_ENTITY_FAILURE:
            return {
                ...state,
                isLoading: false,
                isFailure: true,
                isFileListLoading: false
            };
        case DELETE_FILE_MESSAGE:
            return {
                ...state,
                fileDeletionStatus: action.payload,
                isLoading: true,
                isFailure: false
            };
        case RECEIVE_DOWNLOAD_SUCCESS:
            return {
                ...state,
                fileToDownLoad: action.payload.path,
                fileReceiveSuccess: true,
                isFailure: false
            };
        case VISIT_BULK_UPLOAD_PRACTICES_REQUEST:
            return {
                ...state,
                isPracticeFailure: false
            }

        case VISIT_BULK_UPLOAD_PRACTICES_RECEIVE:
            return {
                ...state,
                isPracticeFailure: false,
                practices: action.payload
            }

        case VISIT_BULK_UPLOAD_PRACTICES_FAILURE:
            return {
                ...state,
                isPracticeFailure: true
            }
        default:
            return state;
    }
};
