import { getActionCreator, getActionType, getActionTypes, getReducer } from '@/utils/redux';

import { getClinicians } from '@/redux/services/cliniciansApi';

// Types
export const ProviderListTypes = getActionTypes('PROVIDERLISTPMS');
export const UpdateSelectedProviderIdType = getActionType('UPDATESELECTEDPROVIDERID');
export const UpdateSelectedProviderIdAction = getActionCreator(UpdateSelectedProviderIdType);

export const getProviderAction = queryParam => ({
    types: ProviderListTypes,
    callAPI: () => getClinicians(queryParam)
});

const initialState = {
    isProviderLoading: false,
    Providers: [],
    error: {
        code: '',
        Message: ''
    }
};

// Reducer
export default getReducer(initialState, {
    [ProviderListTypes.READY]: state => ({
        ...state,
        isProviderLoading: true,
        error: {
            code: '',
            Message: ''
        }
    }),
    [ProviderListTypes.SUCCESS]: (state, { payload }) => ({
        ...state,
        Providers: payload.getProvidersByPracticeId,
        // selectedProviderId:
        //     payload.getProvidersByPracticeId.length !== 0 && payload.getProvidersByPracticeId[0].id,
        isProviderLoading: false,
        error: {
            code: '',
            Message: ''
        }
    }),
    [ProviderListTypes.ERROR]: (state, { payload }) => {
        return {
            ...state,
            isProviderLoading: false,
            error: {
                code: '',
                Message: 'Error occured while fetching error.'
            }
        };
    }
});
