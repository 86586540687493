import React, { Component } from 'react';
import { ProApp } from 'pro-admin-subapp';
import { connect } from 'react-redux';
import * as constants from '../../helper/constants';
import { getJwt } from '../../utils/jwtUtils';
class ProAppComponent extends Component {
    componentDidMount() {
        this.saveLocalStorage();
    }

    componentWillReceiveProps() {
        if (!getJwt()) {
            this.clearLocalStorage();
        }
    }

    saveLocalStorage() {
        localStorage.setItem('Unit', constants.REGISTRY_UNIT_NAME);
        localStorage.setItem('API_GATEWAY_URL', constants.API_GATEWAY_URL);
        if (constants.EMIT_DECLINED_STATUS_ASSIGN_PRO_LIST) {
            localStorage.setItem(
                'EMIT_DECLINED_STATUS_ASSIGN_PRO_LIST',
                constants.EMIT_DECLINED_STATUS_ASSIGN_PRO_LIST
            );
        } else {
            localStorage.removeItem('EMIT_DECLINED_STATUS_ASSIGN_PRO_LIST');
        }

        if (constants.PERMIT_ASSIGN_TO_PENDING_CONSENT) {
            localStorage.setItem(
                'PERMIT_ASSIGN_TO_PENDING_CONSENT',
                constants.PERMIT_ASSIGN_TO_PENDING_CONSENT
            );
        } else {
            localStorage.removeItem('PERMIT_ASSIGN_TO_PENDING_CONSENT');
        }

        if (constants.HIDE_PATIENT_BROWSER_EMAIL_FILTER) {
            localStorage.setItem('HIDE_EMAIL_FILTER', constants.HIDE_PATIENT_BROWSER_EMAIL_FILTER);
        } else {
            localStorage.removeItem('HIDE_EMAIL_FILTER');
        }

        if (constants.DISPLAY_PRO_EXPIRY_IN_DAYS) {
            localStorage.setItem('DISPLAY_EXPIRY_IN_DAYS', constants.DISPLAY_PRO_EXPIRY_IN_DAYS);
        } else {
            localStorage.removeItem('DISPLAY_EXPIRY_IN_DAYS');
        }

        if (constants.DISPLAY_PRO_SCORE_MISSING) {
            localStorage.setItem('DISPLAY_PRO_SCORE_MISSING', constants.DISPLAY_PRO_SCORE_MISSING);
        } else {
            localStorage.removeItem('DISPLAY_PRO_SCORE_MISSING');
        }
    }

    clearLocalStorage() {
        localStorage.removeItem('Unit');
        localStorage.removeItem('API_GATEWAY_URL');
        localStorage.removeItem('activeTabIndex');
        localStorage.removeItem('activeSearchKey');
        localStorage.removeItem('activePageIndex');
        localStorage.removeItem('EMIT_DECLINED_STATUS_ASSIGN_PRO_LIST');
        localStorage.removeItem('PERMIT_ASSIGN_TO_PENDING_CONSENT');
        localStorage.removeItem('HIDE_PATIENT_BROWSER_EMAIL_FILTER');
        localStorage.removeItem('DISPLAY_EXPIRY_IN_DAYS');
        localStorage.removeItem('DISPLAY_PRO_SCORE_MISSING');
    }

    componentWillUnmount() {
        localStorage.removeItem('activeTabIndex');
        localStorage.removeItem('activeSearchKey');
        localStorage.removeItem('activePageIndex');
    }

    render() {
        const { SelectedPractice } = this.props;

        return (
            <ProApp
                SelectedPractice={SelectedPractice}
                Unit={process.env.REGISTRY_UNIT}
                constants={constants}
            />
        );
    }
}

const mapStateToProps = ({ QualityDashboard }) => ({
    SelectedPractice: QualityDashboard.selectedPractice
});

export default connect(mapStateToProps, null)(ProAppComponent);
