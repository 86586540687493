import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Resetpasswordform from '@/components/Resetpasswordform';
import EmailSent from '@/components/EmailSent';
import APIHelper from '@/helper/apihelper';
import './Resetpassword.less';
import Cryptr from 'cryptr';
import { CRYPTR_ASE_KEY } from '@/helper/constants.js';

const apiHelperInstance = new APIHelper();

class Resetpassword extends Component {
    constructor(props) {
        super(props);
        /* SET INITIAL STATE:
    ===================================== */
        this.state = {
            isFormSubmit: false,
            password: '',
            confirmpassword: '',
            location: props.location.search.substr(4, props.location.search.length),
            verified: undefined,
            errors: {},
            isComplexityMeet: false
        };
    }

    /* VALIDATE RESET TOKEN. VALID: RESET PASSWORD FORM. INVALID: EXPIRED LINK MESSAGE SHOWED:
  ===================================== */
    componentDidMount() {
        fetch(apiHelperInstance.Resources.AuthenticateToken, {
            method: 'GET',
            headers: {
                Authorization: this.state.location,
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => {
                if (data.data.validate === false) {
                    this.setState({ verified: false });
                } else this.setState({ verified: true });
            })
            .catch(() => this.props.history.push('/forgotpassword'));
    }

    /* HANDLE WHEN INPUT TEXT VALUE CHANGED (E.G. PASSWORD OR CONFIRM PASSWORD):
  ================================================================ */
    onHandleChange = (field, value) => {
        if (
            this.state.errors.password ||
            this.state.errors.confirmpassword ||
            this.state.errors.error
        ) {
            const err = this.state.errors;
            err[field] = '';
            err.error = '';
            this.setState({ errors: err });
        }
        if (field === 'password') {
            //            if (!value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&*()._*])(?=.{8,})/)) {
            if (
                !value.match(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@!"#$%&'()*+, -./:;<=>?[\]^`{|}~_*\\])(?=.{8,})/
                )
            ) {
                this.setState({ [field]: value, isComplexityMeet: false });
            } else {
                this.setState({ [field]: value, isComplexityMeet: true });
            }
        } else {
            this.setState({ [field]: value });
        }
    };

    /* WHEN FORM SUBMITED (API CALL):
  ================================================================ */
    onHandleSubmit = () => {
        if (this.handleValidation()) {
            const cryptr = new Cryptr(CRYPTR_ASE_KEY);
            const encryptedPassword = cryptr.encrypt(this.state.password);
            fetch(apiHelperInstance.Resources.ResetPassword, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, cors, *same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json, text/plain, */*'
                },
                body: JSON.stringify({
                    token: this.state.location,
                    password: encryptedPassword
                })
            })
                .then(res => res.json())
                .then(res => {
                    if (res.statusCode === 1) {
                        this.setState({ isFormSubmit: true });
                    } else if (res.statusCode === 0 || res.statusCode === 6) {
                        const errors = {};
                        errors.error = res.description;
                        this.setState({ errors });
                    }
                })
                .catch(() => this.props.history.push('/'));
        }
    };

    /* HANDLE VALIDATION (PASSWORD: REQUIRED, COMPLEX , CONFIRM PASSWORD:REQUIRED, COMPLEX):
  ================================================================ */
    handleValidation() {
        const errors = {};
        let formIsValid = true;
        if (this.state.password === '') {
            formIsValid = false;
            errors.password = 'Password is required';
        } else if (typeof this.state.password !== 'undefined') {
            if (!this.state.password.match(/^[a-zA-Z0-9 !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\\]*$/)) {
                formIsValid = false;
                errors.password = 'Please use only letters, numbers and punctuation characters';
            }
        }

        if (this.state.confirmpassword === '') {
            formIsValid = false;
            errors.confirmpassword = 'Confirm password is required';
        } else if (this.state.password !== this.state.confirmpassword) {
            formIsValid = false;
            errors.error = 'Password do not match';
        }

        this.setState({ errors });
        return formIsValid;
    }

    /* HANDLE WHEN AT INPUT TEXT ENTER BUTTON HIT
  ================================================================ */
    handleKeyUp(event) {
        if (event.keyCode === 13) this.onHandleSubmit(event);
    }

    render() {
        const { verified } = this.state;
        const { custom } = this.props.inputProps;
        if (verified === undefined) {
            return <div>Loading...</div>;
        }

        if (verified === false) {
            return (
                <div>
                    Reset password link expired <Button href='/forgotpassword'>Click here</Button>{' '}
                    to regenerate
                </div>
            );
        }
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {this.state.isFormSubmit ? (
                        <EmailSent {...this.props.emailSent} />
                    ) : (
                        <Resetpasswordform
                            errors={this.state.errors}
                            isComplexityMeet={this.state.isComplexityMeet}
                            onChange={(field, value) => {
                                this.onHandleChange(field, value);
                            }}
                            onSubmit={() => {
                                this.onHandleSubmit();
                            }}
                            onKeyUp={event => this.handleKeyUp(event)}
                            inputProps={{ value: this.state.password, custom }}
                        />
                    )}
                </Grid>
            </Grid>
        );
    }
}

/* SOME DEFAULT PROPS (REST USED COMPONENT ITSELF):
================================================================ */
Resetpassword.defaultProps = {
    emailSent: {
        type: {
            heading: 'Password changed successfully',
            subheading: '',
            buttonLabel: 'Back to Login',
            link: ''
        }
    },
    inputProps: {
        value: '',
        custom: [{ regex: /^.{8,}$/, Message: 'Must be at least 8 characters in length' }]
    }
};

export default Resetpassword;
