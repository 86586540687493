// import { GET_PRACTICE } from '../../graphql/query';
// import APIHelper from '@/helper/apiHelper';
// import { getJwt } from '../../utils/jwtUtils';

// export const getWebtoolMeasures = () => {
//   //debugger;;
//   return fetch("http://demo8514396.mockable.io/measureget");
// };

import {
    CREATE_PREFERENCE_MEASURE,
    CREATE_PROVIDER_PROFILE,
    GET_CALENDAR,
    GET_PRACTICE,
    GET_PREFERENCE_MEASURE,
    GET_PROVIDERS,
    GET_PROVIDER_PAYMENT,
    GET_PROVIDER_PROFILE,
    GET_WEBTOOL_MEASURES
} from '../../graphql/query';

import APIHelper from '@/helper/apihelper';
import { getJwt } from '../../utils/jwtUtils';

export const getWebtoolYeasList = () => {
    const api = new APIHelper();
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Action: api.Actions.View,
            Authorization: getJwt()
        })
        // body: JSON.stringify({ query: GET_PRACTICE, variables: {} }),
    };

    return fetch(api.Resources.Year, requestOptions);
};

export const getPractices = (params, signal) => {
    const api = new APIHelper();

    let requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Action: api.Actions.View,
            Authorization: getJwt()
        }),
        body: JSON.stringify({ query: GET_PRACTICE, variables: params })
    };

    if (signal) {
        requestOptions = {...requestOptions, signal};
    } 

    return fetch(api.Resources.Practice, requestOptions);
};

export const getClinicianData = params => {
    const api = new APIHelper();
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Action: api.Actions.View,
            Authorization: getJwt()
        }),
        body: JSON.stringify({ query: GET_PROVIDERS, variables: params })
    };
    return fetch(api.Resources.Provider, requestOptions);
};

export const getMeasuresList = (params,signal) => {
    const api = new APIHelper();
    let requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Action: api.Actions.View,
            Authorization: getJwt()
        }),
        body: JSON.stringify({ query: GET_WEBTOOL_MEASURES, variables: params })
    };
    if(signal){
        requestOptions={...requestOptions,signal}
    } 
    return fetch(api.Resources.Measure, requestOptions);
};

export const getWebtoolProviderProfile = (params,signal) => {
    const api = new APIHelper();
    let requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Action: api.Actions.View,
            Authorization: getJwt()
        }),
        body: JSON.stringify({ query: GET_PROVIDER_PROFILE, variables: params })
    };
    if(signal){
        requestOptions={...requestOptions,signal}
    } 
    return fetch(api.Resources.qualityPreference, requestOptions);
};

export const webtoolcreateProfile = params => {
    const api = new APIHelper();
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Action: api.Actions.Create,
            Authorization: getJwt()
        }),
        body: JSON.stringify({
            query: CREATE_PROVIDER_PROFILE,
            variables: params
        })
    };

    return fetch(api.Resources.qualityPreference, requestOptions);
};

export const setWebtoolProfileMeasurePreference = params => {
    const api = new APIHelper();
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Action: api.Actions.Update,
            Authorization: getJwt()
        }),
        body: JSON.stringify({
            query: CREATE_PREFERENCE_MEASURE,
            variables: params
        })
    };
    return fetch(api.Resources.qualityPreference, requestOptions);
};

export const getWebtoolProfileMeasurePreference = (params,signal) => {
    const api = new APIHelper();
    let requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Action: api.Actions.View,
            Authorization: getJwt()
        }),
        body: JSON.stringify({ query: GET_PREFERENCE_MEASURE, variables: params })
    };
    if(signal){
        requestOptions={...requestOptions,signal}
    }
    return fetch(api.Resources.qualityPreference, requestOptions);
};
export const getProviderPaymentStatus = params => {
    const api = new APIHelper();
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Action: api.Actions.View,
            Authorization: getJwt()
        }),
        body: JSON.stringify({ query: GET_PROVIDER_PAYMENT, variables: params })
    };
    return fetch(api.Resources.Provider, requestOptions);
};

export const getWebtoolCalendar = (signal) => {
    const api = new APIHelper();
    let requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Action: api.Actions.View,
            Authorization: getJwt()
        }),
        body: JSON.stringify({ query: GET_CALENDAR, variables: { input: { orderBy: 'id' } } })
    };
    if(signal){
        requestOptions={...requestOptions,signal}
    }
    return fetch(api.Resources.Measure, requestOptions);
};
