import { GET_ENTITY_STATUS_DATA, GET_MODULE_STATUS_DATA, UPDATE_SLIDER } from '@/graphql/query';

import APIHelper from '@/helper/apihelper';
import { getJwt } from '../../utils/jwtUtils';

const api = new APIHelper();

export const getEntityStatusApi = userID => {
    const requestOption = api.getRequestOption(
        GET_ENTITY_STATUS_DATA,
        {
            input: {
                entityid: userID
            }
        },
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.Common, requestOption, response => response);
};

export const getModuleStatusApi = () => {
    const requestOption = api.getRequestOption(
        GET_MODULE_STATUS_DATA,
        {
            input: {}
        },
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.Common, requestOption, response => response);
};

export const updateEntityStatusApi = requestObj => {
    const requestOption = api.getRequestOption(
        UPDATE_SLIDER,
        {
            input: { ...requestObj }
        },
        api.Actions.Create,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.Common, requestOption, response => response);
};
