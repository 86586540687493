import { GET_LOCATIONS, GET_PRACTICE, GET_MEASURE_STATUS_AND_PROFILE_BY_ED } from '@/graphql/query';
import APIHelper from '@/helper/apihelper';
import { getJwt } from '@/utils/jwtUtils';

const api = new APIHelper();

export const getLocationData = params => {
    const requestOption = api.getRequestOption(GET_LOCATIONS, params, api.Actions.View, getJwt());
    return api.PegasusAPI(api.Resources.Location, requestOption, response => response);
};

export const getEdsList = param => {
    const requestOption = api.getRequestOption(GET_PRACTICE, param, api.Actions.View, getJwt());
    return api.PegasusAPI(api.Resources.Practice, requestOption, response => response);
};

export const getEdMeasureStatusAndProfileData = (EdID, measureno, year) => {
    const queryInput = {
        input: { ed_id: EdID.toString(), measure_idIn: measureno, year: year && year.toString() }
    };
    const requestOption = api.getRequestOption(
        GET_MEASURE_STATUS_AND_PROFILE_BY_ED,
        queryInput,
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.SD, requestOption, response => response);
};

export const getEDsMeasureStatusAndEHRFlag = (edIdArr, measureno, year) => {
    const queryInput = {
        input: { ed_idIn: edIdArr, measure_id: measureno, year: year && year.toString() }
    };
    const requestOption = api.getRequestOption(
        GET_MEASURE_STATUS_AND_PROFILE_BY_ED,
        queryInput,
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.SD, requestOption, response => response);
};
