import * as strings from './strings';

import {
    DOMAIN_NAME,
    HTTP_ONLY,
    SAME_SITE,
    SECURE_COOKIES,
    ENABLE_AUTHENTICATION_LINKS,
    WEBTOOL_DATE_TIME_PICKER_AJV_VALIDATION,
    ALLOW_WEBTOOL_DATA_ENTRY_PREVIOUS_YEAR_DATE_TIME,
    ALLOW_WEBTOOL_DATA_ENTRY_CURRENT_YEAR_DATE_TIME,
    GET_CURRENT_DATE_FROM_SERVER
} from '@/helper/constants';

import Cookies from 'universal-cookie';
// import GENDER from '../../../../assets/json/gender/gender.json';
import GENDER from '../../../src/assets/json/gender/gender.json';
import _ from 'lodash';
import moment from 'moment';

export function _calculateAge(birthday, visitDate) {
    let patientAge = '';
    const formattedBirthDate = moment(birthday).startOf('day');
    const formattedVisitDate = moment(visitDate).startOf('day');
    const ageInYears = formattedVisitDate.diff(formattedBirthDate, 'years');
    if (ageInYears < 1) {
        const months = formattedVisitDate.diff(formattedBirthDate, 'months');
        const days = formattedVisitDate.diff(formattedBirthDate, 'days');
        if (months > 1) {
            patientAge = months + ' ' + strings.ageInMonthPlural;
        } else if (months === 1) {
            patientAge = months + ' ' + strings.ageInMonthSingular;
        } else if (days > 1 || days === 0) {
            patientAge = days + ' ' + strings.ageInDayPlural;
        } else {
            patientAge = days + ' ' + strings.ageInDaySingular;
        }
    } else {
        if (ageInYears === 1) {
            patientAge = ageInYears + ' ' + strings.ageInYearSingular;
        } else {
            patientAge = ageInYears + ' ' + strings.ageInYearPlural;
        }
    }
    // console.log("formattedBirthDate", formattedBirthDate, "formattedVisitDate", formattedVisitDate ,"patientAge ", patientAge)
    return patientAge;
}

export function _dateFormatter(date, strFormat) {
    if (!_.isEmpty(WEBTOOL_DATE_TIME_PICKER_AJV_VALIDATION)) {
        let dateFormat = !_.isUndefined(date) ? date.replace('T',' ').replace('.000Z','') : '';
        return moment(dateFormat).format(strFormat)
    } else {
        return moment(new Date(date)).format(strFormat);
    }
}

export function _calculateAgeInMonths(birthday) {
    var a = moment(new Date());
    var b = moment(birthday);
    var years = a.diff(b, 'year');
    b.add(years, 'years');
    var months = a.diff(b, 'months');
    b.add(months, 'months');
    var days = a.diff(b, 'days');
    if (months > 1) {
        return months + ' ' + strings.ageInMonthPlural;
    } else if (months === 1) {
        return months + ' ' + strings.ageInMonthSingular;
    } else if (days > 1 || days === 0) {
        return days + ' ' + strings.ageInDayPlural;
    } else {
        return days + ' ' + strings.ageInDaySingular;
    }
}

export function getLocationName(list, id) {
    let name = '';
    if (list && list.length) {
        const filteredLocation = _.find(list, { id: id });

        if (filteredLocation) {
            let locationDetails = '';
            if (filteredLocation.externalid != null) {
                locationDetails = filteredLocation.externalid;
            }
            if (filteredLocation.externalid != null && filteredLocation.name != null) {
                locationDetails = filteredLocation.externalid && filteredLocation.name ? filteredLocation.externalid + ' - ' : filteredLocation.externalid;
            }
            let locationName = '';
            if (filteredLocation.name != null) {
                locationName = filteredLocation.name;
            }
            name = locationDetails + locationName;
        }
    }
    return name;
}

export function getFullGenderValue(text) {
    if (!text || text.length === 0) return text;
    const genderObj = GENDER.superSet;
    return genderObj && genderObj[text.toUpperCase()] ? genderObj[text.toUpperCase()] : '';
}

/**
 * Function used to clear cookies according to given status.
 * This will execute if status is 401
 */
export function handleUnauthorizedRequest(status) {
    if (status === 401) {
        const cookies = new Cookies();
        cookies.set('interval', '', {
            path: '/',
            secure: parseInt(SECURE_COOKIES),
            maxAge: 1,
            sameSite: SAME_SITE,
            domain: DOMAIN_NAME,
            httpOnly: parseInt(HTTP_ONLY)
        });
        const cookiesJwt = new Cookies();
        cookiesJwt.set('jwt-token', '', {
            path: '/',
            secure: parseInt(SECURE_COOKIES),
            maxAge: 1,
            sameSite: SAME_SITE,
            domain: DOMAIN_NAME,
            httpOnly: parseInt(HTTP_ONLY)
        });
        window.location.href = ENABLE_AUTHENTICATION_LINKS ? '/' : '/SSORouter?sessionexpired=true';
    }
    return;
}

/**
 * TODO: Enhance the removeFalsyProperty function
 */
export function removeFalsyProperty(
    object,
    propertyName,
    permittedValues = [],
    nonPermittedValues = []
) {
    var deepClonedObject = _.cloneDeep(object);
    var shouldRemoveFalsyProperty = false;

    if (
        deepClonedObject[propertyName] == null ||
        typeof deepClonedObject[propertyName] == 'undefined'
    ) {
        shouldRemoveFalsyProperty = true;
    } else if (permittedValues && permittedValues.length) {
        shouldRemoveFalsyProperty = !_.includes(permittedValues, deepClonedObject[propertyName]);
    } else if (nonPermittedValues && nonPermittedValues.length) {
        shouldRemoveFalsyProperty = _.includes(nonPermittedValues, deepClonedObject[propertyName]);
    } else if (
        typeof deepClonedObject[propertyName] == 'string' &&
        deepClonedObject[propertyName].trim().length === 0
    ) {
        shouldRemoveFalsyProperty = true;
    }

    if (shouldRemoveFalsyProperty) {
        delete deepClonedObject[propertyName];
    }

    return deepClonedObject;
}

export function removeEmptyFields(obj) {
    Object.entries(obj).forEach(([key, val]) => {
        if (val && typeof val === 'object') {
            val = removeEmptyFields(val);
        }

        if (val == null || typeof val == 'undefined') {
            delete obj[key];
        }
    });

    if (Object.entries(obj).length === 0 && obj.constructor === Object) {
        return null;
    }

    return obj;
}

export function getInfoAboutAllowDataEntry(year, dateGivenByServer) {
    let responseMap = {
        minDate: "",
        maxDate: "",
        isAllowDataEntry: false
    };
    const currentYear = new Date().getFullYear();
    const yearDiff = (parseInt(currentYear) - parseInt(year));

    if (yearDiff > 1) {
        return responseMap;
    } 
    
    const registryWiseEndDate = (yearDiff === 1) ? ALLOW_WEBTOOL_DATA_ENTRY_PREVIOUS_YEAR_DATE_TIME : ALLOW_WEBTOOL_DATA_ENTRY_CURRENT_YEAR_DATE_TIME
    
    const minDate = moment([year, 0]).startOf("year").format('YYYY-MM-DD HH:mm');
    const maxDate = moment([year, 0]).endOf("year").format('YYYY-MM-DD HH:mm');
    const currentDate = moment();
    const endDate = moment(moment(registryWiseEndDate).endOf('day').format('YYYY-MM-DD HH:mm'));    
    let diffBetweenTwoDates = 0;

    if (GET_CURRENT_DATE_FROM_SERVER === 'true') {
        diffBetweenTwoDates = dateGivenByServer ? moment(moment.utc(dateGivenByServer).local().format("YYYY-MM-DD HH:mm")).diff(endDate) : moment(currentDate.format("YYYY-MM-DD HH:mm")).diff(endDate);
    } else {
        diffBetweenTwoDates = moment(currentDate.format("YYYY-MM-DD HH:mm")).diff(endDate);
    }
    let isAllowDataEntry = false;

    if (diffBetweenTwoDates <= 0) {
        isAllowDataEntry = true;
    }

    responseMap = {
        minDate: minDate,
        maxDate: maxDate,
        isAllowDataEntry: isAllowDataEntry
    };

    return responseMap;
}