import React from 'react';
import { Link } from 'react-router-dom';
import {
    Grid,
    Typography,
    FormHelperText,
    Button,
    FormControl,
    CircularProgress,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Fab
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const OtpVerificationModeOptionsForm = props => {
    const {
        heading,
        optionsheading,
        usernamelabel,
        userName,
        mobile,
        getotpbuttonlabel,
        email,
        isNoMobile,
        selectedPreference,
        error,
        isSendOTPInProgress
    } = props;
    const iconMobile = (
        <span className='option--mobile'>
            <FontAwesomeIcon icon={['fal', 'mobile-alt']} className='phone__icon' />
            {' ' + mobile}
        </span>
    );
    const iconEmail = (
        <span className='option--email'>
            <FontAwesomeIcon icon={['fal', 'envelope']} className='cg__mail-icon' />
            {' ' + email}
        </span>
    );

    return (
        <Grid
            container
            justify='center'
            alignItems='center'
            className='login__contanier  otp-form__wrapper'
        >
            <Grid item className='login__signin'>
                <Grid item className='login__head'>
                    <Link to='/'>
                        <Typography variant='h4' className='login__title' component='h3'>
                            <ArrowBack name='arrow left' size='large' />
                            {heading}
                        </Typography>
                    </Link>
                </Grid>
                <Grid item className='otp-form__mail-label'>
                    <FormHelperText>{usernamelabel}</FormHelperText>
                </Grid>
                <Grid item className='otp-form__mail'>
                    <Typography variant='h6' className='otp-form__email__h6'>
                        {userName}
                    </Typography>
                </Grid>
                <Grid item className='verification-code__options-grid'>
                    <FormControl component='fieldset' className='verification-code__options'>
                        <FormLabel component='legend' className='verification-code_options-heading'>
                            {optionsheading}
                        </FormLabel>
                        <RadioGroup
                            aria-label='Mode'
                            name='medium'
                            className='verification-code__radio-group'
                            value={selectedPreference}
                            onChange={e => {
                                props.onChange(e.target.value);
                            }}
                        >
                            <FormControlLabel
                                value='email'
                                className='option--email-form-control'
                                control={<Radio color='primary' className='radio-btn' />}
                                label={iconEmail}
                            />
                            {!isNoMobile && (
                                <FormControlLabel
                                    value='mobile'
                                    className='option--mobile-form-control'
                                    control={<Radio color='primary' className='radio-btn' />}
                                    label={iconMobile}
                                />
                            )}
                        </RadioGroup>
                    </FormControl>
                </Grid>
                {isNoMobile && (
                    <Grid item className='register-mobile_grid'>
                        <Button
                            className='to-register__btn'
                            onClick={props.onGenerateRegisterPhoneToken}
                        >
                            Click here
                        </Button>
                        <span className='to-register_msg'>to register your mobile number</span>
                    </Grid>
                )}
                {error && (
                    <Grid item xs={12} className='error__wrapper'>
                        <FormHelperText className='error__text'> {error} </FormHelperText>
                    </Grid>
                )}
                <Grid item className='verification-code--send__otp-grid'>
                    {/* <Button
            variant='contained'
            color='primary'
            className='verification-code--send__otp'
            onClick={props.onSendOTP}
          >
            {getotpbuttonlabel}
          </Button>  */}
                    <Fab
                        disabled={isSendOTPInProgress}
                        variant='extended'
                        onClick={props.onSendOTP}
                        color='primary'
                        className='verification-code--send__otp'
                    >
                        {getotpbuttonlabel}
                    </Fab>
                    {isSendOTPInProgress && <CircularProgress size={20} />}
                </Grid>
            </Grid>
        </Grid>
    );
};

OtpVerificationModeOptionsForm.defaultProps = {
    heading: '2 Step Verification',
    optionsheading: 'Please choose one of the below option to receive 6 digit verification code.',
    usernamelabel: "This extra step shows it's really you trying to login.",
    username: 'abc.xyz',
    email: 'abc*****.com',
    mobile: '23********678',
    getotpbuttonlabel: 'SEND OTP'
};

export default OtpVerificationModeOptionsForm;
