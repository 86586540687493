import { getActionCreator, getActionType, getActionTypes, getReducer } from '@/utils/redux';
import {
    getLocationCount,
    getPractices,
    getProviderCount,
    getPracticeSDMeasureStatus
} from '@/redux/services/practiceApi';
import { getPracticesRefreshDate } from '@/redux/services/practiceRefreshDateApi';
import { requestTypesAssociatedWithSpecificServiceDeskAPI } from '@/redux/services/serviceDeskApi';
import {
    convertDateToUTC,
    fnGenericAPICall,
    fnCreateDataWithUniqueKey,
    getRefreshDataObject,
    getFromToDateByMeasureSetYear,
    getLatestYearQuaterFilter
} from '@/helper/commonFunction';
import {
    PRACTICE_REFRESH_END_DATE_LABEL,
    FETCH_PRACTICE_REFRESH_DATE,
    FETCH_PRACTICE_MEASURE_STATUS,
    DEFAULT_FILTER_BY_REFRESH_DATE
} from '@/helper/constants';
import {
    UpdateMeasureFilterDefaultSelectionAction,
    getMeasureFilterDataAction
} from '@/redux/modules/measureFilter';
import i18next from 'i18next';
import { FETCH_ED } from '@/helper/constants';

// Types
export const PracticeListTypes = getActionTypes('PRACTICELIST');
export const PracticeChangeTypes = getActionTypes('PRACTICECHANGE');
export const SetSelectedPracticeType = getActionType('SETSELECTEDPRACTICE');
export const GetPracticeMeasureStatusTypes = getActionTypes('GETPRACTICEMEASURESTATUS');
export const SetPracticeMeasureStatusType = getActionType('SETPRACTICEMEASURESTATUS');
export const SetPracticeChangeLoadingType = getActionType('SETPRACTICECHANGELOADING');
export const SetDashboardTicketCategoryType = getActionType('SETDASHBOARDTICKETCATEGORY');
export const SetLocationCountType = getActionTypes('SetLocationCount');
export const ClearPracticeRefreshDateType = getActionType('CLEARPRACTICEREFRESHDATE');
export const SetPracticeRefreshDateTypes = getActionTypes('SETPRACTICEREFRESHDATE');
// Actions
export const SetSelectedPracticeAction = getActionCreator(SetSelectedPracticeType);
export const SetPracticeMeasureStatusAction = getActionCreator(SetPracticeMeasureStatusType);
export const SetPracticeChangeLoadingAction = getActionCreator(SetPracticeChangeLoadingType);
export const SetDashboardTicketSubTypeAction = getActionCreator(SetDashboardTicketCategoryType);
export const SetLocationCountAction = getActionCreator(SetLocationCountType);
export const ClearPracticeRefreshDateAction = getActionCreator(ClearPracticeRefreshDateType);

export const SetPracticeRefreshDateAction = param => ({
    types: SetPracticeRefreshDateTypes,
    callAPI: () => getPracticeRefreshDateDetails(param)
});

const getPracticeRefreshDateDetails = async param => {
    const edrefreshDateData = await getPracticesRefreshDate(param);
    return {
        data: {
            refreshDateData: getRefreshDataObject(edrefreshDateData)
        }
    };
};

export const LocationCountAction = param => ({
    types: SetLocationCountType,
    callAPI: () => getLocationCount(param)
});

export const getPracticeMeasureStatusAction = (queryParam, measureNoArr, year) => ({
    types: GetPracticeMeasureStatusTypes,
    callAPI: () => getPracticeSDMeasureStatus(queryParam, measureNoArr, year)
});

export const getDashboardTicketSubType = async () => {
    let returnValue = [];
    const res = await fnGenericAPICall(requestTypesAssociatedWithSpecificServiceDeskAPI, [
        'Pegasus Measure'
    ]);
    returnValue =
        res.values !== null && res.values !== undefined
            ? res.values.filter(
                  ({ description, helpText, id, issueTypeId, name, serviceDeskId }) => {
                      //if (regexp.test(description)) {
                      return fnCreateDataWithUniqueKey({
                          description,
                          helpText,
                          id,
                          issueTypeId,
                          name,
                          serviceDeskId
                      });
                      //}
                  }
              )
            : [];
    return {
        data: {
            dashboardTicketSubType: returnValue
        }
    };
};

export const getSelectedPracticeData = async (
    selectedPracticeId,
    selectedPracticeName,
    selectedPracticeExternalID,
    selectedPracticeSystem
) => {
    const response = FETCH_PRACTICE_REFRESH_DATE
        ? await Promise.all([
              getProviderCount(selectedPracticeId),
              getLocationCount(selectedPracticeId),
              getPracticesRefreshDate(selectedPracticeId)
          ])
        : await Promise.all([
              getProviderCount(selectedPracticeId),
              getLocationCount(selectedPracticeId)
          ]);
    return {
        data: {
            id: selectedPracticeId,
            name: selectedPracticeName,
            externalID: selectedPracticeExternalID,
            system: selectedPracticeSystem,
            providerCnt:
                response[0].status === 403 || response[0].status === 502
                    ? null
                    : response[0].data.getProviderCount && response[0].data.getProviderCount.count,
            locationCnt:
                response[0].status === 403 || response[0].status === 502
                    ? null
                    : FETCH_ED
                    ? response[1].data.getPracticeChildCount &&
                      response[1].data.getPracticeChildCount.count
                    : response[1].data.getLocationCount && response[1].data.getLocationCount.count,
            refreshDateData: FETCH_PRACTICE_REFRESH_DATE
                ? [
                      {
                          label: i18next.t('qualityDashboard.refreshDateData.label.updatedOn'),
                          format: 'MMM Do YYYY HH:mm',
                          date:
                              response[2].data.getPracticeRefreshStatus &&
                              response[2].data.getPracticeRefreshStatus.length > 0 &&
                              response[2].data.getPracticeRefreshStatus[0].lastrefreshtime &&
                              convertDateToUTC(
                                  new Date(
                                      Number(
                                          response[2].data.getPracticeRefreshStatus[0]
                                              .lastrefreshtime
                                      )
                                  )
                              ),
                          title: i18next.t('qualityDashboard.refreshDateData.title.updatedOn')
                      },
                      {
                          label: PRACTICE_REFRESH_END_DATE_LABEL,
                          format: 'MMM Do YYYY',
                          date:
                              response[2].data.getPracticeRefreshStatus &&
                              response[2].data.getPracticeRefreshStatus.length > 0 &&
                              response[2].data.getPracticeRefreshStatus[0].enddate &&
                              convertDateToUTC(
                                  new Date(
                                      Number(response[2].data.getPracticeRefreshStatus[0].enddate)
                                  )
                              ),
                          title: i18next.t('qualityDashboard.refreshDateData.title.endDate')
                      }
                  ]
                : []
        }
    };
};

export const getPracticeListAction = () => ({
    types: PracticeListTypes,
    callAPI: () => getPractices(),
    handleAction: async ({ type, payload, store }) => {
        switch (type) {
            case PracticeListTypes.SUCCESS: {
                const practiceList =
                    payload.getPractices !== null
                        ? payload.getPractices.length > 0
                            ? payload.getPractices
                            : []
                        : [];
                if (practiceList.length > 0) {
                    const subType = (await getDashboardTicketSubType()).data;
                    store.dispatch(SetDashboardTicketSubTypeAction(subType));
                    const selectedPracticeId = payload.getPractices && payload.getPractices[0].id;
                    const selectedPracticeName =
                        payload.getPractices && payload.getPractices[0].name;
                    const selectedPracticeExternalID =
                        payload.getPractices && payload.getPractices[0].externalid;
                    const selectedPracticeSystem =
                        payload.getPractices && payload.getPractices[0].system;
                    const response = (await getSelectedPracticeData(
                        selectedPracticeId,
                        selectedPracticeName,
                        selectedPracticeExternalID,
                        selectedPracticeSystem
                    )).data;
                    store.dispatch(SetSelectedPracticeAction(response));
                    if (store.getState().MeasureFilter.measureFilterData.measureSet.length === 0) {
                        store.dispatch(getMeasureFilterDataAction('QualityDashboard', response));
                    }
                }
                break;
            }
            default:
                break;
        }
    }
});

export const onPracticeChangeAction = param => ({
    types: PracticeChangeTypes,
    callAPI: () => getSelectedPracticeData(param),
    handleAction: ({ type, payload, store }) => {
        switch (type) {
            case PracticeChangeTypes.SUCCESS: {
                store.dispatch(SetPracticeMeasureStatusAction());
                payload.name = store
                    .getState()
                    .QualityDashboard.practiceList.filter(obj => obj.id === payload.id)[0].name;
                payload.externalID = store
                    .getState()
                    .QualityDashboard.practiceList.filter(
                        obj => obj.id === payload.id
                    )[0].externalid;
                payload.system = store
                    .getState()
                    .QualityDashboard.practiceList.filter(obj => obj.id === payload.id)[0].system;
                var dataAvailableTillDate =
                    payload.refreshDateData.length > 0 ? payload.refreshDateData[1].date : null;
                let storeObj = store.getState().MeasureFilter.measureFilterData;
                if (DEFAULT_FILTER_BY_REFRESH_DATE && dataAvailableTillDate instanceof Date) {
                    const month = dataAvailableTillDate.getMonth() + 1;
                    const quater = Math.ceil(month / 3);
                    const defaultSelectedValue = storeObj.duration.filter(item => {
                        return item.value === `${dataAvailableTillDate.getFullYear()}Q${quater}`;
                    });
                    const currentMeasureSetId = storeObj.selectedValues.measureSet;
                    const currentMeasureSetData = storeObj.measureSet.filter(
                        p => p.id === currentMeasureSetId
                    );
                    const currMeasureSetYear =
                        currentMeasureSetData.length > 0 ? currentMeasureSetData[0].year : null;
                    if (
                        defaultSelectedValue.length > 0 &&
                        currMeasureSetYear === dataAvailableTillDate.getFullYear()
                    ) {
                        storeObj.selectedValues.year = dataAvailableTillDate.getFullYear();
                        storeObj.selectedValues.duration = defaultSelectedValue[0].value;
                        storeObj.selectedValues.durationFrom = defaultSelectedValue[0].startdate;
                        storeObj.selectedValues.durationTo = defaultSelectedValue[0].enddate;
                        const customDateDuration = getFromToDateByMeasureSetYear(
                            storeObj.selectedValues.year
                        );
                        storeObj.selectedValues.fromdate = customDateDuration.fromdate;
                        storeObj.selectedValues.todate = customDateDuration.todate;
                        store.dispatch(UpdateMeasureFilterDefaultSelectionAction(storeObj));
                    } else
                        store.dispatch(
                            UpdateMeasureFilterDefaultSelectionAction(
                                getLatestYearQuaterFilter(store, storeObj)
                            )
                        );
                } else
                    store.dispatch(
                        UpdateMeasureFilterDefaultSelectionAction(
                            getLatestYearQuaterFilter(store, storeObj)
                        )
                    );
                store.dispatch(SetSelectedPracticeAction(payload));
                break;
            }
            default:
                break;
        }
    }
});

const initialState = {
    isLoading: true,
    practiceList: [],
    onPracticeChangeLoading: false,
    dashboardTicketSubType: [],
    selectedPractice: {
        id: null,
        name: null,
        externalID: null,
        system: null,
        providerCnt: null,
        locationCnt: null,
        refreshDateData: [],
        isPracticeRefreshDateLoading: false
    },
    fetchPracticeMeasureStatus: FETCH_PRACTICE_MEASURE_STATUS ? true : false,
    isPracticeMeasureStatusLoading: FETCH_PRACTICE_MEASURE_STATUS ? true : false,
    practiceMeasureStatus: [],
    error: {
        code: '',
        Message: ''
    }
};

// Reducer
export default getReducer(initialState, {
    [ClearPracticeRefreshDateType]: (state, { payload }) => {
        return {
            ...state,
            selectedPractice: {
                ...state.selectedPractice,
                refreshDateData: [],
                isPracticeRefreshDateLoading: true
            }
        };
    },
    [SetPracticeRefreshDateTypes.SUCCESS]: (state, { payload }) => {
        return {
            ...state,
            selectedPractice: {
                ...state.selectedPractice,
                refreshDateData: payload.refreshDateData,
                isPracticeRefreshDateLoading: false
            }
        };
    },
    [SetDashboardTicketCategoryType]: (state, { payload }) => {
        return {
            ...state,
            dashboardTicketSubType: payload.dashboardTicketSubType
        };
    },
    [SetPracticeChangeLoadingType]: (state, { payload }) => {
        return {
            ...state,
            onPracticeChangeLoading: payload,
            fetchPracticeMeasureStatus: true
        };
    },
    [PracticeListTypes.SUCCESS]: (state, { payload }) => {
        return {
            ...state,
            practiceList: payload.getPractices || [],
            isLoading:
                payload.getPractices === null
                    ? false
                    : payload.getPractices.length > 0
                    ? state.isLoading
                    : false,
            error: {
                code: '',
                Message: ''
            }
        };
    },
    [SetSelectedPracticeType]: (state, { payload }) => ({
        ...state,
        selectedPractice: payload,
        onPracticeChangeLoading: false,
        isLoading: false,
        error: {
            code: '',
            Message: ''
        }
    }),
    [SetPracticeMeasureStatusType]: (state, { payload }) => ({
        ...state,
        isPracticeMeasureStatusLoading: FETCH_PRACTICE_MEASURE_STATUS ? true : false,
        fetchPracticeMeasureStatus: FETCH_PRACTICE_MEASURE_STATUS ? true : false,
        practiceMeasureStatus: []
    }),
    [SetLocationCountType.SUCCESS]: (state, { payload }) => ({
        ...state,
        selectedPractice: { ...state.selectedPractice, locationCnt: payload.getLocationCount.count }
    }),
    [GetPracticeMeasureStatusTypes.READY]: (state, { payload }) => ({
        ...state,
        isPracticeMeasureStatusLoading: true
    }),
    [GetPracticeMeasureStatusTypes.SUCCESS]: (state, { payload }) => ({
        ...state,
        isPracticeMeasureStatusLoading: false,
        fetchPracticeMeasureStatus: false,
        practiceMeasureStatus: payload.getMeasureStatus !== null ? payload.getMeasureStatus : []
    }),
    [GetPracticeMeasureStatusTypes.ERROR]: (state, { payload }) => ({
        ...state,
        isPracticeMeasureStatusLoading: false,
        fetchPracticeMeasureStatus: false,
        practiceMeasureStatus: []
    }),
    [PracticeListTypes.ERROR]: (state, { payload }) => {
        return {
            ...state,
            practiceList: [],
            isLoading: false,
            error: {
                code: '',
                Message: 'Error occured while fetching error.'
            }
        };
    }
});
