import { getActionCreator, getActionType, getReducer } from '@/utils/redux';

// Types
export const SetMessageInfoType = getActionType('SETMESSAGEINFOTYPE');
export const setMessageInfoAction = getActionCreator(SetMessageInfoType);

const initialState = {
    messageInfo: {
        displayContainer: '',
        open: false,
        message: null,
        variant: ''
    }
};

// Reducer
export default getReducer(initialState, {
    [SetMessageInfoType]: (state, { payload }) => {
        return {
            ...state,
            messageInfo: {
                displayContainer: payload.displayContainer,
                open: payload.open,
                message: payload.message,
                variant: payload.variant
            }
        };
    }
});
