import React from 'react';
import LoginBranding from '@/components/LoginBranding';
import Footer from '@/components/Footer';
import PropTypes from 'prop-types';
import { Grid, Hidden } from '@material-ui/core';
import Particles from 'react-particles-js';

const particleOpt = {
    particles: {
        number: {
            value: 80,
            density: {
                enable: true,
                value_area: 710
            }
        },
        color: {
            value: '#d3e3f2'
        },
        shape: {
            type: 'circle',
            stroke: {
                width: 1,
                color: '#f9f9f9'
            }
        },
        polygon: {
            nb_sides: 6
        },
        opacity: {
            value: 1,
            random: true
        },
        size: {
            value: 10,
            random: true
        },
        line_linked: {
            distance: 150,
            color: '#e2e9ee',
            opacity: 1
        }
    }
};

export const publicLayout = props => {
    const { children } = props;
    const loginleftProp = {
        logo: 'LOGO',
        showtagline: process.env.SHOW_TAGLINE === 'true' ? true : false,
        tagline: process.env.TAGLINE,
        showinfo: process.env.SHOW_CONTACT_INFORMATION === 'true' ? true : false,
        info: process.env.CONTACT_INFORMATION
    };
    const footerProps = {
        copyright: `© ${new Date().getFullYear()} ${process.env.COPY_RIGHT}`,
        showPrivacy: process.env.SHOW_PRIVACY === 'true' ? true : false,
        showTerms: process.env.SHOW_TERMS === 'true' ? true : false,
        privacyfilename: `Privacy_${process.env.REGISTRY_UNIT}`,
        termsfilename: `Terms_${process.env.REGISTRY_UNIT}`
    };
    return (
        <React.Fragment>
            <div className='particles_wrapper'>
                <Particles params={particleOpt} />
            </div>
            <Grid container className='login--signin  login--page'>
                <Grid container className='login--signin__row'>
                    <Grid item xs={12} md>
                        <LoginBranding loginleftProp={loginleftProp} />
                    </Grid>
                    <Hidden only={['sm', 'xs']}>
                        <Grid item className='login--page--ver__divider' />
                    </Hidden>
                    <Grid item xs={12} md>
                        <Grid
                            container
                            justify='center'
                            alignItems='center'
                            className='login__wrapper-2'
                            id='home'
                        >
                            {children}
                        </Grid>
                    </Grid>
                </Grid>{' '}
                <Grid container className='footer-copyright'>
                    <Grid item xs={12}>
                        <Footer footerProps={footerProps} />
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

/* PROPTYPES
  ================================================================ */
publicLayout.propTypes = {
    children: PropTypes.node
};
