import { 
    GROUP_BY_ENTITY, 
    PRACTICE, 
    REGISTRY_UNIT_NAME,
    // FETCH_ED 
} from '@/helper/constants';
import {
    getClinicianListEntityGroup,
    getGroupMeasurePerformance
} from '@/redux/services/practiceUserApi';
import { practiceDisplayFormat, providerDisplayFormat } from '@/helper/commonFunction';

import { formateDate } from '@/helper/commonFunction';
import { getLocationData } from '@/redux/services/locationPerformanceApi';
import i18next from 'i18next';

export const getEntityGroupDropDownOptionList = optionList => {
    const practiceListOption = [];
    const providerListOption = [];
    const locationListOption = [];
    optionList.map(obj => {
        switch (obj.entity) {
            case GROUP_BY_ENTITY.PRACTICE:
                practiceListOption.push({ value: obj.id, label: obj.name, entity: obj.entity });
                break;
            case GROUP_BY_ENTITY.PROVIDER:
                providerListOption.push({ value: obj.id, label: obj.name, entity: obj.entity });
                break;
            case GROUP_BY_ENTITY.LOCATION:
                locationListOption.push({ value: obj.id, label: obj.name, entity: obj.entity });
                break;
            default:
                break;
        }
    });
    return [
        {
            label: GROUP_BY_ENTITY.PRACTICE,
            options: practiceListOption
        },
        {
            label: i18next.t('groupDashboard.label.clinician'),
            options: providerListOption
        },
        {
            label: GROUP_BY_ENTITY.LOCATION,
            options: locationListOption
        }
    ];
};

export const groupDashboardTabProp = (selectedGroupInfo, GroupPerformanceGrid) => {
    const tabName =
        selectedGroupInfo.groupEntity === GROUP_BY_ENTITY.PROVIDER
            ? i18next.t('groupDashboard.label.clinician')
            : selectedGroupInfo.groupEntity;
    let fontIcon = '',
        iconUrl = '';
    switch (selectedGroupInfo.groupEntity) {
        case GROUP_BY_ENTITY.PRACTICE:
            fontIcon = i18next.t('qualityDashboard.fontIcon.hospital');
            iconUrl = '../../assets/svg/practice.svg';
            break;
        case GROUP_BY_ENTITY.PROVIDER:
            fontIcon = i18next.t('qualityDashboard.fontIcon.stethoscope');
            iconUrl = '../../assets/svg/clinician.svg';
            break;
        case GROUP_BY_ENTITY.LOCATION:
            fontIcon = i18next.t('qualityDashboard.fontIcon.map-marker-alt');
            iconUrl = '../../assets/svg/ehr.svg';
            break;
    }
    return [
        {
            menuItem:
                selectedGroupInfo.id &&
                `${tabName} GROUP` + `(${selectedGroupInfo.groupInfo.length})`,
            defaultActiveIndex: true,
            iconUrl: iconUrl,
            fontIcon: fontIcon,
            router: GroupPerformanceGrid,
            action: () => {}
        }
    ];
};

export const getGroupEntityDropDownList = (entity, props) => {
    let entityList = [];
    switch (entity) {
        case GROUP_BY_ENTITY.PRACTICE:
            props.PracticeList.map(item => {
                entityList.push({
                    value: item.id,
                    label: practiceDisplayFormat(item),
                    entityid: item.id,
                    parententityid: '',
                    parententityname: ''
                });
            });
            break;
        case GROUP_BY_ENTITY.PROVIDER:
            props.ProviderList.map(item => {
                if (item.practice !== null) {
                    item.practice.map(pracObj => {
                        entityList.push({
                            value: `${item.id}- ${pracObj.id}`,
                            providerId: item.id,
                            label:
                                providerDisplayFormat(item) +
                                ` (${practiceDisplayFormat(pracObj)})`,
                            entityid: item.id,
                            parententityid: pracObj.id,
                            parententityname: GROUP_BY_ENTITY.PRACTICE
                        });
                    });
                } else {
                    entityList.push({
                        value: item.id,
                        providerId: item.id,
                        label: providerDisplayFormat(item),
                        entityid: item.id,
                        parententityid: '',
                        parententityname: GROUP_BY_ENTITY.PRACTICE
                    });
                }
            });
            break;
        case GROUP_BY_ENTITY.LOCATION:
            props.LocationList.map(item => {
                if (item.practicename !== null) {
                    entityList.push({
                        value: item.id,
                        label:
                            item.externalid !== null
                                ? `${item.externalid}${
                                      item.name !== null ? item.name && '-' + item.name : ''
                                  } (${item.practiceexternalid} - ${item.practicename})`
                                : `${item.name} (${item.practiceexternalid} - ${item.practicename})`,
                        entityid: item.id,
                        parententityid: item.practiceid,
                        parententityname: GROUP_BY_ENTITY.PRACTICE
                    });
                } else {
                    entityList.push({
                        value: item.id,
                        label:
                            item.externalid !== null
                                ? `${item.externalid}${
                                      item.name !== null ? item.name && '-' + item.name : ''
                                  }`
                                : `${item.name}`,
                        entityid: item.id,
                        parententityid: '',
                        parententityname: GROUP_BY_ENTITY.PRACTICE
                    });
                }
            });
            break;

        case GROUP_BY_ENTITY.ED:
            props.EdList.map(item => {
                if (item.practice !== null) {
                    item.practice.map(pracObj => {
                        entityList.push({
                            value: `${item.id}- ${pracObj.id}`,
                            providerId: item.id,
                            label:
                                providerDisplayFormat(item) +
                                ` (${practiceDisplayFormat(pracObj)})`,
                            entityid: item.id,
                            parententityid: pracObj.id,
                            parententityname: GROUP_BY_ENTITY.PRACTICE
                        });
                    });
                } else {
                    entityList.push({
                        value: item.id,
                        providerId: item.id,
                        label: providerDisplayFormat(item),
                        entityid: item.id,
                        parententityid: '',
                        parententityname: GROUP_BY_ENTITY.PRACTICE
                    });
                }
            });
            break;
        default:
            break;
    }
    return entityList;
};

export const getCurrentGroupDetails = props => {
    if (props.selectedEntity === GROUP_BY_ENTITY.PROVIDER) {
        const providerID = [];
        const practiceID = [];
        let qparams = '';
        if (props.PracticeList.length === 1) {
            practiceID.push(props.PracticeList[0].id);
            qparams = {
                input: {
                    practiceidIn: practiceID,
                    orderBy: 'firstname',
                    inactive: false
                }
            };
        } else {
            props.selectedGroupInfo.groupInfo.map(obj => {
                if (!providerID.includes(parseInt(obj.entityid))) {
                    providerID.push(parseInt(obj.entityid));
                }
                if (
                    !practiceID.includes(parseInt(obj.parententityid)) &&
                    obj.parententityid !== '' &&
                    obj.parententityid !== null
                ) {
                    practiceID.push(parseInt(obj.parententityid));
                }
            });
            qparams = {
                input: {
                    orderBy: 'firstname',
                    inactive: false
                }
            };
            if (practiceID.length > 0) {
                qparams.input.practiceidIn = practiceID;
            } else {
                qparams.input.idIn = providerID;
            }
        }
        // if (FETCH_ED) props.getEDOnPhycianAction(qparams, props.selectedPracticeOption);
        props.getClinicianGroupEntityListAction(qparams, props.selectedPracticeOption);
    } else if (props.selectedEntity === GROUP_BY_ENTITY.LOCATION) {
        const practiceID = [];
        const locationID = [];
        let qparams = '';
        if (props.PracticeList.length === 1) {
            practiceID.push(props.PracticeList[0].id);
            qparams = {
                input: {
                    practiceidIn: practiceID,
                    orderBy: 'firstname',
                    inactive: false
                }
            };
        } else {
            props.selectedGroupInfo.groupInfo.map(obj => {
                if (!locationID.includes(parseInt(obj.entityid))) {
                    locationID.push(parseInt(obj.entityid));
                }
                if (
                    !practiceID.includes(parseInt(obj.parententityid)) &&
                    obj.parententityid !== '' &&
                    obj.parententityid !== null
                ) {
                    practiceID.push(parseInt(obj.parententityid));
                }
            });
            qparams = {
                input: {
                    orderBy: 'name',
                    inactive: false
                }
            };
            if (practiceID.length > 0) {
                qparams.input.practiceidIn = practiceID;
            } else {
                qparams.input.idIn = locationID;
            }
        }
        props.getLocationGroupEntityListAction(qparams, props.selectedPracticeOption);
    }
};

export const getDefaultPracticeSelectedOption = (props, practicelist, onPracticeselection) => {
    let defaultValue = [],
        entityList = [];
    props.PracticeList.map(item => {
        entityList.push({
            value: item.id,
            label: practiceDisplayFormat(item),
            entityid: item.id,
            parententityid: '',
            parententityname: ''
        });
    });
    practicelist.map(item => {
        if (item != null) {
            entityList.map(opt => {
                if (opt.value.toString() === item.toString() && !defaultValue.includes(opt)) {
                    defaultValue.push(opt);
                }
            });
        }
    });
    onPracticeselection(defaultValue);
};

export const getDefaultEDSelectedOption = (props, edlist, onEDChange) => {
    let defaultValue = [],
        entityList = [];
    props.EdList.map(item => {
        // props.getPractices.map(item => {
        entityList.push({
            value: item.id,
            //providerId: item.id,
            label: `${item.externalid} - ${item.name}`,
            externalid: item.externalid,
            parentid: item.parentid,
            parententityname: GROUP_BY_ENTITY.ED
        });
        // });
    });
    edlist &&
        edlist.map(item => {
            if (item != null) {
                entityList.map(opt => {
                    if (opt.value.toString() === item.toString() && !defaultValue.includes(opt)) {
                        defaultValue.push(opt);
                    }
                });
            }
        });
    onEDChange(defaultValue);
};

export const getDefaultProviderSelectedOption = (
    props,
    clinicianlist,
    onEntityGroupSelectChange
) => {
    let defaultValue = [],
        entityList = [];
    props.ProviderList.map(item => {
        if (item.practice !== null) {
            item.practice.map(pracObj => {
                entityList.push({
                    value: `${item.id}- ${pracObj.id}`,
                    providerId: item.id,
                    label: providerDisplayFormat(item) + ` (${practiceDisplayFormat(pracObj)})`,
                    entityid: item.id,
                    parententityid: pracObj.id,
                    parententityname: GROUP_BY_ENTITY.PRACTICE
                });
            });
        } else {
            entityList.push({
                value: item.id,
                providerId: item.id,
                label: providerDisplayFormat(item),
                entityid: item.id,
                parententityid: '',
                parententityname: GROUP_BY_ENTITY.PRACTICE
            });
        }
    });
    clinicianlist &&
        clinicianlist.map(item => {
            if (item != null) {
                entityList.map(opt => {
                    if (
                        opt.entityid.toString() === item.toString() &&
                        !defaultValue.includes(opt)
                    ) {
                        defaultValue.push(opt);
                    }
                });
            }
        });
    onEntityGroupSelectChange(defaultValue);
};

export const selectfilterbyEntity = (inputValue, props) => {
    if (
        props.selectedPracticeOption.length === 0 &&
        inputValue !== '' &&
        props.PracticeList.length > 1
    ) {
        const entity = props.selectedEntity;
        if (entity === GROUP_BY_ENTITY.PROVIDER) {
            let queryParams = '';
            if (isNaN(inputValue)) {
                queryParams = {
                    input: {
                        firstnameLikeNoCase: `%${inputValue}%`,
                        inactive: false,
                        orderBy: 'firstname'
                    }
                };
            } else {
                queryParams = {
                    input: {
                        npi: parseInt(inputValue),
                        inactive: false,
                        orderBy: 'firstname'
                    }
                };
            }
            props.filterClinicianAction(queryParams);
        }
    }
};

export const createEditGroupHelper = (props, groupName) => {
    const entity = props.selectedEntity;
    let groupentitylist = [];
    switch (entity) {
        case GROUP_BY_ENTITY.PRACTICE:
            groupentitylist = parseSelectedOptionBysEntity(
                props.selectedPracticeOption,
                entity,
                props
            );
            break;
        case GROUP_BY_ENTITY.PROVIDER:
            groupentitylist = parseSelectedOptionBysEntity(
                props.selectedClinicianOption,
                entity,
                props
            );
            break;
        case GROUP_BY_ENTITY.LOCATION:
            groupentitylist = parseSelectedOptionBysEntity(
                props.selectedLocationOption,
                entity,
                props
            );
            break;
        default:
            break;
    }
    const createGroupInput = {
        input: {
            name: groupName.trim(),
            entity: entity,
            groupentitylist: groupentitylist
        }
    };
    if (props.editGroup) {
        createGroupInput.input.id = props.selectedGroupInfo.id;
    }
    props.createEditGroupHandle(createGroupInput);
};

export const parseSelectedOptionBysEntity = (selectedOptionList, entity, props) => {
    const returnValue = [];
    selectedOptionList.map(obj => {
        returnValue.push({
            entityid:
                GROUP_BY_ENTITY.PROVIDER === entity
                    ? obj.providerId.toString()
                    : obj.entityid.toString(),
            parententityid:
                props.selectedPracticeOption.length === 0 && GROUP_BY_ENTITY.LOCATION === entity
                    ? ''
                    : obj.parententityid.toString(),
            parententityname: obj.parententityname
        });
    });
    return returnValue;
};

export const onPracticeselectionHelper = (props, selectedOptions, resolve) => {
    if (props.selectedPracticeOption.length > 0 && selectedOptions.length === 0) {
        props.ResetClinicianLocationEntityListAction();
    }
    props.SetDefaultPracticeOptionAction(selectedOptions);
    switch (props.selectedEntity) {
        case GROUP_BY_ENTITY.PROVIDER:
            const qparams = {
                input: {
                    practiceidIn: selectedOptions.map(obj => obj.value),
                    orderBy: 'firstname',
                    inactive: false
                }
            };
            if (selectedOptions.length > 0) {
                if (resolve) {
                    if (false) props.getEDOnPhycianAction(qparams, selectedOptions);
                    props
                        .getClinicianGroupEntityListAction(qparams, selectedOptions)
                        .then(res => resolve());
                } else {
                    if (false) props.getEDOnPhycianAction(qparams, selectedOptions);
                    props.getClinicianGroupEntityListAction(qparams, selectedOptions);
                }
            } else {
                if (props.selectedClinicianOption.length > 0)
                    props.SetDefaultProviderOptionAction([]);
            }
            break;
        default:
            break;
    }
};

export const getEntityListByPracticeID = props => {
    if (props.PracticeList.length === 1) {
        let qparams = '';
        const practiceID = [];
        practiceID.push(props.PracticeList[0].id);
        if (props.selectedEntity === GROUP_BY_ENTITY.PROVIDER) {
            qparams = {
                input: {
                    practiceidIn: practiceID,
                    orderBy: 'firstname',
                    inactive: false
                }
            };
            // if (FETCH_ED) props.getEDOnPhycianAction(qparams, props.selectedPracticeOption);
            props.getClinicianGroupEntityListAction(qparams, props.selectedPracticeOption);
        } else {
            qparams = {
                input: {
                    practiceidIn: practiceID,
                    orderBy: 'name',
                    inactive: false
                }
            };
            props.getLocationGroupEntityListAction(qparams, props.selectedPracticeOption);
        }
    }
};

export const getSelectedGroupPerformance = (filterValue, props) => {
    const params = getInputParamForGroupPerformance(props.MeasureFilterData.selectedValues);
    const customRange = props.MeasureFilterData.selectedValues.customrange;
    let groupMeasureList = props.MeasureList;
    groupMeasureList = groupMeasureList.map(obj => ({
        ...obj,
        practiceid: params.EntityId,
        performance: {
            performanceData: {
                GroupId: parseInt(props.selectedGroupInfo.id),
                DurationFrom: formateDate(new Date(params.DurationFrom), 'YYYY-MM-DD', customRange),
                DurationTo: formateDate(new Date(params.DurationTo), 'YYYY-MM-DD', customRange),
                Flag: params.Flag,
                MeasureId: obj.id,
                Unit: REGISTRY_UNIT_NAME,
                IsPatientSpecific: obj.performance.performanceData.IsPatientSpecific
            },
            isStopPropagation: false
        },
        getGroupMeasurePerformance: getGroupMeasurePerformance
    }));
    return groupMeasureList;
};

export const getInputParamForGroupPerformance = props => ({
    MeasuresetId: props.measureSet,
    DurationFrom: props.customrange ? props.fromdate.replace(/-/g, '/') : props.durationFrom,
    DurationTo: props.customrange ? props.todate.replace(/-/g, '/') : props.durationTo,
    Flag: props.durationFlag
});

export const setDefaultGroupSelected = payload => {
    if (payload.getGroups.length > 1) {
        let uniqueEntity = [...new Set(payload.getGroups.map(item => item.entity))];
        let practiceGroups;
        if (uniqueEntity.includes(GROUP_BY_ENTITY.PRACTICE)) {
            practiceGroups = payload.getGroups.filter(option => {
                if (option.entity === GROUP_BY_ENTITY.PRACTICE) {
                    return option;
                }
            });
            return setDefaultGroupObj(practiceGroups);
        } else if (uniqueEntity.includes(GROUP_BY_ENTITY.PROVIDER)) {
            practiceGroups = payload.getGroups.filter(option => {
                if (option.entity === GROUP_BY_ENTITY.PROVIDER) {
                    return option;
                }
            });
            return setDefaultGroupObj(practiceGroups);
        } else {
            return setDefaultGroupObj(payload.getGroups);
        }
    } else {
        return setDefaultGroupObj(payload.getGroups);
    }
};

export const setDefaultGroupObj = practiceGroups => ({
    id: practiceGroups.length !== 0 && practiceGroups[0].id,
    name: practiceGroups.length !== 0 && practiceGroups[0].name,
    entity: practiceGroups.length !== 0 && practiceGroups[0].entity
});

export const generatePerformanceObject = (
    entityName,
    entityId,
    parentEntityId,
    parentEntityName,
    formatOptionLabel,
    props
) => ({
    id: props.id,
    displayname: props.displayname,
    measureId: props.id,
    measure: props.measure,
    groupEntityheader: formatOptionLabel,
    performance: {
        performanceData: {
            EntityName: entityName,
            EntityId: `${entityId}`,
            ParentEntityId: parentEntityId,
            ParentEntityName: parentEntityName,
            DurationFrom: props.performance.performanceData.DurationFrom,
            DurationTo: props.performance.performanceData.DurationTo,
            Flag: props.performance.performanceData.Flag,
            MeasureId: props.performance.performanceData.MeasureId,
            Unit: REGISTRY_UNIT_NAME,
            IsPatientSpecific: props.performance.performanceData.IsPatientSpecific
        },
        isStopPropagation: false
    },
    basePageLink: '/GroupPerformance'
});

export const getMeasurePerformanceForGroupPracticesInput = props => {
    const inputEntityList = [];
    props.selectedGroupInfo.groupInfo.map(obj => {
        const returnValue = props.PracticeList.filter(
            option => option.id.toString() === obj.entityid.toString()
        );
        const formatOptionLabel = returnValue.length > 0 && practiceDisplayFormat(returnValue[0]);
        inputEntityList.push(
            generatePerformanceObject(PRACTICE, obj.entityid, [], '', formatOptionLabel, props)
        );
    });
    return inputEntityList;
};

export const getGroupEntityByHeader = entity => {
    let header = '';
    switch (entity) {
        case GROUP_BY_ENTITY.PRACTICE:
            header = i18next.t('groupDashboard.getGroupEntityByHeader.practice');
            break;
        case GROUP_BY_ENTITY.PROVIDER:
            header = i18next.t('groupDashboard.getGroupEntityByHeader.provider');
            break;
        default:
            break;
    }
    return header;
};

export const getClinicanList = async providerId => {
    const param = {
        input: {
            idIn: providerId,
            orderBy: 'firstname',
            inactive: false
        }
    };
    let data = await getClinicianListEntityGroup(param)
        .then(res => {
            if (res.data.getProviderPracticeDetail != null) {
                return res.data.getProviderPracticeDetail;
            }
        })
        .catch(error => {});
    return data;
};

export const getLocationList = async locationId => {
    const param = {
        input: {
            idIn: locationId,
            inactive: false
        }
    };
    let data = await getLocationData(param)
        .then(res => {
            if (res.data.getLocations != null) {
                return res.data.getLocations;
            }
        })
        .catch(error => {});
    return data;
};
