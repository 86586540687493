import {
    AppBar,
    CssBaseline,
    Drawer,
    Grid,
    Hidden,
    IconButton,
    Button,
    Toolbar,
    Tooltip
} from '@material-ui/core';
import {
    ENABLE_AUTHENTICATION_LINKS,
    PEGASUS_ROUTES,
    SERVICE_DESK_PATH,
    WEBTOOL_PATH,
    CREATE_ASSERTION_URL,
    HTTP_ONLY,
    SAME_SITE,
    SECURE_COOKIES,
    DISPLAY_HELP_ICON,
    HELP_PAGE_URL,
    PRO_MENU_PATH,
    IS_SAML_AUTH_ENABLED
} from '@/helper/constants';
import { getEntityStatus, getModuleStatus } from '@/redux/modules/entityModuleStatus';
import { getJwt, removeJwt } from '@/utils/jwtUtils';
import { getUserPermissions } from '@/redux/modules/userModule';
import { updateUserProfileTypeAction, userProfileAction } from '@/redux/modules/userProfile';
import APIHelper from '@/helper/apihelper';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Cookies from 'universal-cookie';
import MasterNavigationMenu from '@/components/MasterNavigationMenu';
import MenuIcon from '@material-ui/icons/Menu';
import NavigationMenu from '@/json/navigation.json';
import PropTypes from 'prop-types';
import React from 'react';
import SliderApp from '@/components/Slider';
import { Dynamic_Tour_Path } from './Path';
import Userprofile from '../../components/Header/Header';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { updateEntityStatus } from '@/redux/modules/entityModuleStatus';
import userProfileImage from '../../assets/svg/user_profile.png';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import i18next from 'i18next';
import { googleAnalyticsTrackPageView } from '@/helper/commonFunction';
import ReactGA from 'react-ga';

const apiHelperInstance = new APIHelper();

const MainLogo = require(`@/${process.env.REGISTRY_DASHBOARD_PAGE_LOGO_FILEPATH}`);

//Dynamic Tour Guide Json File Import
const SliderContentJson = Dynamic_Tour_Path[process.env.REGISTRY_UNIT]
    ? Dynamic_Tour_Path[process.env.REGISTRY_UNIT]
    : Dynamic_Tour_Path.Default;

const WEBTOOL_SUBMODULE_PATH = Object.values(WEBTOOL_PATH);
const SERVICE_DESK_SUBMODULE_PATH = Object.values(SERVICE_DESK_PATH);
const PRO_SUBMODULE_PATH = Object.values(PRO_MENU_PATH);

const drawerWidth = '350px';
// const drawerWidth = 'auto';

const styles = theme => ({
    root: {
        display: 'flex'
    },
    list: {
        width: 350
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36
    },
    hide: {
        display: 'none'
    },
    drawer: {
        // zIndex: theme.zIndex.drawer + 3,
        boxSizing: 'border-box',
        [theme.breakpoints.down('sm')]: {
            margin: '0px'
        },
        margin: '0 80px 0 0px',
        width: drawerWidth,
        flexShrink: 0,
        [theme.breakpoints.down('sm')]: {
            margin: '0',
            width: 'auto'
        },
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0
        }
    },
    drawerOpen: {
        // zIndex: theme.zIndex.drawer + 3,
        // Removed it for large screen so horizontal scroll removed from menu
        // width: drawerWidth,
        [theme.breakpoints.down('md')]: {
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: drawerWidth
        },
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        [theme.breakpoints.down('sm')]: {
            top: '28px'
        },
        [theme.breakpoints.up('sm')]: {
            top: '32px'
        },
        [theme.breakpoints.up('md')]: {
            top: '0'
        }
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: 'hidden',
        // [theme.breakpoints.down('md')]: {
        //     position: 'inherit'
        // },
        // [theme.breakpoints.up('md')]: {
        // position: 'fixed'
        // },
        width: theme.spacing(7 + 1),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9 + 1)
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        margin: '0 0 0 0'
    },
    toolbar: {
        // minHeight: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '0 8px',
        // ...theme.mixins.toolbar,
        zIndex: theme.zIndex.drawer + 5
    },
    hamburgerClose: {
        alignItems: 'center'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3)
        // [theme.breakpoints.down('md')]: {
        //     marginLeft: '0'
        // },
        // [theme.breakpoints.up('md')]: {
        // marginLeft: '80px'
        // }
    }
});

class DashboardLayout extends React.Component {
    // state = {
    //   mobileOpen: false,
    // };

    constructor(props) {
        super(props);
        /* SET INITIAL STATE:
    ===================================== */
        this.state = {
            SliderContent: [],
            tempvar: false,
            entityStatus: null,
            isShowAgainChecked: false,
            startSlide: false,

            activeIcon: 'selected-main__nav',
            open: false,
            CurrentSelectedMenu: 0,
            CollapseIndex: false,
            selectedMenuIndex: 0,
            menu: {},
            controller: new AbortController(),
            guideIsActive: true,
            isSubMenuClicked: false
        };
    }

    /* GET ALL SIDE MENU AND LOAD FIRST MENU
  ===================================== */
    componentDidMount() {
        const jwt = getJwt();
        if (jwt) {
            const signal = this.state.controller.signal;
            fetch(apiHelperInstance.Resources.GetMenuDetails, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    Authorization: getJwt(),
                    'Content-Type': 'application/json',
                    resource: 'defaultread',
                    action: 'view'
                },
                body: JSON.stringify({ ismenu: 'true' }),
                signal
            })
                .then(res => res.json())
                .then(data => {
                    if (data.statusCode === 1 && !signal.aborted) {
                        let pathname = IS_SAML_AUTH_ENABLED
                            ? window.sessionStorage.getItem('requestedpath')
                                ? JSON.parse(window.sessionStorage.getItem('requestedpath'))
                                      .pathname
                                : this.props.location.pathname
                            : this.props.location.pathname;
                        window.sessionStorage.clear();
                        const menuItems = data.data.menudetails;
                        if (menuItems.length > 0) {
                            const selectedRoute = this.getSelectedMenuOnPageReload(
                                menuItems,
                                pathname
                            );
                            const isSubMenu = selectedRoute.isSubMenu;
                            pathname = selectedRoute.pathname;
                            const policy = menuItems.find(
                                f => f.router && f.router.toLowerCase() === pathname.toLowerCase()
                            );
                            if (policy) this.props.getUserPermissions(policy.resource);

                            isSubMenu
                                ? this.setState({
                                      menu: menuItems,
                                      selectedMenuIndex: selectedRoute.id,
                                      subMenuIndex: selectedRoute.id,
                                      CollapseIndex: true,
                                      currentIndex: selectedRoute.id
                                  })
                                : this.setState({
                                      menu: menuItems,
                                      selectedMenuIndex: selectedRoute.id,
                                      currentIndex: selectedRoute.id,
                                      CollapseIndex: false
                                  });

                            if (this.props.location.pathname !== pathname) {
                                googleAnalyticsTrackPageView(
                                    ReactGA,
                                    `${pathname}${this.props.location.search}`
                                );
                                this.props.history.push(`${pathname}${this.props.location.search}`);
                            }
                        } else {
                            googleAnalyticsTrackPageView(ReactGA, '/NoAccess');
                            this.props.history.push('/NoAccess');
                        }
                    }
                })
                .catch(e => {
                    this.props.history.push('/login');
                });
            const { userProfileAction, getModuleStatus } = this.props;
            userProfileAction();
            getModuleStatus();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.userProfile.id !== prevProps.userProfile.id) {
            this.props.getEntityStatus(this.props.userProfile.id);
        }
    }

    getSelectedMenuOnPageReload = (menuItems, pathname) => {
        let id,
            isSubMenu = false;
        let selectedIndex = _.findIndex(menuItems, (menu, index) => {
            let isMenuFound = false;
            if (menu.router && menu.router.toLowerCase().includes(pathname.toLowerCase())) {
                isMenuFound = true;
                id = menu.id;
            }
            // For Webtool inner routes
            const isWebtoolPath = WEBTOOL_SUBMODULE_PATH.includes(pathname);
            if (!isMenuFound && menu.submenus) {
                _.map(menu.submenus, subMenu => {
                    if (isWebtoolPath && WEBTOOL_SUBMODULE_PATH.includes(subMenu.router)) {
                        isMenuFound = true;
                        isSubMenu = true;
                        id = subMenu.id;
                    }
                    if (subMenu.router && subMenu.router.includes(pathname)) {
                        isMenuFound = true;
                        isSubMenu = true;
                        id = subMenu.id;
                    }
                });
            }
            return isMenuFound;
        });
        const isProPatientBrowserPath = PRO_SUBMODULE_PATH.includes(pathname.toLowerCase());
        if (isProPatientBrowserPath) {
            id = this.getProMenuId(menuItems);
            isSubMenu = false;
            selectedIndex = 0;
        }
        const isServiceDeskPath = SERVICE_DESK_SUBMODULE_PATH.includes(pathname.toLowerCase());
        if (isServiceDeskPath) {
            id = this.getServiceDeskMenuId(menuItems);
            isSubMenu = false;
            selectedIndex = 0;
        }

        if (selectedIndex === -1) {
            if (menuItems[0] && menuItems[0].submenus) {
                pathname = menuItems[0].submenus[0].router;
                id = menuItems[0].submenus[0].id;
                isSubMenu = true;
            } else if (
                this.props.history.location.state &&
                this.props.history.location.state.basePageLink &&
                this.props.history.location.state.basePageLink !== ''
            ) {
                const routeDetails = this.getMainRouter(
                    this.props.history.location.state.basePageLink,
                    this.state.menu
                );
                pathname = routeDetails.routeName;
                id = routeDetails.route;
            } else {
                pathname = menuItems[0].router;
                id = menuItems[0].id;
            }
        }
        return { pathname, isSubMenu, id };
    };

    getMainRouter = (routeId, menu) => {
        let routeDetails = {
            route: 0,
            routeName: ''
        };
        for (let menuIndex = 0; menuIndex < menu.length; menuIndex++) {
            if (menu[menuIndex].constructor === Object && menu[menuIndex].router === routeId) {
                routeDetails.routeName = menu[menuIndex].router;
                routeDetails.route = menu[menuIndex].id;
                break;
            } else if (
                menu[menuIndex].submenus !== undefined &&
                menu[menuIndex].submenus !== null
            ) {
                routeDetails = this.getMainRoute(routeId, menu[menuIndex].submenus);
            }
        }
        return routeDetails;
    };

    getProMenuId = menuItems => {
        let menuId;
        _.each(menuItems, menu => {
            _.each(PRO_SUBMODULE_PATH, pathName => {
                if (menu.router && pathName.includes(menu.router.toLowerCase())) {
                    menuId = menu.id;
                }
            });
        });
        return menuId;
    };

    getServiceDeskMenuId = menuItems => {
        let menuId;
        _.each(menuItems, menu => {
            if (menu.router && SERVICE_DESK_SUBMODULE_PATH.includes(menu.router.toLowerCase())) {
                menuId = menu.id;
            }
        });
        return menuId;
    };
    /* HANDLE LOGOUT BUTTON CLICK. REMOVED COOKIES AND REDIRECT TO LOGINPAGE
  ================================================================ */
    handleLogoutClick = () => {
        const jwt = getJwt('Logout');

        if (jwt) {
            fetch(apiHelperInstance.Resources.TokenDeactivate, {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    authorization: jwt,
                    Action: 'delete'
                }
            })
                .then(res => res.json())
                .then(res => {
                    if (res.data.deactivated) {
                        if (res.data.logouturl !== '') {
                            // console.log(
                            //     'TCL: DashboardLayout -> handleLogoutClick -> res.data.logouturl',
                            //     res.data.logouturl
                            // );
                            removeCookieWithoutDomain(res.data.logouturl);
                        } else {
                            // console.log(
                            //     'TCL: DashboardLayout -> handleLogoutClick -> res.data.logouturl is empty'
                            // );
                            removeJwt();
                            window.location = '/';
                            //this.props.history.push('/');
                        }
                    } else {
                        // console.log(
                        //     'TCL: DashboardLayout -> handleLogoutClick -> res.data.deactivated in else',
                        //     res.data.deactivated
                        // );
                        removeJwt();
                        window.location = '/';
                        // this.props.history.push('/');
                    }
                })
                .catch(e => {
                    // console.log(
                    //     'TCL: DashboardLayout -> handleLogoutClick -> exception occured -> e',
                    //     e
                    // );
                    removeJwt();
                    //this.props.history.push('/');
                    window.location = '/';
                });
        } else {
            window.location = '/';
        }
    };

    activeMenu = () => {
        this.setState({ activeIcon: 'selected-main__nav', startSlide: false });
    };

    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: false });
    };
    // When user click outside except hamburger menu will close automatically.
    handleClickAway = event => {
        if (document.getElementsByClassName('hamburger-menu')[0].contains(event.target)) {
            return;
        }
        const { isSubMenuClicked, open } = this.state;
        this.setState({
            open: false,
            isSubMenuClicked: false
        });
    };

    /* HANDLE SIDE MENU SELECTION. REDIRECT PAGE TO ACTIVE MENU
  ================================================================ */
    handleActiveInactiveMenu = (index, menuItemObject, submenuFlag) => {
        const jwt = getJwt();
        if (!jwt) {
            if (!ENABLE_AUTHENTICATION_LINKS)
                this.props.history.push({ pathname: '/SSORouter', search: '?sessionexpired=true' });
            else if (
                ENABLE_AUTHENTICATION_LINKS &&
                localStorage.getItem('ssologin') &&
                localStorage.getItem('ssologin').toLowerCase() === 'true'
            )
                this.props.history.push({ pathname: '/SSORouter', search: '?sessionexpired=true' });
            else this.props.history.push('/');
            return false;
        }
        if (menuItemObject.router) {
            if (
                menuItemObject.router.indexOf('http://') !== -1 ||
                menuItemObject.router.indexOf('https://') !== -1
            ) {
                googleAnalyticsTrackPageView(ReactGA, menuItemObject.router);
                window.open(menuItemObject.router, '_blank');
                return;
            } else {
                let currentSelectedMenuIndex = this.state.selectedMenuIndex;
                if (this.props.Permissions[menuItemObject.resource] === undefined)
                    this.props.getUserPermissions(menuItemObject.resource);
                submenuFlag
                    ? this.setState({
                          selectedMenuIndex: index,
                          subMenuIndex: index,
                          CollapseIndex: true
                      })
                    : this.setState({ selectedMenuIndex: index, CollapseIndex: false });
                if (menuItemObject.router.indexOf('legacydashboard?mn') !== -1) {
                    this.setState(
                        {
                            jwttoken: jwt,
                            selectedMenuIndex: currentSelectedMenuIndex,
                            modulename: menuItemObject.router.substr(
                                menuItemObject.router.indexOf('=') + 1,
                                menuItemObject.router.length
                            )
                        },
                        () => {
                            document.getElementById('main-assertion').submit();
                        }
                    );
                } else {
                    googleAnalyticsTrackPageView(ReactGA, menuItemObject.router);
                    this.props.history.push(menuItemObject.router);
                }
            }
        }
    };

    handleSubMenuNavigation = (e, index, subMenuHeader) => {
        this.state.currentIndex === index
            ? this.setState({ CollapseIndex: !this.state.CollapseIndex })
            : this.setState({ CollapseIndex: true });
        this.setState({ currentIndex: index, isSubMenuClicked: true });

        this.activeMenu('', index);
    };

    componentWillReceiveProps = nextProps => {
        // const userID = this.props.userProfile.id;
        const isServiceDeskPath = nextProps.location.pathname
            .toLowerCase()
            .includes(SERVICE_DESK_PATH.SERVICE_DESK.toLowerCase());

        if (
            isServiceDeskPath ||
            (!this.props.location.pathname.toLowerCase().includes(PEGASUS_ROUTES.LAYOUT) &&
                this.props.location.pathname !== nextProps.location.pathname)
        ) {
            this.updateMenu(nextProps.location.pathname);
        }
        const { moduleStatus, EntityStatusLoading, currentRoute } = nextProps;

        // let id = null;
        // let status = false;
        if (moduleStatus.length === 0 || EntityStatusLoading) {
            // status = false;
        } else if (this.state.SliderContent.length === 0) {
            this.tourGuideSetup(currentRoute, nextProps);
        }
    };

    shouldComponentUpdate(nextProps, nextState) {
        const { selectedMenuIndex, menu } = nextState;
        if (this.state.selectedMenuIndex !== nextState.selectedMenuIndex) {
            const currentRoute = this.getMainRoute(selectedMenuIndex, menu);
            this.tourGuideSetup(currentRoute, nextProps);
        }
        return true;
    }

    getMainRoute = (routeId, menu) => {
        let route = 0;
        for (let menuIndex = 0; menuIndex < menu.length; menuIndex++) {
            if (menu[menuIndex].constructor === Object && menu[menuIndex].id === routeId) {
                route = menu[menuIndex].router;
                break;
            } else if (
                menu[menuIndex].submenus !== undefined &&
                menu[menuIndex].submenus !== null
            ) {
                route = this.getMainRoute(routeId, menu[menuIndex].submenus);
            }
        }
        return route;
    };

    tourGuideSetup = (currentRoute, nextPropsObj) => {
        const userID = this.props.userProfile.id;
        let id = null;
        let status = false;
        const { entityStatus, moduleStatus, EntityStatusLoading } = nextPropsObj;
        if (moduleStatus.length === 0 || EntityStatusLoading) {
            status = false;
        } else {
            this.setState({ SliderContent: [] });
            moduleStatus.forEach((value, index) => {
                if (value.attributes.indexOf(currentRoute.replace('/', '')) !== -1) {
                    this.setState({ guideIsActive: value.isactive });
                    console.log('current Route', currentRoute);
                    console.log(process.env.REACT_APP_API_GATEWAY_URL);
                    id = value.id;
                    if (currentRoute.charAt(0) === '/') {
                        var newCurrentRoute = currentRoute.substr(1);
                        const sliderContentKeys = Object.keys(SliderContentJson);
                        for (
                            let sliderContentIndex = 0;
                            sliderContentIndex < sliderContentKeys.length;
                            sliderContentIndex++
                        ) {
                            if (newCurrentRoute === sliderContentKeys[sliderContentIndex]) {
                                status = true;
                                this.setState({
                                    SliderContent:
                                        SliderContentJson[sliderContentKeys[sliderContentIndex]]
                                });
                                if (entityStatus.length !== 0) {
                                    entityStatus.forEach(entity => {
                                        if (
                                            value.id === entity.moduleattributeid &&
                                            entity.entityid === userID
                                        )
                                            status = entity.status === 1;
                                    });
                                }
                                break;
                            }
                        }
                    }
                }
            });
        }
        this.setState({
            startSlide: status,
            routerId: id,
            isShowAgainChecked: !status,
            tempvar: !status
        });
    };

    updateMenu = pathname => {
        const menuItems = this.state.menu;
        if (!menuItems || !menuItems.length) {
            return;
        }
        const selectedRoute = this.getSelectedMenuOnPageReload(menuItems, pathname);

        const isSubMenu = selectedRoute.isSubMenu;
        pathname = selectedRoute.pathname;

        isSubMenu
            ? this.setState({
                  menu: menuItems,
                  selectedMenuIndex: selectedRoute.id,
                  subMenuIndex: selectedRoute.id,
                  CollapseIndex: true,
                  currentIndex: selectedRoute.id
              })
            : this.setState({
                  menu: menuItems,
                  selectedMenuIndex: selectedRoute.id,
                  currentIndex: selectedRoute.id,
                  CollapseIndex: false
              });
    };

    onShowAgain = event => {
        this.setState({ isShowAgainChecked: event.target.checked });
    };

    handleOpen = () => {
        const jwt = getJwt();
        if (jwt) {
            this.setState({ startSlide: true });
        } else {
            if (ENABLE_AUTHENTICATION_LINKS) this.props.history.push('/');
            else
                this.props.history.push({ pathname: '/SSORouter', search: '?sessionexpired=true' });
        }
        this.setState({ startSlide: true });
    };

    handleClose = () => {
        const { isShowAgainChecked, routerId, tempvar } = this.state;
        const { userProfile } = this.props;
        const requestObj = {
            moduleattributeid: routerId,
            entityid: userProfile.id,
            entityname: 'USER',
            isactive: true,
            status: !isShowAgainChecked ? 1 : 2
        };
        this.setState({ startSlide: false });
        if (tempvar !== isShowAgainChecked) {
            //after api integration
            this.setState({ tempvar: true, isShowAgainChecked: true });
            this.props.updateEntityStatus(requestObj);
        }
    };

    redirectToHelpPage = () => {
        window.open(HELP_PAGE_URL);
    };

    render() {
        const { classes, theme, updateEntityStatus } = this.props;
        const { isShowAgainChecked, SliderContent, startSlide, tempvar } = this.state;
        const Ssologin =
            localStorage.getItem('ssologin') &&
            localStorage.getItem('ssologin').toLowerCase() === 'true'
                ? true
                : false;
        const userProfileDetais = {
            firstname:
                Object.keys(this.props.UserProfileDetails).length > 0
                    ? this.props.UserProfileDetails.firstname
                    : '',
            lastname:
                Object.keys(this.props.UserProfileDetails).length > 0
                    ? this.props.UserProfileDetails.lastname
                    : '',
            userName:
                Object.keys(this.props.UserProfileDetails).length > 0
                    ? this.props.UserProfileDetails.firstname +
                      ' ' +
                      this.props.UserProfileDetails.lastname
                    : '',
            email:
                Object.keys(this.props.UserProfileDetails).length > 0
                    ? this.props.UserProfileDetails.emailaddress
                    : '',
            userRole:
                Object.keys(this.props.UserProfileDetails).length > 0
                    ? this.props.UserProfileDetails.roles
                    : '',
            iconUrl: userProfileImage.iconUrl,
            onClick: function onClick(e) {},
            visibility: {
                editProfile: false
            }
        };

        return (
            <div className={classes.root + ' page-header__wrapper'}>
                <CssBaseline />

                <AppBar
                    position='fixed'
                    className={
                        classNames(classes.appBar, {
                            [classes.appBarShift]: this.state.open
                        }) + ' page-header'
                    }
                >
                    <Toolbar disableGutters={!this.state.open} className='page-header__toolbar'>
                        {/* <ClickAwayListener onClickAway={this.handleClickAway}> */}
                        <Grid
                            item
                            className='fi-container fi-container--fluid fi-container--full-height'
                        >
                            <Grid item className='fi-stack fi-stack--ver fi-stack--desktop'>
                                <div className='fi-stack__item fi-brand '>
                                    <div className='fi-stack fi-stack--ver fi-stack--general'>
                                        <Grid className='hamburger-menu fi-stack__item fi-stack__item--middle fi-hamburger-container'>
                                            <IconButton
                                                color='inherit'
                                                aria-label='Open drawer'
                                                onClick={this.handleDrawerOpen}
                                                className={classNames(classes.menuButton, {
                                                    [classes.hide]: this.state.open
                                                })}
                                            >
                                                <MenuIcon />
                                            </IconButton>

                                            <div className={classes.toolbar}>
                                                <IconButton
                                                    onClick={this.handleDrawerClose}
                                                    className={classNames(classes.hamburgerClose, {
                                                        [classes.hide]: !this.state.open
                                                    })}
                                                >
                                                    {theme.direction === 'rtl' ? (
                                                        <ChevronRightIcon />
                                                    ) : (
                                                        <ChevronLeftIcon />
                                                    )}
                                                </IconButton>
                                            </div>
                                        </Grid>
                                        <Grid className='fi-stack__item fi-stack__item--middle fi-brand__logo'>
                                            <img src={MainLogo} alt='Logo' />
                                        </Grid>

                                        <Grid
                                            item
                                            className='fi-stack__item fi-stack__item--middle fi-brand__tools'
                                        >
                                            <Grid className='help-icon-container'>
                                                {DISPLAY_HELP_ICON && (
                                                    <Tooltip
                                                        title={i18next.t(
                                                            'dashboardLayout.helpIconTitle'
                                                        )}
                                                    >
                                                        <Button
                                                            onClick={() =>
                                                                this.redirectToHelpPage()
                                                            }
                                                            className='help-icon'
                                                        >
                                                            <FontAwesomeIcon
                                                                key='help-icon'
                                                                icon={['fal', `question-circle`]}
                                                            />
                                                        </Button>
                                                    </Tooltip>
                                                )}
                                                {!Ssologin && ENABLE_AUTHENTICATION_LINKS && (
                                                    <Userprofile
                                                        UserCard={userProfileDetais}
                                                        onLogoutClick={() =>
                                                            this.handleLogoutClick()
                                                        }
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        {/* </ClickAwayListener> */}
                    </Toolbar>
                </AppBar>

                <ClickAwayListener onClickAway={this.handleClickAway}>
                    <div>
                        <Hidden mdUp implementation='css'>
                            <Drawer
                                variant='permanent'
                                ModalProps={{ onBackdropClick: this.handleDrawerClose }}
                                className={
                                    'fi-aside-left__expand ' +
                                    classNames(classes.drawer, {
                                        [classes.drawerOpen]: this.state.open,
                                        [classes.drawerClose]: !this.state.open
                                    })
                                }
                                classes={{
                                    paper: classNames({
                                        [classes.drawerOpen]: this.state.open,
                                        [classes.drawerClose]: !this.state.open
                                    })
                                }}
                                open={this.state.open}
                            >
                                {/* <div className={classes.toolbar}>
                            <IconButton onClick={this.handleDrawerClose}>
                                {theme.direction === 'rtl' ? (
                                    <ChevronRightIcon />
                                ) : (
                                    <ChevronLeftIcon />
                                )}
                            </IconButton>
                        </div> */}

                                {/* <List>
            {menu.map(({ MenuName, Icon, RoutePath }, index) => (
              <Link to={RoutePath}>
                <ListItem button key={MenuName}>
                  <ListItemIcon>
                    <Icon />
                  </ListItemIcon>
                  <ListItemText primary={MenuName} />
                </ListItem>
              </Link>
            ))}
          </List> */}
                                <Grid container className={classes.list}>
                                    <Grid item xs={12}>
                                        <MasterNavigationMenu
                                            handleOpen={this.handleOpen}
                                            handleDrawerClose={this.handleDrawerClose}
                                            handleSubMenuNavigation={this.handleSubMenuNavigation}
                                            CurrentSelectedMenu={this.state.CurrentSelectedMenu}
                                            menu={this.state.menu}
                                            {...this.state}
                                            sideNavigation={NavigationMenu.sideNavigation}
                                            activeMenu={this.activeMenu}
                                            selectedMenuIndex={this.state.selectedMenuIndex}
                                            onListItemClick={(
                                                e,
                                                id,
                                                menuItemObject,
                                                RoutePath,
                                                submenuFlag
                                            ) => {
                                                this.handleActiveInactiveMenu(
                                                    menuItemObject.id,
                                                    menuItemObject,
                                                    submenuFlag
                                                );
                                                this.activeMenu(e, id, RoutePath);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Drawer>
                        </Hidden>
                        <Hidden smDown implementation='css' className='fi-aside-left--fixed'>
                            {/* <ClickAwayListener onClickAway={this.handleClickAway}> */}
                            <Drawer
                                variant='permanent'
                                className={
                                    'fi-aside-left ' +
                                    classNames(classes.drawer, {
                                        [classes.drawerOpen]: this.state.open,
                                        [classes.drawerClose]: !this.state.open
                                    })
                                }
                                classes={{
                                    paper: classNames({
                                        [classes.drawerOpen]: this.state.open,
                                        [classes.drawerClose]: !this.state.open
                                    })
                                }}
                                open={this.state.open}
                            >
                                {/* <div className={classes.toolbar}>
                            <IconButton onClick={this.handleDrawerClose}>
                                {theme.direction === 'rtl' ? (
                                    <ChevronRightIcon />
                                ) : (
                                    <ChevronLeftIcon />
                                )}
                            </IconButton>
                        </div> */}
                                {/* <List>
              {menu.map(({ MenuName, Icon, RoutePath }, index) => (
                <ListItem
                  button
                  selected={this.state.selectedMenuIndex === index}
                  key={MenuName}
                  onClick={(event) => { this.onListItemClick(event, index) }}
                >
                  <ListItemIcon >
                    <Icon />
                  </ListItemIcon>
                  <ListItemText primary={MenuName} />
                </ListItem>
              ))}
            </List> */}
                                <Grid container className={classes.list}>
                                    <Grid item xs={12}>
                                        <MasterNavigationMenu
                                            handleOpen={this.handleOpen}
                                            handleDrawerClose={this.handleDrawerClose}
                                            handleSubMenuNavigation={this.handleSubMenuNavigation}
                                            CurrentSelectedMenu={this.state.CurrentSelectedMenu}
                                            menu={this.state.menu}
                                            {...this.state}
                                            sideNavigation={NavigationMenu.sideNavigation}
                                            activeMenu={this.activeMenu}
                                            selectedMenuIndex={this.state.selectedMenuIndex}
                                            onListItemClick={(
                                                e,
                                                id,
                                                menuItemObject,
                                                RoutePath,
                                                submenuFlag
                                            ) => {
                                                this.handleActiveInactiveMenu(
                                                    menuItemObject.id,
                                                    menuItemObject,
                                                    submenuFlag
                                                );
                                                this.activeMenu(e, id, RoutePath);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Drawer>
                            {/* </ClickAwayListener>                 */}
                        </Hidden>
                    </div>
                </ClickAwayListener>
                <main
                    className={
                        classes.content +
                        ' fi-container fi-container--fluid fi-container--full-height'
                    }
                >
                    <div
                        className={
                            classes.toolbar +
                            ' fi-stack fi-stack--ver fi-stack--desktop fi-stack--desktop--toolbar'
                        }
                    />

                    {this.state.SliderContent.length > 0 && this.state.guideIsActive === true && (
                        <SliderApp
                            isShowAgainChecked={isShowAgainChecked}
                            handleClose={this.handleClose}
                            handleOpen={this.handleOpen}
                            onShowAgain={this.onShowAgain}
                            sliderData={SliderContent}
                            className={classes.paper}
                            startSlide={startSlide}
                            updateRoutesTourStatus={updateEntityStatus}
                            isCheckbox={!tempvar}
                        />
                    )}

                    {this.props.children}
                </main>
                <form
                    target='_blank'
                    id='main-assertion'
                    action={CREATE_ASSERTION_URL}
                    method='post'
                >
                    <input type='text' hidden name='jwttoken' value={this.state.jwttoken} />
                    <input type='text' hidden name='modulename' value={this.state.modulename} />
                </form>
            </div>
        );
    }
}

const removeCookieWithoutDomain = URL => {
    const cookiesinterval = new Cookies();
    cookiesinterval.set('interval', '', {
        secure: parseInt(SECURE_COOKIES),
        maxAge: 1,
        sameSite: SAME_SITE,
        httpOnly: parseInt(HTTP_ONLY)
    });
    const cookies = new Cookies();
    cookies.set('jwt-token', '', {
        secure: parseInt(SECURE_COOKIES),
        maxAge: 1,
        sameSite: SAME_SITE,
        httpOnly: parseInt(HTTP_ONLY)
    });
    const registryUnit = new Cookies();
    registryUnit.set('unit', '', {
        secure: parseInt(SECURE_COOKIES),
        maxAge: 1,
        sameSite: SAME_SITE,
        httpOnly: parseInt(HTTP_ONLY)
    });
    window.location.href = URL;
};

/* DEFAULT PROPS
  ================================================================ */
DashboardLayout.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};
const mapStateToProps = state => {
    return {
        Permissions: state.Permissions,
        UserProfileDetails: state.userProfile.UserDataList,
        IsFailure: state.userProfile.isFailure,
        IsLoading: state.userProfile.isLoading,
        entityStatus: state.EntityModuleStatus.EntityStatus,
        moduleStatus: state.EntityModuleStatus.ModuleStatus,
        currentRoute: state.router.location.pathname,
        userProfile: state.userProfile.UserDataList,
        EntityStatusLoading: state.EntityModuleStatus.isLoading
    };
};

const mapDispatchToProps = dispatch => ({
    updateUserProfileTypeAction,
    userProfileAction: bindActionCreators(userProfileAction, dispatch),
    getUserPermissions: bindActionCreators(getUserPermissions, dispatch),
    getEntityStatus: bindActionCreators(getEntityStatus, dispatch),
    getModuleStatus: bindActionCreators(getModuleStatus, dispatch),
    updateEntityStatus: async params => dispatch(updateEntityStatus(params))
});

export default withRouter(
    withStyles(styles, { withTheme: true })(
        connect(mapStateToProps, mapDispatchToProps)(DashboardLayout)
    )
);
