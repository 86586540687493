import { API_GATEWAY_URL, REGISTRY_UNIT_NAME } from '@/helper/constants.js';
import {
    DOMAIN_NAME,
    HTTP_ONLY,
    SAME_SITE,
    SECURE_COOKIES,
    ENABLE_AUTHENTICATION_LINKS
} from '@/helper/constants';

import Cookies from 'universal-cookie';

const APIHelper = function() {};
Object.defineProperty(APIHelper.prototype, 'Url', {
    value: API_GATEWAY_URL,
    writable: false
});

//const businessUrl = 'http://localhost:3005/'

Object.defineProperty(APIHelper.prototype, 'Resources', {
    get() {
        return {
            PatientDrilDownExport: this.Url + 'patientdrilldownexport',
            Common: this.Url + 'common',
            Practice: this.Url + 'practice',
            Provider: this.Url + 'provider',
            Location: this.Url + 'location',
            Group: this.Url + 'group',
            Measure: this.Url + 'measure',
            Performance: this.Url + 'performance',
            Patient: this.Url + 'patient',
            Authenticate: this.Url + 'checkidpwddb',
            FogotPassword: this.Url + 'getresetpasswordtoken',
            AuthenticateToken: this.Url + 'validateresetpasswordtoken',
            ResetPassword: this.Url + 'updatepassword',
            RenewToken: this.Url + 'renewtoken',
            TokenDeactivate: this.Url + 'token/deactivate',
            GetMenuDetails: this.Url + 'getresourcedetails',
            ValidateToken: this.Url + 'validatetoken',
            GetUserProfile: this.Url + 'getuserprofile',
            ResetPasswordLink: this.Url + 'resetpassword',
            ValidateRegistryDashboardToken: this.Url + 'validateregistrydashboardtoken',
            Visit: this.Url + 'GetVisit',
            SearchVisit: this.Url + 'SearchVisit',
            Webtool: this.Url + 'webtool',
            qualityPreference: this.Url + 'webtool',
            patientVisit: this.Url + 'patient',
            Export: this.Url + 'report',
            ReportDownload: this.Url + 'export',
            RegistryUnit: REGISTRY_UNIT_NAME,
            OTPVerification: this.Url + 'verifyotp',
            OTPResend: this.Url + 'resendotp',
            MobileRegistration: this.Url + 'registerphone',
            FileUpload: this.Url + 'fileupload',
            SAMLLogin: this.Url + 'samlloginurl',
            GetUserType: this.Url + 'checkuserauthtype',
            GenerateOTP: this.Url + 'generateotp',
            GenerateRegisterPhoneToken: this.Url + 'generateregisterphonetoken',
            PIA: this.Url + 'pia',
            // JIRA INTEGRATION
            SD: this.Url + 'sd',
            JiraAPI: {
                searchTicketByCustomer: this.Url + 'servicedesk/getticket',
                createIssue: this.Url + 'servicedesk/createissue',
                getIssueComment: this.Url + 'servicedesk/getissuecomments',
                addIssueComments: this.Url + 'servicedesk/addissuecomments',
                // FEB SPRINT
                RequestTypesAssociatedWithSpecificServiceDesk:
                    this.Url + 'servicedesk/requesttypesassociatedwithspecificservicedesk',
                createTicket: this.Url + 'servicedesk/createticket',
                getMeasureStatus: this.Url + 'servicedesk/getmeasurestatus',
                getPracticeStatus: this.Url + 'servicedesk/getpracticestatus',
                getMeasureStatusByMeasureset: this.Url + 'servicedesk/getmeasurestatusbymeasureset'
            },
            IAMApi: {
                createPracticeUser: this.Url + 'user/create',
                getAllPracticeUserByPractice: this.Url + 'user/getusermgtdetailsbypractice',
                getPracticeUserByUser: this.Url + 'user/getusermgtdetailsbyuser',
                updatePracticeUser: this.Url + 'user/update'
            }
        };
    },
    readable: true
});

Object.defineProperty(APIHelper.prototype, 'Actions', {
    get() {
        return {
            View: 'View',
            Create: 'Create',
            Update: 'Update',
            Delete: 'Delete'
        };
    },
    readable: true
});

Object.defineProperty(APIHelper.prototype, 'PegasusAPI', {
    get() {
        return async (url, requestOptions, callback) => {
            var response = await fetch(url, requestOptions).then(async res => {
                if (res.ok) return res.json();
                if (res.status === 401) {
                    const cookies = new Cookies();
                    cookies.set('interval', '', {
                        path: '/',
                        secure: parseInt(SECURE_COOKIES),
                        maxAge: 1,
                        sameSite: SAME_SITE,
                        domain: DOMAIN_NAME,
                        httpOnly: parseInt(HTTP_ONLY)
                    });
                    const cookiesJwt = new Cookies();
                    cookiesJwt.set('jwt-token', '', {
                        path: '/',
                        secure: parseInt(SECURE_COOKIES),
                        maxAge: 1,
                        sameSite: SAME_SITE,
                        domain: DOMAIN_NAME,
                        httpOnly: parseInt(HTTP_ONLY)
                    });
                    // window.location.href = ENABLE_AUTHENTICATION_LINKS
                    //     ? '/'
                    //     : '/SSORouter?sessionexpired=true';
                    if (
                        !ENABLE_AUTHENTICATION_LINKS ||
                        (ENABLE_AUTHENTICATION_LINKS &&
                            localStorage.getItem('ssologin') &&
                            localStorage.getItem('ssologin').toLowerCase() === 'true')
                    )
                        window.location.href = '/SSORouter?sessionexpired=true';
                    else window.location.href = '/';
                } else if (res.status === 403) {
                    return res;
                } else {
                    const error = new Error(res.statusText);
                    error.response = res;
                    return error;
                }
            });
            return response;
        };
    },
    readable: true
});

Object.defineProperty(APIHelper.prototype, 'getRequestOption', {
    get() {
        return (query, variables, action, token, signal) => ({
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Action: action,
                Authorization: token
            },
            body: JSON.stringify({ query, variables }),
            signal
        });
    },
    readable: true
});

Object.defineProperty(APIHelper.prototype, 'JiraGetRequestOption', {
    get() {
        return (body, action, token) => ({
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Action: action,
                Authorization: token
            },
            body: JSON.stringify(body)
        });
    },
    readable: true
});

Object.defineProperty(APIHelper.prototype, 'IAMGetRequestOption', {
    get() {
        return (body, action, token) => ({
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Action: action,
                Authorization: token
            },
            body: JSON.stringify(body)
        });
    },
    readable: true
});

export default APIHelper;
