import { getAllFiles, deleteFile, downloadFileApi } from '../services/fileUploadApi';
import { getCalendar } from '../services/visitApi';
import FileDownload from 'js-file-download';
import { handleUnauthorizedRequest } from '@/containers/Webtool/webtoolUtils';
import { getPractices } from '../services/QualityWebtoolMeasureService';

export const FILES_BY_ENTITY_REQUEST = 'FILES_BY_ENTITY_REQUEST';
export const FILES_BY_ENTITY_RECEIVE = 'FILES_BY_ENTITY_RECEIVE';
export const FILES_BY_ENTITY_FAILURE = 'FILES_BY_ENTITY_FAILURE';
export const DELETE_FILE_MESSAGE = 'DELETE_FILE_MESSAGE';
export const RECEIVE_DOWNLOAD_SUCCESS = 'RECEIVE_DOWNLOAD_SUCCESS';

/**
 * THIS SECTION USED GET YEARS
 */
export const VISIT_BULK_UPLOAD_YEAR_FAILURE = 'VISIT_BULK_UPLOAD_YEAR_FAILURE';
export const VISIT_BULK_UPLOAD_YEAR_REQUEST = 'VISIT_BULK_UPLOAD_YEAR_REQUEST';
export const VISIT_BULK_UPLOAD_YEAR_RECEIVE = 'VISIT_BULK_UPLOAD_YEAR_RECEIVE';

const failureVisitYear = () => ({
    type: VISIT_BULK_UPLOAD_YEAR_FAILURE
});

const requestVisitYear = () => ({
    type: VISIT_BULK_UPLOAD_YEAR_REQUEST
});

const receiveVisitYear = payload => ({
    type: VISIT_BULK_UPLOAD_YEAR_RECEIVE,
    payload
});

export const getYearsData = (signal) => {
    return async dispatch => {
        dispatch(requestVisitYear());
        try {
            let resp = await getCalendar(signal);
            handleUnauthorizedRequest(resp.status);
            if (resp.ok) {
                let parsedResponse = await resp.json();
                const calendarData = parsedResponse.data.getCalendars;
                let year = [];
                let _year = [];
                // eslint-disable-next-line no-unused-vars
                let duration = [];
                // eslint-disable-next-line no-unused-vars
                let _duration = [];
                calendarData.map((obj, index) => {
                    if (_year.indexOf(obj.year) === -1) {
                        _year.push(obj.year);
                        year.push({
                            id: obj.id,
                            text: obj.year,
                            value: obj.id
                        });
                    }
                });

                dispatch(receiveVisitYear(year));
            } else {
                throw new Error();
            }
        } catch (ex) {
            dispatch(failureVisitYear());
        }
    };
};

const requestFileInfo = () => ({
    type: FILES_BY_ENTITY_REQUEST
});

const receiveFileInfo = payload => ({
    type: FILES_BY_ENTITY_RECEIVE,
    payload
});

const failureFileInfo = () => ({
    type: FILES_BY_ENTITY_FAILURE
});

const receiveDeleteFileMessage = payload => ({
    type: DELETE_FILE_MESSAGE,
    payload
});
const receiveDDownlaodSuccess = payload => ({
    type: RECEIVE_DOWNLOAD_SUCCESS,
    payload
});

// export const getAllFilesData = params => dispatch => {
//     dispatch(requestFileInfo());
//     getAllFiles(params)
//         .then(json => {
//             dispatch(receiveFileInfo(json.data.fileList));
//         })
//         .catch(ex => {
//             dispatch(failureFileInfo());
//             throw new Error(ex);
//         });
// };

export const getAllFilesData = (params, signal) => {
    return async dispatch => {
        dispatch(requestFileInfo());
        try {
            let fileList = await getAllFiles(params, signal);
            handleUnauthorizedRequest(fileList.status);
            if (fileList.ok) {
                let parsedfileList = await fileList.json();
                dispatch(receiveFileInfo(parsedfileList.data.getFileList));
            } else {
                throw new Error();
            }
        } catch (ex) {
            dispatch(failureFileInfo());
        }
    };
};
export const deleteFileData = params => dispatch => {
    dispatch(requestFileInfo());
    deleteFile(params)
        .then(res => {
            dispatch(receiveDeleteFileMessage(res.data.deleteFile));
        })
        .catch(ex => {
            dispatch(failureFileInfo());
            throw new Error(ex);
        });
};

// export const downloadFile = params => dispatch => {
//     // dispatch(requestFileInfo());
//     downloadFileApi(params)
//         .then(res => {
//
//             //FileDownload(res.data, res.data.downloadFile[0]);

//             dispatch(receiveDDownlaodSuccess(res.data.downloadFile));
//         })
//         .catch(ex => {
//             dispatch(failureFileInfo());
//             throw new Error(ex);
//         });
// };

export const downloadFile = params => {
    return async dispatch => {
        try {
            let downloadResp = await downloadFileApi(params);
            handleUnauthorizedRequest(downloadResp.status);
            if (downloadResp.ok) {
                let parsedDownloadResp = await downloadResp.json();
                dispatch(receiveDDownlaodSuccess(parsedDownloadResp.data.downloadFile));
            } else {
                throw new Error();
            }
        } catch (ex) {
            dispatch(failureFileInfo());
        }
    };
};

/**
 * THIS SECTION USED GET PRACTICE
 */
export const VISIT_BULK_UPLOAD_PRACTICES_REQUEST = 'VISIT_BULK_UPLOAD_PRACTICES_REQUEST';
export const VISIT_BULK_UPLOAD_PRACTICES_RECEIVE = 'VISIT_BULK_UPLOAD_PRACTICES_RECEIVE';
export const VISIT_BULK_UPLOAD_PRACTICES_FAILURE = 'VISIT_BULK_UPLOAD_PRACTICES_FAILURE';

const requestPractices = () => ({
    type: VISIT_BULK_UPLOAD_PRACTICES_REQUEST
});

const receivePractices = payload => {
    return {
        type: VISIT_BULK_UPLOAD_PRACTICES_RECEIVE,
        payload
    };
};

const failurePractices = () => ({
    type: VISIT_BULK_UPLOAD_PRACTICES_FAILURE
});

export const getPracticeData = (param, signal) => {
    return async dispatch => {
        dispatch(requestPractices());
        try {
            let resp = await getPractices(param, signal);
            handleUnauthorizedRequest(resp.status);
            if (resp.ok) {
                let practiceData = await resp.json();

                dispatch(receivePractices(practiceData.data.getPractices));
            } else {
                throw new Error();
            }
        } catch (ex) {
            dispatch(failurePractices());
        }
    };
};