import {
    DELETED_ELEMENT,
    DELETING_ELEMENT,
    MEASURES_FAILURE,
    MEASURES_RECEIVE,
    MEASURES_REQUEST,
    PI_MEASURE_META_DATA,
    SAVED_ANSWER,
    SAVING_ANSWER,
    MARKING_FAVORITE,
    MARKED_FAVORITE,
    FILTER_TAGS,
    FILTER_TAG_DETAILS
} from '@/redux/actions/piMeasureAction';

const initialState = {
    isLoading: false,
    isFailure: false,
    piMeasures: null,
    isSaving: null,
    isSaved: null,
    errorMessage: null,
    piMeasureMetaData: null,
    isDeleting: false,
    deleteElementResponse: null,
    isMarking: false,
    markFavoriteResponse: null
};

const piMeasureReducer = (state = initialState, action) => {
    switch (action.type) {
        case MEASURES_REQUEST:
            return {
                ...state,
                isLoading: true,
                isFailure: false,
                isSaving: null,
                isSaved: null
            };
        case MEASURES_RECEIVE:
            return {
                ...state,
                isLoading: false,
                isFailure: false,
                isSaving: null,
                isSaved: null,
                piMeasures: action.payload
            };
        case MEASURES_FAILURE:
            return {
                ...state,
                isLoading: false,
                isFailure: true,
                errorMessage: action.errorMessage,
                piMeasures: null,
                deleteElementResponse: null,
                isCompleteDeleting: false,
                completeDeleteElementResponse: null,
                isMarking: false,
                markFavoriteResponse: null
            };
        case SAVING_ANSWER:
            return {
                ...state,
                isSaving: true,
                isSaved: false
            };
        case SAVED_ANSWER:
            return {
                ...state,
                isLoading: false,
                isFailure: false,
                errorMessage: null,
                isSaving: false,
                isSaved: true
            };
        case PI_MEASURE_META_DATA:
            return {
                ...state,
                piMeasureMetaData: action.payload
            };
        case DELETING_ELEMENT:
            return {
                ...state,
                isDeleting: true
            };
        case DELETED_ELEMENT:
            return {
                ...state,
                isDeleting: false,
                deleteElementResponse: action.payload
            };
        case MARKING_FAVORITE:
            return {
                ...state,
                isMarking: true
            };
        case MARKED_FAVORITE:
            return {
                ...state,
                isMarking: false,
                markFavoriteResponse: action.payload
            };
        case FILTER_TAGS:
            return {
                ...state,
                tags: action.payload
            };
        case FILTER_TAG_DETAILS:
            return {
                ...state,
                tagDetails: action.payload
            };
        default:
            return state;
    }
};

export default piMeasureReducer;
