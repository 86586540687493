import React, { Component } from 'react';
import './Unsubscribe.less';
import axios from 'axios';
import { Button, Grid } from '@material-ui/core';
import { API_GATEWAY_URL, REGISTRY_UNIT_NAME } from '@/helper/constants.js';
import { getJwt } from '../utils/jwtUtils';

const MainLogo = require(`@/${process.env.REGISTRY_UNSUBSCRIBE_PAGE_LOGO_FILEPATH}`);

class UnsubscribePRO extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDisabled: true,
            checked: true
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillMount = () => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let token = params.get('token');
        localStorage.setItem('token', token);

        this.saveLocalStorage();
    };
    saveLocalStorage() {
        localStorage.setItem('Unit', REGISTRY_UNIT_NAME);
    }

    handleChange() {
        console.log('handleChange *8');
        this.setState({ checked: !this.state.checked });
        if (this.state.checked) {
            this.setState({ isDisabled: false });
        } else this.setState({ isDisabled: true });
    }

    handleClick() {
        let registry = localStorage.getItem('Unit');
        document.getElementById(
            'demo'
        ).innerHTML = `Thank you for choosing to receive Questionnaires from the ${registry} Registry. Your responses will help your clinician in providing better care.`;
    }

    handleSubmit(e) {
        e.preventDefault();
        const headers = {
            'Content-Type': 'application/json',
            Authorization: getJwt()
        };
        axios({
            method: 'get',
            url: API_GATEWAY_URL + 'manageConsent?token=' + localStorage.getItem('token'),
            headers
        })
            .then(() => {
                console.log('success!');
            })
            .catch(error => {
                console.log('failed', error);
                return error;
            });
        if (this.state.isDisabled === false) {
            let registry = localStorage.getItem('Unit');
            document.getElementById(
                'demo'
            ).innerHTML = `Thank you. You will no longer receive Questionnaires from the ${registry} Registry. If you want to receive Questionnaires, please contact your clinician/care provider.`;
        } else {
            alert('form has not submitted');
        }
    }

    render() {
        let registry = localStorage.getItem('Unit');
        return (
            <Grid container className='header-container'>
                <Grid item md={12} sm={6} container className='header-icon_wrapper'>
                    <Grid
                        item
                        className='header-icon_container'
                        justify='center'
                        alignItems='center'
                    >
                        <img src={MainLogo} alt='logo' className='logo' />
                        <hr />
                    </Grid>
                    {/* <Grid item className='title' justify='center' alignItems='center'>
                            {registry}
                        </Grid> */}
                </Grid>

                <div id='demo' className='content'>
                    <p>Dear user,</p>
                    <span>
                        As part of your participation in the patient reported outcomes portal, your
                        provider can send you questionnaires. The information collected will be used
                        to better understand your unique medical history, health needs and develop
                        the most effective treatment strategy.
                    </span>
                    <br />
                    <br />
                    <span>
                        If you do not want to receive any questionnaires going forward from the{' '}
                        {registry} registry, then kindly select the checkbox below and save your
                        preference.
                    </span>

                    <br />
                    <br />
                    <form onSubmit={this.handleSubmit} className='form-wrapper'>
                        <input
                            type='checkbox'
                            id='myCheck'
                            class='checkbox'
                            onChange={this.handleChange}
                        />
                        <span className='checkbox-text'>
                            Unsubscribe all future questionnaires from {registry} registry
                            <span className='asterisk-icon'>*</span>
                        </span>
                        <br />
                        <br />
                        <Button
                            type='submit'
                            className='save-button'
                            variant='contained'
                            color='primary'
                            disabled={this.state.isDisabled}
                        >
                            Save
                        </Button>
                        <tb />
                        <Button
                            className='cancle-button'
                            variant='outlined'
                            onClick={this.handleClick}
                        >
                            Cancel
                        </Button>
                    </form>
                </div>
            </Grid>
        );
    }
}

export default UnsubscribePRO;
