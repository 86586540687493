import { GET_CALENDAR, GET_MEASURE_SET } from '@/graphql/query';
import APIHelper from '@/helper/apihelper';
import { getJwt } from '@/utils/jwtUtils';
import { MEASURE_SET_LISTORDER_COLUMN } from '@/helper/constants';

const api = new APIHelper();

export const getMeasureSet = () => {
    const requestOption = {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Action: api.Actions.View,
            Resource: 'measureset',
            Authorization: getJwt()
        }),
        body: JSON.stringify({
            query: GET_MEASURE_SET,
            variables: { input: { inactive: false, orderBy: MEASURE_SET_LISTORDER_COLUMN } }
        })
    };
    return api.PegasusAPI(api.Resources.Measure, requestOption, response => response);
};

export const getCalendar = () => {
    const requestOption = api.getRequestOption(
        GET_CALENDAR,
        {
            input: {
                orderByDesc: 'id'
            }
        },
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.Measure, requestOption, response => response);
};
