import './assets/less/main.less';
import 'cross-fetch/polyfill';
import 'abortcontroller-polyfill';
import i18n from './i18n';

import * as serviceWorker from './serviceWorker';

import React from 'react';
import Root from './Root';
import createStore from '@/redux/store.config';
import faIcons from '@/fontAwesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { render } from 'react-dom';
import { REGISTRY_DISPLAY_NAME } from '@/helper/constants';

library.add(faIcons);

// Create a store and get back itself and its history object
const { store, history } = createStore();
if (REGISTRY_DISPLAY_NAME) {
document.title = REGISTRY_DISPLAY_NAME;
} else {
document.title = 'Pegasus';
}

document.title = REGISTRY_DISPLAY_NAME;

i18n.on('initialized', function(options) {
    render(
        <Root store={store} history={history} />,
        document.getElementById('root')
    );
 });
// render(<Root store={store} history={history} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();