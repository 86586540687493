import React from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import { Grid } from '@material-ui/core';
/* THIS IS FOOTER COMPOENT
  ================================================================ */
const Footer = props => {
    const { copyright, showPrivacy, showTerms, privacyfilename, termsfilename } = props.footerProps;
    return (
        <Grid container className='footer_wrapper'>
            <Grid item align="center" className='footer_grid footer_fragment'>                
                    {showPrivacy && <Grid item className='footer_privacy-link'><Link
                        component="button"
                        block={true}
                        variant="body2"
                        className='privacy--btn'
                        onClick={(e) => {
                            e.preventDefault();
                            window.open(
                                `/static/PrivacyTerms/${privacyfilename}.pdf`,
                                '_blank'
                            );
                        }}
                    >
                        Privacy
            </Link></Grid>}
                    {showTerms && <Grid item className='footer_terms-link'><Link
                        component="button"
                        block={true}
                        variant="body2"
                        className='terms--btn'
                        onClick={(e) => {
                            e.preventDefault();
                            window.open(
                                `/static/PrivacyTerms/${termsfilename}.pdf`,
                                '_blank'
                            );
                        }}
                    >
                        Terms
            </Link></Grid> }
            </Grid>
            <Grid item className='copyright_grid'>
                {copyright}
            </Grid>
        </Grid>);
};

/* DEFAULT PROPS
  ================================================================ */
Footer.defaultProps = {
    copyright: `© ${new Date().getFullYear()}, ${process.env.COPY_RIGHT}`
};

Footer.propTypes = {
    copyright: PropTypes.string
};
export default Footer;
