import { getActionCreator, getActionType, getActionTypes, getReducer } from '@/utils/redux';
import {
    getLocationData,
    getEdsList,
    getEdMeasureStatusAndProfileData
} from '@/redux/services/locationPerformanceApi';
import { getPracticesRefreshDate } from '@/redux/services/practiceRefreshDateApi';
import { FETCH_ED, FETCH_PRACTICE_REFRESH_DATE } from '@/helper/constants';
import { getRefreshDataObject } from '@/helper/commonFunction';
import { practiceDisplayFormat } from '@/helper/commonFunction';

// Types
export const LocationListTypes = getActionTypes('LOCATIONLIST');
export const EDListTypes = getActionTypes('ED');
export const EDRefreshDateTypes = getActionTypes('EDREFRESHDATE');
export const EDServiceDeskDataTypes = getActionTypes('EDSERVICEDESKDATA');

export const SetEDMeasureStatusAndEHRFlagLoadingType = getActionType(
    'SETEDMEASURESTATUSANDEHRFLAGLOADING'
);
export const ResetEDMeasureStatusAndEHRFlagLoadingType = getActionType(
    'RESETEDMEASURESTATUSANDEHRFLAGLOADING'
);
export const UpdateSelectedLocationIdType = getActionType('UPDATESELECTEDLOCATIONID');
export const UpdateLocationPracticeIdType = getActionType('UPDATELOCATIONPRACTICEID');
export const UpdateEDListDataAndDetailsType = getActionType('UPDATEEDLISTDATAANDDETAILS');
export const SetEDRefreshDateType = getActionType('SETEDREFRESHDATE');
export const SetEDRefreshDateAction = getActionCreator(SetEDRefreshDateType);
export const UpdateSelectedLocationIdAction = getActionCreator(UpdateSelectedLocationIdType);
export const UpdateLocationPracticeIdAction = getActionCreator(UpdateLocationPracticeIdType);
export const UpdateEDListDataAndDetailsAction = getActionCreator(UpdateEDListDataAndDetailsType);
export const SetEDMeasureStatusAndEHRFlagLoadingAction = getActionCreator(
    SetEDMeasureStatusAndEHRFlagLoadingType
);
export const ResetEDMeasureStatusAndEHRFlagLoadingAction = getActionCreator(
    ResetEDMeasureStatusAndEHRFlagLoadingType
);

export const GetEDServiceDeskDataAction = (EdID, measureno, year) => ({
    types: EDServiceDeskDataTypes,
    callAPI: () => getEdMeasureStatusAndProfileData(EdID, measureno, year)
});

export const getEDRefreshDateAction = edId => ({
    types: EDRefreshDateTypes,
    callAPI: () => fetchEDPracticeRefreshDate(edId),
    handleAction: async ({ type, payload, store }) => {
        switch (type) {
            case EDRefreshDateTypes.SUCCESS: {
                store.dispatch(SetEDRefreshDateAction(payload));
                break;
            }
            default:
                break;
        }
    }
});

export const getLocationAction = queryParam => ({
    types: FETCH_ED ? EDListTypes : LocationListTypes,
    callAPI: () => (FETCH_ED ? getEDData(queryParam) : getLocationData(queryParam)),
    handleAction: async ({ type, payload, store }) => {
        if (FETCH_ED) {
            switch (type) {
                case EDListTypes.SUCCESS: {
                    if (payload.location.length > 0) {
                        const year = store
                            .getState()
                            .MeasureFilter.measureFilterData.measureSet.filter(
                                p =>
                                    p.id ===
                                    store.getState().MeasureFilter.measureFilterData.selectedValues
                                        .measureSet
                            )[0].year;
                        const measureNoList = store
                            .getState()
                            .RegistryMeasureList.registrymeasureList.map(m => m.id);
                        const edExternalID = payload.location.filter(
                            p => p.id === payload.selectedLocationId
                        );
                        const response = await getEdMeasureStatusAndProfileData(
                            edExternalID[0].externalid,
                            measureNoList,
                            year
                        );
                        const resultObj = {
                            edLevelMeasureStatusList:
                                response.data.getMeasureStatus !== null
                                    ? response.data.getMeasureStatus
                                    : [],
                            ...payload
                        };
                        store.dispatch(UpdateEDListDataAndDetailsAction(resultObj));
                    } else {
                        const paloadObj = {
                            edLevelMeasureStatusList: [],
                            ...payload
                        };
                        store.dispatch(UpdateEDListDataAndDetailsAction(paloadObj));
                    }
                    break;
                }
                default:
                    break;
            }
        } else {
            switch (type) {
                case LocationListTypes.SUCCESS: {
                    store.dispatch(UpdateLocationPracticeIdAction(queryParam.input.practiceid));
                    break;
                }
                default:
                    break;
            }
        }
    }
});

export const getEDData = async queryParam => {
    const fetchEDQqueryParam = {
        input: {
            inactive: false,
            orderBy: 'name',
            practicetype: 0,
            parentid: queryParam.input.practiceid
        }
    };
    const response = await getEdsList(fetchEDQqueryParam);
    const edList = response.data.getPractices != null ? response.data.getPractices : [];
    edList.map(obj => {
        obj.name = practiceDisplayFormat(obj);
    });
    const edId = response.data.getPractices.length > 0 && response.data.getPractices[0].id;
    const edrefreshDateData =
        edId && FETCH_PRACTICE_REFRESH_DATE ? await getPracticesRefreshDate(edId) : null;
    return {
        data: {
            location: edList,
            locationPracticeId: queryParam.input.practiceid,
            selectedLocationId: edId,
            islocationLoading: false,
            refreshDateData: getRefreshDataObject(edrefreshDateData)
        }
    };
};

export const fetchEDPracticeRefreshDate = async edId => {
    const edrefreshDateData = edId ? await getPracticesRefreshDate(edId) : null;
    return {
        data: {
            refreshDateData: getRefreshDataObject(edrefreshDateData)
        }
    };
};

const initialState = {
    islocationLoading: true,
    location: [],
    locationPracticeId: null,
    selectedLocationId: null,
    selectedEDProviderId: null,
    isEDRefreshDateLoading: false,
    refreshDateData: [],
    edLevelMeasureStatusList: [],
    isEDLevelMeasureStatusLoading: false,
    error: {
        code: '',
        Message: ''
    }
};

// Reducer
export default getReducer(initialState, {
    [EDServiceDeskDataTypes.READY]: (state, { payload }) => ({
        ...state,
        isEDLevelMeasureStatusLoading: true
    }),
    [EDServiceDeskDataTypes.SUCCESS]: (state, { payload }) => {
        return {
            ...state,
            edLevelMeasureStatusList:
                payload.getMeasureStatus !== null ? payload.getMeasureStatus : [],
            isEDLevelMeasureStatusLoading: false
        };
    },
    [EDServiceDeskDataTypes.ERROR]: (state, { payload }) => ({
        ...state,
        edLevelMeasureStatusList: [],
        isEDLevelMeasureStatusLoading: false
    }),
    [UpdateLocationPracticeIdType]: (state, { payload }) => {
        return {
            ...state,
            locationPracticeId: payload
        };
    },
    [LocationListTypes.READY]: state => ({
        ...state,
        islocationLoading: true,
        location: [],
        locationPracticeId: null,
        selectedLocationId: null,
        error: {
            code: '',
            Message: ''
        }
    }),
    [ResetEDMeasureStatusAndEHRFlagLoadingType]: (state, { payload }) => ({
        ...state,
        edLevelMeasureStatusList: [],
        isEDLevelMeasureStatusLoading: true
    }),
    [SetEDMeasureStatusAndEHRFlagLoadingType]: (state, { payload }) => ({
        ...state,
        isEDLevelMeasureStatusLoading: true
    }),
    [EDRefreshDateTypes.READY]: state => ({
        ...state,
        isEDRefreshDateLoading: FETCH_PRACTICE_REFRESH_DATE
    }),
    [SetEDRefreshDateType]: (state, { payload }) => {
        return {
            ...state,
            isEDRefreshDateLoading: false,
            refreshDateData: payload.refreshDateData
        };
    },
    [EDRefreshDateTypes.ERROR]: state => ({
        ...state,
        isEDRefreshDateLoading: false,
        refreshDateData: []
    }),
    [EDListTypes.READY]: state => {
        return {
            ...state,
            islocationLoading: true,
            location: [],
            locationPracticeId: null,
            selectedLocationId: null,
            selectedEDProviderId: null,
            isEDRefreshDateLoading: false,
            refreshDateData: [],
            error: {
                code: '',
                Message: ''
            }
        };
    },
    [UpdateEDListDataAndDetailsType]: (state, { payload }) => {
        return {
            ...state,
            edLevelMeasureStatusList: payload.edLevelMeasureStatusList,
            location: payload.location,
            locationPracticeId: payload.locationPracticeId,
            selectedLocationId: payload.selectedLocationId,
            selectedEDProviderId: null,
            islocationLoading: payload.islocationLoading,
            refreshDateData: payload.refreshDateData
        };
    },
    [LocationListTypes.SUCCESS]: (state, { payload }) => {
        const locationList = payload.getLocations || [];
        locationList.map(obj => {
            obj.name =
                obj.externalid !== null
                    ? `${obj.externalid}${obj.name !== null ? obj.name && '-' + obj.name : ''}`
                    : `${obj.name}`;
        });
        return {
            ...state,
            location: locationList,
            selectedLocationId:
                payload.getLocations !== null
                    ? payload.getLocations.length !== 0 && payload.getLocations[0].id
                    : null,
            islocationLoading: false,
            error: {
                code: '',
                Message: ''
            }
        };
    },
    [UpdateSelectedLocationIdType]: (state, { payload }) => {
        return {
            ...state,
            selectedLocationId: payload.locationId,
            selectedEDProviderId: payload.providerId === undefined ? null : payload.providerId,
            error: {
                code: '',
                Message: ''
            }
        };
    },
    [LocationListTypes.ERROR]: (state, { payload }) => {
        return {
            ...state,
            islocationLoading: false,
            error: {
                code: '',
                Message: 'Error occured while fetching error.'
            }
        };
    }
});
