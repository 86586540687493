import { getActionTypes, getReducer } from '@/utils/redux';
import {
    getCareGapMeasures,
    getCareGapMeasuresDescription,
    getPatients,
    getPatientsVisits,
    getVisitScoreCard
} from '@/redux/services/careGapApi';

// Types
export const PatientListTypes = getActionTypes('CARE_GAP_PATIENT_LIST');
export const PatientVisitsTypes = getActionTypes('CARE_GAP_PATIENT_VISITS');
export const CareGapMeasureTypes = getActionTypes('CARE_GAP_MEASURE_TYPE');
export const CareGapMeasureDescriptionTypes = getActionTypes('CARE_GAP_MEASURE_DESCRIPTION_TYPE');
export const CareGapVisitScoreCardTypes = getActionTypes('CARE_GAP_VISIT_SCORE_CARD');

//Get Patients call
export const getPatientsAction = queryParam => ({
    types: PatientListTypes,
    callAPI: () => getPatients(queryParam)
});

//Get Patient Visits call.
export const getPatientsVisitsAction = queryParam => ({
    types: PatientVisitsTypes,
    callAPI: () => getPatientsVisits(queryParam)
});

//Get CareGap Measure call.
export const getCareGapMeasure = queryParam => ({
    types: CareGapMeasureTypes,
    callAPI: () => getCareGapMeasures(queryParam),
    handleAction: ({ type, payload, store }) => {
        switch (type) {
            case CareGapMeasureTypes.SUCCESS:
                const param = {
                    input: {
                        measurenoIn: payload.getTagDetails.map(m => m.entityid),
                        inactive: false,
                        orderBy: 'listorder'
                    }
                };
                store.dispatch(getCareGapMeasureDescription(param));
                break;
            default:
                break;
        }
    }
});

//Get CareGap Measure Description call.
export const getCareGapMeasureDescription = queryParam => ({
    types: CareGapMeasureDescriptionTypes,
    callAPI: () => getCareGapMeasuresDescription(queryParam)
});

// Get CareGap VisitScoreCard
export const getCareGapVisitScoreCard = (queryParam, signal) => ({
    types: CareGapVisitScoreCardTypes,
    callAPI: () => getVisitScoreCard(queryParam, signal)
});

const initialState = {
    isPatientsLoading: false,
    PatientDataList: [],
    isPatientVisitsLoading: false,
    PatientVisitsList: [],
    CareGapMeasure: [],
    CareGapMeasureDescription: [],
    CareGapVisitScoreCard: [],
    isVisitScoreCardLoading: false
};

// Reducer
export default getReducer(initialState, {
    [CareGapVisitScoreCardTypes.READY]: state => ({
        ...state,
        isVisitScoreCardLoading: true
    }),
    [CareGapVisitScoreCardTypes.ERROR]: state => ({
        ...state,
        isVisitScoreCardLoading: false
    }),
    [CareGapVisitScoreCardTypes.SUCCESS]: (state, { payload }) => ({
        ...state,
        CareGapVisitScoreCard: payload.getVisitScoreCard,
        isVisitScoreCardLoading: false
    }),
    [CareGapMeasureTypes.SUCCESS]: (state, { payload }) => ({
        ...state,
        CareGapMeasure: payload.getTagDetails
    }),
    [CareGapMeasureDescriptionTypes.SUCCESS]: (state, { payload }) => ({
        ...state,
        CareGapMeasureDescription: payload.getMeasures
    }),
    [PatientListTypes.READY]: state => ({
        ...state,
        isPatientsLoading: true,
        error: {
            code: '',
            Message: ''
        }
    }),
    [PatientListTypes.SUCCESS]: (state, { payload }) => ({
        ...state,
        PatientDataList: payload.getPatients,
        isPatientsLoading: false,
        error: {
            code: '',
            Message: ''
        }
    }),
    [PatientListTypes.ERROR]: (state, { payload }) => {
        return {
            ...state,
            isPatientsLoading: false,
            error: {
                code: '',
                Message: 'Error occured while fetching patients.'
            }
        };
    },
    [PatientVisitsTypes.READY]: state => ({
        ...state,
        isPatientVisitsLoading: true,
        error: {
            code: '',
            Message: ''
        }
    }),
    [PatientVisitsTypes.SUCCESS]: (state, { payload }) => ({
        ...state,
        PatientVisitsList: payload.getPatientVisits,
        isPatientVisitsLoading: false,
        error: {
            code: '',
            Message: ''
        }
    }),
    [PatientVisitsTypes.ERROR]: (state, { payload }) => {
        return {
            ...state,
            isPatientVisitsLoading: false,
            error: {
                code: '',
                Message: 'Error occured while fetching patients visists.'
            }
        };
    }
});
