import { CREATE_PLAN_FOR_PIA, DELETE_PLAN_FOR_PIA, UPDATE_PLAN_FOR_PIA } from '../../graphql/mutation';
import { GET_PLANS_FOR_PIA,GET_STANDARD_INTERVENTIONS } from '../../graphql/query';

import { getJwt } from '@/utils/jwtUtils';
import APIHelper from '@/helper/apihelper';

const api = new APIHelper();

export const getPlanApi = (params) => {
    const requestOption = api.getRequestOption(
        GET_PLANS_FOR_PIA,
        params,
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.PIA, requestOption, response => response);
};

export const createPlansApi = (params) => {
    const requestOption = api.getRequestOption(
        CREATE_PLAN_FOR_PIA,
        {
            input: params
        },
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.PIA, requestOption, response => response);
};

export const deletePlanApi = (params) => {
    const requestOption = api.getRequestOption(
        DELETE_PLAN_FOR_PIA,
        params,
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.PIA, requestOption, response => response);
};

export const updatePlansApi = (params) => {
    const requestOption = api.getRequestOption(
        UPDATE_PLAN_FOR_PIA,
        {
            input: params
        },
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.PIA, requestOption, response => response);
};

export const getStandardInterventionsApi = (params) => {
    const requestOption = api.getRequestOption(
        GET_STANDARD_INTERVENTIONS,
        {
            input: params
        },
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.PIA, requestOption, response => response);
};



