import { getJwt } from '@/utils/jwtUtils';
import APIHelper from '@/helper/apihelper';
import { GET_ALL_FILES_FOR_MEASURE_BULK_UPLOAD, GET_CURRENT_DATE_FROM_SERVER } from '@/graphql/query';

const api = new APIHelper();

export const getAllFiles = (params, signal) => {
    const api = new APIHelper();
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Action: api.Actions.View,
            Authorization: getJwt(),
            Resource: 'fileupload'
        }),
        body: JSON.stringify({ query: GET_ALL_FILES_FOR_MEASURE_BULK_UPLOAD, variables: params }),
        signal
    };
    //api.Resources.FileUpload
    return fetch(api.Resources.FileUpload, requestOptions);
};

export const getCurrentDateFromServer = () => {
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Action: api.Actions.View,
            Authorization: getJwt(),
            Resource: 'fileupload'
        }),
        body: JSON.stringify({ query: GET_CURRENT_DATE_FROM_SERVER })
    };
    return api.PegasusAPI(api.Resources.FileUpload, requestOptions, response => response);
}