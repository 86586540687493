export const registryUnit = process.env.REGISTRY_UNIT;
export const strLoading = 'Loading.......';
export const mrnLabel = ' MRN - ';
export const genderLabel = ' Gender - ';
export const ageLabel = 'Age - ';
export const dateOfVisit = 'Date of Visit - ';
export const dateAndTimeOfVisit = 'Visit Date & Time -';
export const episodeId = 'Episode Id - ';
export const insuranceType = 'Insurance Type - ';
export const somethingWentWrong = 'Oops! Something went wrong. Please try again later';
export const btnSave = 'Save';
export const btnReset = 'Reset';
export const btnClear = 'Clear';
export const webtool = 'Measure Questionnaire';
export const noMeasures = 'No measures to display';
export const plzAnswerQuestionnaire = 'Please answer the measure questionnaire';
export const formSuccess = 'Data saved successfully';
export const visitSaveSuccess =
    'Data saved successfully! Now you will be redirected to Webtool questionnaire page';
export const dataCantBeUpdated = 'Data was submitted, so cannot update the form';
export const capMRNLabel = ' Accession ID - ';
export const capDateOfVisit = 'Accession Date - ';
export const ageInYearSingular = 'Year';
export const ageInYearPlural = 'Years';
export const ageInMonthSingular = 'Month';
export const ageInMonthPlural = 'Months';
export const ageInDaySingular = 'day';
export const ageInDayPlural = 'days';
export const providerNPI = 'NPI-';
export const locationLabel = 'Location - ';
/* Quality Measure Preference  */
export const selectProvider = 'Select Clinician *';
export const selectPractice = 'Select Practice *';
export const selectYear = 'Select Year *';
export const measureGridMeasureId = 'ID';
export const measureGridMeasureTitleLbl = 'MEASURE';
export const measureGridMeasureTitleId = 'MEASURETITLE';
export const measureGridMeasureTagLbl = 'TAGS';
export const measureGridMeasureTagId = 'TAGS';
export const lblGoToVisit = 'Go to visit list';
export const lblSave = 'Save';
export const measureSaveSuccess = 'Quality measure profile saved successfully';

export const emptyProviderprofile =
    'You have not selected any preferred measures. Please click on Select/Deselect Preferred Measures button to select preferred measures.';

/* VISIT LIST PAGE */
export const modifiedDate = 'UPDATED DATE';
export const episodeID = 'EPISODE ID';
export const drpType = 'select';
export const drpYear = 'drpYear';
export const drpYearSelectLabel = 'Select Year';
export const drpYearSelectedLabel = 'Selected Year';
export const drpClinician = 'drpClinician';
export const drpLocation = 'drpLocation';
export const drpClinicianSelectLabel = 'Select Practice';
export const drpClinicianSelectedLabel = 'Selected Practice';
export const drpPractice = 'drpPractice';
export const drpPracticeSelectLabel = 'Select Clinician';
export const drpPracticeSelectedLabel = 'Selected Clinician';
export const drpLocationSelectLabel = 'Select Location';
export const drpLocationSelectedLabel = 'Selected Location';
export const redirectEditMeasures = '/editmeasures';
export const redirectEditVisit = '/editvisit';
export const redirectVisit = '/visits';
export const redirectQualityPreferenceManagement = '/qualitypreferencemanagement';
//export const modelTitle = 'Confirmation of Delete Visit';
export const dateFormate = 'MM-DD-YYYY HH:mm:ss';
export const fromDate = '12-01-2018 12:41:53';
export const unit = 'AAO';
//export const measureDetailEditBtnText = 'Edit Measure Details';
//export const editVisitBtnText = 'Edit Profile & Visit';
//export const addVisitBtnText = 'Add Profile & Visit';
//export const confirmationDeleteMessageDefault =
//'Are you sure you want to delete visit for Patient Name ({FIRSTNAME_LASTNAME}) with MRN ({MRN}) and visit date ({VISIT_DATE})?';
//export const confirmationDeleteMessageCAP =
//'Are you sure you want to delete visit for Accession ID ({MRN}) and visit date ({VISIT_DATE})?';
export const blankGridMessage = 'Please add visit for the selected provider';
export const deleteVisitSuccess = 'Visit Deleted Successfully';
export const deletIconTooltipTitle = 'Delete Visit';
export const noResultMessage = "Please add Patient and Patient's Visit";
export const noResultMessageForCAP = 'Please add Episode/Case Details';

export const systemIn = [2, 3];
export const selectDeselectMeasures = 'Select/Deselect Preferred Measures';
export const selectdeselectTooltip = 'Manage quality measure preference';
export const noMeasureError = 'Please select atleast one measure to set your measure preference';
export const patientNameAndMrn = 'PATIENT MRN & NAME';
export const patientMrn = 'MRN';
export const patientMrnForCAP = 'PATIENT ID';
export const patientGender = 'GENDER';
export const patientDob = 'DATE OF BIRTH';
export const patientProvider = 'CLINICIAN';
export const patientVisitDateAndTime = 'VISIT DATE & TIME';
export const patientVisitDate = 'VISIT DATE';
export const patientIunsurenceType = 'INSURANCE TYPE';
export const practiceLocation = 'LOCATION';
export const patientAccessionId = 'ACCESSION ID';
export const patientNameAndAccessionId = 'PATIENT NAME & ACCESSION ID';
export const patientAccessionDate = 'ACCESSION DATE';
export const patientServiceDate = 'SERVICE DATE';
export const patientVerificationDate = 'REPORT VERIFICATION DATE';
export const highPriorityMeasure = 'High Priority';
export const outcomeMeasure = 'Outcome';

/* EDITVISIT LIST PAGE */
export const accessionIdError =
    'Accession ID should contain alphabets, numbers and special characters with 20 characters of limit';
export const accessionDateError = 'Select Accession Date';
export const serviceDateError = 'Select Service Date';
export const verificationDateError = 'Select Verification Date';
export const mrnError = 'Sorry, could not add Visit';
export const alertMessage =
    'Are you sure you want to navigate from this page? If you navigate the data will be lost. Press No to stay on the same page or press Yes to navigate.';
export const resetMeasure = 'Reset';
export const saveMeasure = 'Save';
export const cancelMeasure = 'Cancel';
export const goToMeasureQuestionnaire = 'Go to Measure Questionnaire';
export const selectedMeasureCountText = 'Selected quality profile measure';
export const measureText = '';
export const notSelected = 'Not selected';
export const btnCancel = 'Cancel';
export const btnFilter = 'Apply Filter';
export const btnChange = 'Change';
export const lblFrom = 'From Date';
export const lblTo = 'To Date';
export const firstNameError = 'First Name should contain alphabets only with 35 characters limit.';
export const lastNameError = 'Last Name should contain alphabets only with 35 characters limit.';
export const genderError = 'Select any one option';
export const dobError = 'Select Date of Birth';
export const insuranceTypeError = 'Select any one option';
export const visitDateError = 'Select Visit date';
export const locationIdError = 'Select any one option';
export const mrnValidationError =
    'MRN should contain alphabets, numbers, and special characters  with 20 characters limit.';
export const dateFormat = 'Visit Date should be greater than Date of birth';
export const dateFormatForCap = ' Date of birth should not be greater than Accession Date';
export const visitAndServiceDateError =
    'Service Date of collection should be earlier or equal to Accession Date';
export const visitAndVerificationDateError =
    'Report verification date must be greater than or equal to Accession date';
export const pageTitleHeaderTypeForVisitListPage = 'Label';
export const pageTitleHeaderValueForVisitListPage = 'Visit List';
export const defaultRegistryVisitColumnPath = './Baseline/visitColumns';
export const capUnitName = 'CAP';
export const capRegistryVisitColumnPath = './RegistryCap/visitColumns';
export const aptaUnitName = 'APTA';
export const aptaRegistryVisitColumnPath = './RegistryAPTA/visitColumns';
export const aaoUnitName = 'AAO';
export const aaoRegistryVisitColumnPath = './RegistryAAO/visitColumns';
export const aadUnitName = 'AAD';
export const errorMessageForBlankEpisodeId = "EpisodeId can not be blank";

/* PI CONSTANTS */
export const piTitle = 'Promoting Interoperability Data entry';
export const piSearchFilterPlaceHolder = 'Search';
export const piClearButton = 'Clear';
export const piResetButton = 'Reset';
export const piDeleteButton = 'Delete';
export const piSaveButton = 'Save';
export const piDeleteAllButton = 'Delete All Data';
export const piApplyFilterButton = 'Apply Filter';
export const addDurationOfMeasureErrorMessage = 'Please add duration for selected measure';
export const piPdfPrefix = '2019-PI-Measure-';
export const piTagSubHeading = 'Measure Details';

/* IA & PI COMMON ERROR MESSAGES CONSTANTS */
export const pleasesSelectFilterErrorMessage = 'Please select filter to fetch data';
export const isRequiredFieldErrorMessage = 'is a required field';
export const noDataFoundErrorMessage = 'No data found';
export const numeratorValueErrorMessage = 'Numerator must be equal to or less than the denominator';
export const fromDateErrorMessage = 'Please select from date';
export const toDateErrorMessage = 'Please select to date';
export const addDurationOfCurrentYearErrorMessage = 'Please add duration of current year';
export const fromDateNotGreaterThanToDateErrorMessage = 'From date cannot be greater than to date';
export const dateRangeAlreadyAddedErrorMessage =
    'Data has already been added for date(s) in the selected date range';
export const valueGreaterThanLimitErrorMessage = 'should be more than or equal to ';
export const valueSmallerThanLimitErrorMessage = 'should be smaller than or equal to ';

export const saveConfirmationModalCancelButton = 'Cancel';
export const saveConfirmationModalOkButton = 'Ok';
export const saveConfirmationModalYesButton = 'YES';
export const saveConfirmationModalNoButton = 'NO';
export const saveConfirmationModalTitle = 'Confirmation';
export const defaultSaveConfirmationModalTitle = 'Are you sure want to navigate from this page';
export const saveConfirmationModalContent =
    'You are trying to navigate from the current tab which has unsaved changes. Please click on Ok to continue to navigate by discarding the unsaved changes or click on Cancel to stay on the current tab.';
export const defaultSaveConfirmationModalContent =
    'If you proceed without saving, all the changes will be lost. Please save the changes first. Press "YES" to continue. or "NO" to stay on same page.';
export const resetSaveConfirmationModalContent =
    'All the unsaved changes will be lost and form will be set back to the previous state. Please click Ok to continue or Cancel if you want to continue editing form.';

export const resetConfirmationModalCancelButton = 'Cancel';
export const resetConfirmationModalOkButton = 'Ok';
export const resetConfirmationModalTitle = 'Confirmation';
export const resetConfirmationModalContent =
    'All the unsaved changes will be lost and form will be set back to the previous state. Please click Ok to continue or Cancel if you want to continue editing form.';

export const deleteSectionDataConfirmationModalCancelButton = 'No';
export const deleteSectionDataConfirmationModalOkButton = 'Yes';
export const deleteSectionDataConfirmationModalTitle = 'Confirmation';

export const deletePIConfirmationModalContent =
    'Are you sure? You are deleting all durations of Measure ';
export const deleteIAConfirmationModalContent =
    'Are you sure? You are deleting all durations of Activity ';
export const deletePISuccessMessage = 'Data deleted successfully';
export const deleteIASuccessMessage = 'Data deleted successfully';

export const completeDeleteSectionDataConfirmationModalCancelButton = 'No';
export const completeDeleteSectionDataConfirmationModalOkButton = 'Yes';
export const completeDeleteSectionDataConfirmationModalTitle = 'Confirmation';

export const completeDeletePIConfirmationModalContent =
    'Are you sure? You are deleting all durations of all the Measures.';
export const completeDeleteIAConfirmationModalContent =
    'Are you sure? You are deleting all durations of all the Activities.';
export const completeDeletePISuccessMessage = 'Data deleted successfully';
export const completeDeleteIASuccessMessage = 'Data deleted successfully';

export const IA_PI_PDF_DOWNLOAD_PATH = '/static/pdf/';

/* IA CONSTANTS */
export const iaTitle = 'Improvement Activity Data Entry';
export const iaSearchFilterPlaceHolder = 'Search';
export const iaClearButton = 'Clear';
export const iaResetButton = 'Reset';
export const iaDeleteButton = 'Delete';
export const iaSaveButton = 'Save';
export const iaDeleteAllButton = 'Delete All Data';
export const iaApplyFilterButton = 'Apply Filter';
export const addDurationOfActivityErrorMessage = 'Please add duration for selected activity';
export const markAsFavoriteSuccessMessage = 'Added to favorite list';
export const unmarkAsFavoriteSuccessMessage = 'Removed from favorite list';
export const iaPdfPrefix = '2019-IA-Activity-';
export const iaTagSubHeading = 'Activity Details';

export const iaLocationNote =
    'Note: If your practice has (or you anticipate) multiple TINS and participates in MIPS submission, request you to ensure all the basic details (clinician, location) are tagged against the data you enter. This will enable the system to distinguish the entered data and in turn, associate the data with the respective TIN while you actually do the submission.';
export const piLocationNote =
    'Note: If your practice has (or you anticipate) multiple TINS and participates in MIPS submission, request you to ensure all the basic details (clinician, location) are tagged against the data you enter. This will enable the system to distinguish the entered data and in turn, associate the data with the respective TIN while you actually do the submission.';

export const filterErrorText = 'Please Select Practice, Clinician & Location';
export const filterErrorMessage =
    "Sorry, we can't show you data unless you select the mentioned values.";

export const Patient_Profile_Visit = 'Patient Profile And Visit Details';

/* Filter labels */
export const notSelectedTxt = 'Not selected';
export const filterBtnTxt = 'Apply Filter';
export const cancelBtnTxt = 'Cancel';
export const changeBtnTxt = 'Change';
export const fromDateLbl = 'From Date';
export const toDateLbl = 'To Date';
export const searchYear = 'Search year';
export const searchPractice = 'Search practice';
export const searchClinician = 'Search clinician';
export const searchLocation = 'Search location';

export const MQDeleteConfirmationModalTitle = 'Confirmation';
export const MQDeleteConfirmationModalContent =
    'Are you sure? You are deleting All Answers of Measure ';
export const MQDeleteConfirmationModalCancelButton = 'No';
export const MQDeleteConfirmationModalOkButton = 'Yes';
export const MQDeleteSuccessMessage = 'Data deleted successfully';

export const MQCompleteDeleteConfirmationModalTitle = 'Confirmation';
export const MQCompleteDeleteConfirmationModalContent =
    'Are you sure? You are deleting All Answers of ALL Measures.';
export const MQCompleteDeleteConfirmationModalCancelButton = 'No';
export const MQCompleteDeleteConfirmationModalOkButton = 'Yes';
export const MQCompleteDeleteSuccessMessage = 'Data deleted successfully';

export const btnDelete = 'Delete';
export const btnDeleteAll = 'Delete All';

export const defaultLocationId = -1;
export const defaultLocationName = 'Default Location';
export const providerNotSelected = 'Please Select Practice and Clinician';
export const noClinicianSelectedText =
    "Sorry, we can't show you data unless practice and clinician is selected.";
export const noResultForPatientsVisits =
    'Sorry, there is no data added yet to this selected clinician';
