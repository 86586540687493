export const SAVE_WEBTOOL_FORM = `mutation($input: FormInstanceUpdateInput){
  saveOrSubmit(input:$input){
    isSubmitted,
    data
  }
}`;

export const DELETE_SOFT_VISIT = ` mutation($input: deleteVisitInput){
  deleteVisit(input: $input){
    VisitUid
  }
}`;

export const DELETE_MEASURE_DATA = `mutation($input: FormInstanceDeleteVisitInput){
  deleteVisit(input: $input){
    message
    statusCode
  }
}`;

export const SAVE_PI_MEASURE_FORM = `mutation($input: FormInstanceUpdateInput){
  saveOrSubmit(input:$input){
    isSubmitted,
    data
  }
}`;

export const DELETE_IA_PI_ELEMENT = `mutation($input: DeleteSectionInput){
  deleteSection(input:$input){
    statusCode,
  	message
  }
}`;

export const DELETE_MEASURE_QUESTIONNAIRE = `mutation($input: DeleteSectionInput){
  deleteSection(input:$input){
    statusCode,
  	message
  }
}`;

export const DELETE_ALL_MEASURE_QUESTIONNAIRE = `mutation($input: deleteAllSectionsInput){
  deleteAllSections(input:$input){
    statusCode,
  	message
  }
}`;

export const CREATE_PERFROMANCE_GROUP = `mutation ($input: inputPerformanceGroup!) {
    createPerformanceGroup(input: $input){
       id
    }
}`;


export const CREATE_PLAN_FOR_PIA = `mutation($input:CreatePlan!) {
  createplan(input: $input)
}`;

export const UPDATE_PLAN_FOR_PIA = `mutation($input:UpdatePlan!) {
  updateplan(input: $input)
}`;

export const DELETE_PLAN_FOR_PIA = `mutation($input:removeplan!){
    removeplan(input: $input)
  }`;