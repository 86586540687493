import APIHelper from '@/helper/apihelper';
import { getJwt } from '../../utils/jwtUtils';

const apiHelper = new APIHelper();

export const userdetailApi = params => {
    return fetch(apiHelper.Resources.GetUserProfile, {
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
            resource: 'defaultread',
            Action: 'view'
        }
    });
};

export const userPermissionsApi = params => {
    return fetch(apiHelper.Resources.GetMenuDetails, {
        method: 'POST',
        mode: 'cors',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
            resource: 'defaultread',
            Action: 'view'
        },
        body: JSON.stringify({
            ismenu: 'false',
            resourcename: params
        })
    });
};
