import { getActionCreator, getActionType, getActionTypes, getReducer } from '@/utils/redux';
import { getCalendar, getMeasureSet } from '@/redux/services/measureFilterApi';
import { getMeasureListAction } from '@/redux/modules/registryMeasureList';
import { DEFAULT_FILTER_BY_REFRESH_DATE } from '@/helper/constants';
import { getFromToDateByMeasureSetYear } from '@/helper/commonFunction';

// Types
export const MeasureFilterTypes = getActionTypes('MEASUREFILTERTYPES');
export const MeasureFilterUpdateType = getActionType('MEASUREFILTERUPDATETYPE');
export const UpdateMeasureFilterDefaultSelectionType = getActionType(
    'UPDATEMEASUREFILTERDEFAULTSELECTIONTYPE'
);
export const SetMeasureSetFilterLoadingFlagType = getActionType('SETMEASURESETFILTERLOADINGFLAG');
export const SetMeasureFilterYearType = getActionType('SETMEASUREFILTERYEAR');

export const SetMeasureFilterYearAction = getActionCreator(SetMeasureFilterYearType);

export const measureFilterUpdateAction = getActionCreator(MeasureFilterUpdateType);
export const UpdateMeasureFilterDefaultSelectionAction = getActionCreator(
    UpdateMeasureFilterDefaultSelectionType
);

export const SetMeasureSetFilterLoadingFlagAction = getActionCreator(
    SetMeasureSetFilterLoadingFlagType
);

export const transformCalendarData = calendarData => {
    const year = [];
    const tempyear = [];
    const duration = [];
    const tempduration = [];
    const tempseletedYearDuration = [];
    const currentYear = calendarData[0].year;

    calendarData.map(obj => {
        if (/^(\d{4}Q[1-4])$/.test(obj.duration) && currentYear === obj.year) {
            tempseletedYearDuration.push(obj);
        }

        if (tempyear.indexOf(obj.year) === -1) {
            tempyear.push(obj.year);
            year.push({
                id: obj.year,
                name: obj.year,
                value: obj.year,
                year: obj.year
            });
        }
        if (tempduration.indexOf(`${obj.year}${obj.duration}`) === -1) {
            tempduration.push(`${obj.year}${obj.duration}`);
            duration.push({
                id: obj.duration,
                name: /^(\d{4}Q[1-4])$/.test(obj.duration)
                    ? obj.duration.replace(/(\d{4})|(Q[1-4])/, '$1-$2')
                    : obj.duration,
                value: obj.duration,
                flag: obj.flag,
                startdate: new Date(Number(obj.startdate)),
                enddate: new Date(Number(obj.enddate)),
                year: obj.year
            });
        }
    });

    let seletedYearDuration = {};

    if (tempseletedYearDuration.length === 1) {
        let first = tempseletedYearDuration[0];
        seletedYearDuration = {
            id: first.id,
            year: first.year,
            duration: first.duration,
            startdate: new Date(Number(first.startdate)),
            enddate: new Date(Number(first.enddate)),
            flag: first.flag
        };
    } else {
        seletedYearDuration = tempseletedYearDuration.reduce((first, second) => {
            return first.id > second.id
                ? {
                      id: first.id,
                      year: first.year,
                      duration: first.duration,
                      startdate: new Date(Number(first.startdate)),
                      enddate: new Date(Number(first.enddate)),
                      flag: first.flag
                  }
                : {
                      id: second.id,
                      year: second.year,
                      duration: second.duration,
                      startdate: new Date(Number(second.startdate)),
                      enddate: new Date(Number(second.enddate)),
                      flag: second.flag
                  };
        });
    }

    return { year, duration, seletedYearDuration };
};

export const getMeasureFilterData = async page => {
    const response = await Promise.all([getMeasureSet(), getCalendar()]);
    const { year, duration, seletedYearDuration } = transformCalendarData(
        response[1].data.getCalendars
    );
    const measureSetRes = response[0].data.getMeasureSets.map(p => {
        p.year = p.year ? p.year : new Date().getFullYear();
        return p;
    });
    if (page === 'Group_Dashboard_View') {
        let latestQuater = duration.filter(
            item => item.year === measureSetRes[0].year && item.flag === 'QNR'
        );
        const currentDateQuater = duration.filter(
            item =>
                item.flag === 'QNR' &&
                item.value ===
                    `${new Date().getFullYear()}Q${Math.ceil((new Date().getMonth() + 1) / 3)}`
        );
        if (currentDateQuater.length > 0 && measureSetRes[0].year === new Date().getFullYear()) {
            latestQuater = currentDateQuater;
        }
        const customDateDuration = getFromToDateByMeasureSetYear(measureSetRes[0].year);
        return {
            data: {
                measureSet: measureSetRes,
                year,
                duration,
                selectedValues: {
                    measureSet: measureSetRes[0].id,
                    year: measureSetRes[0].year,
                    duration: latestQuater[0].value,
                    durationFlag: seletedYearDuration.flag,
                    durationFrom: latestQuater[0].startdate,
                    durationTo: latestQuater[0].enddate,
                    customrange: DEFAULT_FILTER_BY_REFRESH_DATE ? false : true,
                    fromdate: customDateDuration.fromdate,
                    todate: customDateDuration.todate
                }
            }
        };
    }
    return {
        data: {
            measureSet: measureSetRes,
            year,
            duration,
            selectedValues: {
                measureSet: measureSetRes[0].id,
                year: seletedYearDuration.year,
                duration: seletedYearDuration.duration,
                durationFlag: seletedYearDuration.flag,
                durationFrom: seletedYearDuration.startdate,
                durationTo: seletedYearDuration.enddate,
                customrange: DEFAULT_FILTER_BY_REFRESH_DATE ? false : true,
                fromdate: DEFAULT_FILTER_BY_REFRESH_DATE
                    ? null
                    : `01-01-${new Date().getFullYear()}`,
                todate: DEFAULT_FILTER_BY_REFRESH_DATE
                    ? null
                    : `${new Date().getMonth() +
                          1}-${new Date().getDate()}-${new Date().getFullYear()}`
            }
        }
    };
};

export const getMeasureFilterDataAction = (page = null, practicepayload = null) => ({
    types: MeasureFilterTypes,
    callAPI: () => getMeasureFilterData(page),
    handleAction: ({ type, payload, store }) => {
        switch (type) {
            case MeasureFilterTypes.SUCCESS: {
                const params = {
                    MeasuresetId: payload.selectedValues.measureSet
                };
                store.dispatch(getMeasureListAction(params, payload, practicepayload));
                break;
            }
            default:
                break;
        }
    }
});

const initialState = {
    isLoading: true,
    // year:{ },
    measureFilterData: {
        measureSet: [],
        year: [],
        duration: [],
        selectedValues: {
            measureSet: null,
            year: null,
            duration: null,
            durationFlag: null,
            durationFrom: null,
            durationTo: null,
            customrange: false,
            fromdate: null,
            todate: null
        }
    }
};

// Reducer
export default getReducer(initialState, {
    [MeasureFilterTypes.SUCCESS]: (state, { payload }) => ({
        ...state,
        measureFilterData: payload
    }),
    [SetMeasureSetFilterLoadingFlagType]: (state, { payload }) => ({
        ...state,
        isLoading: false
    }),
    // [SetMeasureFilterYearType]: (state,  {payload} ) => ({
    //     ...state,
    //     year: {
    //         year: payload.year,
    //         id: payload.id,
    //         value: payload.value,
    //         name: payload.name
    //     }
    // }),
    [UpdateMeasureFilterDefaultSelectionType]: (state, { payload }) => {
        return {
            ...state,
            isLoading: false,
            measureFilterData: {
                ...state.measureFilterData,
                selectedValues: {
                    ...state.measureFilterData.selectedValues,
                    year: payload.selectedValues.year,
                    duration: payload.selectedValues.duration,
                    durationFrom: payload.selectedValues.durationFrom,
                    durationTo: payload.selectedValues.durationTo,
                    durationFlag: 'QNR',
                    customrange: DEFAULT_FILTER_BY_REFRESH_DATE ? false : true,
                    fromdate: payload.selectedValues.fromdate,
                    todate: payload.selectedValues.todate
                }
            }
        };
    },
    [MeasureFilterUpdateType]: (state, { payload }) => ({
        ...state,
        measureFilterData: {
            ...state.measureFilterData,
            selectedValues: payload
        }
    }),
    [MeasureFilterTypes.ERROR]: () => state => ({
        ...state,
        isLoading: false
    })
});
