import {
    getMeasuresList,
    getWebtoolProfileMeasurePreference,
    getWebtoolProviderProfile,
    setWebtoolProfileMeasurePreference,
    webtoolcreateProfile,
    getPractices,
    getProviderPaymentStatus,
    getWebtoolCalendar
} from '../services/QualityWebtoolMeasureService';
import { getClinicianData } from '../services/clinicianPerformanceApi';
import _ from 'lodash';
import { PAYMENTFOR_WEBTOOL_PROVIDER } from '@/helper/constants';
//import { getClinicianData } from '../services/clinicianPerformanceApi';
import { handleUnauthorizedRequest } from '@/containers/Webtool/webtoolUtils';

export const WEBTOOL_PRACTICES_REQUEST = 'WEBTOOL_PRACTICES_REQUEST';
export const WEBTOOL_PRACTICES_RECEIVE = 'WEBTOOL_PRACTICES_RECEIVE';
export const WEBTOOL_PRACTICES_FAILURE = 'WEBTOOL_PRACTICES_FAILURE';
export const WEBTOOL_PRACTICE_CHANGE = 'WEBTOOL_PRACTICE_CHANGE';

export const WEBTOOL_CLINICIANS_REQUEST = 'WEBTOOL_LINICIANS_REQUEST';
export const WEBTOOL_CLINICIANS_RECEIVE = 'WEBTOOL_LINICIANS_RECEIVE';
export const WEBTOOL_CLINICIANS_FAILURE = 'WEBTOOL_LINICIANS_FAILURE';

export const PROVIDER_CHANGE = 'PROVIDER_CHANGE';
export const YEAR_CHANGE = 'YEAR_CHANGE';
export const MEASURE_GET = 'MEASURE_GET';

export const WEBTOOL_MEASURE_REQUEST = 'WEBTOOL_MEASURE_REQUEST';
export const WEBTOOL_MEASURE_RECEIVE = 'WEBTOOL_MEASURE_RECEIVE';
export const WEBTOOL_MEASURE_FAILURE = 'WEBTOOL_MEASURE_FAILURE';

export const WEBTOOL_YEAR_REQUEST = 'WEBTOOL_YEAR_REQUEST';
export const WEBTOOL_YEAR_RECEIVE = 'WEBTOOL_YEAR_RECEIVE';
export const WEBTOOL_YEAR_FAILURE = 'WEBTOOL_YEAR_FAILURE';

export const MEASURE_SELECTION_CHANGE = 'MEASURE_SELECTION_CHANGE';
export const WEBTOOL_ALLMEASURESHOW = 'WEBTOOL_ALLMEASURESHOW';

export const WEBTOOL_PROVIDERPROFILE_REQUEST = 'WEBTOOL_PROVIDERPROFILE_REQUEST';
export const WEBTOOL_PROVIDERPROFILE_RECEIVE = 'WEBTOOL_PROVIDERPROFILE_RECEIVE';
export const WEBTOOL_PROVIDERPROFILE_FAILURE = 'WEBTOOL_PROVIDERPROFILE_FAILURE';

export const WEBTOOL_GETPROVIDER_PROFILE = 'WEBTOOL_GETPROVIDER_PROFILE';

export const WEBTOOL_CREATEPROFILE_REQUEST = 'WEBTOOL_CREATEPROFILE_REQUEST';
export const WEBTOOL_CREATEPROFILE_RECEIVE = 'WEBTOOL_CREATEPROFILE_RECEIVE';
export const WEBTOOL_CREATEPROFILE_FAILURE = 'WEBTOOL_CREATEPROFILE_FAILURE';

export const WEBTOOL_PROVIDERPROFILEPREFERENCE_REQUEST =
    'WEBTOOL_PROVIDERPROFILEPREFERENCE_REQUEST';
export const WEBTOOL_PROVIDERPROFILEPREFERENCE_RECEIVE =
    'WEBTOOL_PROVIDERPROFILEPREFERENCE_RECEIVE';
export const WEBTOOL_PROVIDERPROFILEPREFERENCE_FAILURE =
    'WEBTOOL_PROVIDERPROFILEPREFERENCE_FAILURE';

export const WEBTOOL_GETPROVIDERPROFILEPREFERENCE_REQUEST =
    'WEBTOOL_GETPROVIDERPROFILEPREFERENCE_REQUEST';
export const WEBTOOL_GETPROVIDERPROFILEPREFERENCE_RECEIVE =
    'WEBTOOL_GETPROVIDERPROFILEPREFERENCE_RECEIVE';
export const WEBTOOL_GETPROVIDERPROFILEPREFERENCE_FAILURE =
    'WEBTOOL_GETPROVIDERPROFILEPREFERENCE_FAILURE';

export const WEBTOOL_SAVEMEASURES_REQUEST = 'WEBTOOL_SAVEMEASURES_REQUEST';
export const CLEAR_STATE_PROVIDERCHANGE = 'CLEAR_STATE_PROVIDERCHANGE';

export const RESET_MEASURE_CLICK = 'RESET_MEASURE_CLICK';

export const RESET_MEASURECLICK_RERENDER = 'RESET_MEASURECLICK_RERENDER';

export const WEBTOOL_PROVIDERPAYMENT_REQUEST = 'WEBTOOL_PROVIDERPAYMENT_REQUEST';
export const WEBTOOL_PROVIDERPAYMENT_RECEIVE = 'WEBTOOL_PROVIDERPAYMENT_RECEIVE';
export const WEBTOOL_PROVIDERPAYMENT_FAILURE = 'WEBTOOL_PROVIDERPAYMENT_FAILURE';

export const UPDATE_FILTERED_PROVIDER = 'UPDATE_FILTERED_PROVIDER';

const requestPractices = () => ({
    type: WEBTOOL_PRACTICES_REQUEST
});

const receivePractices = payload => ({
    type: WEBTOOL_PRACTICES_RECEIVE,
    payload
});

const failurePractices = () => ({
    type: WEBTOOL_PRACTICES_FAILURE
});

export const updatePracticeId = payload => ({
    type: WEBTOOL_PRACTICE_CHANGE,
    payload
});

const requestClinicians = () => ({
    type: WEBTOOL_CLINICIANS_REQUEST
});

const receiveClinicians = payload => ({
    type: WEBTOOL_CLINICIANS_RECEIVE,
    payload
});

const failureClinicians = () => ({
    type: WEBTOOL_CLINICIANS_FAILURE
});

export const changeProviderId = payload => ({
    type: PROVIDER_CHANGE,
    payload
});

export const changeyearId = payload => ({
    type: YEAR_CHANGE,
    payload
});

const requestWebtoolMeasure = () => ({
    type: WEBTOOL_MEASURE_REQUEST
});

const receiveWebtoolMeasures = payload => ({
    type: WEBTOOL_MEASURE_RECEIVE,
    payload
});

const failureWebtoolMeasures = () => ({
    type: WEBTOOL_MEASURE_FAILURE
});

const requestCreateProfile = () => ({
    type: WEBTOOL_CREATEPROFILE_REQUEST
});

const receiveCreateProfile = payload => ({
    type: WEBTOOL_CREATEPROFILE_RECEIVE,
    payload
});

const failureCreateProfile = () => ({
    type: WEBTOOL_CREATEPROFILE_FAILURE
});

const requestProfileMeasurePreference = payload => ({
    type: WEBTOOL_PROVIDERPROFILEPREFERENCE_REQUEST,
    payload
});

const receiveProfileMeasurePreference = payload => ({
    type: WEBTOOL_PROVIDERPROFILEPREFERENCE_RECEIVE,
    payload
});

const failureProfileMeasurePreference = () => ({
    type: WEBTOOL_GETPROVIDERPROFILEPREFERENCE_FAILURE
});

const requestgetProfileMeasurePreference = () => ({
    type: WEBTOOL_GETPROVIDERPROFILEPREFERENCE_REQUEST
});

const receivegetProfileMeasurePreference = payload => ({
    type: WEBTOOL_GETPROVIDERPROFILEPREFERENCE_RECEIVE,
    payload
});

const failuregetProfileMeasurePreference = () => ({
    type: WEBTOOL_PROVIDERPROFILEPREFERENCE_FAILURE
});

const requestWebtoolYear = () => ({
    type: WEBTOOL_YEAR_REQUEST
});

const receiveWebtoolYear = payload => ({
    type: WEBTOOL_YEAR_RECEIVE,
    payload
});

const failureWebtoolYear = () => ({
    type: WEBTOOL_YEAR_FAILURE
});

const requestProviderProfile = () => ({
    type: WEBTOOL_PROVIDERPROFILE_REQUEST
});

const receiveProviderProfile = payload => ({
    type: WEBTOOL_PROVIDERPROFILE_RECEIVE,
    payload
});

const failureProviderProfile = () => ({
    type: WEBTOOL_PROVIDERPROFILE_FAILURE
});

export const updateMeasureSelection = payload => ({
    type: MEASURE_SELECTION_CHANGE,
    payload
});
export const updateShowAllMasure = payload => ({
    type: WEBTOOL_ALLMEASURESHOW,
    payload
});
export const saveMeasures = payload => ({
    type: WEBTOOL_SAVEMEASURES_REQUEST,
    payload
});
export const clearStateOnProviderChange = () => ({
    type: CLEAR_STATE_PROVIDERCHANGE
});

export const onResetSelectedMeasure = payload => ({
    type: RESET_MEASURE_CLICK,
    payload
});
export const refreshRenderForResetClick = payload => ({
    type: RESET_MEASURECLICK_RERENDER,
    payload
});

const requestProviderPaymentStatus = () => ({
    type: WEBTOOL_PROVIDERPAYMENT_REQUEST
});

const receiveProviderPaymentStatus = payload => ({
    type: WEBTOOL_PROVIDERPAYMENT_RECEIVE,
    payload
});

const failureProviderPaymentStatus = () => ({
    type: WEBTOOL_PROVIDERPAYMENT_FAILURE
});

export const updatefilteredProvider = payload => ({
    type: UPDATE_FILTERED_PROVIDER,
    payload
});

export const getYearsData = (signal) => {
    return async dispatch => {
        dispatch(requestWebtoolYear());
        getWebtoolCalendar(signal)
            .then(response => response.json())
            .then(response => {
                const calendarData = response.data.getCalendars;
                let year = [];
                let _year = [];
                // eslint-disable-next-line no-unused-vars
                let duration = [];
                // eslint-disable-next-line no-unused-vars
                let _duration = [];
                calendarData.map((obj, index) => {
                    if (_year.indexOf(obj.year) === -1) {
                        _year.push(obj.year);
                        year.push({
                            id: obj.id,
                            name: obj.year,
                            value: obj.id
                        });
                    }
                });

                dispatch(receiveWebtoolYear(year));
            })
            .catch(ex => {
                dispatch(failureWebtoolYear());
                // throw new Error(ex);
            });
    };
};

export const getMeasures = (params,signal) => {
    return dispatch => {
        dispatch(requestWebtoolMeasure());
        getMeasuresList(params,signal)
            .then(data => {
                return data.json();
            })
            .then(data => {
                dispatch(receiveWebtoolMeasures(data.data.getMeasures));
            })
            .catch(ex => {
                dispatch(failureWebtoolMeasures());
                // throw new Error(ex);
            });
    };
};

export const getClinicians = params => {
    return async dispatch => {
        dispatch(requestClinicians());
        try {
            let resp = await getClinicianData(params);
            handleUnauthorizedRequest(resp.status);
            let clinicianData;
            if (resp.data.getProvidersByPracticeId) {
                clinicianData = resp.data.getProvidersByPracticeId;
                if (
                    PAYMENTFOR_WEBTOOL_PROVIDER === 'true' &&
                    clinicianData &&
                    clinicianData.length
                ) {
                    let arrProviderId = [];
                    clinicianData.map(nextProvider => {
                        arrProviderId.push(nextProvider.id.toString());
                    });
                    const queryParams = {
                        input: {
                            entityidIn: arrProviderId || [],
                            entitynameLikeNoCase: 'provider',
                            parententityid: params.input.practiceid.toString() || '',
                            parententitynameLikeNoCase: 'practice'
                        }
                    };
                    dispatch(requestProviderPaymentStatus());
                    clinicianData = await getPaymentStatusForProvider(
                        queryParams,
                        clinicianData,
                        dispatch
                    );
                }
                dispatch(receiveClinicians(clinicianData));
            } else {
                throw new Error();
            }
        } catch (ex) {
            dispatch(failureClinicians());
        }
    };
};
const getPaymentStatusForProvider = async (
    providerPaymentQueryParamsWithId,
    providers,
    dispatch
) => {
    try {
        let paymentInfo = await getProviderPaymentStatus(providerPaymentQueryParamsWithId);
        handleUnauthorizedRequest(paymentInfo.status);
        if (paymentInfo.ok) {
            let parsedPaymentInfo = await paymentInfo.json();

            dispatch(receiveProviderPaymentStatus(parsedPaymentInfo));

            let arrPaymentDoneId = getPaymentDoneId(parsedPaymentInfo.data.getPayments);

            const filteredData = _.filter(providers, next => arrPaymentDoneId.includes(next.id));

            return filteredData;
        } else {
            throw new Error();
        }
    } catch (ex) {
        dispatch(failureProviderPaymentStatus());
    }
};

const getPaymentDoneId = list => {
    let arrPaymentDoneId = [];
    _.map(list, next => {
        if (next.paymentstatus == 1) {
            arrPaymentDoneId.push(parseInt(next.entityid));
        }
    });

    return arrPaymentDoneId;
};
export const getPracticeData = (params,signal) => {
    return async dispatch => {
        dispatch(requestPractices());

        try {
            let practiceInfo = await getPractices(params,signal);
            handleUnauthorizedRequest(practiceInfo.status);
            if (practiceInfo.ok) {
                let parsedpracticeInfo = await practiceInfo.json();

                dispatch(receivePractices(parsedpracticeInfo.data.getPractices));
            } else {
                // throw new Error();
            }
        } catch (ex) {
            dispatch(failurePractices());
        }
    };
};

export const getProviderPaymentStatusList = params => {
    return async dispatch => {
        dispatch(requestProviderPaymentStatus());
        try {
            let paymentInfo = await getProviderPaymentStatus(params);
            handleUnauthorizedRequest(paymentInfo.status);
            if (paymentInfo.ok) {
                let parsedPaymentInfo = await paymentInfo.json();
                dispatch(receiveProviderPaymentStatus(parsedPaymentInfo.data.getPayments));
            } else {
                throw new Error();
            }
        } catch (ex) {
            dispatch(failureProviderPaymentStatus());
        }
    };
};

export const getProviderProfile = (params,signal) => {
    return async dispatch => {
        dispatch(requestProviderProfile());
        if (!params.input.providerid) {
        } else {
            try {
                let profileInfo = await getWebtoolProviderProfile(params,signal);
                handleUnauthorizedRequest(profileInfo.status);
                if (profileInfo.ok) {
                    let parsedProfileInfo = await profileInfo.json();
                    dispatch(receiveProviderProfile(parsedProfileInfo.data.getProfiles));
                } else {
                    // throw new Error();
                }
            } catch (ex) {
                dispatch(failureProviderProfile());
            }
        }
    };
};

export const createProfile = params => {
    return async dispatch => {
        dispatch(requestCreateProfile());
        if (!params.input.providerid) {
        } else {
            try {
                let profileInfo = await webtoolcreateProfile(params);
                handleUnauthorizedRequest(profileInfo.status);
                if (profileInfo.ok) {
                    let parsedProfileInfo = await profileInfo.json();
                    dispatch(receiveCreateProfile(parsedProfileInfo.data.createProfile));
                } else {
                    throw new Error();
                }
            } catch (ex) {
                dispatch(failureCreateProfile());
            }
        }
    };
};

export const getProfileMeasurePreference = (params,signal) => {
    return async dispatch => {
        dispatch(requestgetProfileMeasurePreference());

        try {
            let profilePref = await getWebtoolProfileMeasurePreference(params,signal);
            handleUnauthorizedRequest(profilePref.status);
            if (profilePref.ok) {
                let parsedProfileInfo = await profilePref.json();

                let measureList = [];
                parsedProfileInfo.data.getProfileMeasurePreferences.map(measure => {
                    measureList.push(measure.measureid);
                });
                dispatch(receivegetProfileMeasurePreference(measureList));
            } else {
                // throw new Error();
            }
        } catch (ex) {
            dispatch(failuregetProfileMeasurePreference());
        }
    };
};

export const setProfileMeasurePreference = params => {
    return async dispatch => {
        dispatch(requestProfileMeasurePreference(params.input.measureidList));
        try {
            let profilePref = await setWebtoolProfileMeasurePreference(params);
            handleUnauthorizedRequest(profilePref.status);
            if (profilePref.ok) {
                let parsedProfileInfo = await profilePref.json();

                dispatch(receiveProfileMeasurePreference(parsedProfileInfo));
            } else {
                throw new Error();
            }
        } catch (ex) {
            dispatch(failureProfileMeasurePreference());
        }
    };
};
