import { getActionCreator, getActionType, getActionTypes, getReducer } from '@/utils/redux';

import { getPlanApi, createPlanApi ,deletePlanApi } from '@/redux/services/improvementPlansApi';
import { getMeasuresList } from '@/redux/services/practiceMeasureApi';

// Types
	
export const MeasuresTypes = getActionTypes('MEASURES');
export const CreatePlanType = getActionTypes('CREATEPLANTYPE');
export const GetPlanTypes = getActionTypes('GETPLANTYPES');
export const DeletePlanType = getActionType('DELETEPLANTYPE');

export const getPlanAction = queryParam => ({
    types: GetPlanTypes,
    callAPI: () => getPlanApi(queryParam)
});

export const deletePlanAction = queryParam => ({
    types: DeletePlanType,
    callAPI: () => deletePlanApi(queryParam)
});

export const getMeasureListAction = (queryParam) => ({	
    types: MeasuresTypes,	
    callAPI: () => getMeasuresList(queryParam)	
});

const initialState = {
    isSubscriberLoading:false,
    planList: [],
    isMeasureListLoading:false,	
    measureList:[],
    error: {
        code: '',
        Message: ''
    }
};

// Reducer
export default getReducer(initialState, {
    [GetPlanTypes.READY]: (state, { payload }) => ({
        ...state,
        isSubscriberLoading: true
    }),
    [GetPlanTypes.SUCCESS]: (state, { payload }) => ({
        ...state,
        planList: payload.subscribers,
        isSubscriberLoading: false
    }),
    [DeletePlanType.SUCCESS]: (state, { payload }) => ({
        ...state,
        planList: payload
    }),
    [MeasuresTypes.READY]: (state, { payload }) => ({	
        ...state,	
        isMeasureListLoading: true	
    }),	
    [MeasuresTypes.SUCCESS]: (state, { payload }) => ({	
        ...state,	
        measureList: payload ? payload.getMeasures :[],	
        isMeasureListLoading: false	
    }),
    [GetPlanTypes.ERROR]: () => initialState
});