import { getJwt } from '@/utils/jwtUtils';
import APIHelper from '@/helper/apihelper';
import { GET_ALL_FILES, DELETE_FILE, DOWNLOAD_FILE } from '@/graphql/query';

const api = new APIHelper();

export const deleteFile = params => {
    const requestOption = api.getRequestOption(DELETE_FILE, null, api.Actions.View, getJwt());
    return api.PegasusAPI(api.Resources.FileUpload, requestOption, response => response);
};

export const getAllFiles = (params, signal) => {
    const api = new APIHelper();
    let requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Action: api.Actions.View,
            Authorization: getJwt(),
            Resource: 'fileupload'
        }),
        body: JSON.stringify({ query: GET_ALL_FILES, variables: params })
    };

    if (signal) {
        requestOptions = {...requestOptions, signal};
    }

    //api.Resources.FileUpload
    return fetch(api.Resources.FileUpload, requestOptions);
};
export const downloadFileApi = params => {
    const api = new APIHelper();
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Action: api.Actions.View,
            Authorization: getJwt(),
            Resource: 'fileupload'
        }),
        body: JSON.stringify({ query: DOWNLOAD_FILE, variables: params })
    };

    return fetch(api.Resources.FileUpload, requestOptions);
};
