import { addVisit, updateVisit } from '../services/editVisitApi';
import {
    deleteMeasures,
    deleteSoftVisit,
    getCalendar,
    getInsuranceType,
    getLocations,
    getPatientVisitList,
    getPatientsDemographics,
    getVisitProfileMeasurePreference,
    getVisitProviderProfile,
    getVisits,
    searchVisits,
    getMeasureSet
} from '../services/visitApi';
import { getPractices, getProviderPaymentStatus } from '../services/QualityWebtoolMeasureService';

import { PAYMENTFOR_WEBTOOL_PROVIDER } from '@/helper/constants';
import _ from 'lodash';
import { getClinicianData } from '../services/clinicianPerformanceApi';
import { handleUnauthorizedRequest } from '@/containers/Webtool/webtoolUtils';
import moment from 'moment';

export const VISITS_REQUEST = 'VISITS_REQUEST';
export const VISITS_RECEIVE = 'VISITS_RECEIVE';
export const VISITS_FAILURE = 'VISITS_FAILURE';
export const VISITS_SELECTION = 'VISITS_SELECTION';
export const VISITS_SEARCH = 'VISITS_SEARCH';
export const VISITS_MEASUREDETAILS = 'VISITS_MEASUREDETAILS';
export const VISIT_CHANGE = 'VISIT_CHANGE';
export const VISITS_EDIT = 'VISITS_EDIT';
export const VISITS_MEASURE_QUERY_PARAM = 'VISITS_MEASURE_QUERY_PARAM';
export const VISITS_UPDATE = 'VISITS_UPDATE';
export const VISITS_ADD = 'VISITS_ADD';

export const VISITS_SETSELECTEDCLINICIAN = 'VISITS_SETSELECTEDCLINICIAN';
export const VISITS_ADD_ACTION = 'VISITS_ADD_ACTION';
export const UPDATE_PRACTICE_VISIT = 'UPDATE_PRACTICE_VISIT';
export const UPDATE_PROVIDER_VISIT = 'UPDATE_PROVIDER_VISIT';
export const UPDATE_YEAR_VISIT = 'UPDATE_YEAR_VISIT';

export const ADD_VISIT_REQUEST = 'ADD_VISIT_REQUEST';
export const ADD_VISIT_RECEIVE = 'ADD_VISIT_RECEIVE';
export const ADD_VISIT_FAILURE = 'ADD_VISIT_FAILURE';

export const UPDATE_VISIT_REQUEST = 'UPDATE_VISIT_REQUEST';
export const UPDATE_VISIT_RECEIVE = 'UPDATE_VISIT_RECEIVE';
export const UPDATE_VISIT_FAILURE = 'UPDATE_VISIT_FAILURE';

export const EDIT_VISITS_RECEIVE = 'EDIT_VISITS_RECEIVE';
export const PATIENT_VISIT_LIST = 'PATIENT_VISIT_LIST';

export const VISIT_PRACTICE_CHANGE = 'VISIT_PRACTICE_CHANGE';
export const VISIT_CLINICIAN_CHANGE = 'VISIT_CLINICIAN_CHANGE';

export const REQUEST_SINGLE_VISIT = 'REQUEST_SINGLE_VISIT';
export const RECEIVE_SINGLE_VISIT = 'RECEIVE_SINGLE_VISIT';
export const FAILURE_SINGLE_VISIT = 'FAILURE_SINGLE_VISIT';

export const RECEIVE_VISIT = 'RECEIVE_VISIT';

export const WEBTOOL_PROVIDERPAYMENT_REQUEST = 'WEBTOOL_PROVIDERPAYMENT_REQUEST';
export const WEBTOOL_PROVIDERPAYMENT_RECEIVE = 'WEBTOOL_PROVIDERPAYMENT_RECEIVE';
export const WEBTOOL_PROVIDERPAYMENT_FAILURE = 'WEBTOOL_PROVIDERPAYMENT_FAILURE';
export const UPDATE_FILTERED_PROVIDER = 'UPDATE_FILTERED_PROVIDER';

export const INSURANCETYPES_REQUEST = 'INSURANCETYPES_REQUEST';
export const INSURANCETYPES_RECEIVE = 'INSURANCETYPES_RECEIVE';
export const INSURANCETYPES_FAILURE = 'INSURANCETYPES_FAILURE';

export const LOCATIONS_REQUEST = 'LOCATIONS_REQUEST';
export const LOCATIONS_RECEIVE = 'LOCATIONS_RECEIVE';
export const LOCATIONS_FAILURE = 'LOCATIONS_FAILURE';

export const requestVisits = () => ({
    type: VISITS_REQUEST
});

export const receiveVisits = payload => ({
    type: VISITS_RECEIVE,
    payload
});

// eslint-disable-next-line no-unused-vars
const receiveVisitDetails = payload => ({
    type: EDIT_VISITS_RECEIVE,
    payload
});

export const failureVisits = () => ({
    type: VISITS_FAILURE
});

// export const addVisit = payload => ({
//     type: VISITS_ADD,
//     payload
// });

// export const updateVisit = payload => ({
//     type: VISITS_UPDATE,
//     payload
// });

export const editVisit = payload => ({
    type: VISITS_EDIT,
    payload
});

export const measureQueryParam = payload => ({
    type: VISITS_MEASURE_QUERY_PARAM,
    payload
});

export const receiveSearchVisit = payload => ({
    type: VISITS_SEARCH,
    payload
});

const requestVisit = () => ({
    type: ADD_VISIT_REQUEST
});

const receiveVisit = payload => ({
    type: ADD_VISIT_RECEIVE,
    payload
});

const failureVisit = () => ({
    type: ADD_VISIT_FAILURE
});

const requestUpdateVisit = () => ({
    type: UPDATE_VISIT_REQUEST
});

const receiveUpdateVisit = payload => ({
    type: UPDATE_VISIT_RECEIVE,
    payload
});

const failureUpdateVisit = () => ({
    type: UPDATE_VISIT_FAILURE
});

export const setSelectedPractice = payload => ({
    type: VISIT_PRACTICE_CHANGE,
    payload
});

export const setSelectedClinician = payload => ({
    type: VISIT_CLINICIAN_CHANGE,
    payload
});

export const measureDetailVisit = payload => ({
    type: VISITS_MEASUREDETAILS,
    payload
});

const requestSingleVisit = payload => ({
    type: REQUEST_SINGLE_VISIT
});

const receiveSingleVisit = payload => ({
    type: RECEIVE_SINGLE_VISIT,
    payload
});

const receiveVisitInfo = payload => ({
    type: RECEIVE_VISIT,
    payload
});

const failureSingleVisit = () => ({
    type: FAILURE_SINGLE_VISIT
});

export const updatefilteredProvider = payload => ({
    type: UPDATE_FILTERED_PROVIDER,
    payload
});

const requestProviderPaymentStatus = () => ({
    type: WEBTOOL_PROVIDERPAYMENT_REQUEST
});

const receiveProviderPaymentStatus = payload => ({
    type: WEBTOOL_PROVIDERPAYMENT_RECEIVE,
    payload
});

const failureProviderPaymentStatus = () => ({
    type: WEBTOOL_PROVIDERPAYMENT_FAILURE
});

export const getProviderPaymentStatusList = params => {
    return async dispatch => {
        dispatch(requestProviderPaymentStatus());
        try {
            let paymentInfo = await getProviderPaymentStatus(params);
            handleUnauthorizedRequest(paymentInfo.status);
            if (paymentInfo.ok) {
                let parsedPaymentInfo = await paymentInfo.json();
                dispatch(receiveProviderPaymentStatus(parsedPaymentInfo.data.getPayments));
            } else {
                throw new Error();
            }
        } catch (ex) {
            dispatch(failureProviderPaymentStatus());
        }
    };
};

export const addVisitData = params => {
    return async dispatch => {
        dispatch(requestVisit());
        try {
            let resp = await addVisit(params);
            handleUnauthorizedRequest(resp.status);
            if (resp.ok) {
                const parsedResp = await resp.json();
                let response = parsedResp.data.createVisit;
                if (parsedResp.errors) {
                    response = parsedResp.errors[0];
                }
                dispatch(
                    receiveVisit({
                        ...params.input,
                        ...response
                    })
                );
            } else {
                throw new Error('Invalid input provided');
            }
        } catch (ex) {
            dispatch(failureVisit());
        }
    };
};

export const updateVisitData = params => {
    return async dispatch => {
        dispatch(requestUpdateVisit());
        try {
            let resp = await updateVisit(params);
            handleUnauthorizedRequest(resp.status);
            if (resp.ok) {
                const parsedResp = await resp.json();
                let response = parsedResp.data.createVisit;
                if (parsedResp.errors) {
                    response = parsedResp.errors[0];
                }
                dispatch(
                    receiveUpdateVisit({
                        ...params.input,
                        ...response
                    })
                );
            } else {
                throw new Error('Invalid input provided');
            }
        } catch (ex) {
            dispatch(failureUpdateVisit());
        }
    };
};

export const selectionVisit = payload => ({
    type: VISITS_SELECTION,
    payload
});

export const selectVisit = payload => ({
    type: VISITS_SELECTION,
    payload
});

export const addVisitAction = payload => ({
    type: VISITS_ADD_ACTION,
    payload
});

export const updateSelectedPracticeforVisits = payload => ({
    type: UPDATE_PRACTICE_VISIT,
    payload
});

export const updateSelectedPracticeforVisit = payload => {
    return dispatch => {
        dispatch(updateSelectedPracticeforVisits(payload));
    };
};

export const updateSelectedProviderforVisits = payload => ({
    type: UPDATE_PROVIDER_VISIT,
    payload
});

export const updateSelectedProviderforVisit = payload => {
    return dispatch => {
        dispatch(updateSelectedProviderforVisits(payload));
    };
};

export const updateSelectedYearforVisits = payload => ({
    type: UPDATE_YEAR_VISIT,
    payload
});

export const updateSelectedYearforVisit = payload => {
    return dispatch => {
        dispatch(updateSelectedYearforVisits(payload));
    };
};

export const displayLoaderForVisitList = () => {
    return async dispatch => {
        dispatch(requestVisits());
    };
};

export const getVisitData = (params, signal) => {
    return async dispatch => {
        dispatch(requestVisits());
        try {
            let data = await getVisits(params, signal);
            if (data.data.getPatientVisitsWebtool) {
                const visitData = data.data.getPatientVisitsWebtool;
                dispatch(receiveVisits(visitData));
            } else {
                dispatch(failureVisits());
            }
        } catch (ex) {
            dispatch(failureVisits());
        }
    };
};

/**
 * THIS SECTION USED GET GET YEARS
 * ADDED BY - RAVIRAJ CHOUGULE
 */
export const VISIT_YEAR_FAILURE = 'VISIT_YEAR_FAILURE';
export const VISIT_YEAR_REQUEST = 'VISIT_YEAR_REQUEST';
export const VISIT_YEAR_RECEIVE = 'VISIT_YEAR_RECEIVE';
export const VISIT_YEAR_CHANGE = 'VISIT_YEAR_CHANGE';
export const VISIT_SELECTEDVISIT = 'VISIT_SELECTEDVISIT';

const failureVisitYear = () => ({
    type: VISIT_YEAR_FAILURE
});

const requestVisitYear = () => ({
    type: VISIT_YEAR_REQUEST
});

const receiveVisitYear = payload => ({
    type: VISIT_YEAR_RECEIVE,
    payload
});

export const setSelectedVisitYear = payload => ({
    type: VISIT_YEAR_CHANGE,
    payload
});

export const setSelectedVisitYearPracticeProvider = payload => ({
    type: VISIT_SELECTEDVISIT,
    payload
});

export const getYearsData = (signal) => {
    return async dispatch => {
        dispatch(requestVisitYear());
        try {
            let resp = await getCalendar(signal);
            handleUnauthorizedRequest(resp.status);
            if (resp.ok) {
                let parsedResponse = await resp.json();
                const calendarData = parsedResponse.data.getCalendars;
                let year = [];
                let _year = [];
                // eslint-disable-next-line no-unused-vars
                let duration = [];
                // eslint-disable-next-line no-unused-vars
                let _duration = [];
                calendarData.map((obj, index) => {
                    if (_year.indexOf(obj.year) === -1) {
                        _year.push(obj.year);
                        year.push({
                            id: obj.id,
                            text: obj.year,
                            value: obj.id
                        });
                    }
                });

                dispatch(receiveVisitYear(year));
            } else {
                throw new Error();
            }
        } catch (ex) {
            dispatch(failureVisitYear());
        }
    };
};

/**
 * THIS SECTION USED GET GET PRACTICE
 * ADDED BY - RAVIRAJ CHOUGULE
 */
export const VISIT_PRACTICES_REQUEST = 'VISIT_PRACTICES_REQUEST';
export const VISIT_PRACTICES_RECEIVE = 'VISIT_PRACTICES_RECEIVE';
export const VISIT_PRACTICES_FAILURE = 'VISIT_PRACTICES_FAILURE';

const requestPractices = () => ({
    type: VISIT_PRACTICES_REQUEST
});

const receivePractices = payload => {
    return {
        type: VISIT_PRACTICES_RECEIVE,
        payload
    };
};

const failurePractices = () => ({
    type: VISIT_PRACTICES_FAILURE
});

export const getPracticeData = (param,signal) => {
    return async dispatch => {
        dispatch(requestPractices());
        try {
            let resp = await getPractices(param,signal);
            handleUnauthorizedRequest(resp.status);
            if (resp.ok) {
                let practiceData = await resp.json();

                dispatch(receivePractices(practiceData.data.getPractices));
            } else {
                throw new Error();
            }
        } catch (ex) {
            dispatch(failurePractices());
        }
    };
};

/**
 * THIS SECTION USED GET GET PROVIDER
 * ADDED BY - RAVIRAJ CHOUGULE
 */
export const VISIT_CLINICIANS_REQUEST = 'VISIT_CLINICIANS_REQUEST';
export const VISIT_CLINICIANS_RECEIVE = 'VISIT_CLINICIANS_RECEIVE';
export const VISIT_CLINICIANS_FAILURE = 'VISIT_CLINICIANS_FAILURE';

const requestClinicians = () => ({
    type: VISIT_CLINICIANS_REQUEST
});

const receiveClinicians = payload => {
    return {
        type: VISIT_CLINICIANS_RECEIVE,
        payload
    };
};

const failureClinicians = () => ({
    type: VISIT_CLINICIANS_FAILURE
});

export const getClinicians = params => {
    return async dispatch => {
        dispatch(requestClinicians());
        try {
            let resp = await getClinicianData(params);
            handleUnauthorizedRequest(resp.status);
            let clinicianData = [];
            if (resp.data.getProvidersByPracticeId) {
                clinicianData = resp.data.getProvidersByPracticeId;

                if (
                    PAYMENTFOR_WEBTOOL_PROVIDER === 'true' &&
                    clinicianData &&
                    clinicianData.length
                ) {
                    let arrProviderId = [];
                    clinicianData.map(nextProvider => {
                        arrProviderId.push(nextProvider.id.toString());
                    });

                    const queryParams = {
                        input: {
                            entityidIn: arrProviderId || [],
                            entitynameLikeNoCase: 'provider',
                            parententityid: params.input.practiceid.toString() || '',
                            parententitynameLikeNoCase: 'practice'
                        }
                    };

                    dispatch(requestProviderPaymentStatus());

                    clinicianData = await getPaymentStatusForProvider(
                        queryParams,
                        clinicianData,
                        dispatch
                    );
                }

                dispatch(receiveClinicians(clinicianData));
            } else {
                dispatch(receiveClinicians(clinicianData));
            }
        } catch (ex) {
            dispatch(failureClinicians());
        }
    };
};

const getPaymentStatusForProvider = async (
    providerPaymentQueryParamsWithId,
    providers,
    dispatch
) => {
    try {
        let paymentInfo = await getProviderPaymentStatus(providerPaymentQueryParamsWithId);
        handleUnauthorizedRequest(paymentInfo.paymentInfo);
        if (paymentInfo.ok) {
            let parsedPaymentInfo = await paymentInfo.json();

            dispatch(receiveProviderPaymentStatus(parsedPaymentInfo));

            let arrPaymentDoneId = getPaymentDoneId(parsedPaymentInfo.data.getPayments);

            const filteredData = _.filter(providers, next => arrPaymentDoneId.includes(next.id));

            return filteredData;
        } else {
            throw new Error();
        }
    } catch (ex) {
        dispatch(failureProviderPaymentStatus());
    }
};

const getPaymentDoneId = list => {
    let arrPaymentDoneId = [];
    _.map(list, next => {
        if (next.paymentstatus == 1) {
            arrPaymentDoneId.push(parseInt(next.entityid));
        }
    });

    return arrPaymentDoneId;
};

/**
 * THIS SECTION USED GET GET PROVIDER PROFILE
 * ADDED BY - RAVIRAJ CHOUGULE
 */
export const VISIT_PROVIDERPROFILE_REQUEST = 'VISIT_PROVIDERPROFILE_REQUEST';
export const VISIT_PROVIDERPROFILE_RECEIVE = 'VISIT_PROVIDERPROFILE_RECEIVE';
export const VISIT_PROVIDERPROFILE_FAILURE = 'VISIT_PROVIDERPROFILE_FAILURE';

const requestProviderProfile = () => ({
    type: VISIT_PROVIDERPROFILE_REQUEST
});

const receiveProviderProfile = payload => ({
    type: VISIT_PROVIDERPROFILE_RECEIVE,
    payload
});

const failureProviderProfile = () => ({
    type: VISIT_PROVIDERPROFILE_FAILURE
});

export const getProviderProfile = (params,signal) => {
    return async dispatch => {
        dispatch(requestProviderProfile());
        try {
            let profileInfo = await getVisitProviderProfile(params,signal);
            handleUnauthorizedRequest(profileInfo.status);
            if (profileInfo.ok) {
                let parsedProfileInfo = await profileInfo.json();
                dispatch(receiveProviderProfile(parsedProfileInfo.data.getProfiles));
            } else {
                throw new Error();
            }
        } catch (ex) {
            dispatch(failureProviderProfile());
        }
    };
};

/**
 * THIS SECTION USED GET GET PROVIDER PROFILE PREFERENCE
 * ADDED BY - RAVIRAJ CHOUGULE
 */
export const VISIT_GETPROVIDERPROFILEPREFERENCE_REQUEST =
    'VISIT_GETPROVIDERPROFILEPREFERENCE_REQUEST';
export const VISIT_GETPROVIDERPROFILEPREFERENCE_RECEIVE =
    'VISIT_GETPROVIDERPROFILEPREFERENCE_RECEIVE';
export const VISIT_GETPROVIDERPROFILEPREFERENCE_FAILURE =
    'VISIT_GETPROVIDERPROFILEPREFERENCE_FAILURE';

const requestGetProfileMeasurePreference = () => ({
    type: VISIT_GETPROVIDERPROFILEPREFERENCE_REQUEST
});

const receiveGetProfileMeasurePreference = payload => ({
    type: VISIT_GETPROVIDERPROFILEPREFERENCE_RECEIVE,
    payload
});

const failureGetProfileMeasurePreference = () => ({
    type: VISIT_GETPROVIDERPROFILEPREFERENCE_FAILURE
});

export const getProfileMeasurePreference = (params,signal) => {
    return async dispatch => {
        dispatch(requestGetProfileMeasurePreference());

        try {
            let profilePref = await getVisitProfileMeasurePreference(params,signal);
            handleUnauthorizedRequest(profilePref.status);
            if (profilePref.ok) {
                let parsedProfileInfo = await profilePref.json();
                let measureList = [];
                parsedProfileInfo.data.getProfileMeasurePreferences.map(measure => {
                    measureList.push(measure.measureno);
                });
                dispatch(receiveGetProfileMeasurePreference(measureList));
            } else {
                throw new Error();
            }
        } catch (ex) {
            dispatch(failureGetProfileMeasurePreference());
        }
    };
};

export const VISIT_SOFTDELETE_FAILURE = 'VISIT_SOFTDELETE_FAILURE';
export const VISIT_SOFTDELETE_REQUEST = 'VISIT_SOFTDELETE_REQUEST';
export const VISIT_SOFTDELETE_RECEIVE = 'VISIT_SOFTDELETE_RECEIVE';
export const VISIT_SOFTDELETE_RECEIVE_BUT_ERROR = 'VISIT_SOFTDELETE_RECEIVE_BUT_ERROR';

const failureSoftDeleteVisit = () => ({
    type: VISIT_SOFTDELETE_FAILURE
});

const requestSoftDeleteVisit = () => ({
    type: VISIT_SOFTDELETE_REQUEST
});

const receiveSoftDeleteVisit = payload => ({
    type: VISIT_SOFTDELETE_RECEIVE,
    payload
});

const receiveWithErrorSoftDeleteVisit = payload => ({
    type: VISIT_SOFTDELETE_RECEIVE_BUT_ERROR,
    payload
});

export const deleteSoftVisitbyId = params => {
    return async dispatch => {
        dispatch(requestSoftDeleteVisit());
        try {
            let resp = await deleteSoftVisit(params);
            handleUnauthorizedRequest(resp.status);
            if (resp.data.deleteVisit) {
                let visitUid = resp.data.deleteVisit.VisitUid;
                dispatch(receiveSoftDeleteVisit(visitUid));
            } else {
                dispatch(receiveWithErrorSoftDeleteVisit(resp.errors));
                dispatch(failureSoftDeleteVisit());
            }
        } catch (ex) {
            dispatch(failureSoftDeleteVisit());
        }
    };
};

export const deleteMeasureData = params => {
    return async dispatch => {
        //dispatch(requestSoftDeleteVisit());
        try {
            let resp = await deleteMeasures(params);
            handleUnauthorizedRequest(resp.status);
            if (resp.data.deleteVisit.VisitUid) {
                let visitUid = resp.data.deleteVisit.VisitUid;
            } else {
                //dispatch(failureSoftDeleteVisit());
            }
        } catch (ex) {
            //dispatch(failureSoftDeleteVisit());
        }
    };
};

export const searchVisitData = () => {
    return dispatch => {
        dispatch(requestVisits());
        searchVisits()
            .then(res => res.json())
            .then(json => {
                dispatch(receiveSingleVisit(json.ResultInfo.Data.VisitInfo));
            })
            .catch(ex => {
                dispatch(failureVisits());
            });
    };
};

export const receivedPatientVisits = payload => ({
    type: PATIENT_VISIT_LIST,
    payload
});

export const fetchListOfPatientVisits = params => {
    return async dispatch => {
        try {
            let resp = await getPatientVisitList(params);
            handleUnauthorizedRequest(resp.status);
            if (resp.data.getVisits.length) {
                dispatch(receivedPatientVisits(resp.data.getVisits));
            } else {
                throw Error();
            }
        } catch (ex) {
            dispatch(failureVisits());
        }
    };
};

export const getSingleVisit = (params,signal) => {
    return async dispatch => {
        dispatch(requestSingleVisit());
        try {
            let resp = await getVisits(params,signal);
            handleUnauthorizedRequest(resp.status);
            if (resp.data.getPatientVisitsWebtool) {
                let singleVisitData = resp.data.getPatientVisitsWebtool[0];
                if (singleVisitData.DOB && singleVisitData.VisitDate) {
                    var dob = moment(singleVisitData.DOB).startOf("day")
                    var visitDate = moment(singleVisitData.VisitDate).startOf("day")
                    var ageInMonths = visitDate.diff(dob, "months", true)
                    singleVisitData.Age = ageInMonths;
                }
                dispatch(receiveVisitInfo(singleVisitData));
            } else {
                dispatch(failureSingleVisit());
            }
        } catch (ex) {
            dispatch(failureSingleVisit());
        }
    };
};

const requestInsuranceTypes = () => ({
    type: INSURANCETYPES_REQUEST
});

const receiveInsuranceTypes = payload => ({
    type: INSURANCETYPES_RECEIVE,
    payload
});

const failureInsuranceTypes = () => ({
    type: INSURANCETYPES_FAILURE
});

export const getIsuranceTypeData = (params,signal) => {
    return async dispatch => {
        dispatch(requestInsuranceTypes());
        try {
            let insuranceTypeInfo = await getInsuranceType(params,signal);
            handleUnauthorizedRequest(insuranceTypeInfo.status);
            if (insuranceTypeInfo.ok) {
                let parsedInsuranceTypeInfo = await insuranceTypeInfo.json();
                dispatch(receiveInsuranceTypes(parsedInsuranceTypeInfo.data.getInsuranceTypes));
            } else {
                throw new Error();
            }
        } catch (ex) {
            dispatch(failureInsuranceTypes());
        }
    };
};

const requestLocations = () => ({
    type: LOCATIONS_REQUEST
});

const receiveLocations = payload => ({
    type: LOCATIONS_RECEIVE,
    payload
});

const failureLocations = () => ({
    type: LOCATIONS_FAILURE
});

export const getLocationData = (params,signal) => {
    return async dispatch => {
        dispatch(requestLocations());
        try {
            let locationInfo = await getLocations(params,signal);
            handleUnauthorizedRequest(locationInfo.status);
            if (locationInfo.ok) {
                let parsedlocationInfo = await locationInfo.json();
                dispatch(receiveLocations(parsedlocationInfo.data.getLocations));
            } else {
                throw new Error();
            }
        } catch (ex) {
            dispatch(failureLocations());
        }
    };
};

export const loadOptions = async params => {
    let searched = [];
    try {
        let practiceInfo = await getLocations(params);
        handleUnauthorizedRequest(practiceInfo.status);
        if (practiceInfo.ok) {
            let parsedpracticeInfo = await practiceInfo.json();
            return parsedpracticeInfo.data.getLocations;
        } else {
            throw new Error();
        }
    } catch (ex) {}
};

export const loadOptionsForInsuranceType = async params => {
    try {
        let insuranceTypeInfo = await getInsuranceType(params);
        handleUnauthorizedRequest(insuranceTypeInfo.status);
        if (insuranceTypeInfo.ok) {
            let parsedInsuranceTypeInfo = await insuranceTypeInfo.json();
            return parsedInsuranceTypeInfo.data.getInsuranceTypes;
        } else {
            throw new Error();
        }
    } catch (ex) {}
};

export const searchPatientsByMRN = async params => {
    let searched = [];
    try {
        let patientInfo = await getPatientsDemographics(params);
        handleUnauthorizedRequest(patientInfo.status);
        if (patientInfo.ok) {
            let parsedPatientInfo = await patientInfo.json();
            return parsedPatientInfo.data.getPatients;
        } else {
            throw new Error();
        }
    } catch (ex) {}
};

export const UPDATE_STORE_FOR_WEBTOOL = 'UPDATE_STORE_FOR_WEBTOOL';
const updateDataIntoStoreForWebtool = payload => ({
    type: UPDATE_STORE_FOR_WEBTOOL,
    payload
});

export const updateStoreForWebtool = params => {
    return async dispatch => {
        try {
            dispatch(updateDataIntoStoreForWebtool(params));
        } catch(ex) {
            console.log('Error in update data into store for webtool...!');
        }
    };
}

export const GET_MEASURE_SET_REQUEST = 'GET_MEASURE_SET_REQUEST';
export const GET_MEASURE_SET_RECEIVE = 'GET_MEASURE_SET_RECEIVE';
export const GET_MEASURE_SET_FAILURE = 'GET_MEASURE_SET_FAILURE';

const requestMeasureSets = () => ({
    type: GET_MEASURE_SET_REQUEST
});

const receiveMeasureSets = payload => ({
    type: GET_MEASURE_SET_RECEIVE,
    payload
});

const failureMeasureSets = () => ({
    type: GET_MEASURE_SET_FAILURE
});

export const getMeasureSetData = () => {
    return async dispatch => {
        dispatch(requestMeasureSets());
        try {
            let responseInfo = await getMeasureSet();
            dispatch(receiveMeasureSets(responseInfo.data.getMeasureSets));
        } catch (ex) {
            dispatch(failureMeasureSets());
        }
    };
};