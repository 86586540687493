import {
    MEASURES_FAILURE,
    MEASURES_RECEIVE,
    MEASURES_REQUEST,
    SAVED_ANSWER,
    SAVING_ANSWER,
    RECV_DISPLAY_NAMES,
    DELETING_MEASURE_QUESTIONNAIRE,
    DELETED_MEASURE_QUESTIONNAIRE,
    DELETED_ALL_MEASURE_QUESTIONNAIRE,
    CLEAR_STORE
} from '@/redux/actions/fetchMeasuresActions';

const initialState = {
    isLoading: false,
    isFailure: false,
    measures: null,
    isSaving: null,
    isSaved: null,
    errorMessage: null,
    displayMeasureNames: null,
    isDeleting: false,
    deleteMeasureQuestionnaireResponse: null,
    deleteAllMeasureQuestionnaireResponse: null
};

const measuresReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_STORE:
            return {
                ...initialState
            };
        case MEASURES_REQUEST:
            return {
                ...state,
                isLoading: true,
                isFailure: false,
                isSaving: null,
                isSaved: null
            };
        case MEASURES_RECEIVE:
            return {
                ...state,
                isLoading: false,
                isFailure: false,
                isSaving: null,
                isSaved: null,
                measures: action.payload
            };
        case MEASURES_FAILURE:
            return {
                ...state,
                isLoading: false,
                isFailure: true,
                isDeleting: false,
                deleteMeasureQuestionnaireResponse: null,
                deleteAllMeasureQuestionnaireResponse: null,
                errorMessage: action.errorMessage
            };
        case SAVING_ANSWER:
            return {
                ...state,
                isSaving: true,
                isSaved: false
            };
        case SAVED_ANSWER:
            return {
                ...state,
                isLoading: false,
                isFailure: false,
                errorMessage: null,
                isSaving: false,
                isSaved: true
            };
        case RECV_DISPLAY_NAMES:
            return {
                ...state,
                isLoading: false,
                isFailure: false,
                errorMessage: null,
                isSaving: false,
                isSaved: false,
                displayMeasureNames: action.payload
            };
        case DELETING_MEASURE_QUESTIONNAIRE:
            return {
                ...state,
                isDeleting: true
            };
        case DELETED_MEASURE_QUESTIONNAIRE:
            return {
                ...state,
                isDeleting: false,
                deleteMeasureQuestionnaireResponse: action.payload
            };
        case DELETED_ALL_MEASURE_QUESTIONNAIRE:
            return {
                ...state,
                isDeleting: false,
                deleteAllMeasureQuestionnaireResponse: action.payload
            };
        default:
            return state;
    }
};

export default measuresReducer;
