import './LoginBranding.less';

import { Grid, Typography } from '@material-ui/core';

import PropTypes from 'prop-types';
import React from 'react';

const MainLogo = require(`@/${process.env.REGISTRY_LOGIN_PAGE_LOGO_FILEPATH}`);

/* THIS IS LOGIN BRANDING COMPONENT USED TO SHOW LOGO AND OBJECTIVE OR TAGLINE
  ================================================================ */
const LoginBranding = props => {
    const { loginleftProp } = props;
    const { logo, showtagline, tagline, showinfo, info } = loginleftProp;

    var maxLength = 300;

    let newInfo = '';
    if (info.length > maxLength) {
        for (var i = 0; i < maxLength; i++) {
            if (info.charAt(i) === 'Ω') {
                maxLength = maxLength + 1;
            }
        }
        newInfo = info.substr(0, maxLength);
    } else {
        newInfo = info;
    }

    return (
        <Grid container justify='center' alignItems='center' className='login__wrapper-1'>
            <Grid item className='login__contanier'>
                <Grid item xs={12} className='login__logo'>
                    <img src={MainLogo} alt={logo} />
                </Grid>
                <Grid item className='login__contanier-info'>
                    {showtagline && (
                        <Typography variant='h3' className='login--title__h3'>
                            {tagline}
                        </Typography>
                    )}
                    {/* <Typography component='h4' className='login--title__h4'>
                        {first}
                    </Typography> */}
                    {showinfo &&
                        newInfo.split('Ω').map((data, index) => (
                            <Typography key={index} component='h5' className='login--title__h5'>
                                {data}
                            </Typography>
                        ))}
                </Grid>
            </Grid>
        </Grid>
    );
};

/* LOGIN BRANDING DEFAULT PROPS:
  ================================================================ */
LoginBranding.defaultProps = {
    loginleftProp: {
        logo: 'LOGO',
        // path: '../../../assets/media/img/logo/figmd_logo.svg',
        showtagline: false,
        tagline: 'The goal is to turn data into information, and information into insight',
        showinfo: false,
        info: ['FIGmd-abc@figmd.in']
    }
};

/* PROP TYPES:
================================================================ */
LoginBranding.propTypes = {
    loginleftProp: PropTypes.shape({
        logo: PropTypes.string,
        showtagline: PropTypes.bool,
        tagline: PropTypes.string,
        showinfo: PropTypes.bool,
        info: PropTypes.string
    })
};
export default LoginBranding;
