import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
let ns = 'registryConfig';

i18n
    // load translation using xhr -> see /public/locales
    // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        lng: 'en',
        fallbackLng: false,
        preload: ['en'],
        load: 'languageOnly',
        debug: false,
        ns: [ns, 'translation'],
        fallbackNS: [ns, 'translation'],
        defaultNS: ns,
        // saveMissing: true, // send not translated keys to endpoint
        // keySeparator: false, // we do not use keys in form messages.welcome>
        backend: {
            // for all available options read the backend's repository readme file
            loadPath: process.env.PUBLIC_URL + `/locales/{{lng}}/{{ns}}.json`
            // addPath:
            //     (process.env.NODE_ENV === 'development' ? '' : '/mipsux') +
            //     '/locales/{{lng}}/{{ns}}.json'
        },
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        },
        react: {
            wait: true
        }
    });

export default i18n;
