import i18next from 'i18next';

import {
    deleteIA_PI_ElementApi,
    fetchMeasureListApi,
    getMeasureMetaDataApi,
    saveMeasureDetailsApi,
    setFavoriteActivityApi,
    getFilterTagsApi,
    getFilterTagDetailsApi
} from '@/redux/services/piMeasureApi';

import { handleUnauthorizedRequest } from '@/containers/Webtool/webtoolUtils';

export const MEASURES_REQUEST = 'MEASURES_REQUEST';
export const MEASURES_RECEIVE = 'MEASURES_RECEIVE';
export const MEASURES_FAILURE = 'MEASURES_FAILURE';
export const SAVING_ANSWER = 'SAVING_ANSWER';
export const SAVED_ANSWER = 'SAVED_ANSWER';
export const PI_MEASURE_META_DATA = 'PI_MEASURE_META_DATA';
export const DELETING_ELEMENT = 'DELETING_ELEMENT';
export const DELETED_ELEMENT = 'DELETED_ELEMENT';
export const MARKING_FAVORITE = 'MARKING_FAVORITE';
export const MARKED_FAVORITE = 'MARKED_FAVORITE';
export const FILTER_TAGS = 'FILTER_TAGS';
export const FILTER_TAG_DETAILS = 'FILTER_TAG_DETAILS';

const requestMeasures = () => ({
    type: MEASURES_REQUEST
});

const receiveMeasures = payload => ({
    type: MEASURES_RECEIVE,
    payload
});

const failureMeasures = errMsg => ({
    type: MEASURES_FAILURE,
    errorMessage: errMsg
});

const savingAnswer = () => ({
    type: SAVING_ANSWER
});

const savedAnswer = () => ({
    type: SAVED_ANSWER
});

const piMeasureMetaDataReceived = payload => ({
    type: PI_MEASURE_META_DATA,
    payload: payload
});

const deletingElement = () => ({
    type: DELETING_ELEMENT
});

const deletedElement = payload => ({
    type: DELETED_ELEMENT,
    payload: payload
});

const markingFavorite = () => ({
    type: MARKING_FAVORITE
});

const markedFavorite = payload => ({
    type: MARKED_FAVORITE,
    payload: payload
});

const filterTagsReceived = payload => ({
    type: FILTER_TAGS,
    payload: payload
});

const filterTagDetailsReceived = payload => ({
    type: FILTER_TAG_DETAILS,
    payload: payload
});

export const fetchMeasureList = params => {
    return async dispatch => {
        dispatch(requestMeasures());
        try {
            let measureInfo = await fetchMeasureListApi(params);
            handleUnauthorizedRequest(measureInfo.status);
            if (measureInfo.ok) {
                let parsedMeasureInfo = await measureInfo.json();
                dispatch(receiveMeasures(parsedMeasureInfo));
            } else {
                throw new Error();
            }
        } catch (error) {
            dispatch(failureMeasures());
        }
    };
};

export const saveMeasureDetails = params => {
    return async dispatch => {
        dispatch(savingAnswer());
        try {
            let resp = await saveMeasureDetailsApi(params);
            handleUnauthorizedRequest(resp.status);
            if (resp.ok) {
                let parsedResponse = await resp.json();
                if (parsedResponse.errors) {
                    let errorMsg = parsedResponse.errors[0].message;
                    let errorCode = parsedResponse.errors[0].statusCode;
                    switch (errorCode) {
                        case 403:
                            errorMsg = i18next.t('IA_PI_COMMON.errorMessages.dataCantBeUpdated');
                            break;
                        case 404:
                            errorMsg = i18next.t('IA_PI_COMMON.errorMessages.somethingWentWrong');
                            break; // data not found
                        default:
                            errorMsg = i18next.t('IA_PI_COMMON.errorMessages.somethingWentWrong');
                            break;
                    }
                    dispatch(failureMeasures(errorMsg));
                } else {
                    dispatch(savedAnswer());
                }
            } else {
                throw new Error();
            }
        } catch (error) {
            dispatch(failureMeasures(i18next.t('IA_PI_COMMON.errorMessages.somethingWentWrong')));
        }
    };
};

export const getMeasureMetaData = params => {
    return async dispatch => {
        dispatch(requestMeasures());
        try {
            let resp = await getMeasureMetaDataApi(params);
            handleUnauthorizedRequest(resp.status);
            if (resp.ok) {
                let parsedResponse = await resp.json();
                if (parsedResponse.errors) {
                    dispatch(
                        failureMeasures(i18next.t('IA_PI_COMMON.errorMessages.somethingWentWrong'))
                    );
                } else {
                    dispatch(piMeasureMetaDataReceived(parsedResponse));
                }
            } else {
                throw new Error();
            }
        } catch (error) {
            dispatch(failureMeasures(i18next.t('IA_PI_COMMON.errorMessages.somethingWentWrong')));
        }
    };
};

export const deleteIA_PI_Element = params => {
    return async dispatch => {
        dispatch(deletingElement());
        try {
            let resp = await deleteIA_PI_ElementApi(params);
            handleUnauthorizedRequest(resp.status);
            if (resp.ok) {
                let parsedResponse = await resp.json();
                if (parsedResponse.errors) {
                    dispatch(
                        failureMeasures(i18next.t('IA_PI_COMMON.errorMessages.somethingWentWrong'))
                    );
                } else {
                    dispatch(deletedElement(parsedResponse));
                }
            } else {
                throw new Error();
            }
        } catch (error) {
            dispatch(failureMeasures(i18next.t('IA_PI_COMMON.errorMessages.somethingWentWrong')));
        }
    };
};

export const markActivityAsFavorite = params => {
    return async dispatch => {
        dispatch(markingFavorite());
        try {
            let resp = await setFavoriteActivityApi(params);
            handleUnauthorizedRequest(resp.status);
            if (resp.ok) {
                let parsedResponse = await resp.json();
                if (parsedResponse.errors) {
                    dispatch(
                        failureMeasures(i18next.t('IA_PI_COMMON.errorMessages.somethingWentWrong'))
                    );
                } else {
                    dispatch(markedFavorite(parsedResponse));
                }
            } else {
                throw new Error();
            }
        } catch (error) {
            dispatch(failureMeasures(i18next.t('IA_PI_COMMON.errorMessages.somethingWentWrong')));
        }
    };
};

export const getFilterTags = params => {
    return async dispatch => {
        dispatch(requestMeasures());
        try {
            let resp = await getFilterTagsApi(params);
            handleUnauthorizedRequest(resp.status);
            if (resp.ok) {
                let parsedResponse = await resp.json();
                if (parsedResponse.errors) {
                    dispatch(
                        failureMeasures(i18next.t('IA_PI_COMMON.errorMessages.somethingWentWrong'))
                    );
                } else {
                    dispatch(filterTagsReceived(parsedResponse));
                }
            } else {
                throw new Error();
            }
        } catch (error) {
            dispatch(failureMeasures(i18next.t('IA_PI_COMMON.errorMessages.somethingWentWrong')));
        }
    };
};

export const getFilterTagDetails = params => {
    return async dispatch => {
        dispatch(requestMeasures());
        try {
            let resp = await getFilterTagDetailsApi(params);
            handleUnauthorizedRequest(resp.status);
            if (resp.ok) {
                let parsedResponse = await resp.json();
                if (parsedResponse.errors) {
                    dispatch(
                        failureMeasures(i18next.t('IA_PI_COMMON.errorMessages.somethingWentWrong'))
                    );
                } else {
                    dispatch(filterTagDetailsReceived(parsedResponse));
                }
            } else {
                throw new Error();
            }
        } catch (error) {
            dispatch(failureMeasures(i18next.t('IA_PI_COMMON.errorMessages.somethingWentWrong')));
        }
    };
};
