import Permissions, { ResetReduxStateType } from '@/redux/modules/userModule';
import CareGap from '@/redux/modules/careGap';
import ClinicianTrendPerformance from './modules/clinicianTrendPerformance';
import EntityModuleStatus from '@/redux/modules/entityModuleStatus';
import GroupDashboard from '@/redux/modules/groupDashboard';
import LocationDashboard from './modules/locationDashboard';
import LocationPMS from './modules/locationsPMS';
import LocationTrendPerformance from './modules/locationTrendPerformance';
import MeasureFilter from './modules/measureFilter';
import MeasurePeformanceReducer from './reducers/MeasurePerformanceReducer';
import PracticeDetail from './modules/practiceDetail';
import ProviderDashboard from './modules/providerDashboard';
import ProviderPMS from './modules/cliniciansPMS';
import QualityDashboard from './modules/qualityDashboard';
import RegistryMeasureList from './modules/registryMeasureList';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { fileUploadReducer } from '../redux/reducers/fileUploadReducer';
import getMeasureListReducer from '../redux/reducers/fetchMeasuresListReducer';
import patientReducer from './reducers/patientReducer';
import performanceTrendInfoReducer from './reducers/performanceTrendInfoReducer';
import piMeasureReducer from '@/redux/reducers/piMeasureReducer';
import practiceMeasureReducer from './reducers/practiceMeasureReducer';
import qualityWebtoolMeasureReducer from '../redux/reducers/QualityWebtoolMeasureReducer';
import userProfile from '@/redux/modules/userProfile';
import visitReducer from '../redux/reducers/visitReducer';
import practiceUser from '@/redux/modules/practiceUser';
import measureBulkUploadReducer from '@/redux/reducers/measureBulkUploadReducer';
import measurePerformance from '@/redux/modules/measurePerformance';
import ProgramDetails from '@/redux/modules/programDetails';
import ImprovementPlans from '@/redux/modules/improvementPlans';
import MessageInfo from '@/redux/modules/messageInfo';

export default history => {
    const reducer = combineReducers({
        router: connectRouter(history),
        PerformanceTrendInfo: performanceTrendInfoReducer,
        Patient: patientReducer,
        MeasurePeformanceData: MeasurePeformanceReducer,
        ClinicianTrendPerformance,
        LocationTrendPerformance,
        QualityDashboard,
        PracticeDetail,
        userProfile,
        MeasureFilter,
        LocationDashboard,
        ProviderDashboard,
        RegistryMeasureList,
        LocationMeasureList: practiceMeasureReducer,
        PracticeMeasureList: practiceMeasureReducer,
        ClinicianMeasureList: practiceMeasureReducer,
        MeasureList: getMeasureListReducer,
        visits: visitReducer,
        QualityMeasure: qualityWebtoolMeasureReducer,
        PIMeasures: piMeasureReducer,
        ProviderPMS,
        LocationPMS,
        Permissions,
        GroupDashboard,
        CareGap,
        fileUploadReducer,
        EntityModuleStatus,
        practiceUser,
        MeasureBulkUploadData: measureBulkUploadReducer,
        MeasurePerformanceList: measurePerformance,
        ProgramDetails: ProgramDetails,
        ImprovementPlans,
        MessageInfo
    });

    return (state, action) => {
        if (action.type === ResetReduxStateType) {
            return reducer(undefined, action);
        }
        return reducer(state, action);
    };
};
