import { render } from 'react-dom';
import Carousel from 'nuka-carousel';
import './styles.css';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper } from '@material-ui/core';
import SliderContentJson from '@/json/sliderContent.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, FormControl, FormControlLabel, FormGroup } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
const styles = theme => ({
    paper: {
        position: 'absolute',
        width: theme.spacing(150),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none'
    }
});

class SliderApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startSlide: false,
            autoplayInterval: 3000,
            autoplay: true,
            enableKeyboardControls: true
        };
    }
    componentDidMount() {
        this.setState({ startSlide: this.props.startSlide });
    }
    /**
     *will get executed after receining new props.
     *
     * @param {*} nextProps
     * @memberof SliderApp
     */
    componentWillReceiveProps(nextProps) {
        if (this.props.startSlide !== nextProps.startSlide) {
            this.setState({ startSlide: nextProps.startSlide });
        }
    }

    render() {
        const {
            classes,
            sliderData,
            onShowAgain,
            handleClose,
            isShowAgainChecked,
            isCheckbox
        } = this.props;
        const { startSlide, autoplayInterval, enableKeyboardControls } = this.state;
        return (
            <Grid container>
                <Grid item className='btn-tour--guid'>
                    <Button
                        disableRipple={true}
                        variant='outlined'
                        color='primary'
                        className='start-tour'
                        onClick={this.props.handleOpen}
                    >
                        Tour
                    </Button>
                </Grid>

                <Grid>
                    <Modal
                        className='SliderApp__modal'
                        aria-labelledby='simple-modal-title'
                        aria-describedby='simple-modal-description'
                        open={startSlide}
                        onClose={handleClose}
                        disableBackdropClick={true}
                        // disableEscapeKeyDown={true}
                    >
                        <Grid className={classes.paper} id='SliderApp'>
                            <IconButton
                                aria-label='Close'
                                className='close-icon'
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </IconButton>
                            <Carousel
                                // autoplay={autoplay}
                                enableKeyboardControls={enableKeyboardControls}
                                autoplayInterval={autoplayInterval}
                                renderCenterLeftControls={({ previousSlide, currentSlide }) =>
                                    currentSlide === 0 ? null : (
                                        <FontAwesomeIcon
                                            onClick={previousSlide}
                                            className='go--back__left-arrow arrow-controls'
                                            icon={['fal', 'arrow-left']}
                                        />
                                    )
                                }
                                renderCenterRightControls={({
                                    nextSlide,
                                    currentSlide,
                                    slideCount
                                }) =>
                                    currentSlide === slideCount - 1 ? null : (
                                        <FontAwesomeIcon
                                            onClick={nextSlide}
                                            className='go--back__right-arrow arrow-controls'
                                            icon={['fal', 'arrow-right']}
                                        />
                                    )
                                }
                            >
                                {sliderData !== null
                                    ? sliderData.map((data, index) => {
                                          return (
                                              <Grid
                                                  container
                                                  key={index}
                                                  className='sliderApp-container'
                                              >
                                                  <Grid item className='img__label--wrapper'>
                                                      <Grid className='sliderImage'>
                                                          {data.bannerImage && (
                                                              <img src={data.bannerImage} />
                                                          )}
                                                          {data.bannerIcon && (
                                                              <FontAwesomeIcon
                                                                  className='icon--img_upload'
                                                                  icon={data.bannerIcon}
                                                              />
                                                          )}
                                                      </Grid>
                                                      {data.label && (
                                                          <Grid item className='sliderLabel'>
                                                              {data.label}
                                                          </Grid>
                                                      )}
                                                  </Grid>
                                                  <Grid className='legend'>
                                                      {data.icons && (
                                                          <Grid className='sliderIcons'>
                                                              {data.icons.map(
                                                                  (iconValue, index) => (
                                                                      <FontAwesomeIcon
                                                                          key={index}
                                                                          className='go--back__left-arrow'
                                                                          icon={iconValue}
                                                                      />
                                                                  )
                                                              )}
                                                          </Grid>
                                                      )}
                                                      <Grid className='sliderDescription'>
                                                          {ReactHtmlParser(data.description)}
                                                      </Grid>

                                                      {data.showAgain && isCheckbox && (
                                                          <Grid className='sliderShowHide'>
                                                              <FormGroup row>
                                                                  <FormControlLabel
                                                                      control={
                                                                          <Checkbox
                                                                              onChange={onShowAgain}
                                                                              color='primary'
                                                                              id='box'
                                                                              checked={
                                                                                  isShowAgainChecked
                                                                              }
                                                                          />
                                                                      }
                                                                      label='Do not show this again'
                                                                  />
                                                              </FormGroup>
                                                          </Grid>
                                                      )}
                                                  </Grid>
                                              </Grid>
                                          );
                                      })
                                    : null}
                            </Carousel>
                        </Grid>
                    </Modal>
                </Grid>
            </Grid>
        );
    }
}
SliderApp.defaultProps = {
    sliderData: SliderContentJson.PI
};

export default withStyles(styles)(SliderApp);
