import Cookies from 'universal-cookie';
import APIHelper from '@/helper/apihelper';
import {
    DOMAIN_NAME,
    MINUS_TOKEN_TIME,
    SECURE_COOKIES,
    SAME_SITE,
    HTTP_ONLY,
    REGISTRY_UNIT_NAME,
    ENABLE_AUTHENTICATION_LINKS
} from '@/helper/constants';

export const getUnitName = () => {
    return getCookies('unit');
};
//const UnitName = getUnitName();

const apiHelperInstance = new APIHelper();

let flag = 0;
/* ================================================================
 -----------SET COOKIES WITH COOKIE NAME AND MAX-AGE--------------
================================================================ */
export const setJwt = (jwt, duration) => {
    flag = 0;
    setCookies('interval', jwt, duration * 60 - MINUS_TOKEN_TIME);
    setCookies('jwt-token', jwt, duration * 60);
    setCookies('unit', REGISTRY_UNIT_NAME, duration * 60);
};

/* ================================================================
 -----------GET COOKIE USING COOKIE NAME-------------------------
================================================================ */
export const getJwt = param => {
    if (!getCookies('interval')) {
        if (param !== undefined) {
            flag = 1;
        }
        if (getCookies('jwt-token') && param === undefined) {
            renewToken();
        }
    }
    return getCookies('jwt-token');
};

/* ================================================================
 -----------REMOVE COOKIE USING COOKIE NAME-------------------------
================================================================ */
export const removeJwt = () => {
    localStorage.clear();
    // setCookies('unit', '', 1);  // Changes done for Rajesh Parihar
    setCookies('interval', '', 1);
    return setCookies('jwt-token', '', 1);
};

/* ================================================================
 -----------SET COOKIES AS SECURE-------------------------
================================================================ */
export const isSecure = () => {
    return SECURE_COOKIES;
};

/* ================================================================
 -----------SET COOKIES AS ONLY SPECIFIC DOMAIN-------------------
================================================================ */
export const getdomain = () => {
    return DOMAIN_NAME;
};

/* ================================================================
 -----------SET COOKIES AS ONLY ACCESS FOR SAME SITE--------------
================================================================ */
export const getSameSite = () => {
    return SAME_SITE;
};

/* ================================================================
 -----------SET COOKIES AS HTTP ONLY--------------
================================================================ */
export const getHttpOnly = () => {
    return HTTP_ONLY;
};

/* ================================================================
 -----------PRIVATE ::::SET COOKIES ------------------------------
================================================================ */
function setCookies(name, value, timespan) {
    const cookies = new Cookies();
    cookies.set(name, value, {
        path: '/',
        secure: parseInt(isSecure()),
        maxAge: timespan,
        sameSite: getSameSite(),
        domain: getdomain(),
        httpOnly: parseInt(getHttpOnly())
    });
}

/* ================================================================
 -----------PRIVATE ::::GET COOKIES ------------------------------
================================================================ */
function getCookies(name) {
    const cookies = new Cookies();
    return cookies.get(name);
}

/* ================================================================
 -----------PRIVATE ::::REMOVE COOKIES ------------------------------
================================================================ */
// eslint-disable-next-line no-unused-vars
function removeCookies(name) {
    const cookies = new Cookies();
    return cookies.remove(name);
}

/* ================================================================
 --PRIVATE ::::THIS FUNCTION USED TO RENEW TOKEN-------------------
================================================================ */
function renewToken() {
    if (flag === 0) {
        flag = 1;
        fetch(apiHelperInstance.Resources.RenewToken, {
            headers: {
                Authorization: getCookies('jwt-token'),
                'Content-Type': 'application/json',
                Resource: 'defaultread',
                Action: 'View'
            }
        })
            .then(res => res.json())
            .then(data => {
                if (data.statusCode === 1) {
                    setJwt(data.data.token, data.data.duration);
                }
                flag = 0;
            })
            .catch(e => {
                flag = 1;
                // window.location.href = ENABLE_AUTHENTICATION_LINKS
                //     ? '/'
                //     : '/SSORouter?sessionexpired=true';

                if (
                    !ENABLE_AUTHENTICATION_LINKS ||
                    (ENABLE_AUTHENTICATION_LINKS &&
                        localStorage.getItem('ssologin') &&
                        localStorage.getItem('ssologin').toLowerCase() === 'true')
                )
                    window.location.href = '/SSORouter?sessionexpired=true';
                else window.location.href = '/';
            });
    }
}
