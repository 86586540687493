import {
    GET_CLINICIAN_LIST_GROUP,
    GET_GROUP_PERFORMANCE,
    GET_PERFORMANCE_GROUP_ENTITY_DATA,
    GET_ED_LIST_GROUP
} from '@/graphql/query';

import APIHelper from '@/helper/apihelper';
import { getJwt } from '@/utils/jwtUtils';

const api = new APIHelper();

// export const getPerformanceGroupList = permissionObj => {
//     const entityIn = [];

//     entityIn.push('Provider');

//     const queryInput = { input: { isactive: true, orderBy: 'name', entityIn: entityIn } };
//     const requestOption = api.getRequestOption(
//         GET_PERFORMANCE_GROUPS_INPUT,
//         queryInput,
//         api.Actions.View,
//         getJwt()
//     );
//     return api.PegasusAPI(api.Resources.Group, requestOption, response => response);
// };

export const getPerformanceGroupEntityData = param => {
    const queryInput = { input: { groupid: param } };
    const requestOption = api.getRequestOption(
        GET_PERFORMANCE_GROUP_ENTITY_DATA,
        queryInput,
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.Group, requestOption, response => response);
};

// export const createGroup = param => {
//     const requestOption = api.getRequestOption(
//         CREATE_PERFORMANCE_GROUP_BY_ENTITY,
//         param,
//         api.Actions.Create,
//         getJwt()
//     );
//     return api.PegasusAPI(api.Resources.Group, requestOption, response => response);
// };

export const getClinicianListEntityGroup = param => {
    const requestOption = api.getRequestOption(
        GET_CLINICIAN_LIST_GROUP,
        param,
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.Provider, requestOption, response => response);
};

export const getEDsListEntityGroup = param => {
    const fetchEDQqueryParam = {
        input: {
            inactive: false,
            orderBy: 'name',
            practicetype: 0,
            parentidIn: param.input.practiceidIn
        }
    };
    const requestOption = api.getRequestOption(
        GET_ED_LIST_GROUP,
        fetchEDQqueryParam,
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.Practice, requestOption, response => response);
};

export const getGroupMeasurePerformance = param => {
    const requestOption = api.getRequestOption(
        GET_GROUP_PERFORMANCE,
        param,
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.Performance, requestOption, response => response);
};

export const getPracticeUserByUser = param => {
    const requestOption = api.IAMGetRequestOption(param, api.Actions.View, getJwt());
    return api.PegasusAPI(
        api.Resources.IAMApi.getPracticeUserByUser,
        requestOption,
        response => response
    );
};

export const getAllPracticeUserByPractice = param => {
    const requestOption = api.IAMGetRequestOption(param, api.Actions.View, getJwt());
    return api.PegasusAPI(
        api.Resources.IAMApi.getAllPracticeUserByPractice,
        requestOption,
        response => response
    );
};

export const createUpdatePracticeUser = (isEdit, param) => {
    const requestOption = api.IAMGetRequestOption(
        param,
        isEdit ? api.Actions.Update : api.Actions.Create,
        getJwt()
    );
    return api.PegasusAPI(
        isEdit ? api.Resources.IAMApi.updatePracticeUser : api.Resources.IAMApi.createPracticeUser,
        requestOption,
        response => response
    );
};
