import { getActionCreator, getActionType, getActionTypes, getReducer } from '@/utils/redux';
// import { getLocationData } from '../services/locationPerformanceApi';
import {
    getLocationData,
    getEdsList,
    getEDsMeasureStatusAndEHRFlag
} from '@/redux/services/locationPerformanceApi';
import { FETCH_ED, EDMeasureType } from '@/helper/constants';
import { practiceDisplayFormat } from '@/helper/commonFunction';

// Types
export const LocationTypes = getActionTypes('LOCATION');
export const LocationPerformanceTypes = getActionTypes('LOCATIONPERFORMANCE');
export const LoadLocationTrendType = getActionType('LOCATIONTREND');
export const SetLocationTrendLoadingType = getActionType('SETLOCATIONTRENDLOADING');
// Actions
export const LoadLocationTrend = getActionCreator(LoadLocationTrendType);
export const SetLocationTrendLoadingAction = getActionCreator(SetLocationTrendLoadingType);

// const InputObjectGet = (param, id) => ({
//     input: {
//         ...param.input,
//         EntityId: `${id}`
//     }
// });

export const getlocations = params => {
    return {
        types: LocationTypes,
        callAPI: () =>
            FETCH_ED
                ? getEdsList({
                      input: {
                          inactive: false,
                          orderBy: 'name',
                          practicetype: 0,
                          parentid: params.locations.input.practiceid
                      }
                  })
                : getLocationData(params.locations),
        handleAction: async ({ type, payload, store }) => {
            switch (type) {
                case LocationTypes.SUCCESS:
                    let data = null;
                    if (FETCH_ED) {
                        const edExternalId = payload.getPractices.map(m => m.externalid.toString());
                        const measureId = store.getState().RegistryMeasureList.viewDetailsSelection
                            .id;
                        const response = await getEDsMeasureStatusAndEHRFlag(
                            edExternalId,
                            measureId
                        );
                        const edLevelMeasureStatusList =
                            response.data.getMeasureStatus !== null
                                ? response.data.getMeasureStatus
                                : [];
                        data =
                            payload.getPractices &&
                            payload.getPractices.map(m => {
                                const edFlag = edLevelMeasureStatusList.filter(
                                    p => p.ed_id === m.externalid.toString()
                                );
                                return {
                                    performance: {
                                        performanceData: {
                                            ...params.measureComputations.input,
                                            EDMeasureType:
                                                edFlag.length > 0
                                                    ? edFlag[0].ed_profile
                                                        ? EDMeasureType[
                                                              edFlag[0].ed_profile.toUpperCase()
                                                          ]
                                                        : 0
                                                    : 0,
                                            ParentEntityId: [m.id],
                                            EntityName: 'practice',
                                            EntityId: `${m.id}`
                                        }
                                    }
                                };
                            });
                        if (data === null) {
                            store.dispatch({ type: LoadLocationTrendType, payload: [] });
                        } else {
                            var resolveddata1 = Promise.all(data);
                            resolveddata1.then(d => {
                                var mergeArray = d.map((m, i) => {
                                    return {
                                        ...m,
                                        name: practiceDisplayFormat(payload.getPractices[i]),
                                        id: payload.getPractices[i].id
                                    };
                                });
                                store.dispatch(LoadLocationTrend(mergeArray));
                            });
                        }
                    } else {
                        data =
                            payload.getLocations &&
                            payload.getLocations.map(m => {
                                return {
                                    performance: {
                                        performanceData: {
                                            ...params.measureComputations.input,
                                            EntityId: `${m.id}`
                                        }
                                    }
                                };
                            });
                        if (data === null) {
                            store.dispatch({ type: LoadLocationTrendType, payload: [] });
                        } else {
                            var resolveddata2 = Promise.all(data);
                            resolveddata2.then(d => {
                                var mergeArray = d.map((m, i) => {
                                    return {
                                        ...m,
                                        name:
                                            payload.getLocations[i].externalid !== null
                                                ? `${payload.getLocations[i].externalid}${
                                                      payload.getLocations[i].name !== null
                                                          ? payload.getLocations[i].name &&
                                                            '-' + payload.getLocations[i].name
                                                          : ''
                                                  }`
                                                : `${payload.getLocations[i].name}`,
                                        id: payload.getLocations[i].id
                                    };
                                });
                                store.dispatch(LoadLocationTrend(mergeArray));
                            });
                        }
                    }
                    break;
                default:
                    break;
            }
        }
    };
};

const initialState = {
    isLoading: true,
    Locations: []
};

// Reducer
export default getReducer(initialState, {
    [SetLocationTrendLoadingType]: (state, { payload }) => {
        return {
            ...state,
            isLoading: payload
        };
    },
    [LocationTypes.LOADING]: state => {
        return {
            ...state,
            isLoading: true
        };
    },
    [LoadLocationTrendType]: (state, { payload }) => {
        return {
            ...state,
            Locations: payload,
            isLoading: false
        };
    },
    [LocationTypes.ERROR]: (state, { payload }) => {
        return {
            isLoading: false,
            Locations: []
        };
    }
});
