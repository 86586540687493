export const GET_PRACTICE = `query ($input:GetPracticesInput){
  getPractices (input:$input){
    id
    externalid
    name
    inactive
    system
    parentid
  }
}
`;

export const GET_LOCATION_BY_PRACTICE = `query ($input:GetPracticesInput){
  getPractices (input:$input){
    id
    externalid
    name
    address
    city	
    state
    postalcode
    inactive
    system
    parentid
  }
}
`;

export const GET_PRACTICE_PMS = `query ($input:GetPracticesInput){
  getPractices (input:$input){
    id
    externalid
    name
    inactive
    address
    city
    state
    postalcode
    country
    email
    contactnumber
    tin
    practicetype
    logo
    timezone
    createdat
    ehrdetails{
      ehrname
      ehrid
      ehrdescription
    }
  }
}`;

export const GET_DEMOGRAPHIC_DETAILS_PMS = `query ($input:GetPracticesInput){
  getPractices (input:$input){
    id
    externalid
    name
    inactive
    address
    city
    state
    postalcode
    country
    email
    contactnumber
    tin
    practicetype
    logo
    timezone
    createdat
    ehrdetails{
      ehrname
      ehrid
      ehrdescription
    }
  }
}`;

export const GET_MEASURE_SET = `query($input:GetMeasureSetsInput){
  getMeasureSets(input:$input){
    id
    name
    inactive
    isformips
    listorder
    year
  }
}`;

export const GET_CALENDAR = `query($input:GetCalendarsInput){
  getCalendars(input:$input){
    id
    year
    flag
    duration
    startdate
    enddate
  }
}`;

export const GET_MEASURES = `
query($input:GetMeasuresInput){
  getMeasures(input:$input){
    id
    isstrata
    parentmeasureid
    measuredescription
    domaindescription
    measureno
    displayname
    measuredescription
    isinverse
    IsFavorite
    rational
    ispatientspecific
    ishighpriority
    isoutcome
    isprocess
    type
    iscrosscutting
    filepath
    computationtype
    cmsno
    nqfmeasureno
    registrymeasureid
    ecqmno
    listorder
	}
}
`;

export const GET_MEASURE_PERFORMANCE = `
query($input:FilterMeasureInfoInput){
  getMeasurePerformanceAverage(input:$input){
    EntityAverage
    CMSBenchmark
    RegistryAverage
    RegistryBenchmark
    EntityComparison
    NumException
    NumExclusion
    DenomExclusion
    DenomException
    Numerator
    Denominator
    DataCompleteAverage
    NotMet
    DataIncomplete
    MeasureRefreshDate
  }
}
`;

export const GET_INSURANCE = `query($input:GetInsuranceTypesInput){
  getInsuranceTypes(input:$input){
    id
    name
    description
    isactive
    count
  }
}`;

export const GET_LOCATIONS = `
query($input:GetLocationsInput) {
getLocations(input:$input) {
  id
  name
  city
  address
  practiceid
  practicename
  practiceexternalid
  externalid
  count
  }
}
`;

export const GET_PATIENTS_DEMOGRAPHICS = `query($input:getPatientsInput){
  getPatients(input:$input){
    patientuid
    firstname
    mrn
    lastname
    dob
    gender
    visitdate
    totalrecords
  }
}`;

export const GET_PROVIDERS = `
query($input: getProvidersByPracticeIdInput!){
getProvidersByPracticeId(input:$input){
  firstname
  lastname
  id
  tin
    country
    npi
  }
}`;

export const GET_PERFORMANCE_TREND_INFO = `
query($input:FilterTrendInfoInput){
  getPerformanceTrendInfo(input:$input){
    DurationName
    EntityName
    EntityId
    ParentEntityId
    ParentEntityName
    MeasureId
    Numerator
    NumExclusion
    NumException
    Denominator
    DenomException
    DenomExclusion
    NotMet
    RegistryAverage
    MeasureAverage
    DurationFrom
    DurationTo
    ListOrder
    DataCompleteAverage
    DataIncomplete
  }
}
`;

export const GET_ALL_MEASURE_OUTPUT = `
query($input:FilterMeasureInfoInput){
  getMeasureOutputByEntity(input:$input){
    EntityName
    EntityId
    ParentEntityId
    ParentEntityName
    MeasureId
    Numerator
    Denominator
    DenomException
    DenomExclusion
    NumExclusion
    NumException
    NotMet
  }
  }`;

export const GET_PRACTICE_REFRESH_DATE = `
query($input: GetPracticeRefreshStatusInput!){
  getPracticeRefreshStatus(input:$input)
  {
    id
    startdate
    enddate
    lastrefreshtime
    practiceid
    jobid
    status
    practice
    {
      id
      name
    }
  }
}`;

export const GET_PRACTICE_PROVIDER_COUNT = `
query ($input: GetProviderCountInput!) {
  getProviderCount(input:$input)
  {
    count
  }
}
`;

export const GET_PRACTICE_LOCATION_COUNT = `
query ($input: GetLocationCountInput!)
{
  getLocationCount (input:$input){
    count
  }
}
`;

export const GET_PHYSICIAN_GROUP_ED_COUNT = `
query($input:GetPracticeChildCountInput!){
  getPracticeChildCount(input:$input){
    count
  }
}
`;

export const GET_PRACTICE_CONTACT_DETAILS = `
query($input:GetPracticeContactDetailsInput!){
  getPracticeContactDetails(input:$input){
    firstname
    middlename
    lastname
    emailaddress
    address
    mobilenumber
    inactive
  }
}
`;

export const SET_USER_FAVORITE_MEASURE = `
mutation($input:FavoriteInput!){
  setFavorite(input:$input)
}`;

export const GET_PATIENTS_BY_ENTITY = `
query($input:InputFilterTrendInfo){
  getAllPatientByEntity(input:$input){
        patient_id
        firstname
        middlename
        lastname
        dob
        mrn
        gender
        TotalRecords
        npi
        physicianfirstname
        physicianmiddlename
        physicianlastname
        visitdate
        accessionid
        patient_uid
        visit_uid
        practice_name
    }
}
`;

export const VALIDATE_REGISTRY_TOKEN = `
query($input:validateRegistryDashboardToken){
  validateRegistryDashboardToken(input:$input){
    data{
      token
      duration
    }
    statusCode
    description
  }
}
`;
export const GET_MEASURES_FOR_WEBTOOL = `
query($input:SectionListInput) {
  sectionList(input: $input) {
    formInstanceId
    data
    dataSchema
    uiSchema
    formMetaInformation
    sectionMetaInformation
    mappingMeasureNo
  }
}`;

export const GET_MEASURE_BY_ID_FOR_WEBTOOL = `
query($input: sectionInput) {
  getSection(input: $input) {
      id,
      name,
      dataSchema,
      uiSchema,
      data
  }
}`;

export const GET_WEBTOOL_MEASURES = `
  query($input: GetMeasuresInput) {
    getMeasures(input: $input) {
        id
        displayname
        measuredescription
        measureno
        rational
        cmsno
        year,
        iscrosscutting,
        isinverse,
        ishighpriority,
        isoutcome,
        isregistry,
        ismips,
        inactive,
        domaindescription,
        type,
        filepath
        cmsno
        nqfmeasureno
        registrymeasureid
        ecqmno
        isintermediateoutcome
        }
      }`;

export const GET_PROVIDER_PROFILE = `
      query($input:GetProfilesInput){
        getProfiles(input:$input){
          id
          calendarid
          practiceid
          providerid
          locationid
          tin
          tinvalidfrom
        }
      }`;

export const CREATE_PROVIDER_PROFILE = `
      mutation($input:CreateProfileInput!){
        createProfile(input:$input){
          id
        }
      }`;

export const CREATE_PREFERENCE_MEASURE = `
      mutation ($input: setMeasuresListPreferenceForProfileInput!) {
        setMeasuresListPreferenceForProfile(input: $input) {
          id
        }
      }`;

export const CREATE_ADD_VISIT = `
      mutation($input:createVisitInput!){
        createVisit(input:$input){
          VisitUid,
          PatientUid
        }
      }`;

export const UPDATE_VISIT = `
      mutation($input:updateVisitInput!){
        updateVisit(input:$input){
          VisitUid,
          PatientUid
        }
      }`;

export const GET_EDIT_VISIT = `query($input:getVisitsInput){
  getVisits(input:$input){
    FirstName,
    LastName,
    MRN,
    Gender,
    DOB,
    InsuranceType,
    VisitDate
  }
}`;

export const GET_PREFERENCE_MEASURE = `
      query($input:GetProfileMeasurePreferencesInput){
        getProfileMeasurePreferences(input:$input){
          id
          profileid
          measureid
          isactive
          measureno
        }
      }`;

export const GET_VISIT = `query ($input:getVisitsInput){
        getVisits (input:$input){
          AccessionId
          VisitUid
          PatientUid
          FirstName
          LastName
          MRN
          Gender
          Unit
          PracticeId
          DOB
          VisitDate
          Age
          InsuranceType
          VerificationDate
          ServiceDate
          DataSource
          PracticeId
          ProviderId
          LocationId
          IsActive
          TotalRecords
          EpisodeId
        }
      }`;

export const GET_PATIENTS_VISITS = `query($input:getPatientVisitsWebtoolInput){
        getPatientVisitsWebtool(input:$input){
          AccessionId
          VisitUid
          PatientUid
          FirstName
          LastName
          MRN
          Gender
          Unit
          PracticeId
          DOB
          VisitDate
          Age
          InsuranceType
          VerificationDate
          ServiceDate
          DataSource
          PracticeId
          ProviderId
          LocationId
          TotalRecords
          EpisodeId
          ModifiedDate
        }
      }`;

export const GET_PATIENT_VISIT = `query ($input:getVisitsInput){
        getVisits (input:$input){
          VisitUid
          FirstName
          LastName
          MRN
        }
      }`;

export const SINGLE_MEASURE_PERFORMANCE = `query ($input: SingleMeasurePerformanceInput) {
  singleMeasurePerformance(input: $input)
}
`;

export const FETCH_MEASURES_FOR_PI = `
query($input:SectionListInput) {
  sectionList(input: $input) {
    formInstanceId
    data
    dataSchema
    uiSchema
    formMetaInformation
    sectionMetaInformation
  }
}`;
export const ALL_MEASURE_PERFORMANCE = `query ($input: AllMeasurePerformanceInput) {
  allMeasurePerformance(input: $input)
}
`;

export const SINGLE_MEASURE__ALL_PROVIDER_PERFORMANCE = `query ($input: singleMeasureAllClinicianPerformanceInput) {
  singleMeasureAllClinicianPerformance(input: $input)
}
`;

export const GET_PATIENTS = `query ($input: filterPatients) {
  patients(input: $input)
}`;

export const GET_PROVIDERS_FOR_PMS = `query($input: getProvidersByPracticeIdInput!){
getProvidersByPracticeId(input:$input){
    id
    firstname
    middlename
  	lastname
  	npi
  	type
    contactnumber
    email
    inactive
    suffix
    prefix
    designationname
    providerSpeciality{
      Speciality{
        id
        name
        inactive
      }
    }
    membershipdetails {
      entityid
      entityname
      membershipenrollmentdate
      membershipdisenrollmentdate
      membershipid
    }
  }
}`;

export const GET_LOCATIONS_FOR_PMS = `
query($input:GetLocationsInput) {
getLocations(input:$input) {
    inactive
    id
    externalid
    name
    address
    city
    state
    postalcode
  }
}
`;
export const GET_PROVIDER_PAYMENT = `query($input : GetPaymentsInput!){
  getPayments(input:$input){
   id
    entityid
    entityname
    parententityid
    parententityname
    paymentstatus
    isactive
    paymentdate
  }
  }
`;

export const GET_DISPLAY_MEASURE_NAMES = `query($input : GetMeasuresInput){
  getMeasures(input:$input){
    id
    displayname
    measureno
    IsFavorite
    ishighpriority
    priority
    iscrosscutting
    ispatientspecific
    filepath
    measuredescription
    isinverse
  }
  }
`;

export const GET_PI_MEASURE_META_DATA = `query($input : GetMeasuresInput){
  getMeasures(input:$input){
    id
    measureno
   	displayname
   	IsFavorite
    ishighpriority
    priority
    filepath
  }
  }
`;

export const GET_PERFORMANCE_GROUPS_INPUT = `query($input:GetGroupsInput){
    getGroups(input:$input){
      id
      name
      description
      entity
      isactive
      groupentitycount
    }
  }`;

export const GET_PERFORMANCE_GROUP_ENTITY_DATA = `query($input:GetGroupEntitysInput){
    getGroupEntitys(input:$input){
      id
      groupid
      entityid
      entityname
      parententityid
      parententityname
      isactive
      group  {
         id
        name
        description
      }
      PracticeIdList
    }
  }`;

export const GET_PRACTICE_COUNT = `query {
    getPracticeCount{
        count
    }
}`;

export const CREATE_PERFORMANCE_GROUP_BY_ENTITY = `mutation($input:inputSetGroup!){
    setGroup(input:$input){
      id
    }
  }`;

export const GET_CLINICIAN_LIST_GROUP = `query ($input: getProviderPracticeDetailInput) {
    getProviderPracticeDetail(input: $input) {
        id
        npi
        firstname
        lastname
        practice {
          id
          name
          externalid
        }
      }
  }`;

export const GET_ED_LIST_GROUP = `query ($input:GetPracticesInput){
  getPractices (input:$input){
    id
    externalid
    name
    address
    city	
    state
    postalcode
    inactive
    system
    parentid      
      }
  }`;

export const GET_GROUP_PERFORMANCE = `query ($input:FilterGroupMeasureInfoInput) {
    getGroupMeasurePerformanceAverage(input:$input)
    {
      EntityAverage
      RegistryBenchmark
      EntityComparison
      EntityName
      EntityId
      ParentEntityId
      ParentEntityName
      MeasureId
      Numerator
      Denominator
      DenomExclusion
      DenomException
      NumExclusion
      NumException
      RegistryAverage
      MeasureAverage
      CMSBenchmark
      DataCompleteAverage
      NotMet
      DataIncomplete
      MeasureRefreshDate
    }
}`;

export const GET_CAREGAP_PATIENTS = `query($input:getPatientsInput){
  getPatients(input:$input){
    practiceid
    patientuid
    firstname
    lastname
    midname
    dob
    gender
    mrn
    patientemrid
    emailid
  }
}`;

export const GET_CAREGAP_PATIENT_VISITS = `query($input:getPatientVisitsInput){
  getPatientVisits(input:$input){
    firstname
    lastname
    midname
    mrn
    practiceid
    npi
    patientuid
    visitdate
    visituid
  }
}`;

export const GET_TAGS = `query($input:GetTagInput){
	getTags(input:$input){
	  id
	  tagname
	  tagvalue
	  system
	  tagcategory
	  isactive
	}
  }`;

export const GET_TAG_DETAILS = `query($input:GetTagDetailsInput){
	getTagDetails(input:$input){
	  entityid
	  entityname
	  tagconfigisactive
	  Tags{
		tagid
		tagname
		tagvalue
		system
		tagcategory
		tagisactive
	  }
	  }
  }`;

export const GET_ALL_FILES = `
  query($input:FileListInput){
    getFileList(input:$input){
    id
    fileid
    createdat
    filename
    totalrecords
    successcount
    failedcount
    status
    processedfileid
    username
    createdbyid
  }
}`;

export const GET_ALL_FILES_FOR_MEASURE_BULK_UPLOAD = `
  query($input:MeasureFileListInput){
    getMeasureFileList(input:$input){
    id
    fileid
    createdat
    filename
    totalrecords
    successcount
    failedcount
    partialcount
    status
    processedfileid
    username
    createdbyid
  }
}`;

export const GET_CURRENT_DATE_FROM_SERVER = `
  query{
   getDate
  }
`;

export const DELETE_FILE = `
query{
  deleteFile(input:"new1.csv")
}`;

export const DOWNLOAD_FILE = `
query($input:DownloadFileInput){
  downloadFile(input:$input){
    id
    path
    filename
    gcsfilename
  }
}`;

export const GET_ENTITY_STATUS_DATA = `
query($input:GetEntityAttributesInput){
  getEntityAttributess(input:$input){
    id
    status
    moduleattributeid
    entityname
    entityid
  }
}
`;

export const GET_MODULE_STATUS_DATA = `
query($input:GetModuleAttributesInput){
  getModuleAttributess(input:$input){
    id
    module
    modulekey
    attributes
    isactive
    createdat
    createdbyid
    updatedat
    updatedbyid
  }
}`;

export const UPDATE_SLIDER = `
mutation($input:SetEntityAttributesInput){
  setEntityAttributes(input:$input){
    id
  }
}
`;

export const CAREGAP_MEASURES = `
query($input:GetTagDetailsInput){
  getTagDetails(input:$input){
    entityid
    entityname
    tagconfigisactive
  }
}
`;

export const CAREGAP_MEASURE_DESCRIPTION = `
query($input:GetMeasuresInput){
  getMeasures(input:$input){
    id
    measureno
    measuredescription
    displayname
    rational
    isinverse
  }
}`;

export const CAREGAP_VISIT_SCORE_CARD = `
query($input:getVisitScoreCardInput){
  getVisitScoreCard(input:$input){
    measureid
    numerator
    initialpopulation
    numeratorexception
    denominator
    denominatorexception
    denominatorexclusion
    notmet
  }
}`;

export const CAREGAP_PATIENT_VISIT_CARE_GAP_DETAILS = `
query($input:getPatientVisitCareGapInput){
  getPatientVisitCareGap(input:$input){
    measureid
    numerator{
    element
    value
    date
  }
  numeratorexception{
    element
    value
    date
  }
  denominatorexception{
    element
    value
    date
  }
  denominatorexclusion{
    element
    value
    date
  }
  initialpopulation{
    element
    value
    date
  }
  denominator{
    element
    value
    date
  }
  }
}`;

export const GET_MEASURE_STATUS_BY_PRACTICE = `
query($input:GetMeasureStatusInput) {
    getMeasureStatus(input:$input){
    status
    measure_id
    practice_id
    }
  }
`;

export const GET_MEASURE_STATUS_AND_PROFILE_BY_ED = `
query($input:GetMeasureStatusInput) {
    getMeasureStatus(input:$input){
    status
    measure_id
    ed_profile
    ed_id
    }
  }
`;

export const ADD_LOCATION_BY_PRACTICE = `
mutation($input:CreateLocationInput!){
  createLocation(input:$input){
    id
  }
}
`;

export const UPDATE_LOCATION_BY_PRACTICE = `
mutation($input:UpdateLocationInput!){
  updateLocation(input:$input){
       id
    }
  }
`;

export const UPDATE_PRACTICE_DETAILS = `
  mutation($input:UpdatePracticeInput!){
    updatePractice(input:$input){
      id
    }
  }
`;

export const GET_ZIP_TO_CITY_STATE = `
  query($input: getPostalCodemaster){
    getpostalcodemasters(input: $input) {
    city
    state
    }
  }
`;

export const GET_ENTITY_CONTACT_DETAILS = `
query($input: getEntityContactInput){
  getEntityContacts(input:$input){
  id
  entityid
  entityname
  firstname
  middlename
  lastname
  email
  contact1
  usertype
  }
  }
`;

export const UPDATE_ENTITY_CONTACT_DETAILS = `
mutation($input: updateEntityContacts!){
  updateEntitycontact(input:$input){
  id
  }
  }
`;

export const CREATE_ENTITY_CONTACT_DETAILS = `
mutation($input: inputEntityContacts!) {
  createEntityContact(input: $input) {
     id
  }
  }
`;

export const GET_ALL_PROGRAM_DETAILS = `query($input:GetModuleAttributesInput){
  getModuleAttributess(input:$input){
    id
    module
    modulekey
    attributes
    isactive
    createdat
    createdbyid
    updatedat
    updatedbyid
  }
}`;

export const GET_PRACTICE_PROGRAM_DETAILS = `query($input:GetEntityAttributesInput){
  getEntityAttributess(input:$input){
    id
    moduleattributeid
    entityid
    entityname
    createdat
    isactive
  }
}`;

export const GET_PLANS_FOR_PIA = `query($is: FilterSubscribe, $ip: FilterPlan) {
  subscribers(input: $is) {
  id
  entityid
  entityname
  parententityid
  parententityname
  plans(input: $ip) {
  id
  type
  description
  name
  status
  startdate
  enddate
  notificationemailid
  isdeleted
  durationfrom
  durationto
  createdby
  createdat
  measures {
  id
  measureid
  measureno
  displayname
  description
  measuresetid
  prepiaentityaverage
  registryaverage
  standard_interventions
  custom_interventions
  }
  }
  }
  }
`;

export const GET_STANDARD_INTERVENTIONS = `query($input:FilterInterventions){
  interventions(input:$input){
    measureno
    standard_interventions
  }
}`;
