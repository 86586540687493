import { getActionCreator, getActionType, getActionTypes, getReducer } from '@/utils/redux';
import { getMeasuresList, setUserFavoriteMeasure } from '@/redux/services/practiceMeasureApi';
import { GetEDServiceDeskDataAction } from '@/redux/modules/locationDashboard';
import { REGISTRY_UNIT_NAME, FETCH_ED } from '@/helper/constants';
import { setDefaultDurationFilter } from '@/helper/commonFunction';

export const MeasureListTypes = getActionTypes('MEASURELIST');
export const FilterRegistryMeasureType = getActionType('FILTERREGISTRYMEASURE');
export const SetUserFavoriteMeasureTypes = getActionTypes('SETUSERFAVORITEMEASURE');
export const UpdateRegistryMeasureListType = getActionType('UPDATEREGISTRYMEASURELIST');
export const SetMeasureIdSelectionType = getActionType('SETMEASURESELECTION');
// Actions
export const SetMeasureIdSelectionAction = getActionCreator(SetMeasureIdSelectionType);
export const UpdateRegistryMeasureListAction = getActionCreator(UpdateRegistryMeasureListType);

export const SetUserFavoriteMeasureAction = params => ({
    types: SetUserFavoriteMeasureTypes,
    callAPI: () =>
        setUserFavoriteMeasure({
            input: {
                measureid: params.measureId,
                IsFavorite: !params.isFavorite
            }
        }),
    handleAction: ({ type, payload, store }) => {
        switch (type) {
            case SetUserFavoriteMeasureTypes.SUCCESS: {
                const measureList = store
                    .getState()
                    .RegistryMeasureList.registrymeasureList.filter(value => {
                        if (value.measureId === params.measureId) {
                            value.isFavourite = !value.isFavourite;
                            value.favourite.icon = value.isFavourite
                                ? ['fas', 'heart']
                                : ['fal', 'heart'];
                        }
                        return value;
                    });
                store.dispatch(UpdateRegistryMeasureListAction(measureList));
                break;
            }
            default:
                break;
        }
    }
});

export const FilterRegistryMeasureAction = getActionCreator(FilterRegistryMeasureType);

const getMeasureGridPerformanceObj = measureGrid => {
    let returnValue = [];
    // const parentStrataMeasureList = measureGrid.filter(p => p =>
    //     p.parentmeasureid === null && p.isstrata
    // );
    measureGrid.forEach(obj => {
        // let measure_id = '';
        // if (obj.isstrata && obj.parentmeasureid !== null) {
        //     const parentObj = parentStrataMeasureList.filter(p => p.id === obj.parentmeasureid);
        //     measure_id = parentObj.length > 0 ? parentObj[0].measureno : '';
        // }
        let childMeasureNo = [];
        if (obj.isstrata && obj.parentmeasureid === null)
            childMeasureNo = measureGrid.filter(p => p.parentmeasureid === obj.id && p.isstrata);
        returnValue.push({
            listorder: obj.listorder,
            isDisabled: obj.isstrata && obj.parentmeasureid === null ? false : true,
            isStrata: obj.isstrata,
            parentmeasureid: obj.parentmeasureid,
            childmeasureno: childMeasureNo.map(p => p.measureno),
            measureSDID: obj.measureno,
            // obj.isstrata === null ? obj.measureno : obj.isstrata ? measure_id : obj.measureno,
            isFavourite: obj.IsFavorite,
            computationtype: obj.computationtype ? obj.computationtype.toUpperCase() : '',
            favourite: {
                icon: obj.IsFavorite ? ['fas', 'heart'] : ['fal', 'heart']
            },
            domaindescription: obj.domaindescription,
            category: {
                isprocess: obj.isprocess,
                ishighpriority: obj.ishighpriority,
                isoutcome: obj.isoutcome,
                type: obj.type,
                domaindescription: obj.domaindescription,
                iscrosscutting: obj.iscrosscutting,
                cmsno: obj.cmsno ? obj.cmsno : 'N/A',
                nqfmeasureno: obj.nqfmeasureno ? obj.nqfmeasureno : 'N/A',
                registrymeasureid: obj.registrymeasureid ? obj.registrymeasureid : 'N/A'
            },
            measureEHRFlag: '',
            measureStatus: [],
            practiceid: '',
            id: obj.measureno,
            displayname: obj.displayname,
            filepath: obj.filepath,
            measureId: obj.id,
            measure: {
                measure: {
                    measureno: obj.measureno,
                    displayname: obj.measuredescription || '',
                    measuredescription: obj.rational,
                    isinverse: obj.isinverse || false
                },
                isStopPropagation: true
            },
            performance: {
                performanceData: {
                    EntityName: '',
                    EntityId: '',
                    ParentEntityId: '',
                    ParentEntityName: '',
                    DurationFrom: '',
                    DurationTo: '',
                    Flag: '',
                    MeasureId: '',
                    Unit: REGISTRY_UNIT_NAME,
                    IsPatientSpecific: obj.ispatientspecific
                },
                isStopPropagation: false
            },
            dataCompleteAverage: ''
        });
    });
    returnValue.sort((a, b) => a.listorder - b.listorder);
    return returnValue;
};

export const getMeasureListData = async params => {
    const queryParam = {
        input: {
            measuresetid: params.MeasuresetId,
            inactive: false,
            orderBy: 'listorder'
        }
    };
    const response = await getMeasuresList(queryParam);
    return {
        data:
            response.data.getMeasures !== null
                ? getMeasureGridPerformanceObj(response.data.getMeasures)
                : []
    };
};

export const getMeasureListAction = (params, storeObj = null, practicepayload = null) => ({
    types: MeasureListTypes,
    callAPI: () => getMeasureListData(params),
    handleAction: ({ type, payload, store }) => {
        switch (type) {
            case MeasureListTypes.SUCCESS: {
                setDefaultDurationFilter(storeObj, practicepayload, store);
                if (FETCH_ED && store.getState().LocationDashboard.location.length > 0) {
                    const year = store
                        .getState()
                        .MeasureFilter.measureFilterData.measureSet.filter(
                            p => p.id === params.MeasuresetId
                        )[0].year;
                    const measureNoList = store
                        .getState()
                        .RegistryMeasureList.registrymeasureList.map(m => m.id);
                    const edExternalID = store
                        .getState()
                        .LocationDashboard.location.filter(
                            p => p.id === store.getState().LocationDashboard.selectedLocationId
                        );
                    store.dispatch(
                        GetEDServiceDeskDataAction(edExternalID[0].externalid, measureNoList, year)
                    );
                }
                break;
            }
            default:
                break;
        }
    }
});

const initialState = {
    isRegistryMeasureListLoading: true,
    registrymeasureList: [],
    favoritefilter: false,
    viewDetailsSelection: {
        id: null,
        rowData: null,
        basePageLink: null
    }
};

// Reducer
export default getReducer(initialState, {
    [MeasureListTypes.READY]: (state, { payload }) => ({
        ...state,
        isRegistryMeasureListLoading: true
    }),
    [MeasureListTypes.SUCCESS]: (state, { payload }) => ({
        ...state,
        registrymeasureList: payload,
        isRegistryMeasureListLoading: false
    }),
    [FilterRegistryMeasureType]: (state, { payload }) => ({
        ...state,
        favoritefilter: payload
    }),
    [UpdateRegistryMeasureListType]: (state, { payload }) => ({
        ...state,
        registrymeasureList: payload
    }),
    [SetMeasureIdSelectionType]: (state, { payload }) => ({
        ...state,
        viewDetailsSelection: payload
    }),
    [MeasureListTypes.ERROR]: () => initialState
});
