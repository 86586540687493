import { GET_LOCATIONS_FOR_PMS, GET_LOCATION_BY_PRACTICE } from '@/graphql/query';
import { getJwt } from '@/utils/jwtUtils';
import APIHelper from '@/helper/apihelper';

const api = new APIHelper();

export const getLocations = params => {
    const requestOption = api.getRequestOption(
        GET_LOCATIONS_FOR_PMS,
        params,
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.Location, requestOption, response => response);
};

export const getPractices = params => {    
    const requestOption = api.getRequestOption(
        GET_LOCATION_BY_PRACTICE,
        params,
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.Practice, requestOption, response => response);
}
