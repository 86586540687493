import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Grid,
    Typography,
    FormHelperText,
    Button,
    Input,
    InputLabel,
    FormControl,
    CircularProgress
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const OtpVerificationForm = props => {
    const {
        heading,
        emaillabel,
        email,
        mobilelabel,
        mobile,
        buttonlabel,
        secleft,
        codelabel,
        codeinputtype,
        placeholder,
        name,
        error,
        inputValue,
        isProgress,
        isResendOtpProgress,
        isAccountBlock,
        stopResendOTP,
        userName,
        selectedPreference
    } = props;
  
    return (
        <Grid
            container
            justify='center'
            alignItems='center'
            className='login__contanier  otp-form__wrapper '
        >
            <Grid item className='login__signin'>
                <Grid item className='login__head'>
                    <Link to='/'>
                        <Typography variant='h4' className='login__title' component='h3'>
                            <ArrowBack name='arrow left' size='large' />
                            {heading}
                        </Typography>
                    </Link>
                </Grid>
                <Grid item className='otp-form__mail-label'>
                    <FormHelperText>{emaillabel}</FormHelperText>
                </Grid>
                <Grid item className='otp-form__mail'>
                    <Typography variant='h6' className='otp-form__email__h6'>
                        {userName}
                    </Typography>
                </Grid>
                <Grid item className='otp-form__phone-lable'>
                    <FormHelperText>{mobilelabel}</FormHelperText>
                </Grid>
                <Grid item className='otp-form__phone'>
                    {selectedPreference==='mobile' && <Typography variant='h6'>
                        <FontAwesomeIcon icon={['fal', 'mobile-alt']} className='phone__icon' />
                        <span className='mobile-num'>{mobile}</span>
                    </Typography>}
                    {selectedPreference==='email' && <Typography variant='h6'>
                        <FontAwesomeIcon icon={['fal', 'envelope']} className='phone__icon' />
                        <span className='mobile-num'>{email}</span>
                    </Typography>}
                    {!isAccountBlock ? (
                        <Fragment>
                            <Button
                                variant='contained'
                                color='primary'
                                className='otp-form--resend__otp'
                                onClick={props.onSubmitResendOTP}
                                disabled={stopResendOTP}
                            >
                                {buttonlabel}
                            </Button>
                        </Fragment>
                    ) : null}

                    {isResendOtpProgress ? (
                        <CircularProgress size={20} />
                    ) : (
                        <Grid item xs className='timer_container'>
                            {!isAccountBlock && secleft > 0 ? (
                                <span className='sec-timer__span'>{secleft + ' '} </span>
                            ) : null}
                            {!isAccountBlock && secleft > 0 ? (
                                <span className='sec-left__span'> sec left</span>
                            ) : null}
                        </Grid>
                    )}
                </Grid>

                <Grid item className='otp-form__code'>
                    <FormControl className='otp-form__content' error={error ? true : false}>
                        <InputLabel>{codelabel}</InputLabel>
                        <Input
                            type={codeinputtype}
                            placeholder={placeholder}
                            name={name}
                            onChange={props.onChange}
                            onKeyUp={props.onKeyUp}
                            value={inputValue}
                        />
                        {isProgress ? <CircularProgress size={20} /> : null}
                    </FormControl>
                </Grid>
                {error ? (
                    <Grid item xs={12} className='error__wrapper'>
                        <FormHelperText className='error__text'> {error} </FormHelperText>
                    </Grid>
                ) : null}
            </Grid>
        </Grid>
    );
};

OtpVerificationForm.defaultProps = {
    heading: '2 Step Verification',
    emaillabel: "This extra step shows it's really you trying to login.",
    email: 'abc@xyz.com',
    mobilelabel: 'Click on send OTP to receive 6 digit verification OTP.',
    mobile: '23********678',
    buttonlabel: 'Resend OTP',
    secleft: 55,
    codelabel: 'Enter code',
    label: 'Enter code',
    name: 'otp',
    placeholder: 'Enter code',
    codeinputtype: 'password',
    onKeyUp: () => {
        // console.log(("onHandleChnage is executed:" + event + " : " + value);
    },
    onChange: () => {
        // console.log(("onHandleChnage is executed:" + event + " : " + value);
    },
    error: '',
    onSubmitResendOTP: () => {
        // console.log();
    },
    inputValue: '',
    isProgress: false,
    isResendOtpProgress: false,
    isAccountBlock: false,
    stopResendOTP: false
};

/* PROP TYPES
================================================================ */
OtpVerificationForm.propTypes = {
    heading: PropTypes.string,
    emaillabel: PropTypes.string,
    email: PropTypes.string,
    mobilelabel: PropTypes.string,
    mobile: PropTypes.string,
    buttonlabel: PropTypes.string,
    secleft: PropTypes.number,
    codelabel: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    codeinputtype: PropTypes.string,
    error: PropTypes.string,
    inputValue: PropTypes.string,
    isProgress: PropTypes.bool,
    isResendOtpProgress: PropTypes.bool,
    isAccountBlock: PropTypes.bool,
    stopResendOTP: PropTypes.bool,
    onSubmit: PropTypes.func,
    onKeyUp: PropTypes.func,
    onChange: PropTypes.func,
    onSubmitResendOTP: PropTypes.func
};

export default OtpVerificationForm;
