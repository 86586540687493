import { GroupDashboardTab } from '@/helper/constants';
import {
    GET_PERFORMANCE_GROUPS_INPUT,
    GET_PERFORMANCE_GROUP_ENTITY_DATA,
    CREATE_PERFORMANCE_GROUP_BY_ENTITY,
    GET_CLINICIAN_LIST_GROUP,
    GET_GROUP_PERFORMANCE
} from '@/graphql/query';
import APIHelper from '@/helper/apihelper';
import { getJwt } from '@/utils/jwtUtils';
import { FETCH_ED } from '@/helper/constants';

const api = new APIHelper();

export const getPerformanceGroupList = permissionObj => {
    const entityIn = [];
    if (permissionObj.filter(tab => tab.resource === GroupDashboardTab.PRACTICETAB).length === 1) {
        entityIn.push('Practice');
    }
    if (permissionObj.filter(tab => tab.resource === GroupDashboardTab.CLINICIANTAB).length === 1) {
        entityIn.push('Provider');
    }
    if (permissionObj.filter(tab => tab.resource === GroupDashboardTab.LOCATIONTAB).length === 1) {
        if (FETCH_ED) entityIn.push('ED');
        else entityIn.push('Location');
    }
    const queryInput = { input: { isactive: true, orderBy: 'name', entityIn: entityIn } };
    const requestOption = api.getRequestOption(
        GET_PERFORMANCE_GROUPS_INPUT,
        queryInput,
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.Group, requestOption, response => response);
};

export const getPerformanceGroupEntityData = param => {
    const queryInput = { input: { groupid: param } };
    const requestOption = api.getRequestOption(
        GET_PERFORMANCE_GROUP_ENTITY_DATA,
        queryInput,
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.Group, requestOption, response => response);
};

export const createGroup = param => {
    const requestOption = api.getRequestOption(
        CREATE_PERFORMANCE_GROUP_BY_ENTITY,
        param,
        api.Actions.Create,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.Group, requestOption, response => response);
};

export const getClinicianListEntityGroup = param => {
    const requestOption = api.getRequestOption(
        GET_CLINICIAN_LIST_GROUP,
        param,
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.Provider, requestOption, response => response);
};

export const getGroupMeasurePerformance = param => {
    const requestOption = api.getRequestOption(
        GET_GROUP_PERFORMANCE,
        param,
        api.Actions.View,
        getJwt()
    );
    return api.PegasusAPI(api.Resources.Performance, requestOption, response => response);
};
